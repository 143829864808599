import 'braft-editor/dist/index.css'
import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber, Image} from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils';
import Service from '../../../Service/index';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import officialNewsService from '../../../Service/Operation/officialNewsService';


const { Option } = Select;
const { TextArea } = Input;

/**
 * 更新
 */
class UpdateOfficialNews extends Component {
    formRef = React.createRef();

  state={
    loading: false,
    content: null,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props; 
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appOfficialNews = {
            id: values.id,
            title: values.title,
            introduction: values.introduction,
            content: this.state.content,
            logo: values.logo,
            publishStatus: values.publishStatus,
            pushStatus: 0,
            pushNum: 0,
            num: 0
          }
          officialNewsService.insertOrUpdateOfficialNews(appOfficialNews)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };


  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表

  };

  handleEditorChange = (content) => {
    console.log("content="+JSON.stringify(content.toHTML()));

    this.setState({
      content: content.toHTML()
    })
  }

  render () {
    return (
      <Modal
        title="修改"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={1000}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="消息标题" name="title"  labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="消息标题" />
            </Form.Item>

            <Form.Item label="消息简介" labelCol={{span:10}} name="introduction" style={{ width: 420 }} >
                <Input placeholder="消息简介" />
            </Form.Item>

            <Form.Item label="发布状态" labelCol={{span:10}} name="publishStatus" style={{ width: 420 }} initialValue={0}>
                <Radio.Group name="publishStatus" defaultValue={0}>
                    <Radio value={0}>未发布</Radio>
                    <Radio value={1}>已发布</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="图片" name="logo" 
                valuePropName="file"
                labelCol={{span:10}} 
                style={{ width: 420 }}
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                    defaultFileList={[
                        {
                            url: this.props.rowData != null ? this.props.rowData.logo : null,
                            thumbUrl: this.props.rowData != null ? this.props.rowData.logo : null,
                        }
                      ]}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="内容" name="content"  >
              <BraftEditor
                  defaultValue={BraftEditor.createEditorState(this.state.content != null ? this.state.content : (this.props.rowData != null ? this.props.rowData.content : null))}
                  value={this.state.content}
                  onChange={this.handleEditorChange}
                  style={{ border: '1px solid #dedede', marginTop: 10 }}
                  placeholder="请输入正文内容"
              />
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default UpdateOfficialNews