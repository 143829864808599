import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import withdrawalRulesService from "../../../Service/FinanceMgr/withdrawalRulesService";


const { Option } = Select;
const { TextArea } = Input;

/**
 * 添加用户任务
 */
class AddIosWithdrawal extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appWithdrawalRules = values;
          withdrawalRulesService.insertOrUpdateWithdrawalRules(appWithdrawalRules)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  
  }



  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="排序" name="orderno" labelCol={{span:10}} style={{ width: 420 }}>
                <InputNumber />
            </Form.Item>

            <Form.Item label="名称" name="name" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="名称" />
            </Form.Item>

            <Form.Item label="充值类型" labelCol={{span:10}} name="appType" style={{ width: 420 }} initialValue={2} >
                <Select style={{ width: 220 }} defaultValue={2} >
                    <Option value={2}>苹果</Option>
                </Select>
            </Form.Item>

            <Form.Item label="映票数量" name="ticket" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="映票数量" />
            </Form.Item>
  
            <Form.Item label="苹果价格" name="moneyIos" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="苹果价格" />
            </Form.Item>

            <Form.Item label="苹果项目ID" name="productId" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="苹果项目ID" />
            </Form.Item>

            <Form.Item label="是否启用" name="isTip" labelCol={{span:10}} style={{ width: 420 }}>
                <Radio.Group name="isTip"  >
                    <Radio value={0}>不启用</Radio>
                    <Radio value={1}>启用</Radio>
                </Radio.Group>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddIosWithdrawal