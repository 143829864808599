import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber} from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import vipService from '../../../Service/NobleMgr/vipService';


const { Option } = Select;
const { TextArea } = Input;

/**
 * 更新用户任务
 */
class UpdateNoble extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        //console.log(JSON.stringify(values));

        var usersVipParam = {
          userId: values.userId,
          vipId: values.vipId,
        }
        
        vipService.giveVip(usersVipParam)
        .then(res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("添加失败")
              message.info("添加失败,"+res.message);  
              // 重置关闭对话框
              this.hanldeCloseModal();
            } else {
              message.info('添加成功！');
              // 重置关闭对话框
              this.hanldeCloseModal();
              // 刷新数据
              this.props.refreshData();
            }
        })
    })

}
  

  render () {
    return (
      <Modal
        title="修改"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}            
        >
            <Form.Item label="UID" name="userId" labelCol={{span:10}} style={{ width: 420 }}>
                <InputNumber />
            </Form.Item>

            <Form.Item label="贵族套餐" name="vipId" labelCol={{span:10}} style={{ width: 420 }}
              initialValue={this.props.rowData != null ? this.props.rowData.vipList[0].id : null}>
              <Select style={{ width: 220 }} defaultValue={this.props.rowData != null ? this.props.rowData.vipList[0].id : null}>
                {this.props.rowData == null ? null : this.props.rowData.vipList.map((item)=>
                  <Option key={item.id} value={item.id}>{item.name}{item.length}个月</Option>
                )}
              </Select>
            </Form.Item>


        </Form>
      </Modal>
    )
  }
}

export default UpdateNoble