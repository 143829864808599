import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取收支明细
   * @param {*} 
   * @returns
   */
   getFinTranRecordPage(finTranRecordParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/finTranRecord/getFinTranRecordPage",
      data: finTranRecordParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 插入收支明细
   * @param {*} 
   * @returns
   */
    insertFinTranRecord(finTranRecord) {
        //console.log("commonWordsParam=" + JSON.stringify());
        return axios({
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
          },
          method: "post",
          url: "/admin/finTranRecord/insertFinTranRecord",
          data: finTranRecord,
        }).then((res) => {
          //console.log("retValue=" + JSON.stringify(res));
          return res.data;
        });
    },
    
}