import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取银币转金币列表
   * @param {*} 
   * @returns
   */
  getSilverExchangeRulePage(silverExchangeRuleParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/silverExchangeRule/getSilverExchangeRulePage",
      data: silverExchangeRuleParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入或更新银币转换金币
   * @param {*} 
   * @returns
   */
  insertOrUpdateSilverExchangeRule(silverExchangeRule) {
      //console.log("commonWordsParam=" + JSON.stringify());
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/silverExchangeRule/insertOrUpdateSilverExchangeRule",
        data: silverExchangeRule,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
  },

  /**
   * 删除银币转换金币
   * @param {*} 
   * @returns
   */
  deleteSilverExchangeRule(silverExchangeRuleParam) {
        //console.log("commonWordsParam=" + JSON.stringify());
        return axios({
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
          },
          method: "post",
          url: "/admin/silverExchangeRule/deleteSilverExchangeRule",
          data: silverExchangeRuleParam,
        }).then((res) => {
          //console.log("retValue=" + JSON.stringify(res));
          return res.data;
        });
    },
}