import React, {Component } from 'react';
import {Menu} from 'antd';

import {
  HomeOutlined,
  UserOutlined,
  StarOutlined,
  VideoCameraOutlined,
  SolutionOutlined,
  GiftOutlined,
  DesktopOutlined,
  MoneyCollectOutlined,
  IdcardOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { UsergroupAddOutlined,ShoppingCartOutlined } from '@ant-design/icons';
import { getLoginUserAllPer, Logout } from '../../Common/Auth';


const { SubMenu } = Menu;

class MenuBar extends Component {
  // 参数
  state = {
    current: '',
    perMenu: [],   // 所有当前用户拥有的菜单类型的权限
    openKeys: [],
    rootSubmenuKeys:[]
  }

  componentDidMount() {
    let { history } = this.props;
    // 加载当前登录用户的所有权限
    // service.loadUserAllPer(GetLoginUserInfo().id)
    getLoginUserAllPer()
      .then(res => {
        //console.log("getLoginUserAllPer=" + JSON.stringify(res));
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        } 
        if(res.retValue != null) {
          this.setState({perMenu: res.retValue.filter(m => m.type === 'menu')});
        }
      })
  }

  /**
   * 菜单点击事件
   */
  handleMenuClick=(e)=> {
    console.log(e);
    this.setState({current: e.key});
    this.props.history.push(`/home/${e.key}`)
  }

  /**
   * 获取对象
   * @param {*} label 
   * @param {*} key 
   * @param {*} icon 
   * @param {*} children 
   * @returns 
   */
  getItem=(label,key,iconString,children) => {
    var icon = this.getIcon(iconString);

    return {
      label,
      key,
      icon,
      children
    }
  }

  /**
   * 图标
   * @param {*} icon 
   * @returns 
   */
  getIcon=(icon) => {
    if(icon == "HomeOutlined") {
      return < HomeOutlined/>
    } else if(icon == "UserOutlined") {
      return < UserOutlined/>
    } else if(icon == "StarOutlined") {
      return < StarOutlined/>
    } else if(icon == "VideoCameraOutlined") {
      return < VideoCameraOutlined/>
    } else if(icon == "SolutionOutlined") {
      return < SolutionOutlined/>
    } else if(icon == "GiftOutlined") {
      return <GiftOutlined />
    } else if(icon == "DesktopOutlined") {
      return <DesktopOutlined />
    } else if(icon == "MoneyCollectOutlined") {
      return <MoneyCollectOutlined />
    } else if(icon == "IdcardOutlined") {
      return <IdcardOutlined />
    } else if(icon == "SettingOutlined") {
      return <SettingOutlined />
    } 
  }

  onOpenChange=(keys)=> {
    //console.log("onOpenChange keys=" + JSON.stringify(keys));
    
    const latestOpenKey = keys.find((key) => this.state.openKeys.indexOf(key) === -1);
    //console.log("onOpenChange latestOpenKey=" + JSON.stringify(latestOpenKey));

    if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) !== -1) {
      this.setState({openKeys: keys});
    } else {
      this.setState({openKeys: latestOpenKey ? [latestOpenKey] : []});
    }
    //console.log("onOpenChange openKeys=" + JSON.stringify(this.state.openKeys));
  }


  render=()=> {
    var MenuList = [];
    let rootMenu = this.state.perMenu.filter(m => m.pid == 0);
    rootMenu.sort((a, b) => a.orderNum - b.orderNum);
    // 主目录
    rootMenu.map(rootM => {
      let childMenus = this.state.perMenu.filter(m => m.pid == rootM.id);
      //console.log("childMenus=" + JSON.stringify(childMenus));
      childMenus.sort((a, b) => a.orderNum - b.orderNum);
      // 子目录
      var SubMenu = [];
      if(childMenus != null && childMenus != []) {
        childMenus.map(childM => {
          SubMenu.push(this.getItem(childM.des, childM.url, null, null));
        })
      }
      MenuList.push(this.getItem(rootM.des, rootM.id, rootM.icon, SubMenu));

      this.state.rootSubmenuKeys.push(rootM.id);
    })

    return (
       <div className="aside-menu-bar">
        <Menu 
          openKeys={this.state.openKeys}
          onOpenChange={this.onOpenChange}
          onClick={this.handleMenuClick} 
          // selectedKeys={[this.state.current]}
          mode="inline"
          items={MenuList}
        >
        </Menu>
      </div> 
      // <div className="aside-menu-bar">
      //   <Menu 
      //     onClick={e=>this.handleMenuClick(e)} 
      //     selectedKeys={[this.state.current]}
      //     mode="inline">
      //       <SubMenu title={
      //         <span>
      //           <UsergroupAddOutlined />
      //           后台管理
      //         </span>
      //       }>
      //           <Menu.Item key="user_mgr">用户管理</Menu.Item>
      //           <Menu.Item key="role_mgr">角色管理</Menu.Item>
      //           <Menu.Item key="per_mgr">权限管理</Menu.Item>
      //       </SubMenu>

      //       <SubMenu title={
      //         <span>
      //           <ShoppingCartOutlined />
      //           商铺管理
      //         </span>
      //       }>
      //           <Menu.Item key="goods_mgr">商品管理</Menu.Item>
      //           <Menu.Item key="order_mgr">订单管理</Menu.Item>
      //       </SubMenu>
      //   </Menu>
      // </div>
    );
  }
}

export default MenuBar;