import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Row, Col, Checkbox} from 'antd';
import appUserService from '../../../Service/UserMgr/appUserService';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';

class DisableUser extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var user=GetLoginUserInfo();
          let disableUserParam = {
            disableSource : 1,
            userId : values.userid,
            optId : user.userId,
            typeList : values.type,
            disableTime : values.disableTime,
            reason : values.reason,
          };

          appUserService.disableUser(disableUserParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  }


  render () {
    return (
      <Modal
        title="重置密码"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="用户ID" name="userid">
                <Input placeholder="用户ID" disabled />
            </Form.Item>

            <Form.Item label="禁用类型" name="type" >
              <Checkbox.Group>
                <Row>
                  <Col span={8}>
                    <Checkbox value="1" style={{ lineHeight: '32px' }}>
                      禁止登录
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="2" style={{ lineHeight: '32px' }}>
                      禁止直播
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="3" style={{ lineHeight: '32px' }}>
                      禁止发布内容
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="4" style={{ lineHeight: '32px' }}>
                      禁止评论
                    </Checkbox>
                  </Col>
                  <Col span={8}>
                    <Checkbox value="5" style={{ lineHeight: '32px' }}>
                      禁止直播间发言
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Form.Item label="禁用时间" name="disableTime" >
              <Input placeholder="-1:永久禁用；大于0的数字:禁用天数" />
            </Form.Item>

            <Form.Item label="禁用原因" name="reason" >
              <Input.TextArea placeholder="禁用原因" />
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default DisableUser