import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取官方消息
   * @param {*} officialNewsParam
   * @returns
   */
   getOfficialNewsPage(officialNewsParam) {
      //console.log("officialNewsParam=" + JSON.stringify(officialNewsParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/officialNewsAdmin/getOfficialNewsPage",
        data: officialNewsParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新官方消息
   * @param {*} appOfficialNews
   * @returns
   */
   insertOrUpdateOfficialNews(appOfficialNews) {
        //console.log("sysNotic=" + JSON.stringify(appOfficialNews));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/officialNewsAdmin/insertOrUpdateOfficialNews",
        data: appOfficialNews,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除官方消息
     * @param {*} officialNewsParam 
     * @returns 
     */
     deleteOfficialNews(officialNewsParam) {
        //console.log("sysNoticParam=" + JSON.stringify(officialNewsParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/officialNewsAdmin/deleteOfficialNews",
        data: officialNewsParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}