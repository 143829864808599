import React, { Component } from 'react'
import { Form, Input, Upload, Button, message } from 'antd';
import { UserOutlined,SnippetsOutlined } from '@ant-design/icons';
import { GetLoginToken } from '../../../Common/Auth';

class EditRoleFrm extends Component {
    formRef = React.createRef();

    // 页面第一次渲染时执行
    componentDidMount=() => {
        console.log('data=' +  JSON.stringify(this.props.rowData));
        this.formRef.current.setFieldsValue(this.props.rowData);
    }

    render () {
        return (
            <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>
            
            <Form.Item label="角色名" name="name" rules={ [
                    {
                        min: 2,
                        message: '请输入大于2个字符!',
                    },
                    {
                        required: true,
                        message: '请输入角色名!',
                    }
                    ]
                }>
                <Input prefix={<UserOutlined />} 
                    placeholder="角色名"
                />
            </Form.Item>

            <Form.Item label="角色编码" name="roleCode" rules={ [
                    {
                        min: 2,
                        message: '请输入大于2个字符!',
                    },
                    {
                        required: true,
                        message: '请输入角色编码!',
                    }
                    ]
                }>
                <Input prefix={<UserOutlined />} 
                    placeholder="角色编码"
                />
            </Form.Item>

            <Form.Item label="描述" name="des">
                <Input prefix={<SnippetsOutlined />} 
                    placeholder="描述"
                />
            </Form.Item>

        </Form>
        )
    }
}

export default EditRoleFrm