import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 插入守护
   * @param {*} appGuardUsers
   * @returns
   */
    insertOrUpdateGuardUsers(appGuardUsers) {
      //console.log("appGuardUsersParam=" + JSON.stringify(appGuardUsersParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/guardUsers/insertOrUpdateGuardUsers",
        data: appGuardUsers,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取守护列表
   * @param {*} appGuardUsers
   * @returns
   */
  getGuardUsersPage(appGuardUsers) {
      //console.log("appGuardUsersParam=" + JSON.stringify(appGuardUsersParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/guardUsers/getGuardUsersPage",
        data: appGuardUsers,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 删除
   * @param {*} appGuardUsers
   * @returns
   */
  deleteGuardUsers(appGuardUsers) {
    //console.log("appGuardUsersParam=" + JSON.stringify(appGuardUsersParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/guardUsers/deleteGuardUsers",
      data: appGuardUsers,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },



}