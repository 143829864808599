import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 更新用户余额展示配置
   * @param {*} oooCoinDisplaySettings
   * @returns
   */
   updateConfig(oooCoinDisplaySettings) {
      //console.log("oooCoinDisplaySettings=" + JSON.stringify(oooCoinDisplaySettings));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/coinDisplaySettings/updateConfig",
        data: oooCoinDisplaySettings,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取用户余额展示配置
   * @param {*} 
   * @returns
   */
   getConfig() {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/coinDisplaySettings/getConfig",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


}