import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserId, Logout } from '../../../Common/Auth';
import moment from 'moment'
import adminUserCashrecordService from '../../../Service/Admin/adminUserCashrecordService';
import adminUserCashAccountService from '../../../Service/MyAgent/adminUserCashAccountService';
import userService from '../../../Service/Admin/userService';


import AddMyWallet from './AddMyWallet';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class MyWallet extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 我的钱包 ————————————————————————————
    myWalletColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id',
      colSpan:0,
      render:()=>{return {props:{colSpan:0}};}
    },{
      key: 'type',
      title: '账号类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "我的提现账号"
        } else if(type === 2) {
          return "微信";
        } else if(type === 3) {
          return "银行卡";
        }
      }
    },{
      key: 'account',
      title: '账号',
      dataIndex: 'account'
    },{
      key: 'name',
      title: '真实姓名',
      dataIndex: 'name',
    },{
      key: 'money',
      title: '提现金额',
      dataIndex: 'money',
    },{
      key: 'service',
      title: '服务费',
      dataIndex: 'service',
    },{
      key: 'actualMoney',
      title: '实际到账金额',
      dataIndex: 'actualMoney',
    },{
      key: 'addtime',
      title: '申请时间',
      dataIndex: 'addtime',
    },{
      key: 'uptime',
      title: '审核时间',
      dataIndex: 'uptime',
    },{
      key: 'status',
      title: '状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "审核中"
        } else if(status === 1) {
          return "审核通过";
        } else if(status === 2) {
          return "审核拒绝";
        }
      }
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks',
    }],
    myWalletTotal: 0,
    myWalletList: null,
    addMyWalletRow: null,
    showAddMyWalletDialog: false,
    myWalletPageParam:{
      uid: GetLoginUserId(),
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 我的钱包————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var userId = GetLoginUserId();
    var myWalletParam = {
      uid : userId,
      pageParam : pageParam,
    }
    adminUserCashrecordService.getAdminUserCashrecordPage(myWalletParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({myWalletList: res.retValue.list, myWalletTotal: res.retValue.total});
        }                  
      }
    )

    userService.getAgentMoney()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef.current.setFieldsValue({
            totalMoney: res.retValue
          });
        }                  
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 我的钱包 ————————————————————————————

  // 获取等级礼物
  handleGetMyWalletPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 20
      }

      var userId = GetLoginUserId();
      var myWalletParam = {
        uid : userId,
        pageParam : pageParam,
      }
      adminUserCashrecordService.getAdminUserCashrecordPage(myWalletParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({myWalletList: res.retValue.list, myWalletTotal: res.retValue.total});
          }                  
        }
      )
      
    })
  }

  // 用户分页
  myWalletChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var userId = GetLoginUserId();
        var myWalletParam = {
          uid : userId,
          pageParam : pageParam,
        }

        this.setState({
          myWalletPageParam: myWalletParam,
        });

        adminUserCashrecordService.getAdminUserCashrecordPage(myWalletParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({myWalletList: res.retValue.list, myWalletTotal: res.retValue.total});
            }                  
          }
        )
    })
  }

  // 刷新数据
  refreshMyWalletData=() => {
    //console.log("refreshMyWalletData");
    let { history } = this.props;

    var myWalletParam = this.state.myWalletPageParam;

    adminUserCashrecordService.getAdminUserCashrecordPage(myWalletParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({myWalletList: res.retValue.list, myWalletTotal: res.retValue.total});
        }                  
      }
    )
  }

  // 隐藏
  hideAddMyWalletDialog=() => {
    this.setState({showAddMyWalletDialog: false});
  }

  /**
   * 获取账户数据
   * @param {*} options 
   */
  getAddMyWalletRowData = () => {
      let { history } = this.props;

      var userId = GetLoginUserId();
      var adminUserCashAccount = {
        uid : userId
      }
      adminUserCashAccountService.getCashAccountList(adminUserCashAccount)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          
          this.state.addMyWalletRow = {
            cashAccountList : res.retValue
          }

          //console.log("addGiftPackRow=" + JSON.stringify(this.state.addGiftPackRow))
          this.setState({showAddMyWalletDialog:true })
        }                  
          
      })
       
  };
  
  
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/my_wallet">我的钱包</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="MyWalletListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={4} key={1} style={{ textAlign: 'right' }}>
                      <Form.Item label="佣金余额(元）：" name="totalMoney" labelCol={{span:20}}>
                          <InputNumber bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetMyWalletPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.getAddMyWalletRowData()}  >
                        + 提现
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.myWalletList}
                columns={this.state.myWalletColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.myWalletTotal, defaultCurrent: 1, onChange: this.myWalletChangePage}}
              >
              </Table>
              <AddMyWallet rowData={this.state.addMyWalletRow} close={this.hideAddMyWalletDialog} visible={this.state.showAddMyWalletDialog} refreshData={this.refreshMyWalletData}></AddMyWallet>

        </StickyContainer>
        
      </div>
    );
  }
}
export default MyWallet;