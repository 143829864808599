import React, {Component, Fragment } from 'react';
import { BankTwoTone, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import './top.scss'; 
import { GetLoginUserInfo, Logout } from '../../Common/Auth';
import { Modal } from 'antd';


class Top extends Component {
  state = { loginUser: GetLoginUserInfo() }
  hanldeLogout = () =>{
    let { history } = this.props;
    Modal.confirm({
      title: '提示',
      content: '您确认要退出吗？',
      okText: '退出',
      cancelText: '取消',
      onOk: () => {
        // 清除登录信息
        Logout();
        // 跳转到登录页面
        history.push('/login');
      }
    })
  }
  render () {
    return (
      <Fragment>
        <div className="logo-wrap components-top">
          <a href="/">
            <h1 style={{color: '#fff', fontSize: '30px'}}>
              <BankTwoTone />
              同城交友
            </h1>
          </a>
        </div>
        <div className="user-wrap components-top">
          <div className="btn-group">
            <UserOutlined />
            <span>{ this.state.loginUser.userName }</span>
          </div>
          <div className="btn-group" onClick={this.hanldeLogout}>
            <LogoutOutlined />
            登出
          </div>
        </div>
      </Fragment>
    )
  }
}

export default Top