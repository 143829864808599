import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 更新动态
   * @param {*} appUserVideo
   * @returns
   */
   insertOrUpdateSimulationMssage(appSimulationMssage) {
      //console.log("appSimulationMssage=" + JSON.stringify(appSimulationMssage));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/simulationMssage/insertOrUpdateSimulationMssage",
        data: appSimulationMssage,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取动态列表
   * @param {*} 
   * @returns
   */
   getSimulationMssagePage(simulationMssageParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/simulationMssage/getSimulationMssagePage",
      data: simulationMssageParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除模拟消息
   * @param {*} 
   * @returns
   */
   deleteSimulationMssage(simulationMssageParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/simulationMssage/deleteSimulationMssage",
      data: simulationMssageParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}