import React from 'react';
import Service from "../Service/index";
import userPrivilegeService from '../Service/Admin/userPrivilegeService.js';

// 当前登录用户的appkey
const APP_LOGIN_USER ='APP_LOGIN_USER';
const AUTHORIZATION ='Authorization';

/**
 * 校验当前用户是否已经登录
 * @returns { Boolean } 如果已经登录：true, 否则返回false 
 */
export function AuthLogin() {
    // sessionStorage 存储当前的登录信息
    let token = sessionStorage.getItem(AUTHORIZATION);
    if(token == null) {
        return false;
    }
    return true;
}

/**
 * 存储当前用户登录信息
 * @reaturn
 */
export function SaveLoginUserInfo(user) {
    //console.log("SaveLoginUserInfo="+ JSON.stringify(user));
    let userData = {
        userId : user.userid,
        userName: user.userName
    }
    let userString = JSON.stringify(userData)
    sessionStorage.setItem(APP_LOGIN_USER, userString);
}

/**
 * 获取当前用户登录信息
 */
export function GetLoginUserInfo() {
    let userStr = sessionStorage.getItem(APP_LOGIN_USER);
    if(userStr) {
        let userData = JSON.parse(userStr);
        //console.log("userName="+ userData.userName);
        return userData;
    } 
    return null;
}

export function GetLoginUserId() {
    let userStr = sessionStorage.getItem(APP_LOGIN_USER);
    if(userStr) {
        let userData = JSON.parse(userStr);
        //console.log("userName="+ userData.userName);
        return userData.userId;
    } 
    return null;
}

export function GetLoginUserName() {
    let userStr = sessionStorage.getItem(APP_LOGIN_USER);
    if(userStr) {
        let userData = JSON.parse(userStr);
        //console.log("userName="+ userData.userName);
        return userData.userName;
    } 
    return null;
}

/**
 * 当前用户登出，处理后续的相关信息
 * @param {*} user 
 * @returns 
 */
export function Logout() {
    // 清空用户登录信息
    sessionStorage.clear();
}

/**
 * 保存用户登录后台的token信息
 * @param {*} token 
 */
export function SaveLoginToken(token) {
    sessionStorage.setItem(AUTHORIZATION, token);
}

/**
 * 获取用户登录后台的token信息 
 */
export function GetLoginToken() {
    return sessionStorage.getItem(AUTHORIZATION);
}

/** 
 * 获取当前登录用户的所有权限
 * @return {Promise} 对象内部返回当前登录用户的所有权限
*/
export function getLoginUserAllPer() {
    // 拿到当前登录用户的id
    let userId = GetLoginUserInfo().userId;
    //  先从缓存中获取当前登录用户的所有权限。如果有直接返回
    let loginUserPerStr = sessionStorage.getItem('LOGIN_USER_PER');
    //console.log("loginUserPerStr="+ loginUserPerStr);

    if(loginUserPerStr != null && loginUserPerStr != "" && loginUserPerStr != "{}") {
        /* console.log("进入,"+ loginUserPerStr);
        let userPerList = Promise.resolve(JSON.parse(loginUserPerStr));
        userPerList.then(function (data) {
            console.log(data);
            console.log('这是成功操作');
            data.forEach(element => {
                console.log("element="+JSON.stringify(element));
            });
        }
        )  */
        return Promise.resolve(JSON.parse(loginUserPerStr));
    }
    //console.log("未进入, userId="+ userId);
    // 如果没有 才发送ajax请求获取当前登录用户的所有权限
    //console.log("data="+ JSON.stringify(data));
    return userPrivilegeService.getPrivilegeListByUserId(userId)
        .then(res => {
        //console.log("UserPrivilegeAdminApi_getPrivilegeListByUserId="+ JSON.stringify(res));

        if(res != null && res.retCode === 200) {
            sessionStorage.setItem('LOGIN_USER_PER', JSON.stringify(res));
            return res;
        } else {
            sessionStorage.setItem('LOGIN_USER_PER', null);
            return res;
        }
    })
}