import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {
  /**
   * 获取角色分页
   * @param {*} key 
   * @param {*} pageParam 
   * @returns 
   */
  getRolePage(key, pageParam) {
    let data = {
      key: key,
      pageParam: pageParam,
    };

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/role/getRolePage",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入或更新角色
   * @param {*} roleParam 
   * @returns 
   */
  insertOrUpdateRole(roleParam) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/role/insertOrUpdateRole",
      data: roleParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除角色
   * @param {*} id 
   * @returns 
   */
  deleteRoleById(id) {
    let data = {
      id: id,
    };

    return axios({
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/role/deleteRoleById",
      data: Qs.stringify(data),
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 批量删除角色
   * @param {*} idList 
   * @returns 
   */
  deleteRoleByIdList(idList) {

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/role/deleteRoleByIdList",
      data: idList,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
};
