import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class ShowLogView extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  
  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        width={1000}
        footer={[
          null, null,
        ]}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="模块名称" labelCol={{span:10}} name="module" style={{ width: 420 }}>
                <Input placeholder="模块名称" bordered={false} readOnly/>
            </Form.Item>

            <Form.Item label="执行方法" labelCol={{span:5}} name="method" style={{ width: 840 }}>
                <TextArea bordered={false} readOnly/>
            </Form.Item>

            <Form.Item label="错误等级" labelCol={{span:10}} name="level" style={{ width: 420 }}>
                <Input placeholder="错误等级" bordered={false} readOnly/>
            </Form.Item>

            <Form.Item label="错误消息" labelCol={{span:5}} name="msg" style={{ width: 840 }}>
                <TextArea style={{ height: 300 }} bordered={false} readOnly/>
            </Form.Item>

           
        </Form>
      </Modal>
    )
  }
}

export default ShowLogView