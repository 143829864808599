import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 更新配置
   * @param {*} admodSettings
   * @returns
   */
  updateAdmodConfig(admodSettings) {
      //console.log("vipParam=" + JSON.stringify(vipParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/admodSettingsAdmin/updateAdmodConfig",
        data: admodSettings,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 获取配置
   * @returns
   */
  getAdmodSettings() {
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/admodSettingsAdmin/getAdmodSettings",
        data: null,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


}