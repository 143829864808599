import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 插入更新常用语
   * @param {*} appCommonWords
   * @returns
   */
   insertOrUpdateCommonWords(appCommonWords) {
      //console.log("appCommonWords=" + JSON.stringify(appCommonWords));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/commonWords/insertOrUpdateCommonWords",
        data: appCommonWords,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取常用语列表
   * @param {*} 
   * @returns
   */
   getCommonWordsPage(commonWordsParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/commonWords/getCommonWordsPage",
      data: commonWordsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除常用语
   * @param {*} 
   * @returns
   */
   deleteCommonWords(commonWordsParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/commonWords/deleteCommonWords",
      data: commonWordsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}