import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取等级礼包
   * @param {*} gradeRewarReParam
   * @returns
   */
   getGradeRewarRePage(gradeRewarReParam) {
      //console.log("gradeRewarReParam=" + JSON.stringify(gradeRewarReParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradeRewarRe/getGradeRewarRePage",
        data: gradeRewarReParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入或更新等级礼包
   * @param {*} appGradeReWarRe
   * @returns
   */
   insertGradeRewarRe(appGradeReWarRe) {
        //console.log("appGradeReWarRe=" + JSON.stringify(appGradeReWarRe));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradeRewarRe/insertGradeRewarRe",
        data: appGradeReWarRe,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

  /**
   * 插入或更新等级礼包
   * @param {*} appGradeReWarRe
   * @returns
   */
   deleteGradeRewarRe(gradeRewarReParam) {
      //console.log("gradeRewarReParam=" + JSON.stringify(gradeRewarReParam));
      return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradeRewarRe/deleteGradeRewarRe",
        data: gradeRewarReParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}