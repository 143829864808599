import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import liveConfigService from '../../../Service/1vs1Mgr/liveConfigService';
import liveChannelService from '../../../Service/1vs1Mgr/liveChannelService';
import coinDisplaySettingsService from '../../../Service/1vs1Mgr/coinDisplaySettingsService';
import AddLiveChannel from './AddLiveChannel';
import UpdateLiveChannel from './UpdateLiveChannel';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class OnevsOneSetting extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state={
    // ———————————————————— 导航分类 ————————————————————————————
    liveChannelColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'title',
      title: '频道名称',
      dataIndex: 'title'
    },{
      key: 'sort',
      title: '排序',
      dataIndex: 'sort'
    },{
      key: 'isTip',
      title: '启动状态',
      dataIndex: 'isTip',
      render: (isTip, row) => {
        if(isTip === 0) {
          return "不启用"
        } else if(isTip === 1) {
          return "启用";
        } 
      }
    },{
      key: 'type',
      title: '类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 3) {
          return "一对一"
        } 
      }
    },{
      key: 'num',
      title: '数量累计',
      dataIndex: 'num'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateLiveChannelDialog:true, updateLiveChannelRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
            </div>
          )
      }
    }],
    liveChannelTotal: 0,
    liveChannelList: [],
    updateLiveChannelRow: null,
    showUpdateLiveChannelDialog: false,
    addLiveChannelRow: null,
    showAddLiveChannelDialog: false,
    liveChannelPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 通话设置 ————————————————————————————
    callSettings: null,
  


    // ———————————————————— 免费通话时长设置 ————————————————————————————
    talkTime: null,


    // ———————————————————— 用户余额显示 ————————————————————————————
    coinDisplay: null,

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 导航分类 ————————————————————————————
    this.getLiveChannel();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 导航分类 ————————————————————————————

  // 关闭添加用户弹框
  hideAddLiveChannelDialog=() => {
    this.setState({showAddLiveChannelDialog: false});
  }

  // 关闭编辑用户弹框
  hideUpdateLiveChannelDialog=() => {
    this.setState({showUpdateLiveChannelDialog: false});
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  liveChannelChangePage(page, pageSize) {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      liveChannelPageParam: pageParam,
    });

    var liveChannelParam={
      pageParam: pageParam
    }

    liveChannelService.getLiveChannelPage(liveChannelParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({liveChannelList: res.retValue.list, liveChannelTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新导航数据
   */
  refreshLiveChannelData=() => {
      let { history } = this.props;

      var liveChannelParam={
        pageParam: this.state.liveChannelPageParam
      }

      liveChannelService.getLiveChannelPage(liveChannelParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({liveChannelList: res.retValue.list, liveChannelTotal: res.retValue.total});
          }
        }
      )
  }

// ———————————————————— 通话设置 ————————————————————————————



  /**
   * 一对一
   */
   handleCallSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef2.current.validateFields()
    .then(values => {

        var oooLiveConfig = values;

        liveConfigService.updateConfig(oooLiveConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }



// ———————————————————— 免费通话时长设置 ————————————————————————————

  /**
   * 一对一
   */
   handleTalkTimeSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef3.current.validateFields()
    .then(values => {

        var oooLiveConfig = values;

        liveConfigService.updateConfig(oooLiveConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

// ———————————————————— 导航分类 ————————————————————————————
 
  /**
   * 一对一
   */
  handleCoinDisplaySubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef4.current.validateFields()
    .then(values => {

        var oooLiveConfig = values;

        coinDisplaySettingsService.updateConfig(oooLiveConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
   
  }

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getLiveChannel();
    } else if(key == "2") {
      this.getLiveConfig1();
    } else if(key == "3") {
      this.getLiveConfig2();
    } else if(key == "4") {
      this.getCoinDisplaySettings();
    }
  }

  /**
   * 导航分类
   */
  getLiveChannel=() => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var liveChannelParam={
      pageParam: pageParam
    }

    liveChannelService.getLiveChannelPage(liveChannelParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({liveChannelList: res.retValue.list, liveChannelTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 通话设置
   */
  getLiveConfig1=() => {
    let { history } = this.props;
    liveConfigService.getConfig()
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.formRef2.current.setFieldsValue({
              id: res.retValue.id,
              homePageSwitch: res.retValue.homePageSwitch,
              homeShowAddr: res.retValue.homeShowAddr,
              authIsNoble: res.retValue.authIsNoble,
              mTalkUu: res.retValue.mTalkUu,
              mTalkUa: res.retValue.mTalkUa,
              mTalkAa: res.retValue.mTalkAa,
              fTalkUu: res.retValue.fTalkUu,
              fTalkUa: res.retValue.fTalkUa,
              fTalkAa: res.retValue.fTalkAa,
              dTalkUu: res.retValue.dTalkUu,
              freeCallUu: res.retValue.freeCallUu,
              callPriceUu: res.retValue.callPriceUu,
              callIncomeRatioUu: res.retValue.callIncomeRatioUu,
              fTalkNeedAuth: res.retValue.fTalkNeedAuth,
              fTalkAnswerAuth: res.retValue.fTalkAnswerAuth,
              takeAnchorContact: res.retValue.takeAnchorContact,
              chatRoomAnchorContact: res.retValue.chatRoomAnchorContact,
              homeAnchorContact: res.retValue.homeAnchorContact,
              isComment: res.retValue.isComment,
              lessCoin: res.retValue.lessCoin,
              femaleAnchorLookCity: res.retValue.femaleAnchorLookCity,
              callFreeUser: res.retValue.callFreeUser,
            });

            this.setState({callSettings: res.retValue});
          }
        }
      )
  }

  /**
   * 免费通话时长设置
   */
  getLiveConfig2=() => {
    let { history } = this.props;
    liveConfigService.getConfig()
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.formRef3.current.setFieldsValue({
              id: res.retValue.id,
              registerCallSecond: res.retValue.registerCallSecond,
              registerCallTime: res.retValue.registerCallTime,
              chatCardNum: res.retValue.chatCardNum,
              chatCardPrice: res.retValue.chatCardPrice,
              chatCardDayNum: res.retValue.chatCardDayNum,
            });
            this.setState({talkTime: res.retValue});
          }
        }
      )   
  }

  /**
   * 用户余额显示
   */
  getCoinDisplaySettings=() => {
    let { history } = this.props;
    coinDisplaySettingsService.getConfig()
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.formRef4.current.setFieldsValue({
              id: res.retValue.id,
              iosCoinShow: res.retValue.iosCoinShow,
              androidCoinShow: res.retValue.androidCoinShow,
              androidLiveDisplay: res.retValue.androidLiveDisplay,
              iosLiveDisplay: res.retValue.iosLiveDisplay,
              androidChatSquareDisplay: res.retValue.androidChatSquareDisplay,
              iosChatSquareDisplay: res.retValue.iosChatSquareDisplay,
            });
            this.setState({coinDisplay: res.retValue});
          }
        }
      )
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/1vs1_setting">1VS1设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 导航分类 ————————————————————————————
            <TabPane tab="导航分类" key="1">
              <Form
                name="LiveChannelListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24}>

                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddLiveChannelDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.liveChannelList}
                columns={this.state.liveChannelColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.liveChannelTotal, defaultCurrent: 1, onChange: this.liveChannelChangePage}}
              >
              </Table>
              <AddLiveChannel close={this.hideAddLiveChannelDialog} visible={this.state.showAddLiveChannelDialog} refreshData={this.refreshLiveChannelData}></AddLiveChannel>
              <UpdateLiveChannel rowData={this.state.updateLiveChannelRow} close={this.hideUpdateLiveChannelDialog} visible={this.state.showUpdateLiveChannelDialog} refreshData={this.refreshLiveChannelData}></UpdateLiveChannel>
              
            </TabPane>
            
            // ———————————————————— 通话设置 ——————————————————————
            <TabPane tab="通话设置" key="2">
                <Form
                  name="CallSettingsForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.callSettings}
                >

                      <Form.Item label="id" name="id" hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="首页默认大小图切换" name="homePageSwitch" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="homePageSwitch" defaultValue={0}>
                          <Radio value={0}>大图</Radio>
                          <Radio value={1}>小图</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="首页地址" name="homeShowAddr" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="homeShowAddr" defaultValue={0}>
                          <Radio value={0}>隐藏</Radio>
                          <Radio value={1}>显示</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="用户开通贵族才能发起通话" name="authIsNoble" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="authIsNoble" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="男性用户与男性用户通话" name="mTalkUu" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="mTalkUu" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="男性用户与男性主播通话" name="mTalkUa" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="mTalkUa" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="男性主播与男性主播通话" name="mTalkAa" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="mTalkAa" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="女性用户与女性用户通话" name="fTalkUu" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="fTalkUu" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="女性用户与女性主播通话" name="fTalkUa" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="fTalkUa" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="女性主播与女性主播通话" name="fTalkAa" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="fTalkAa" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="男用户与女用户通话" name="dTalkUu" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="dTalkUu" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="用户之间通话收费" name="freeCallUu" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="freeCallUu" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="用户之间通话价格" name="callPriceUu" labelCol={{span:10}}  style={{ width: 390 }}>
                          <Input placeholder="用户之间通话价格" />
                      </Form.Item>

                      <Form.Item label="用户之间通话收益比例" name="callIncomeRatioUu" labelCol={{span:10}}  style={{ width: 390 }} >
                          <Input placeholder="用户之间通话收益比例" />
                      </Form.Item>

                      <Form.Item label="女性用户发起通话需要认证" name="fTalkNeedAuth" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="fTalkNeedAuth" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="女性用户接听通话需要认证" name="fTalkAnswerAuth" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="fTalkAnswerAuth" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="一对一查看主播联系方式" name="takeAnchorContact" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="takeAnchorContact" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="私信查看主播联系方式" name="chatRoomAnchorContact" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="chatRoomAnchorContact" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="个人主页查看主播联系方式" name="homeAnchorContact" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="homeAnchorContact" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="1v1评论开关" name="isComment" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="isComment" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="最低通话金币" name="lessCoin" labelCol={{span:10}}  style={{ width: 450 }}>
                          <Input placeholder="最低通话金币" />
                      </Form.Item>

                      <Form.Item label="女主播显示用户位置" name="femaleAnchorLookCity" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="femaleAnchorLookCity" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="通话免费用户" name="callFreeUser" labelCol={{span:10}}  style={{ width: 450 }} >
                        <TextArea placeholder="通话免费用户,英文逗号隔开" />
                      </Form.Item>
  
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleCallSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>

            
            </TabPane>

            // —————————————————————— 免费通话时长设置 ————————————————————————
            <TabPane tab="免费通话时长设置" key="3">

                <Form
                  name="TalkTimeForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.talkTime}
                >

                      <Form.Item label="id" name="id" hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="注册赠送通话次数" name="registerCallSecond" labelCol={{span:10}}  style={{ width: 390 }}>
                          <InputNumber />
                      </Form.Item>

                      <Form.Item label="注册赠送通话时长(分钟)" name="registerCallTime" labelCol={{span:10}}  style={{ width: 390 }} >
                          <InputNumber />
                      </Form.Item>

                      <Form.Item label="注册赠送聊天卡数" name="chatCardNum" labelCol={{span:10}}  style={{ width: 390 }} >
                          <InputNumber />
                      </Form.Item>

                      <Form.Item label="聊天卡价格" name="chatCardPrice" labelCol={{span:10}}  style={{ width: 390 }} >
                          <InputNumber />
                      </Form.Item>

                      <Form.Item label="每日赠送聊天卡数" name="chatCardDayNum" labelCol={{span:10}}  style={{ width: 390 }} >
                          <InputNumber />
                      </Form.Item>
  
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleTalkTimeSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
              
            </TabPane>

            // —————————————————————— 用户余额显示 ————————————————————————
            <TabPane tab="用户余额显示" key="4">
              <Form
                  name="CoinDisplayForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef4}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.coinDisplay}
                >

                      <Form.Item label="id" name="id" hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="ios金币显示总开关" name="iosCoinShow" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="iosCoinShow" defaultValue={0}>
                          <Radio value={0}>不显示</Radio>
                          <Radio value={1}>显示</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="android金币显示总开关" name="androidCoinShow" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="androidCoinShow" defaultValue={0}>
                          <Radio value={0}>不显示</Radio>
                          <Radio value={1}>显示</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="通话直播间余额显示(安卓)" name="androidLiveDisplay" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="androidLiveDisplay" defaultValue={0}>
                          <Radio value={0}>不显示</Radio>
                          <Radio value={1}>显示</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="通话直播间余额显示(IOS)" name="iosLiveDisplay" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="iosLiveDisplay" defaultValue={0}>
                          <Radio value={0}>不显示</Radio>
                          <Radio value={1}>显示</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="男士聊场余额显示(安卓)" name="androidChatSquareDisplay" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="androidChatSquareDisplay" defaultValue={0}>
                          <Radio value={0}>不显示</Radio>
                          <Radio value={1}>显示</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="男士聊场余额显示(IOS)" name="iosChatSquareDisplay" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Radio.Group name="iosChatSquareDisplay" defaultValue={0}>
                          <Radio value={0}>不显示</Radio>
                          <Radio value={1}>显示</Radio>
                        </Radio.Group>
                      </Form.Item>
  
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleCoinDisplaySubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>

            </TabPane>



          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default OnevsOneSetting;