import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import appUserService from '../../../Service/UserMgr/appUserService';
import AddCustomer from './AddCustomer';
import EditCustomer from './EditCustomer';
import ResetPwd from '../OrdinaryUsers/ResetPwd';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class CustomerList extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 客服列表 ————————————————————————————

    showEditAnthorDialog: false,
    userSelectRowKeys:[],
    showAddAnthorDialog: false,
    anthortotal: 0,
    anthorlist: null,
    params: {pageNum:1, pageSize: 10},
    anthorColumns: [{
      key: 'avatar',
      title: '客服信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '客服性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 0) {
          return "未设置"
        } else if(sex === 1) {
          return "男";
        } else if(sex === 2) {
          return "女";
        }
      }
    },{
      key: 'coin',
      title: '剩余金币',
      dataIndex: 'coin'
    },{
      key: 'consumption',
      title: '累计消费金币',
      dataIndex: 'consumption'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'votestotal',
      title: '累计收益映票',
      dataIndex: 'votestotal'
    },{
      key: 'pid',
      title: '上级推荐人',
      dataIndex: 'pid'
    },{
      key: 'agentId',
      title: '上级代理',
      dataIndex: 'agentId'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'source',
      title: '注册来源',
      dataIndex: 'source'
    },{
      key: 'phoneFirm',
      title: '手机厂商',
      dataIndex: 'phoneFirm'
    },{
      key: 'phoneModel',
      title: '手机型号',
      dataIndex: 'phoneModel'
    },{
      key: 'appVersion',
      title: 'APP版本',
      dataIndex: 'appVersion'
    },{
      key: 'appVersionCode',
      title: 'APP版本code',
      dataIndex: 'appVersionCode'
    },{
      key: 'ipaddr',
      title: '最后登录IP',
      dataIndex: 'ipaddr'
    },{
      key: 'lastLoginTime',
      title: '最后登录时间',
      dataIndex: 'lastLoginTime'
    },{
      key: 'onlineStatus',
      title: '真实在线状态',
      dataIndex: 'onlineStatus',
      render: (onlineStatus, row) => {
        if(onlineStatus === 0) {
          return "离线"
        } else if(onlineStatus === 1) {
          return "在线";
        }
      }
    },{
      key: 'toDayOnlineTime',
      title: '今日在线时长',
      dataIndex: 'toDayOnlineTime'
    },{
      key: 'status',
      title: '账号状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "正常"
        } else if(status === 1) {
          return "禁用";
        }
      }
    },{
      key: 'province',
      title: '省份',
      dataIndex: 'province'
    },{
      key: 'city',
      title: '城市',
      dataIndex: 'city'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        if(row.status == 0) {
          return (
            <div>
              <Button 
                onClick={() => this.getEditAnthorRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Button 
                onClick={() => this.setState({showResetPwdDialog:true, resetPwdRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">重置密码 
              </Button>
            </div>
          )
        } else {
          return (
            <div>
              <Button 
                onClick={() => this.getEditAnthorRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Button 
                onClick={() => this.setState({showResetPwdDialog:true, resetPwdRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">重置密码 
              </Button>
            </div>
          )
        }
        
      }
    }],
    resetPwdRow: null,
    showResetPwdDialog: false,
    disableAnthorRow: null,
    showDisableAnthorDialog: false,
    enableAnthorRow: null,
    showEnableAnthorDialog: false,
    addAnthorRow: null,
    editAnthorRow: null,
    updateRoleRow: null,
    showUpdateRoleDialog: false,
    anthorPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

  }


  // 第一次渲染后调用
  componentDidMount() {
      let { history } = this.props;
      // ———————————————————— 客服列表 ————————————————————————————
      this.getAppUser();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 客服列表 ————————————————————————————


  // 获取客服用户
  handleGetAnchorUser=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
       //console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var anchorUserParam = {
        userid: values.userid,
        mobile: values.mobile,
        username: values.username,
        role: 2,
        onlineStatus: values.onlineStatus,
        status: values.state,
        pid: values.pid,
        coinStart: values.coinStart,
        coinEnd: values.coinEnd,
        ipaddr: values.ipaddr,
        pageParam: pageParam,
      }

      appUserService.getCustomerPage(anchorUserParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({anthorlist: res.retValue.list, anthortotal: res.retValue.total});
          }                  
        }
      )
       
    })
  }

  // 用户分页
  anthorChangePage=(page, pageSize) => {
      //console.log("changePage 进入");
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          var pageParam = {
            pageNum: page,
            pageSize: pageSize,
          }

          this.setState({
            anthorPageParam: pageParam,
          });

          var anchorUserParam = {
            userid: values.userid,
            mobile: values.mobile,
            username: values.username,
            role: 2,
            onlineStatus: values.onlineStatus,
            status: values.state,
            pid: values.pid,
            coinStart: values.coinStart,
            coinEnd: values.coinEnd,
            ipaddr: values.ipaddr,
            pageParam: pageParam,
          }
    
          appUserService.getCustomerPage(anchorUserParam)
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                message.info("查询失败,"+res.message);  
              } else {
                this.setState({anthorlist: res.retValue.list, anthortotal: res.retValue.total});
              }                  
            }
          )

      });

    }

  // 关闭添加用户弹框
  hideAddAnthorDialog=() => {
    this.setState({showAddAnthorDialog: false});
  }

  // 关闭编辑用户弹框
  hideEditAnthorDialog=() => {
    this.setState({showEditAnthorDialog: false});
  }

  // 关闭密码重置弹框
  hideResetPwdDialog=() => {
    this.setState({showResetPwdDialog: false});
  }

  // 关闭禁用弹窗
  hideDisableAnthorDialog=() => {
    this.setState({showDisableAnthorDialog: false});
  }

  // 启动禁用弹窗
  hideEnableAnthorDialog=() => {
    this.setState({showEnableAnthorDialog: false});
  }

  // 启动禁用弹窗
  hideUpdateRoleDialog=() => {
    this.setState({showUpdateRoleDialog: false});
  }

  // 刷新数据
  refreshAnthorData=() => {
      //console.log("refreshAnthorData进入");
      let { history } = this.props;

      var anchorUserParam = {
        role: 2,
        pageParam: this.state.anthorPageParam,
      }

      appUserService.getCustomerPage(anchorUserParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({anthorlist: res.retValue.list, anthortotal: res.retValue.total});
          }                  
        }
      )
  }

  // 根据Id删除用户
  deleteUser=(userid) => {
      let { history } = this.props;
      let admin = GetLoginUserInfo();
      let appUser = {
         userid : userid,
         adminid : admin.userId,
      };
      appUserService.deleteUser(appUser)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshUserData();
          message.success('删除成功！');
        }                  
        
      })
  }


  /**
   * 获取分成方案列表
   * @param {*} options 
   */
  getAddAnthorRowData = () => {
      let { history } = this.props;
      // 获取分配比例列表
      appUserService.getAnchorAdminConfig()
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          
          this.state.addAnthorRow = {
            dealScalePlanList : res.retValue.dealScalePlanList,
            anchorStarList: res.retValue.anchorStarList,
            liveChannelList: res.retValue.liveChannelList,
          }

          this.setState({showAddAnthorDialog:true})
        }                  
          
      })
     
  };


    /**
   * 获取分成方案列表
   * @param {*} options 
   */
    getEditAnthorRowData = (row) => {
      let { history } = this.props;
      // 获取分配比例列表
      appUserService.getAnchorAdminConfig()
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          
          this.state.editAnthorRow = {
            dealScalePlanList : res.retValue.dealScalePlanList,
            anchorStarList: res.retValue.anchorStarList,
            liveChannelList: res.retValue.liveChannelList,
            userid: row.userid,
            role: row.role,
            avatar: row.avatar,
            username: row.username,
            mobile: row.mobile,
            password: row.password,
            sex: row.sex,
            signature: row.signature,
            status: row.status,
            portrait: row.portrait,
            dealScalePlan: row.dealScalePlan,
            isRecommend: row.isRecommend,
            starId: row.starId,
            isOOOAccount: row.isOOOAccount,
            isShowHomePage: row.isShowHomePage,
            headNo: row.headNo,
            oooHomePageSortNo: row.oooHomePageSortNo,
            videoCoin: row.videoCoin,
            voiceCoin: row.voiceCoin,
            poster: row.poster,
            video: row.video,
            videoImg: row.videoImg,
            voice: row.voice,
          }

          //console.log("editAnthorRow=" + JSON.stringify(this.state.editAnthorRow))
          this.setState({showEditAnthorDialog:true })
        }                  
          
      })
     
  };
  


  getDisableAnthorInfo=(userid) => {
    //console.log("getDisableUserInfo 进入，userid="+userid);
    let { history } = this.props;
     var appUser = {
       userid : userid
     }
     appUserService.getDisableUserInfo(appUser)
     .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        //console.log("getDisableUserInfo res="+JSON.stringify(res));
        this.setState({showEnableAnthor2Dialog:true, enableAnthor2Row: res.retValue})
      }                  
       
     });
   }

  

 

  // ———————————————————————————— tab分页 ——————————————————————————————————


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getAppUser();
    }
  }

  /**
   * 客服列表
   */
  getAppUser = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var anchorUserParam = {
      role: 2,
      pageParam: pageParam,
    }

    appUserService.getCustomerPage(anchorUserParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({anthorlist: res.retValue.list, anthortotal: res.retValue.total});
        }                  
      }
    )
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/customer_list">客服列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            
            // ———————————————————— 客服列表 ——————————————————————
            <TabPane tab="客服列表" key="1">
              
              <Form
                name="customerListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="UID" name="userid">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="手机号码" name="mobile">
                          <Input placeholder="手机号码" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="昵称" name="username">
                          <Input placeholder="昵称" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={5}>
                      <Form.Item label="账号状态" name="status">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>正常</Option>
                              <Option value={1}>禁用</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={6}>
                      <Form.Item label="在线状态" name="onlineStatus">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>离线</Option>
                              <Option value={1}>在线</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={8}>
                      <Form.Item label="上级id" name="pid">
                          <Input placeholder="上级id" />
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetAnchorUser} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                    <Button type="primary" onClick={this.getAddAnthorRowData}  >
                      + 新增客服
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.anthorlist}
                columns={this.state.anthorColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.anthortotal, defaultCurrent: 1, onChange: this.anthorChangePage}}
              >
              </Table>
              <AddCustomer rowData={this.state.addAnthorRow} close={this.hideAddAnthorDialog} visible={this.state.showAddAnthorDialog} refreshData={this.refreshAnthorData}></AddCustomer>
              <EditCustomer rowData={this.state.editAnthorRow} close={this.hideEditAnthorDialog} visible={this.state.showEditAnthorDialog} refreshData={this.refreshAnthorData}></EditCustomer>
              <ResetPwd rowData={this.state.resetPwdRow} close={this.hideResetPwdDialog} visible={this.state.showResetPwdDialog} ></ResetPwd>
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default CustomerList;