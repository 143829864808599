import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {
    /**
     * 获取用户注销分页
     * @param {*} userOldId 
     * @param {*} mobile 
     * @param {*} pageParam 
     * @returns 
     */
   getLogOffUserPage(userOldId, 
        mobile, 
        pageParam) {
    let data = {
        userOldId: userOldId,
        mobile: mobile,
        pageParam: pageParam
    };
    //console.log("data=" + JSON.stringify(data));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/logOffUser/getLogOffUserPage",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 根据id删除
   * @param {*} appLogOffUser
   * @returns
   */
  deleteById(appLogOffUser) {
    //console.log("appUser=" + JSON.stringify(appUser));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/logOffUser/deleteById",
      data: appLogOffUser,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


}