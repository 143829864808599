import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import MD5 from 'crypto-js/md5'
import userService from '../../../Service/Admin/userService';
import Service from '../../../Service/index';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class UserPassword extends Component {
  formRef = React.createRef();
  state={

    // ———————————————————— 修改密码 ————————————————————————————
    userPassword: null,
  

  }


  // 第一次渲染后调用
  componentDidMount() {

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 修改密码 ————————————————————————————



  /**
   * 一对一
   */
   handleUserPasswordSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        if(values.password != values.password2) {
          message.info('两次密码不一致');
          return ;
        }

        var userData = GetLoginUserInfo();
        var userParam = {
          id: userData.userId,
          oldPassword: values.oldPassword,
          password: values.password,
        }
        userService.updatePasswordById(userParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
            }
    
          }
        )
    })

  }

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/user_password">修改密码</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>            
            <Form
              name="UserPasswordForm"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 100px'}}
              initialValues={this.state.userPassword}
            >

                  <Form.Item label="原密码" name="oldPassword" labelCol={{span:10}}  style={{ width: 420 }}>
                      <Input.Password placeholder="原密码" />
                  </Form.Item>

                  <Form.Item label="新密码" name="password" labelCol={{span:10}}  style={{ width: 420 }}>
                      <Input.Password placeholder="新密码" />
                  </Form.Item>


                  <Form.Item label="确认密码" name="password2" labelCol={{span:10}}  style={{ width: 420 }}>
                      <Input.Password placeholder="确认密码" />
                  </Form.Item>

               
            </Form>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.handleUserPasswordSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
              <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
            </div>

            
        </StickyContainer>
        
      </div>
    );
  }
}
export default UserPassword;