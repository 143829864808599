import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取扩列信息
   * @param {*} kuolie
   * @returns
   */
  getKuoliePage(kuolie) {
      //console.log("vipParam=" + JSON.stringify(vipParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/kuolieAdmin/getKuoliePage",
        data: kuolie,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新扩列信息
   * @param {*} kuolie
   * @returns
   */
  insertOrUpdateKuolie(kuolie) {
        //console.log("appVip=" + JSON.stringify(appVip));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/kuolieAdmin/insertOrUpdateKuolie",
        data: kuolie,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

  /**
   * 获取启用的贵族列表
   * @param {*} kuolie
   * @returns
   */
  updateTime(kuolie) {
    //console.log("vipParam=" + JSON.stringify(vipParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/kuolieAdmin/updateTime",
      data: kuolie,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除扩列
   * @returns 
   */
  deleteKuolie(kuolie) {
    //console.log("vipParam=" + JSON.stringify(vipParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/kuolieAdmin/deleteKuolie",
      data: kuolie,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}