import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import commonWordsService from '../../../Service/PrivateLetter/commonWordsService';

import AddCommonWords from './AddCommonWords';
import UpdateCommonWords from './UpdateCommonWords';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class CommonWords extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 财富等级 ————————————————————————————
    commonWordsColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '常用语',
      dataIndex: 'name'
    },{
      key: 'type',
      title: '类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "通用打招呼"
        } else if(type === 2) {
          return "女生话题"
        } else if(type === 3) {
          return "男生话题"
        }
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateCommonWordsDialog:true, updateCommonWordsRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteCommonWords(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    commonWordsTotal: 0,
    commonWordsList: null,
    updateCommonWordsRow: null,
    showUpdateCommonWordsDialog: false,
    addCommonWordsRow: null,
    showAddCommonWordsDialog: false,
    commonWordsPageParam:{
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 获取等级礼物 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var commonWordsParam = {
      pageParam : pageParam,
    }
    commonWordsService.getCommonWordsPage(commonWordsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({commonWordsList: res.retValue.list, commonWordsTotal: res.retValue.total});
        }                  
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 等级礼物列表 ————————————————————————————

  // 获取等级礼物
  handleGetCommonWordsPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 20
      }

      var commonWordsParam = {
        key : values.key,
        pageParam : pageParam,
      }

      this.setState({
        commonWordsPageParam: commonWordsParam,
      });

      commonWordsService.getCommonWordsPage(commonWordsParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({commonWordsList: res.retValue.list, commonWordsTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }

  // 用户分页
  commonWordsChangePage=(page, pageSize) => {
    //console.log("commonWordsChangePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));

        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var commonWordsPageParam = {
          key : values.key,
          pageParam : pageParam,
        }

        this.setState({
          commonWordsPageParam: commonWordsPageParam,
        });

        var commonWordsParam = {
          pageParam : pageParam,
        }
        commonWordsService.getCommonWordsPage(commonWordsParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({commonWordsList: res.retValue.list, commonWordsTotal: res.retValue.total});
            }                  
          }
        )
    })
  }

  // 刷新数据
  refreshCommonWordsData=() => {
    let { history } = this.props;
    //console.log("refreshCommonWordsData");

    var commonWordsParam = {
      pageParam : this.state.commonWordsPageParam,
    }
    commonWordsService.getCommonWordsPage(commonWordsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({commonWordsList: res.retValue.list, commonWordsTotal: res.retValue.total});
        }                  
      }
    )
  }

  // 隐藏
  hideUpdateCommonWordsDialog=() => {
    this.setState({showUpdateCommonWordsDialog: false});
  }

  // 隐藏
  hideAddCommonWordsDialog=() => {
    this.setState({showAddCommonWordsDialog: false});
  }

  // 根据Id删除常用语
  deleteCommonWords=(id) => {
      let { history } = this.props;
      let CommonWordsParam = {
        id : id
      };
      commonWordsService.deleteCommonWords(CommonWordsParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshCommonWordsData();
          message.success('删除成功！');
        }                  
        
      })
  }
  
  
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/common_words">常用语</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="CommonWordsListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="搜索关键词" name="key">
                          <Input placeholder="搜索关键词" />
                      </Form.Item>
                    </Col>

                    <Col span={3} key={2} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetCommonWordsPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>

                    <Col span={2} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddCommonWordsDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.commonWordsList}
                columns={this.state.commonWordsColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.commonWordsTotal, defaultCurrent: 1, onChange: this.commonWordsChangePage}}
              >
              </Table>
              <AddCommonWords close={this.hideAddCommonWordsDialog} visible={this.state.showAddCommonWordsDialog} refreshData={this.refreshCommonWordsData}></AddCommonWords>
              <UpdateCommonWords rowData={this.state.updateCommonWordsRow} close={this.hideUpdateCommonWordsDialog} visible={this.state.showUpdateCommonWordsDialog} refreshData={this.refreshCommonWordsData}></UpdateCommonWords>

        </StickyContainer>
        
      </div>
    );
  }
}
export default CommonWords;