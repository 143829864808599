import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber } from 'antd';
import { SafetyOutlined, UploadOutlined } from '@ant-design/icons';
import { Logout } from '../../../Common/Auth';
import userService from '../../../Service/Admin/userService';
import Service from '../../../Service/index';


const { Option } = Select;
const { TextArea } = Input;

class UpdateProxyPassword extends Component {
  formRef = React.createRef();
  state={
    loading: null,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var agentUser = {
            id : values.id,
            apkUrl : values.apkUrl,
            apkVersion : values.apkVersion,
          }
          userService.insertOrUpdateUser(agentUser)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  }



  // APK修改
  handleChangeFileApk=(e) => {
    if (e.file.status === 'uploading') {
        this.state.loading=true;
        return;
    }
    if (e.file.status === 'done') {
        console.log("handleChangeFileApk="+e.file.response);
        this.state.loading=false;

        this.formRef.current.setFieldsValue({
          apkUrl: e.file.response,
        });
        return e.file.response;
      }
  }


  // 上传app
  doUploadApp = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadApp(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
        next:(res) => {
          //console.log('next，res='+JSON.stringify(res));
          onProgress({percent: res.total.percent});
        },
      });
    })
  };

  handleChange = ({ file, fileList }) => {

  };

  // 上传APK前置校验
  beforeAppUpload(file) {
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
        message.error('video must smaller than 200MB!');
    }
    return isLt200M;
  }


  render () {
    return (
      <Modal
        title="编辑"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="安卓APK文件" name="apkUrl" labelCol={{span:10}} style={{ width: 420 }} >
                <Input placeholder="安卓APK文件"  />
            </Form.Item>

            <Form.Item label="上传安卓APK文件" name="apkUrlUpload" 
                valuePropName="file"
                labelCol={{span:10}} 
                style={{ width: 420 }}
                getValueFromEvent={this.handleChangeFileApk}
                >
                <Upload
                    accept="apk/*"
                    name="fiavatarle"
                    onChange={this.handleChange}
                    customRequest={this.doUploadApp}
                    beforeUpload={this.beforeAppUpload}
                >
                    <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item label="安卓版本号" name="apkVersion" labelCol={{span:10}} style={{ width: 420 }} >
                <Input placeholder="安卓版本号"  />
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default UpdateProxyPassword