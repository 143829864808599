import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select } from 'antd';
import textDescriptionService from '../../../Service/NobleMgr/textDescriptionService';
import { Logout } from '../../../Common/Auth';

const { TextArea } = Input;

class UpdateAndroidText extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          let textDescription = values;
          textDescriptionService.insertOrUpdateText(textDescription)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
              }
          })
      })

  }


  render () {
    return (
      <Modal
        title="IOS贵族页面文字说明"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={900}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id"  hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="充值页面文字说明" name="promptContent" rules={[
                    {
                        required: true,
                        message: '请填写充值页面文字说明!',
                    }]
                }>
                <TextArea placeholder="充值页面文字说明"/>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default UpdateAndroidText