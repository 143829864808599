import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';

import Service from '../../../Service/index';
import qxUserService from '../../../Service/Qx/qxUserService';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class AddQxUser extends Component {
  formRef = React.createRef();
  state={
    loading: false,
    imageUrl: null,
    fileList: [],
    previewOpen: null,
    previewImage: null,
    previewTitle: null,
    previewVisible: false,
}

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var qxUser = values;
          qxUserService.insertOrUpdateQxUser(qxUser)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
 
  }



  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
        >

            <Form.Item label="企微用户Id" name="qxUserId" labelCol={{span:10}} style={{ width: 420 }} >
                <Input placeholder="企微用户Id" />
            </Form.Item>

            <Form.Item label="企微名" name="qxName" labelCol={{span:10}} style={{ width: 420 }} >
                <Input placeholder="企微名" />
            </Form.Item>

            <Form.Item label="手机号" name="mobile" labelCol={{span:10}} style={{ width: 420 }} >
                <Input placeholder="手机号" />
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddQxUser