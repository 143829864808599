import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取广告
   * @param {*} adsParam
   * @returns
   */
   getAdsPage(adsParam) {
      //console.log("adsParam=" + JSON.stringify(adsParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/ads/getAdsPage",
        data: adsParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新广告
   * @param {*} adsParam
   * @returns
   */
   insertOrUpdateAds(adsParam) {
        //console.log("adsParam=" + JSON.stringify(adsParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/ads/insertOrUpdateAds",
        data: adsParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除广告
     * @param {*} adsParam 
     * @returns 
     */
     deleteAds(adsParam) {
        //console.log("adsParam=" + JSON.stringify(adsParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/ads/deleteAds",
        data: adsParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}