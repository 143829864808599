import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import usersVipService from "../../../Service/NobleMgr/usersVipService";



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class NobleList extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 用户特权 ————————————————————————————

    nobleListColumns: [
      {
        key: "avatar",
        title: "用户信息",
        dataIndex: "avatar",
        align: 'center',
        render: (avatar, row) => {
          var info = (
            <>
              <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.userid}</div>
              </div>
              <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={avatar} />
              </div>
              <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.username}</div>
              <div style={{ marginTop: '3px'}}>{row.mobile}</div>
              </div>
            </>
          );
          return info;
        }
      },
      {
        key: "grade",
        title: "贵族等级",
        dataIndex: "grade",
      },
      {
        key: "addtime",
        title: "开通时间",
        dataIndex: "addtime",
      },
      {
        key: "lastPayTime",
        title: "最后续费时间",
        dataIndex: "lastPayTime",
      },
      {
        key: "endtime",
        title: "到期时间",
        dataIndex: "endtime",
      },
    ],
    nobleListTotal: 0,
    nobleListList: null,
    updateNobleListRow: null,
    showUpdateNobleListDialog: false,
    addNobleListRow: null,
    showAddNobleListDialog: false,
    nobleGradeList: null,
  
  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 贵族列表 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var usersVipParam = {
      pageParam: pageParam,
    };
    usersVipService.getUsersVipPage(usersVipParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          nobleListList: res.retValue.list,
          nobleListTotal: res.retValue.total,
        });
      }    
      
    });

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 贵族特权 ————————————————————————————
  // 获取等级礼物
  handleGetNobleListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var usersVipParam = {
        userId: values.userId,
        pageParam: pageParam,
      };
      usersVipService.getUsersVipPage(usersVipParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            nobleListList: res.retValue.list,
            nobleListTotal: res.retValue.total,
          });
        }    
        
      });
      
    })

  }



  // 贵族特权分页
  nobleListChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var usersVipParam = {
        userId: values.userId,
        pageParam: pageParam,
      };
      usersVipService.getUsersVipPage(usersVipParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            nobleListList: res.retValue.list,
            nobleListTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };



  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/noble_list">贵族列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
            <Form
              name="NobleListListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 0px'}}
            >
              <Row gutter={24}>
                  <Col span={8} key={2}>
                    <Form.Item label="UID" name="userId">
                        <Input placeholder="UID"/>
                    </Form.Item>
                  </Col>
                  <Col span={6} key={3} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetNobleListPage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
              </Row>
            </Form>

            <Table
              bordered
              style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
              dataSource={this.state.nobleListList}
              columns={this.state.nobleListColumns}
              rowKey="id"
              scroll={{ x: "max-content" }}
              pagination={{
                total: this.state.nobleListTotal,
                defaultCurrent: 1,
                onChange: this.nobleListChangePage,
              }}
            ></Table>
        </StickyContainer>
      </div>
    );
  }
}
export default NobleList;
