import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserId, Logout } from '../../../Common/Auth';
import moment from 'moment'
import userService from '../../../Service/Admin/userService';
import QRCode from 'qrcode.react';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class AgentInfo extends Component {
  formRef = React.createRef();
  state={

    // ———————————————————— 代理信息 ————————————————————————————
    dynamicSettings: null,
    inviteImage: null,
    apkUrl: null,
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 代理信息 ————————————————————————————

    var userId = GetLoginUserId();
    var  searchParam = {
      id: userId,
    }
    userService.getAgentInfo(searchParam)
      .then(
        res => {
          //console.log("res=" + JSON.stringify(res));

          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          //console.log("retValue=" + JSON.stringify(res.retValue));
          this.formRef.current.setFieldsValue({
            id: res.retValue.id,
            name: res.retValue.name,
            anonymous: res.retValue.anonymous,
            comment: res.retValue.comment,
            phone: res.retValue.phone,
            chargePerc: res.retValue.chargePerc,
            nobleShareRatio: res.retValue.nobleShareRatio,
            inviteLink: res.retValue.inviteLink,
            inviteImage: res.retValue.inviteImage,
            apkFlag: res.retValue.apkFlag,
            apkVersion: res.retValue.apkVersion,
            apkUrl: res.retValue.apkUrl,
            incomePerc: res.retValue.incomePerc,
          })
      
          this.setState({inviteImage: res.retValue.inviteImage})
          this.setState({apkUrl: res.retValue.apkUrl})

        }
      )

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  downloadApk=() => {
    if(this.state.apkUrl == null) {
      return ;
    }
    window.open(this.state.apkUrl);
  }

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/agent_info">代理分享</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>            
            <Form
              name="messageSettingsForm"
              layout="horizontal"
              labelCol={{span:4}}
              wrapperCol={{span: 20}}
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 100px'}}
            >
                  <Form.Item label="代理ID" name="id" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="代理ID" disabled/>
                  </Form.Item>

                  <Form.Item label="登录账号" name="name" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="登录账号" disabled/>
                  </Form.Item>

                  <Form.Item label="代理名称" name="anonymous" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="代理名称" disabled/>
                  </Form.Item>

                  <Form.Item label="联系人" name="comment" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="联系人" disabled/>
                  </Form.Item>

                  <Form.Item label="手机号" name="phone" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="手机号" disabled/>
                  </Form.Item>

                  <Form.Item label="充值佣金比例%" name="chargePerc" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="充值佣金比例%" disabled/>
                  </Form.Item>

                  <Form.Item label="贵族分成比例%" name="nobleShareRatio" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="贵族分成比例%" disabled/>
                  </Form.Item>

                  <Form.Item label="收益佣金比例%" name="incomePerc" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="收益佣金比例%" disabled/>
                  </Form.Item>

                  <Form.Item label="推广码链接" name="inviteLink" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Input placeholder="推广码链接" disabled/>
                  </Form.Item>

                  <Form.Item label="推广码二维码" name="inviteImage" labelCol={{span:10}}  style={{ width: 450 }}>
                    <Image width={150} height={150} src={this.state.inviteImage} />
                  </Form.Item>

                  <Form.Item dependencies={['apkFlag']} noStyle>
                    {({ getFieldValue }) => {
                        const type = getFieldValue('apkFlag')

                        if (type === 0) {
                        return (
                            <>
                            </>
                        )
                        }

                        if (type === 1) {
                        return (
                            <>
                            <Form.Item label="专属推广APK" name="apkUrl" labelCol={{span:10}} style={{ width: 450 }} >
                                <Button type="primary" icon={<DownloadOutlined />} onClick={() => this.downloadApk()}>
                                  下载APK
                                </Button>
                            </Form.Item>
                            <Form.Item label="当前APK版本" name="apkVersion" labelCol={{span:10}}  style={{ width: 450 }} >
                                <Input placeholder="当前APK版本" disabled/>
                            </Form.Item>
                            </>
                        )
                        }

                        return null
                    }}
                </Form.Item>
                  
            </Form>

            
        </StickyContainer>
        
      </div>
    );
  }
}
export default AgentInfo;