import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import cashconfigService from '../../../Service/FinanceMgr/cashconfigService';
import withdrawalRulesService from "../../../Service/FinanceMgr/withdrawalRulesService";
import textDescriptionService from "../../../Service/NobleMgr/textDescriptionService";

import AddAndroidWithdrawal from './AddAndroidWithdrawal';
import UpdateAndroidWithdrawal from './UpdateAndroidWithdrawal';
import UpdateAndroidTextDescription from './UpdateAndroidTextDescription';

import AddIosWithdrawal from './AddIosWithdrawal';
import UpdateIosWithdrawal from './UpdateIosWithdrawal';
import UpdateIosTextDescription from './UpdateIosTextDescription';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class WithdrawalSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  state={

    // ———————————————————— 基础设置 ————————————————————————————
    basicSettings: null,

    // ———————————————————— 收益提现 ————————————————————————————
    incomeSettings: null,
        
    // ———————————————————— 代理提现 ————————————————————————————
    agentSettings: null,

    // ———————————————————— 佣金提现 ————————————————————————————
    commissionSettings: null,

        
    // ———————————————————— 安卓提现设置 ————————————————————————————

    androidWithdrawalColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderno",
        title: "排序",
        dataIndex: "orderno",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "appType",
        title: "充值类型",
        dataIndex: "appType",
        render: (appType, row) => {
          if(appType === 1) {
            return "安卓"
          } else if(appType === 2) {
            return "ios";
          }
        }
      },
      {
        key: "ticket",
        title: "映票数量",
        dataIndex: "ticket",
      },
      {
        key: "money",
        title: "安卓价格",
        dataIndex: "money",
      },
      {
        key: "isTip",
        title: "是否启用",
        dataIndex: "isTip",
        render: (isTip, row) => {
          if(isTip === 0) {
            return "不启用"
          } else if(isTip === 1) {
            return "启用";
          }
        }
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateAndroidWithdrawalDialog:true, updateAndroidWithdrawalRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteAndroidWithdrawal(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    androidWithdrawalTotal: 0,
    androidWithdrawalList: null,
    updateAndroidWithdrawalRow: null,
    showUpdateAndroidWithdrawalDialog: false,
    showAddAndroidWithdrawalDialog:false,
    androidTextDescriptionRow: null,
    showUpdateAndroidTextDescriptionDialog: false,
    androidWithdrawalPageParam:{
      appType: 1,
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },

    // ———————————————————— 苹果提现设置 ————————————————————————————
    
    iosWithdrawalColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderno",
        title: "排序",
        dataIndex: "orderno",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "appType",
        title: "充值类型",
        dataIndex: "appType",
        render: (appType, row) => {
          if(appType === 1) {
            return "安卓"
          } else if(appType === 2) {
            return "ios";
          }
        }
      },
      {
        key: "ticket",
        title: "映票数量",
        dataIndex: "ticket",
      },
      {
        key: "moneyIos",
        title: "苹果价格",
        dataIndex: "moneyIos",
      },
      {
        key: "productId",
        title: "苹果项目ID",
        dataIndex: "productId",
      },
      {
        key: "isTip",
        title: "是否启用",
        dataIndex: "isTip",
        render: (isTip, row) => {
          if(isTip === 0) {
            return "不启用"
          } else if(isTip === 1) {
            return "启用";
          }
        }
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateIosWithdrawalDialog:true, updateIosWithdrawalRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteIosWithdrawal(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    iosWithdrawalTotal: 0,
    iosWithdrawalList: null,
    updateIosWithdrawalRow: null,
    showUpdateIosWithdrawalDialog: false,
    showAddIosWithdrawalDialog:false,
    iosTextDescriptionRow: null,
    showUpdateIosTextDescriptionDialog: false,
    iosWithdrawalPageParam:{
      appType: 2,
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },



  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 基础设置 ————————————————————————————
    this.getCashconfig1();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }


// ———————————————————— 基础设置 ————————————————————————————
 
  handleBasicSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef.current.validateFields()
    .then(values => {
        var list = values.withdrawalMethod;
        var withdrawalMethodString = "";
        if(list != null) {
          list.forEach((item, index)=>{
            console.log("length="+list.length + ", index="+ index)
            if(index >= list.length-1) {
              withdrawalMethodString = withdrawalMethodString + item;
            } else {
              withdrawalMethodString = withdrawalMethodString + item + ",";
            }
          })
        }
        var adminCashConfig = {
          id: values.id,
          cashRate: values.cashRate,
          withdrawalMethod: withdrawalMethodString
        };

        //console.log("adminCashConfig="+JSON.stringify(adminCashConfig));


        cashconfigService.updateCashConfig(adminCashConfig)
        .then(
          res => {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            if(res == null || res.retCode != 200) {
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  // ———————————————————— 收益提现 ————————————————————————————


    handleIncomeSettingsSubmit=() => {
      //console.log("changePage 进入");
      let { history } = this.props;
      this.formRef2.current.validateFields()
      .then(values => {
          var list = values.fixedMonthlyWithdrawalDay;
          var fixedMonthlyWithdrawalDayString = "";
          if(list != null) {
            list.forEach((item, index)=>{
              console.log("length="+list.length + ", index="+ index)
              if(index >= list.length-1) {
                fixedMonthlyWithdrawalDayString = fixedMonthlyWithdrawalDayString + item;
              } else {
                fixedMonthlyWithdrawalDayString = fixedMonthlyWithdrawalDayString + item + ",";
              }
            })
          }
          var adminCashConfig = {
            id: values.id,
            cashAuth: values.cashAuth,
            onlyAnchorCash: values.onlyAnchorCash,
            coinExchange: values.coinExchange,
            withdrawalAmountManner: values.withdrawalAmountManner,
            cashMin:  values.cashMin,
            cashMax:  values.cashMax,
            cashMaxDay:  values.cashMaxDay,
            cashMaxMonth: values.cashMaxMonth,
            service: values.service,
            weeklyFixedWithdrawalDay: values.weeklyFixedWithdrawalDay,
            fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDayString,
          };
  
          cashconfigService.updateCashConfig(adminCashConfig)
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                //this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                //this.hanldeCloseModal();
              }
      
            }
          )
      })

    }

      
  // ———————————————————— 代理提现 ————————————————————————————
  
  handleAgentSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
        var list = values.fixedMonthlyWithdrawalDay;
        var fixedMonthlyWithdrawalDayString = "";
        if(list != null) {
          list.forEach((item, index)=>{
            console.log("length="+list.length + ", index="+ index)
            if(index >= list.length-1) {
              fixedMonthlyWithdrawalDayString = fixedMonthlyWithdrawalDayString + item;
            } else {
              fixedMonthlyWithdrawalDayString = fixedMonthlyWithdrawalDayString + item + ",";
            }
          })
        }
        var adminCashConfig = {
          id: values.id,
          cashAuth: values.cashAuth,
          onlyAnchorCash: values.onlyAnchorCash,
          coinExchange: values.coinExchange,
          withdrawalAmountManner: values.withdrawalAmountManner,
          cashMin:  values.cashMin,
          cashMax:  values.cashMax,
          cashMaxDay:  values.cashMaxDay,
          cashMaxMonth: values.cashMaxMonth,
          service: values.service,
          weeklyFixedWithdrawalDay: values.weeklyFixedWithdrawalDay,
          fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDayString,
        };

        cashconfigService.updateCashConfig(adminCashConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  // ———————————————————— 佣金提现 ————————————————————————————
  handleCommissionSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {
        var list = values.fixedMonthlyWithdrawalDay;
        var fixedMonthlyWithdrawalDayString = "";
        if(list != null) {
          list.forEach((item, index)=>{
            console.log("length="+list.length + ", index="+ index)
            if(index >= list.length-1) {
              fixedMonthlyWithdrawalDayString = fixedMonthlyWithdrawalDayString + item;
            } else {
              fixedMonthlyWithdrawalDayString = fixedMonthlyWithdrawalDayString + item + ",";
            }
          })
        }
        var adminCashConfig = {
          id: values.id,
          cashAuth: values.cashAuth,
          onlyAnchorCash: values.onlyAnchorCash,
          coinExchange: values.coinExchange,
          withdrawalAmountManner: values.withdrawalAmountManner,
          cashMin:  values.cashMin,
          cashMax:  values.cashMax,
          cashMaxDay:  values.cashMaxDay,
          cashMaxMonth: values.cashMaxMonth,
          service: values.service,
          weeklyFixedWithdrawalDay: values.weeklyFixedWithdrawalDay,
          fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDayString,
        };

        cashconfigService.updateCashConfig(adminCashConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  
  }


  // ———————————————————— 安卓提现设置 ————————————————————————————
  
  // 分页
  androidWithdrawalChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef5.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var withdrawalRulesParam = {
        appType: 1,
        pageParam: pageParam,
      };

      this.setState({
        androidWithdrawalPageParam: withdrawalRulesParam,
      });
      withdrawalRulesService.getWithdrawalRulesPage(withdrawalRulesParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            androidWithdrawalList: res.retValue.list,
            androidWithdrawalTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshAndroidWithdrawalData = () => {
    //console.log("refreshAndroidWithdrawalData");
    let { history } = this.props;

    var withdrawalRulesParam = this.state.androidWithdrawalPageParam;
    withdrawalRulesService.getWithdrawalRulesPage(withdrawalRulesParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          androidWithdrawalList: res.retValue.list,
          androidWithdrawalTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateAndroidWithdrawalDialog=() => {
    this.setState({showUpdateAndroidWithdrawalDialog: false});
  }

  // 关闭添加
  hideAddAndroidWithdrawalDialog=() => {
    this.setState({showAddAndroidWithdrawalDialog: false});
  }

  // 关闭添加
  hideUpdateAndroidTextDescriptionDialog=() => {
    this.setState({showUpdateAndroidTextDescriptionDialog: false});
  }

  // 根据Id删除
  deleteAndroidWithdrawal=(id) => {
    let { history } = this.props;
    let withdrawalRulesParam = {
      id : id
    };
    withdrawalRulesService.deleteWithdrawalRules(withdrawalRulesParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshAndroidWithdrawalData();
        message.success('删除成功！');
      }
      
    })

  }

  // 获取充值文本
  getAndroidTextDescription = () => {
    let { history } = this.props;
    //console.log("getAndroidTextDescription");

    var textDescription = {
      promptType: 1,
      osType: 1
    };

    textDescriptionService.getText(textDescription)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          androidTextDescriptionRow: res.retValue,
          showUpdateAndroidTextDescriptionDialog: true,
        });
      }
      
    });
  };

  // ———————————————————— 苹果充值设置 ————————————————————————————

  // 分页
  iosWithdrawalChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef6.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var withdrawalRulesParam = {
        appType: 2,
        pageParam: pageParam,
      };

      this.setState({
        iosWithdrawalPageParam: withdrawalRulesParam,
      });

      withdrawalRulesService.getWithdrawalRulesPage(withdrawalRulesParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            iosWithdrawalList: res.retValue.list,
            iosWithdrawalTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshIosWithdrawalData = () => {
    //console.log("refreshIosWithdrawalData");
    let { history } = this.props;

    var withdrawalRulesParam = this.state.iosWithdrawalPageParam;

    withdrawalRulesService.getWithdrawalRulesPage(withdrawalRulesParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          iosWithdrawalList: res.retValue.list,
          iosWithdrawalTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateIosWithdrawalDialog=() => {
    this.setState({showUpdateIosWithdrawalDialog: false});
  }

  // 关闭添加
  hideAddIosWithdrawalDialog=() => {
    this.setState({showAddIosWithdrawalDialog: false});
  }

  // 关闭添加
  hideUpdateIosTextDescriptionDialog=() => {
    this.setState({showUpdateIosTextDescriptionDialog: false});
  }

  // 根据Id删除
  deleteIosWithdrawal=(id) => {
    let { history } = this.props;
    let chargeRulesParam = {
      id : id
    };
    withdrawalRulesService.deleteWithdrawalRules(chargeRulesParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshIosWithdrawalData();
        message.success('删除成功！');
      }
      
    })

  }

  // 获取充值文本
  getIosTextDescription = () => {
    let { history } = this.props;
    //console.log("getIosTextDescription");

    var textDescription = {
      promptType: 1,
      osType: 2
    };

    textDescriptionService.getText(textDescription)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          androidTextDescriptionRow: res.retValue,
          showUpdateAndroidTextDescriptionDialog: true,
        });
      }
      
    });
  };

      
  // ———————————————————— 提现规则 ————————————————————————————


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getCashconfig1();
    } else if(key == "2") {
      this.getCashconfig2();
    } else if(key == "3") {
      this.getCashconfig3();
    } else if(key == "4") {
      this.getCashconfig4();
    } else if(key == "5") {
      this.getAndroidWithdrawal();
    } else if(key == "6") {
      this.getIosWithdrawal();
    }
  }

  /**
   * 基础设置
   */
  getCashconfig1=()=>{
    let { history } = this.props;

    var adminCashConfig = {
      id : 1
    }

    cashconfigService.getCashConfig(adminCashConfig)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          var stringList = res.retValue.withdrawalMethod;
          var withdrawalMethod = stringList.split(',');
          //console.log("withdrawalMethod=" + JSON.stringify(withdrawalMethod));

          this.formRef.current.setFieldsValue({
            id: res.retValue.id,
            cashRate: res.retValue.cashRate,
            withdrawalMethod: withdrawalMethod,
          });
        }    
        
      }
    )   
  }

  getCashconfig2=()=>{
    let { history } = this.props;
    var incomeSettingsParam = {
      id : 2
    }

    cashconfigService.getCashConfig(incomeSettingsParam)
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            var stringList = res.retValue.fixedMonthlyWithdrawalDay;
            var fixedMonthlyWithdrawalDay = stringList.split(',');
            //console.log("fixedMonthlyWithdrawalDay=" + JSON.stringify(fixedMonthlyWithdrawalDay));
  
            this.formRef2.current.setFieldsValue({
              id: res.retValue.id,
              cashAuth: res.retValue.cashAuth,
              onlyAnchorCash: res.retValue.onlyAnchorCash,
              coinExchange: res.retValue.coinExchange,
              withdrawalAmountManner: res.retValue.withdrawalAmountManner,
              cashMin:  res.retValue.cashMin,
              cashMax:  res.retValue.cashMax,
              cashMaxDay:  res.retValue.cashMaxDay,
              cashMaxMonth: res.retValue.cashMaxMonth,
              service: res.retValue.service,
              weeklyFixedWithdrawalDay: res.retValue.weeklyFixedWithdrawalDay,
              fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDay,
            });

            var incomeSettings = {
              cashAuth: res.retValue.cashAuth,
              onlyAnchorCash: res.retValue.onlyAnchorCash,
              coinExchange: res.retValue.coinExchange,
              withdrawalAmountManner: res.retValue.withdrawalAmountManner,
              cashMin:  res.retValue.cashMin,
              cashMax:  res.retValue.cashMax,
              cashMaxDay:  res.retValue.cashMaxDay,
              cashMaxMonth: res.retValue.cashMaxMonth,
              service: res.retValue.service,
              weeklyFixedWithdrawalDay: res.retValue.weeklyFixedWithdrawalDay,
              fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDay,
            }
  
            this.setState({incomeSettings: incomeSettings});
          }    
          
        }
      )  
  }

  getCashconfig3=()=>{
    let { history } = this.props;
    var agentSettingsParam = {
      id : 4
    }

    cashconfigService.getCashConfig(agentSettingsParam)
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            var stringList = res.retValue.fixedMonthlyWithdrawalDay;
            var fixedMonthlyWithdrawalDay = stringList.split(',');
            //console.log("fixedMonthlyWithdrawalDay=" + JSON.stringify(fixedMonthlyWithdrawalDay));
            
            this.formRef3.current.setFieldsValue({
              id: res.retValue.id,
              cashMin:  res.retValue.cashMin,
              cashMax:  res.retValue.cashMax,
              cashMaxDay:  res.retValue.cashMaxDay,
              cashMaxMonth: res.retValue.cashMaxMonth,
              service: res.retValue.service,
              weeklyFixedWithdrawalDay: res.retValue.weeklyFixedWithdrawalDay,
              fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDay,
            });

            var agentSettings = {
              cashMin:  res.retValue.cashMin,
              cashMax:  res.retValue.cashMax,
              cashMaxDay:  res.retValue.cashMaxDay,
              cashMaxMonth: res.retValue.cashMaxMonth,
              service: res.retValue.service,
              weeklyFixedWithdrawalDay: res.retValue.weeklyFixedWithdrawalDay,
              fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDay,
            }
  
            this.setState({agentSettings: agentSettings});
          }    
         
        }
      )  
  }

  getCashconfig4=()=>{
    let { history } = this.props;
    var commissionSettingsParam = {
      id : 5
    }

    cashconfigService.getCashConfig(commissionSettingsParam)
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            var stringList = res.retValue.fixedMonthlyWithdrawalDay;
            var fixedMonthlyWithdrawalDay = stringList.split(',');
            //console.log("fixedMonthlyWithdrawalDay=" + JSON.stringify(fixedMonthlyWithdrawalDay));
  
            this.formRef4.current.setFieldsValue({
              id: res.retValue.id,
              cashAuth: res.retValue.cashAuth,
              onlyAnchorCash: res.retValue.onlyAnchorCash,
              coinExchange: res.retValue.coinExchange,
              withdrawalAmountManner: res.retValue.withdrawalAmountManner,
              cashMin:  res.retValue.cashMin,
              cashMax:  res.retValue.cashMax,
              cashMaxDay:  res.retValue.cashMaxDay,
              cashMaxMonth: res.retValue.cashMaxMonth,
              service: res.retValue.service,
              weeklyFixedWithdrawalDay: res.retValue.weeklyFixedWithdrawalDay,
              fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDay,
            });

            var commissionSettings = {
              cashAuth: res.retValue.cashAuth,
              onlyAnchorCash: res.retValue.onlyAnchorCash,
              coinExchange: res.retValue.coinExchange,
              withdrawalAmountManner: res.retValue.withdrawalAmountManner,
              cashMin:  res.retValue.cashMin,
              cashMax:  res.retValue.cashMax,
              cashMaxDay:  res.retValue.cashMaxDay,
              cashMaxMonth: res.retValue.cashMaxMonth,
              service: res.retValue.service,
              weeklyFixedWithdrawalDay: res.retValue.weeklyFixedWithdrawalDay,
              fixedMonthlyWithdrawalDay: fixedMonthlyWithdrawalDay,
            }
  
            this.setState({commissionSettings: commissionSettings});
          }    
         
        }
      ) 
  }



  getAndroidWithdrawal = () => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef5.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: 1,
        pageSize: 10,
      };

      var withdrawalRulesParam = {
        appType: 1,
        pageParam: pageParam,
      };

      this.setState({
        androidWithdrawalPageParam: withdrawalRulesParam,
      });
      withdrawalRulesService.getWithdrawalRulesPage(withdrawalRulesParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            androidWithdrawalList: res.retValue.list,
            androidWithdrawalTotal: res.retValue.total,
          });
        }
        
      });
    });
  };


  getIosWithdrawal = () => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef6.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: 1,
        pageSize: 10,
      };

      var withdrawalRulesParam = {
        appType: 2,
        pageParam: pageParam,
      };

      this.setState({
        iosWithdrawalPageParam: withdrawalRulesParam,
      });

      withdrawalRulesService.getWithdrawalRulesPage(withdrawalRulesParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            iosWithdrawalList: res.retValue.list,
            iosWithdrawalTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/withdrawal_settings">提现设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // —————————————————————— 基础设置 ————————————————————————
            <TabPane tab="基础设置" key="1">
              <Form
                  name="BasicSettingsForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 100px'}}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>
                    
                      <Row >
                        <Form.Item label="兑换比例" name="cashRate" labelCol={{span:10}} style={{ width: 450 }} >
                          <Input  />
                        </Form.Item>
                        映票兑换人民币的比例（填写100，则提现时100映票=1元）
                      </Row>

                      <Form.Item label="支持提现的方式" name="withdrawalMethod" labelCol={{span:10}}  style={{ width: 450 }}>
                        <Checkbox.Group>
                          <Row>
                            <Col span={8}>
                              <Checkbox value="1" style={{ lineHeight: '32px', width: 100 }}>
                                支付宝
                              </Checkbox>
                            </Col>
                            <Col span={8}>
                              <Checkbox value="2" style={{ lineHeight: '32px', width: 100 }}>
                                微信
                              </Checkbox>
                            </Col>
                            <Col span={8}>
                              <Checkbox value="3" style={{ lineHeight: '32px', width: 100 }}>
                                银行卡
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleBasicSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>

            </TabPane>

            // —————————————————————— 收益提现 ————————————————————————
            <TabPane tab="收益提现" key="2">
              <Form
                  name="IncomeSettingsForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.incomeSettings}
                >

                      <Form.Item label="id" name="id" initialValue={2} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="提现需要认证" labelCol={{span:10}} name="cashAuth" style={{ width: 420 }} initialValue={0} >
                          <Radio.Group name="cashAuth"  defaultValue={0}>
                              <Radio value={0}>关闭</Radio>
                              <Radio value={1}>开启</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="主播才能提现" labelCol={{span:10}} name="onlyAnchorCash" style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="onlyAnchorCash" defaultValue={0}>
                              <Radio value={0}>关闭</Radio>
                              <Radio value={1}>开启</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Form.Item label="兑换金币" labelCol={{span:10}} name="coinExchange" style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="coinExchange" defaultValue={0}>
                              <Radio value={0}>关闭</Radio>
                              <Radio value={1}>开启</Radio>
                          </Radio.Group>
                      </Form.Item>
                      
                      <Row >
                        <Form.Item label="提现金额方式" labelCol={{span:10}} name="withdrawalAmountManner" style={{ width: 420 }} initialValue={0}>
                            <Radio.Group name="withdrawalAmountManner" defaultValue={0}>
                                <Radio value={0}>关闭</Radio>
                                <Radio value={1}>开启</Radio>
                            </Radio.Group>
                        </Form.Item>
                        选择 固定数值 时，请到(价格设置--收益提现金额配置)中设置
                      </Row>

                      <Row >
                        <Form.Item label="单笔提现限额" name="cashMin" labelCol={{span:14}} style={{ width: 300 }}>
                            <Input placeholder="映票开始" />
                        </Form.Item>
                        -
                        <Form.Item name="cashMax"  style={{ width: 160 }}>
                            <Input placeholder="映票结束" />
                        </Form.Item>
                        映票，（值为空，或者都填0表示不限制）
                      </Row>

                      <Row >
                        <Form.Item label="单日提现次数" name="cashMaxDay" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="单日提现次数" />
                        </Form.Item>
                        次，（主播单日申请提现最大次数）
                      </Row>

                      <Row >
                        <Form.Item label="单月提现次数" name="cashMaxMonth" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="单月提现次数" />
                        </Form.Item>
                        次，（主播单月申请提现最大次数）
                      </Row>

                      <Row >
                        <Form.Item label="提现手续费" name="service" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="提现手续费" />
                        </Form.Item>
                        %，（主播申请提现扣除手续费，为0表示不扣手续费）
                      </Row>

                      <Row >
                        <Form.Item label="每周固定提现日" labelCol={{span:10}} name="weeklyFixedWithdrawalDay" style={{ width: 420 }} initialValue={0}>
                            <Select style={{ width: 220 }} defaultValue={0}>
                                <Option value={-1}>不选择</Option>
                                <Option value={0}>星期日</Option>
                                <Option value={1}>星期一</Option>
                                <Option value={2}>星期二</Option>
                                <Option value={3}>星期三</Option>
                                <Option value={4}>星期四</Option>
                                <Option value={5}>星期五</Option>
                                <Option value={6}>星期六</Option>
                            </Select>
                        </Form.Item>
                      </Row>

                      <Form.Item label="支持提现的方式" name="fixedMonthlyWithdrawalDay" labelCol={{span:4}}  style={{ width: 1000 }}>
                        <Checkbox.Group>
                          <Row>
                            <Col span={4}>
                              <Checkbox value="1" style={{ lineHeight: '32px', width: 100 }}>
                                1号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="2" style={{ lineHeight: '32px', width: 100 }}>
                                2号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="3" style={{ lineHeight: '32px', width: 100 }}>
                                3号
                              </Checkbox>
                            </Col>

                            <Col span={4}>
                              <Checkbox value="4" style={{ lineHeight: '32px', width: 100 }}>
                                4号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="5" style={{ lineHeight: '32px', width: 100 }}>
                                5号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="6" style={{ lineHeight: '32px', width: 100 }}>
                                6号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="7" style={{ lineHeight: '32px', width: 100 }}>
                                7号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="8" style={{ lineHeight: '32px', width: 100 }}>
                                8号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="9" style={{ lineHeight: '32px', width: 100 }}>
                                9号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="10" style={{ lineHeight: '32px', width: 100 }}>
                                10号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="11" style={{ lineHeight: '32px', width: 100 }}>
                                11号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="12" style={{ lineHeight: '32px', width: 100 }}>
                                12号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="13" style={{ lineHeight: '32px', width: 100 }}>
                                13号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="14" style={{ lineHeight: '32px', width: 100 }}>
                                14号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="15" style={{ lineHeight: '32px', width: 100 }}>
                                15号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="16" style={{ lineHeight: '32px', width: 100 }}>
                                16号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="17" style={{ lineHeight: '32px', width: 100 }}>
                                17号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="18" style={{ lineHeight: '32px', width: 100 }}>
                                18号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="19" style={{ lineHeight: '32px', width: 100 }}>
                                19号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="20" style={{ lineHeight: '32px', width: 100 }}>
                                20号
                              </Checkbox>
                            </Col>

                            <Col span={4}>
                              <Checkbox value="21" style={{ lineHeight: '32px', width: 100 }}>
                                21号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="22" style={{ lineHeight: '32px', width: 100 }}>
                                22号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="23" style={{ lineHeight: '32px', width: 100 }}>
                                23号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="24" style={{ lineHeight: '32px', width: 100 }}>
                                24号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="25" style={{ lineHeight: '32px', width: 100 }}>
                                25号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="26" style={{ lineHeight: '32px', width: 100 }}>
                                26号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="27" style={{ lineHeight: '32px', width: 100 }}>
                                27号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="28" style={{ lineHeight: '32px', width: 100 }}>
                                28号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="29" style={{ lineHeight: '32px', width: 100 }}>
                                29号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="30" style={{ lineHeight: '32px', width: 100 }}>
                                30号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="31" style={{ lineHeight: '32px', width: 100 }}>
                                31号
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px', color:'#F00'}}>
                  <h1>特别说明</h1>
                  <p>
                    1：每周固定提现日 和 每月固定提现日 两者为 【或】 的关系。例如：每周固定提现日 设置为【星期一】，每月固定提现日 设置为【2】，则 可以在 每个月的 【星期一】和 【2号】 发起提现。
                  </p>
                  <p>
                    2：当 每周固定提现日 和 每月固定提现日 只设置一种的时候，只有设置的那种才生效，另一项不生效。例如：每周固定提现日 设置为【星期一】，每月固定提现日 一个都不勾选，则 可以在每个月的 【星期一】 发起提现。如果将 每周固定提现日 设置为【不选择】， 每月固定提现日 设置为【2】，则 可以在 每个月的 【2 号】 发起提现。
                  </p>
                  <p>
                    3：如果 每周固定提现日 和 每月固定提现日 都不选择，则表示 不使用提现功能。
                  </p>
                </div>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleIncomeSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 代理提现 ————————————————————————
            <TabPane tab="代理提现" key="3">
              <Form
                  name="AgentSettingsForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.agentSettings}
                >

                      <Form.Item label="id" name="id" initialValue={4} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Row >
                        <Form.Item label="单笔提现限额" name="cashMin" labelCol={{span:14}} style={{ width: 300 }}>
                            <Input placeholder="人民币开始" />
                        </Form.Item>
                        -
                        <Form.Item name="cashMax"  style={{ width: 160 }}>
                            <Input placeholder="人民币结束" />
                        </Form.Item>
                        人民币，（值为空，或者都填0表示不限制）
                      </Row>

                      <Row >
                        <Form.Item label="单日提现次数" name="cashMaxDay" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="单日提现次数" />
                        </Form.Item>
                        次，（主播单日申请提现最大次数）
                      </Row>

                      <Row >
                        <Form.Item label="单月提现次数" name="cashMaxMonth" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="单月提现次数" />
                        </Form.Item>
                        次，（主播单月申请提现最大次数）
                      </Row>

                      <Row >
                        <Form.Item label="提现手续费" name="service" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="提现手续费" />
                        </Form.Item>
                        %，（主播申请提现扣除手续费，为0表示不扣手续费）
                      </Row>

                      <Row >
                        <Form.Item label="每周固定提现日" labelCol={{span:10}} name="weeklyFixedWithdrawalDay" style={{ width: 420 }} initialValue={0}>
                            <Select style={{ width: 220 }} defaultValue={0}>
                                <Option value={-1}>不选择</Option>
                                <Option value={0}>星期日</Option>
                                <Option value={1}>星期一</Option>
                                <Option value={2}>星期二</Option>
                                <Option value={3}>星期三</Option>
                                <Option value={4}>星期四</Option>
                                <Option value={5}>星期五</Option>
                                <Option value={6}>星期六</Option>
                            </Select>
                        </Form.Item>
                      </Row>

                      <Form.Item label="支持提现的方式" name="fixedMonthlyWithdrawalDay" labelCol={{span:4}}  style={{ width: 1000 }}>
                        <Checkbox.Group>
                          <Row>
                            <Col span={4}>
                              <Checkbox value="1" style={{ lineHeight: '32px', width: 100 }}>
                                1号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="2" style={{ lineHeight: '32px', width: 100 }}>
                                2号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="3" style={{ lineHeight: '32px', width: 100 }}>
                                3号
                              </Checkbox>
                            </Col>

                            <Col span={4}>
                              <Checkbox value="4" style={{ lineHeight: '32px', width: 100 }}>
                                4号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="5" style={{ lineHeight: '32px', width: 100 }}>
                                5号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="6" style={{ lineHeight: '32px', width: 100 }}>
                                6号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="7" style={{ lineHeight: '32px', width: 100 }}>
                                7号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="8" style={{ lineHeight: '32px', width: 100 }}>
                                8号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="9" style={{ lineHeight: '32px', width: 100 }}>
                                9号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="10" style={{ lineHeight: '32px', width: 100 }}>
                                10号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="11" style={{ lineHeight: '32px', width: 100 }}>
                                11号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="12" style={{ lineHeight: '32px', width: 100 }}>
                                12号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="13" style={{ lineHeight: '32px', width: 100 }}>
                                13号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="14" style={{ lineHeight: '32px', width: 100 }}>
                                14号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="15" style={{ lineHeight: '32px', width: 100 }}>
                                15号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="16" style={{ lineHeight: '32px', width: 100 }}>
                                16号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="17" style={{ lineHeight: '32px', width: 100 }}>
                                17号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="18" style={{ lineHeight: '32px', width: 100 }}>
                                18号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="19" style={{ lineHeight: '32px', width: 100 }}>
                                19号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="20" style={{ lineHeight: '32px', width: 100 }}>
                                20号
                              </Checkbox>
                            </Col>

                            <Col span={4}>
                              <Checkbox value="21" style={{ lineHeight: '32px', width: 100 }}>
                                21号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="22" style={{ lineHeight: '32px', width: 100 }}>
                                22号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="23" style={{ lineHeight: '32px', width: 100 }}>
                                23号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="24" style={{ lineHeight: '32px', width: 100 }}>
                                24号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="25" style={{ lineHeight: '32px', width: 100 }}>
                                25号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="26" style={{ lineHeight: '32px', width: 100 }}>
                                26号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="27" style={{ lineHeight: '32px', width: 100 }}>
                                27号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="28" style={{ lineHeight: '32px', width: 100 }}>
                                28号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="29" style={{ lineHeight: '32px', width: 100 }}>
                                29号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="30" style={{ lineHeight: '32px', width: 100 }}>
                                30号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="31" style={{ lineHeight: '32px', width: 100 }}>
                                31号
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px', color:'#F00'}}>
                  <h1>特别说明</h1>
                  <p>
                    1：每周固定提现日 和 每月固定提现日 两者为 【或】 的关系。例如：每周固定提现日 设置为【星期一】，每月固定提现日 设置为【2】，则 可以在 每个月的 【星期一】和 【2号】 发起提现。
                  </p>
                  <p>
                    2：当 每周固定提现日 和 每月固定提现日 只设置一种的时候，只有设置的那种才生效，另一项不生效。例如：每周固定提现日 设置为【星期一】，每月固定提现日 一个都不勾选，则 可以在每个月的 【星期一】 发起提现。如果将 每周固定提现日 设置为【不选择】， 每月固定提现日 设置为【2】，则 可以在 每个月的 【2 号】 发起提现。
                  </p>
                  <p>
                    3：如果 每周固定提现日 和 每月固定提现日 都不选择，则表示 不使用提现功能。
                  </p>
                </div>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleAgentSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 佣金提现 ————————————————————————
            <TabPane tab="佣金提现" key="4">
              <Form
                  name="CommissionSettingsForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef4}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.commissionSettings}
                >

                      <Form.Item label="id" name="id" initialValue={5} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="兑换金币" labelCol={{span:10}} name="coinExchange" style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="coinExchange" defaultValue={0}>
                              <Radio value={0}>关闭</Radio>
                              <Radio value={1}>开启</Radio>
                          </Radio.Group>
                      </Form.Item>
                      
                      <Row >
                        <Form.Item label="提现金额方式" labelCol={{span:10}} name="withdrawalAmountManner" style={{ width: 420 }} initialValue={0}>
                            <Radio.Group name="withdrawalAmountManner" defaultValue={0}>
                                <Radio value={0}>关闭</Radio>
                                <Radio value={1}>开启</Radio>
                            </Radio.Group>
                        </Form.Item>
                        选择 固定数值 时，请到(价格设置--佣金提现金额配置)中设置
                      </Row>

                      <Row >
                        <Form.Item label="单笔提现限额" name="cashMin" labelCol={{span:14}} style={{ width: 300 }}>
                            <Input placeholder="映票开始" />
                        </Form.Item>
                        -
                        <Form.Item name="cashMax"  style={{ width: 160 }}>
                            <Input placeholder="映票结束" />
                        </Form.Item>
                        人民币，（值为空，或者都填0表示不限制）
                      </Row>

                      <Row >
                        <Form.Item label="单日提现次数" name="cashMaxDay" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="单日提现次数" />
                        </Form.Item>
                        次，（主播单日申请提现最大次数）
                      </Row>

                      <Row >
                        <Form.Item label="单月提现次数" name="cashMaxMonth" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="单月提现次数" />
                        </Form.Item>
                        次，（主播单月申请提现最大次数）
                      </Row>

                      <Row >
                        <Form.Item label="提现手续费" name="service" labelCol={{span:10}} style={{ width: 420 }}>
                            <Input placeholder="提现手续费" />
                        </Form.Item>
                        %，（主播申请提现扣除手续费，为0表示不扣手续费）
                      </Row>

                      <Form.Item label="第1笔提现不限额" labelCol={{span:10}} name="firstAmountCash" style={{ width: 420 }} initialValue={0}>
                          <Radio.Group name="firstAmountCash" defaultValue={0}>
                              <Radio value={0}>否</Radio>
                              <Radio value={1}>是</Radio>
                          </Radio.Group>
                      </Form.Item>

                      <Row >
                        <Form.Item label="每周固定提现日" labelCol={{span:10}} name="weeklyFixedWithdrawalDay" style={{ width: 420 }} initialValue={0}>
                            <Select style={{ width: 220 }} defaultValue={0}>
                                <Option value={-1}>不选择</Option>
                                <Option value={0}>星期日</Option>
                                <Option value={1}>星期一</Option>
                                <Option value={2}>星期二</Option>
                                <Option value={3}>星期三</Option>
                                <Option value={4}>星期四</Option>
                                <Option value={5}>星期五</Option>
                                <Option value={6}>星期六</Option>
                            </Select>
                        </Form.Item>
                      </Row>

                      <Form.Item label="支持提现的方式" name="fixedMonthlyWithdrawalDay" labelCol={{span:4}}  style={{ width: 1000 }}>
                        <Checkbox.Group>
                          <Row>
                            <Col span={4}>
                              <Checkbox value="1" style={{ lineHeight: '32px', width: 100 }}>
                                1号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="2" style={{ lineHeight: '32px', width: 100 }}>
                                2号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="3" style={{ lineHeight: '32px', width: 100 }}>
                                3号
                              </Checkbox>
                            </Col>

                            <Col span={4}>
                              <Checkbox value="4" style={{ lineHeight: '32px', width: 100 }}>
                                4号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="5" style={{ lineHeight: '32px', width: 100 }}>
                                5号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="6" style={{ lineHeight: '32px', width: 100 }}>
                                6号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="7" style={{ lineHeight: '32px', width: 100 }}>
                                7号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="8" style={{ lineHeight: '32px', width: 100 }}>
                                8号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="9" style={{ lineHeight: '32px', width: 100 }}>
                                9号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="10" style={{ lineHeight: '32px', width: 100 }}>
                                10号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="11" style={{ lineHeight: '32px', width: 100 }}>
                                11号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="12" style={{ lineHeight: '32px', width: 100 }}>
                                12号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="13" style={{ lineHeight: '32px', width: 100 }}>
                                13号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="14" style={{ lineHeight: '32px', width: 100 }}>
                                14号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="15" style={{ lineHeight: '32px', width: 100 }}>
                                15号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="16" style={{ lineHeight: '32px', width: 100 }}>
                                16号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="17" style={{ lineHeight: '32px', width: 100 }}>
                                17号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="18" style={{ lineHeight: '32px', width: 100 }}>
                                18号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="19" style={{ lineHeight: '32px', width: 100 }}>
                                19号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="20" style={{ lineHeight: '32px', width: 100 }}>
                                20号
                              </Checkbox>
                            </Col>

                            <Col span={4}>
                              <Checkbox value="21" style={{ lineHeight: '32px', width: 100 }}>
                                21号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="22" style={{ lineHeight: '32px', width: 100 }}>
                                22号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="23" style={{ lineHeight: '32px', width: 100 }}>
                                23号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="24" style={{ lineHeight: '32px', width: 100 }}>
                                24号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="25" style={{ lineHeight: '32px', width: 100 }}>
                                25号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="26" style={{ lineHeight: '32px', width: 100 }}>
                                26号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="27" style={{ lineHeight: '32px', width: 100 }}>
                                27号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="28" style={{ lineHeight: '32px', width: 100 }}>
                                28号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="29" style={{ lineHeight: '32px', width: 100 }}>
                                29号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="30" style={{ lineHeight: '32px', width: 100 }}>
                                30号
                              </Checkbox>
                            </Col>
                            <Col span={4}>
                              <Checkbox value="31" style={{ lineHeight: '32px', width: 100 }}>
                                31号
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px', color:'#F00'}}>
                  <h1>特别说明</h1>
                  <p>
                    1：每周固定提现日 和 每月固定提现日 两者为 【或】 的关系。例如：每周固定提现日 设置为【星期一】，每月固定提现日 设置为【2】，则 可以在 每个月的 【星期一】和 【2号】 发起提现。
                  </p>
                  <p>
                    2：当 每周固定提现日 和 每月固定提现日 只设置一种的时候，只有设置的那种才生效，另一项不生效。例如：每周固定提现日 设置为【星期一】，每月固定提现日 一个都不勾选，则 可以在每个月的 【星期一】 发起提现。如果将 每周固定提现日 设置为【不选择】， 每月固定提现日 设置为【2】，则 可以在 每个月的 【2 号】 发起提现。
                  </p>
                  <p>
                    3：如果 每周固定提现日 和 每月固定提现日 都不选择，则表示 不使用提现功能。
                  </p>
                </div>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleCommissionSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // ———————————————————— 安卓提现设置 ————————————————————————————
            <TabPane tab="安卓提现设置" key="5">
              <Form
                name="AndroidWithdrawalListForm"
                className="ant-advanced-search-form"
                ref={this.formRef5}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={2} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.getAndroidTextDescription}  >
                        充值页面文字说明
                      </Button>
                    </Col>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddAndroidWithdrawalDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.androidWithdrawalList}
                columns={this.state.androidWithdrawalColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.androidWithdrawalTotal,
                  defaultCurrent: 1,
                  onChange: this.androidWithdrawalChangePage,
                }}
              ></Table>
              <AddAndroidWithdrawal close={this.hideAddAndroidWithdrawalDialog} visible={this.state.showAddAndroidWithdrawalDialog} refreshData={this.refreshAndroidWithdrawalData}></AddAndroidWithdrawal>
              <UpdateAndroidWithdrawal rowData={this.state.updateAndroidWithdrawalRow} close={this.hideUpdateAndroidWithdrawalDialog} visible={this.state.showUpdateAndroidWithdrawalDialog} refreshData={this.refreshAndroidWithdrawalData}></UpdateAndroidWithdrawal>
              <UpdateAndroidTextDescription rowData={this.state.androidTextDescriptionRow} close={this.hideUpdateAndroidTextDescriptionDialog} visible={this.state.showUpdateAndroidTextDescriptionDialog} ></UpdateAndroidTextDescription>
            </TabPane>

            // ———————————————————— 苹果提现设置 ————————————————————————————
            <TabPane tab="苹果提现设置" key="6">
              <Form
                name="IosWithdrawalListForm"
                className="ant-advanced-search-form"
                ref={this.formRef6}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={2} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.getIosTextDescription}  >
                        充值页面文字说明
                      </Button>
                    </Col>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddIosWithdrawalDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.iosWithdrawalList}
                columns={this.state.iosWithdrawalColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.iosWithdrawalTotal,
                  defaultCurrent: 1,
                  onChange: this.iosWithdrawalChangePage,
                }}
              ></Table>
              <AddIosWithdrawal close={this.hideAddIosWithdrawalDialog} visible={this.state.showAddIosWithdrawalDialog} refreshData={this.refreshIosWithdrawalData}></AddIosWithdrawal>
              <UpdateIosWithdrawal rowData={this.state.updateIosWithdrawalRow} close={this.hideUpdateIosWithdrawalDialog} visible={this.state.showUpdateIosWithdrawalDialog} refreshData={this.refreshIosWithdrawalData}></UpdateIosWithdrawal>
              <UpdateIosTextDescription rowData={this.state.iosTextDescriptionRow} close={this.hideUpdateIosTextDescriptionDialog} visible={this.state.showUpdateIosTextDescriptionDialog} ></UpdateIosTextDescription>
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default WithdrawalSettings;