import React, { Component } from 'react'
import { Form, Input, Upload, Button, message, Select } from 'antd';
import { GetLoginToken } from '../../../Common/Auth';
import { UserOutlined,CodepenCircleFilled,EnvironmentOutlined,OrderedListOutlined,FileImageOutlined } from '@ant-design/icons';

const { Option } = Select;

/**
 * 添加权限model
 */
class AddPerFrm extends Component {
    formRef = React.createRef();

    // 页面第一次渲染时执行
    componentDidMount=() => {
        this.formRef.current.setFieldsValue({
            type: "menu"
        });
    }

    render () {
        return (
            <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            
        >
            <Form.Item label="权限名" name="des" rules={ [
                    {
                        min: 2,
                        message: '请输入大于2个字符!',
                    },
                    {
                        required: true,
                        message: '请输入权限名!',
                    }
                    ]
                }>
                <Input prefix={<UserOutlined />} 
                    placeholder="权限名"
                />
            </Form.Item>

            <Form.Item label="权限码" name="code" rules={ [
                    {
                        min: 2,
                        message: '请输入大于2个字符!',
                    },
                    {
                        required: true,
                        message: '请输入权限码',
                    }
                    ]
                }>
                <Input prefix={<CodepenCircleFilled />} 
                    placeholder="权限码"
                />
            </Form.Item>

            <Form.Item label="权限类型" name="type" rules={ [
                    {
                        required: true,
                        message: '请选择权限类型',
                    }
                    ]
                }>
                <Select style={{ width: 220 }}>
                  <Option value="menu">menu</Option>
                  <Option value="route">route</Option>
                  <Option value="data">data</Option>
                  <Option value="component">component</Option>
                  <Option value="file">file</Option>
                </Select>
            </Form.Item>

            <Form.Item label="父权限" name="pid">
                <Input prefix={<UserOutlined />} 
                    placeholder="父权限"
                />
            </Form.Item>

            <Form.Item label="排序" name="orderNum">
                <Input prefix={<OrderedListOutlined />} 
                    placeholder="排序"
                />
            </Form.Item>

            <Form.Item label="层级" name="level">
                <Input prefix={<OrderedListOutlined />} 
                    placeholder="层级"
                />
            </Form.Item>

            <Form.Item label="图标" name="icon">
                <Input prefix={<FileImageOutlined />} 
                    placeholder="图标"
                />
            </Form.Item>

        </Form>
        )
    }
}

export default AddPerFrm