import React, { Component } from 'react'
import { Breadcrumb, Table, Button, message, Modal,
  Avatar, Popconfirm, Input, Row, Col, DatePicker,
 Tabs, Form, Upload, Select, Checkbox, Radio, Image, Statistic} from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import { StickyContainer, Sticky } from 'react-sticky';
import { Logout } from '../../../Common/Auth';
import agentDataStatisticsService from '../../../Service/MyAgent/agentDataStatisticsService';


const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);

class ShowDataStatistics extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  state={
    userId: this.props.rowData != null ? this.props.rowData.id : null,
    // ———————————————————— 今日 ————————————————————————————
    todayData: null,

    // ———————————————————— 昨日 ————————————————————————————
    yesterdayData: null,


    // ———————————————————— 本周 ————————————————————————————
    weekData: null,


    // ———————————————————— 上周 ————————————————————————————
    lastweekData: null,


    // ———————————————————— 本月 ————————————————————————————
    monthData: null,


    // ———————————————————— 上月 ————————————————————————————
    lastmonthData: null,
  }


// ———————————————————— 免费通话时长设置 ————————————————————————————

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "today") {
      this.getTodayData();
    } else if(key == "yesterday") {
      this.getYesterdayData();
    } else if(key == "week") {
      this.getWeekData();
    } else if(key == "lastweek") {
      this.getLastweekData();
    } else if(key == "month") {
      this.getMonthData();
    } else if(key == "lastmonth") {
      this.getLastmonthData();
    }
  }

  /**
   * 获取今天数据
   */
  getTodayData=()=> {
    let { history } = this.props;

    var userId = this.props.rowData != null ? this.props.rowData.id : null;
    var todayParam = {
      id: userId,
      type: "today"
    }

    agentDataStatisticsService.getAgentDataStatisticsAgent(todayParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef.current.setFieldsValue({
            popularizeUserNum_1: res.retValue.popularizeUserNum,
            rechargeTotalMoney_1: res.retValue.rechargeTotalMoney,
            rechargeCommission_1: res.retValue.rechargeCommission,
            nobleTotalMoney_1: res.retValue.nobleTotalMoney,
            nobleCommission_1: res.retValue.nobleCommission,
            totalAmount_1: res.retValue.totalAmount,
            popularizeAnchorNum_1: res.retValue.popularizeAnchorNum,
            anchorIncomeCoin_1: res.retValue.anchorIncomeCoin,
            incomeCommission_1: res.retValue.incomeCommission,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }

  /**
   * 获取昨天数据
   */
  getYesterdayData=()=> {
    let { history } = this.props;

    var userId = this.props.rowData != null ? this.props.rowData.id : null;
    var yesterdayParam = {
      id: userId,
      type: "yesterday"
    }

    agentDataStatisticsService.getAgentDataStatisticsAgent(yesterdayParam)
    .then(
      res => {
        //console.log("yesterday retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            popularizeUserNum_2: res.retValue.popularizeUserNum,
            rechargeTotalMoney_2: res.retValue.rechargeTotalMoney,
            rechargeCommission_2: res.retValue.rechargeCommission,
            nobleTotalMoney_2: res.retValue.nobleTotalMoney,
            nobleCommission_2: res.retValue.nobleCommission,
            totalAmount_2: res.retValue.totalAmount,
            popularizeAnchorNum_2: res.retValue.popularizeAnchorNum,
            anchorIncomeCoin_2: res.retValue.anchorIncomeCoin,
            incomeCommission_2: res.retValue.incomeCommission,
          });
          // this.formRef.current.setFieldsValue(res.retValue);
        }
      
      }
    )   
  }

  /**
   * 获取本周数据
   */
  getWeekData=()=> {
    let { history } = this.props;

    var userId = this.props.rowData != null ? this.props.rowData.id : null;
    var weekParam = {
      id: userId,
      type: "week"
    }

    agentDataStatisticsService.getAgentDataStatisticsAgent(weekParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));

        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef3.current.setFieldsValue({
            popularizeUserNum_3: res.retValue.popularizeUserNum,
            rechargeTotalMoney_3: res.retValue.rechargeTotalMoney,
            rechargeCommission_3: res.retValue.rechargeCommission,
            nobleTotalMoney_3: res.retValue.nobleTotalMoney,
            nobleCommission_3: res.retValue.nobleCommission,
            totalAmount_3: res.retValue.totalAmount,
            popularizeAnchorNum_3: res.retValue.popularizeAnchorNum,
            anchorIncomeCoin_3: res.retValue.anchorIncomeCoin,
            incomeCommission_3: res.retValue.incomeCommission,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    ) 
  }

  /**
   * 获取上周数据
   */
  getLastweekData=()=> {
    let { history } = this.props;

    var userId = this.props.rowData != null ? this.props.rowData.id : null;
    var lastweekParam = {
      id: userId,
      type: "lastweek"
    }

    agentDataStatisticsService.getAgentDataStatisticsAgent(lastweekParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef4.current.setFieldsValue({
            popularizeUserNum_4: res.retValue.popularizeUserNum,
            rechargeTotalMoney_4: res.retValue.rechargeTotalMoney,
            rechargeCommission_4: res.retValue.rechargeCommission,
            nobleTotalMoney_4: res.retValue.nobleTotalMoney,
            nobleCommission_4: res.retValue.nobleCommission,
            totalAmount_4: res.retValue.totalAmount,
            popularizeAnchorNum_4: res.retValue.popularizeAnchorNum,
            anchorIncomeCoin_4: res.retValue.anchorIncomeCoin,
            incomeCommission_4: res.retValue.incomeCommission,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }        
      }
    )   
  }

  /**
   * 获取本月数据
   */
  getMonthData=()=> {
    let { history } = this.props;

    var userId = this.props.rowData != null ? this.props.rowData.id : null;
    var monthParam = {
      id: userId,
      type: "month"
    }

    agentDataStatisticsService.getAgentDataStatisticsAgent(monthParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef5.current.setFieldsValue({
            popularizeUserNum_5: res.retValue.popularizeUserNum,
            rechargeTotalMoney_5: res.retValue.rechargeTotalMoney,
            rechargeCommission_5: res.retValue.rechargeCommission,
            nobleTotalMoney_5: res.retValue.nobleTotalMoney,
            nobleCommission_5: res.retValue.nobleCommission,
            totalAmount_5: res.retValue.totalAmount,
            popularizeAnchorNum_5: res.retValue.popularizeAnchorNum,
            anchorIncomeCoin_5: res.retValue.anchorIncomeCoin,
            incomeCommission_5: res.retValue.incomeCommission,
          //this.formRef.current.setFieldsValue(res.retValue);
        });
      }
      }
    )   
  }

  /**
   * 获取上月数据
   */
  getLastmonthData=()=> {
    let { history } = this.props;

    var userId = this.props.rowData != null ? this.props.rowData.id : null;
    var lastmonthParam = {
      id: userId,
      type: "lastmonth"
    }

    agentDataStatisticsService.getAgentDataStatisticsAgent(lastmonthParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            this.formRef6.current.setFieldsValue({
              popularizeUserNum_6: 0,
              rechargeTotalMoney_6: 0,
              rechargeCommission_6: 0,
              nobleTotalMoney_6: 0,
              nobleCommission_6: 0,
              totalAmount_6: 0,
            });
          } else {
            this.formRef6.current.setFieldsValue({
              popularizeUserNum_6: res.retValue.popularizeUserNum,
              rechargeTotalMoney_6: res.retValue.rechargeTotalMoney,
              rechargeCommission_6: res.retValue.rechargeCommission,
              nobleTotalMoney_6: res.retValue.nobleTotalMoney,
              nobleCommission_6: res.retValue.nobleCommission,
              totalAmount_6: res.retValue.totalAmount,
              popularizeAnchorNum_6: res.retValue.popularizeAnchorNum,
              anchorIncomeCoin_6: res.retValue.anchorIncomeCoin,
              incomeCommission_6: res.retValue.incomeCommission,
            });
          }
          
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }


  render () {
    return (
      <Modal
        destroyOnClose
        visible={this.props.visible}
        width={1000}
        onCancel={this.hanldeCloseModal}
        footer={[
          null, null,
        ]}
      >
          <Tabs defaultActiveKey="today" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 今日统计 ————————————————————————————
            <TabPane tab="今日统计" key="today">
              <Form
                name="TodayStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="popularizeUserNum_1" initialValue={this.props.rowData != null ? this.props.rowData.popularizeUserNum_1 : null}>
                      <Statistic title="推广用户数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="popularizeAnchorNum_1" initialValue={this.props.rowData != null ? this.props.rowData.popularizeAnchorNum_1 : null}>
                      <Statistic title="推广主播数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeTotalMoney_1" initialValue={this.props.rowData != null ? this.props.rowData.rechargeTotalMoney_1 : null}>
                      <Statistic title="充值金额（元）" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeCommission_1" initialValue={this.props.rowData != null ? this.props.rowData.rechargeCommission_1 : null}>
                      <Statistic title="充值佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="anchorIncomeCoin_1" initialValue={this.props.rowData != null ? this.props.rowData.anchorIncomeCoin_1 : null}>
                      <Statistic title="主播收益金币" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="incomeCommission_1" initialValue={this.props.rowData != null ? this.props.rowData.incomeCommission_1 : null}>
                      <Statistic title="主播收益分佣（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleTotalMoney_1" initialValue={this.props.rowData != null ? this.props.rowData.nobleTotalMoney_1 : null}>
                      <Statistic title="购买贵族金额（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleCommission_1" initialValue={this.props.rowData != null ? this.props.rowData.nobleCommission_1 : null}>
                      <Statistic title="购买贵族佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalAmount_1" initialValue={this.props.rowData != null ? this.props.rowData.totalAmount_1 : null}>
                      <Statistic title="总收益佣金" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

            </TabPane>
            
            // ———————————————————— 昨日统计 ——————————————————————
            <TabPane tab="昨日统计" key="yesterday">
            <Form
                name="YesterdayStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                <Col span={8}>
                    <Form.Item name="popularizeUserNum_2" >
                      <Statistic title="推广用户数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="popularizeAnchorNum_2" >
                      <Statistic title="推广主播数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeTotalMoney_2">
                      <Statistic title="充值金额（元）" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeCommission_2">
                      <Statistic title="充值佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="anchorIncomeCoin_2">
                      <Statistic title="主播收益金币" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="incomeCommission_2">
                      <Statistic title="主播收益分佣（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleTotalMoney_2">
                      <Statistic title="购买贵族金额（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleCommission_2">
                      <Statistic title="购买贵族佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalAmount_2">
                      <Statistic title="总收益佣金" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            
            </TabPane>

            // —————————————————————— 本周统计 ————————————————————————
            <TabPane tab="本周统计" key="week">
              <Form
                name="weekStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="popularizeUserNum_3" >
                      <Statistic title="推广用户数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="popularizeAnchorNum_3" >
                      <Statistic title="推广主播数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeTotalMoney_3">
                      <Statistic title="充值金额（元）" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeCommission_3">
                      <Statistic title="充值佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="anchorIncomeCoin_3">
                      <Statistic title="主播收益金币" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="incomeCommission_3">
                      <Statistic title="主播收益分佣（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleTotalMoney_3">
                      <Statistic title="购买贵族金额（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleCommission_3">
                      <Statistic title="购买贵族佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalAmount_3">
                      <Statistic title="总收益佣金" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
               
            </TabPane>

            // —————————————————————— 上周统计 ————————————————————————
            <TabPane tab="上周统计" key="lastweek">
              <Form
                name="lastweekStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef4}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                <Col span={8}>
                    <Form.Item name="popularizeUserNum_4" >
                      <Statistic title="推广用户数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="popularizeAnchorNum_4" >
                      <Statistic title="推广主播数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeTotalMoney_4">
                      <Statistic title="充值金额（元）" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeCommission_4">
                      <Statistic title="充值佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="anchorIncomeCoin_4">
                      <Statistic title="主播收益金币" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="incomeCommission_4">
                      <Statistic title="主播收益分佣（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleTotalMoney_4">
                      <Statistic title="购买贵族金额（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleCommission_4">
                      <Statistic title="购买贵族佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalAmount_4">
                      <Statistic title="总收益佣金" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>

            // —————————————————————— 本月统计 ————————————————————————
            <TabPane tab="本月统计" key="month">
              <Form
                name="monthStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef5}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="popularizeUserNum_5" >
                      <Statistic title="推广用户数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="popularizeAnchorNum_5" >
                      <Statistic title="推广主播数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeTotalMoney_5">
                      <Statistic title="充值金额（元）" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeCommission_5">
                      <Statistic title="充值佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="anchorIncomeCoin_5">
                      <Statistic title="主播收益金币" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="incomeCommission_5">
                      <Statistic title="主播收益分佣（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleTotalMoney_5">
                      <Statistic title="购买贵族金额（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleCommission_5">
                      <Statistic title="购买贵族佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalAmount_5">
                      <Statistic title="总收益佣金" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>

            // —————————————————————— 上月统计 ————————————————————————
            <TabPane tab="上月统计" key="lastmonth">
              <Form
                name="lastmonthStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef6}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="popularizeUserNum_6" >
                      <Statistic title="推广用户数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="popularizeAnchorNum_6" >
                      <Statistic title="推广主播数量" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeTotalMoney_6">
                      <Statistic title="充值金额（元）" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rechargeCommission_6">
                      <Statistic title="充值佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="anchorIncomeCoin_6">
                      <Statistic title="主播收益金币" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="incomeCommission_6">
                      <Statistic title="主播收益分佣（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleTotalMoney_6">
                      <Statistic title="购买贵族金额（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="nobleCommission_6">
                      <Statistic title="购买贵族佣金（元）" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalAmount_6">
                      <Statistic title="总收益佣金" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>

          </Tabs>
      </Modal>
    )
  }
}

export default ShowDataStatistics