import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取贵族列表
   * @param {*} usersVipParam
   * @returns
   */
   getUsersVipPage(usersVipParam) {
      //console.log("usersVipParam=" + JSON.stringify(usersVipParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/usersVip/getUsersVipPage",
        data: usersVipParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

}