import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取管理员日志
   * @param {*} 
   * @returns
   */
  getAdminLogPage(adminLogParam) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/adminLog/getAdminLogPage",
      data: adminLogParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}