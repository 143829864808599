import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,Popconfirm ,Table,Avatar, Image} from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import userChatRecordService from '../../../Service/PrivateLetter/userChatRecordService';
import VideoPlay from '../../UserMgr/HostUser/VideoPlay';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class ShowChatRecord extends Component {
  formRef = React.createRef();

  state={
    // ———————————————————— 拨打记录 ————————————————————————————
    recordColumns: [{
        key: 'id',
        title: 'id',
        dataIndex: 'id'
    },{
      key: 'uid',
      title: '发送者',
      dataIndex: 'uid'
    },{
      key: 'toUid',
      title: '接收者',
      dataIndex: 'toUid'
    },{
      key: 'msgType',
      title: '消息类型',
      dataIndex: 'msgType',
      render: (msgType, row) => {
        if(msgType === 1) {
          return "文本"
        } else if(msgType === 2) {
          return "图片";
        } else if(msgType === 3) {
          return "语音";
        } else if(msgType === 4) {
          return "视频通话";
        }
      }
    },{
      key: 'content',
      title: '消息内容',
      dataIndex: 'content',
      render: (content, row) => {
        if(row.msgType === 1) {
          return content;
        } else if(row.msgType === 2) {
          if(content != null && content != "") {
            return <Image
            width={55}
            height={55}
            src={content}
          />
          }
        } else if(row.msgType === 3) {
          if(content != null && content != "") {
            this.state.videoRow = {
              videoUrl : content ,
            }
            return <Button type="primary" onClick={this.handlePlayVideo} style={{ marginRight: '15px' }}>
                  观看
                </Button>
          }
        } else if(row.msgType === 4) {
          return content;
        }
      }
    },{
      key: 'isCostCoin',
      title: '是否付费',
      dataIndex: 'isCostCoin',
      render: (isCostCoin, row) => {
        if(isCostCoin === 0) {
          return "免费"
        } else if(isCostCoin === 1) {
          return "付费";
        } 
      }
    },{
      key: 'costCoin',
      title: '花费金币',
      dataIndex: 'costCoin'
    },{
      key: 'createTime',
      title: '发送时间',
      dataIndex: 'createTime'
    },{
      key: 'ip',
      title: 'IP地址',
      dataIndex: 'ip'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteRecord(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    recordTotal: 0,
    recordList: null,
    videoRow: null,
    showVideoDialog: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
  }

  // 记录分页
  recordChangePage=(page, pageSize) => {
      this.props.refreshRecordData(page, pageSize, this.props.rowData.userId);
  }

  // 根据Id删除评论
  deleteRecord=(id) => {
      let { history } = this.props;
      let userChatRecordParam = {
        id : id
      };
      userChatRecordService.deleteUserChatRecord(userChatRecordParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          console.log("删除失败")
          message.info("删除失败,"+res.message);  
          // 重置关闭对话框
          this.hanldeCloseModal();
        } else {
          // 刷新数据
          this.props.refreshRecordData(1, 10, this.props.rowData.userId);
          message.success('删除成功！');
        }
        
      })
  }

  // 关闭用户审核弹框
  hideVideoDialog=() => {
    this.setState({showVideoDialog: false});
  }

  /**
   * 播放视频
   */
  handlePlayVideo=() => {
    console.log("videoRow=" + JSON.stringify(this.state.videoRow))
    this.setState({showVideoDialog:true })
  }
    
  render () {
    return (
      <Modal
        title="消息记录"
        destroyOnClose
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        width={1000}
        footer={[
          null, null,
        ]}
      >
        <Table 
          bordered
          style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
          dataSource={this.props.rowData != null ? this.props.rowData.recordList : null}
          columns={this.state.recordColumns}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          pagination = {{ total: this.props.rowData != null ? this.props.rowData.recordTotal : null, defaultCurrent: 1, onChange: this.recordChangePage}}
        >
        </Table>
        <VideoPlay rowData={this.state.videoRow} close={this.hideVideoDialog} visible={this.state.showVideoDialog}></VideoPlay>

      </Modal>
    )
  }
}

export default ShowChatRecord