import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class UpdateNobleLevel extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appGrade = {
            id: values.id,
            grade: values.grade,
            name: values.name,
            gradeIcon: values.gradeIcon,
            avatarFrame: values.avatarFrame,
            rechargeDiscount: values.rechargeDiscount,
            callDiscount: values.callDiscount,
            isEnable: values.isEnable,
            type: 4,
          }
          gradeService.insertOrUpdateGrade(appGrade)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  
  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
    

  };


  render () {
    return (
      <Modal
        title="修改"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>
      
            <Form.Item label="等级" labelCol={{span:10}} name="grade" style={{ width: 420 }} >
                <Input placeholder="等级" />
            </Form.Item>

            <Form.Item label="等级名称" labelCol={{span:10}} name="name" style={{ width: 420 }} rules={ [
                    {
                        required: true,
                        message: '请输入等级名称',
                    }
                    ]
                }>
                <Input placeholder="等级名称" />
            </Form.Item>

            <Form.Item label="等级图片" labelCol={{span:8}}  name="gradeIcon" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                    defaultFileList={[
                      {
                          url: this.props.rowData != null ? this.props.rowData.gradeIcon : null,
                          thumbUrl: this.props.rowData != null ? this.props.rowData.gradeIcon : null,
                      }
                    ]}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="头像框" labelCol={{span:8}}  name="avatarFrame" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                    defaultFileList={[
                      {
                          url: this.props.rowData != null ? this.props.rowData.avatarFrame : null,
                          thumbUrl: this.props.rowData != null ? this.props.rowData.avatarFrame : null,
                      }
                    ]}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="充值折扣" labelCol={{span:10}} name="rechargeDiscount" style={{ width: 420 }} rules={ [
                    {
                        required: true,
                        message: '请输入充值折扣',
                    }
                    ]
                }>
                <Input placeholder="充值折扣" />
            </Form.Item>

            <Form.Item label="通话折扣" labelCol={{span:10}} name="callDiscount" style={{ width: 420 }} rules={ [
                    {
                        required: true,
                        message: '请输入通话折扣',
                    }
                    ]
                }>
                <Input placeholder="通话折扣" />
            </Form.Item>

            <Form.Item label="是否启用" labelCol={{span:10}} name="isEnable" style={{ width: 420 }} initialValue={1} rules={ [
                    {
                        required: true,
                        message: '请选择是否启用',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={1}>
                  <Option value={1}>启用</Option>
                  <Option value={0}>关闭</Option>
                </Select>
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default UpdateNobleLevel