import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserId, GetLoginUserName, Logout } from '../../../Common/Auth';
import { GetAdminPath } from '../../../Common/Domain';

import moment from 'moment'



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const userId = GetLoginUserId();
const userName = GetLoginUserName();

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class OnevsOneUser extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 视频监控 ————————————————————————————

    iFrameHeight: '0px'

    // ———————————————————— 语音监控 ————————————————————————————
  
    
        

  

  }


  // 第一次渲染后调用
  componentDidMount() {
      // ———————————————————— 视频监控 ————————————————————————————

      // ———————————————————— 语音监控 ————————————————————————————
    
    


  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 视频监控 ————————————————————————————


  
  // ———————————————————— 语音监控 ————————————————————————————




 
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/1vs1_monitor">1VS1监控</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
            
            // ———————————————————— 视频监控 ——————————————————————
            <TabPane tab="视频监控" key="1">
              <iframe  ref="iframe" scrolling="no" frameBorder="0"
                  style={{width:'99%',height:this.state.iFrameHeight, overflow:'visible'}}
                  onLoad={() => {//iframe高度不超过content的高度即可
                      let h = document.documentElement.clientHeight + 200;
                      this.setState({
                          "iFrameHeight": h+'px'
                      });
                  }} 
                  src={GetAdminPath() + "static/newWebrtc/monitoring/live-ooo-monitoring.html?adminId="+GetLoginUserId() + "&adminName="+ GetLoginUserName()}
              />
             
            </TabPane>


            // ———————————————————— 语音监控 ————————————————————————————
            <TabPane tab="语音监控" key="2">
              <iframe  ref="iframe" scrolling="no" frameBorder="0"
                  style={{width:'99%',height:this.state.iFrameHeight, overflow:'visible'}}
                  onLoad={() => {//iframe高度不超过content的高度即可
                      let h = document.documentElement.clientHeight + 200;
                      this.setState({
                          "iFrameHeight": h+'px'
                      });
                  }} 
                  src={GetAdminPath() + "static/newWebrtc/monitoring/voice-ooo-monitoring.html?adminId="+GetLoginUserId() + "&adminName="+ GetLoginUserName()}
              />
              
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default OnevsOneUser;