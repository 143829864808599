import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import gradePrivilegeService from "../../../Service/GradeMgr/gradePrivilegeService";
import gradeService from "../../../Service/GradeMgr/gradeService";

import UpdateUserPrivilege from './UpdateUserPrivilege';
import UpdateWealthPrivilege from './UpdateWealthPrivilege';
import UpdateHostPrivilege from './UpdateHostPrivilege';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class HierarchicalPrivilege extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 用户特权 ————————————————————————————

    userPrivilegeColumns: [
      {
        key: "id",
        title: "特权ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "特权名称",
        dataIndex: "name",
      },
      {
        key: "desr",
        title: "特权描述",
        dataIndex: "desr",
      },
      {
        key: "identification",
        title: "标识",
        dataIndex: "identification",
      },
      {
        key: "lineLogo",
        title: "特权彩图",
        dataIndex: "lineLogo",
        render: (lineLogo, row) => {
          if (lineLogo != null && lineLogo != "") {
            return <Image width={50} height={50} src={lineLogo} />;
          }
        },
      },
      {
        key: "offLineLogo",
        title: "特权黑图",
        dataIndex: "offLineLogo",
        render: (offLineLogo, row) => {
          if (offLineLogo != null && offLineLogo != "") {
            return <Image width={50} height={50} src={offLineLogo} />;
          }
        },
      },
      {
        key: "isStart",
        title: "是否启用",
        dataIndex: "isStart",
        render: (isStart, row) => {
          if(isStart === 0) {
            return "未启用"
          } else if(isStart === 1) {
            return "启用";
          }
        }
      },
      {
        key: "type",
        title: "特权类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "用户特权"
          } else if(type === 2) {
            return "财富特权";
          } else if(type === 3) {
            return "主播特权";
          } else if(type === 4) {
            return "贵族特权";
          } else if(type === 5) {
            return "魅力特权";
          } 
        }
      },
      {
        key: "grade",
        title: "达标等级",
        dataIndex: "grade",
      },
      {
        key: "sort",
        title: "排序",
        dataIndex: "sort",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateUserPrivilegeRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    userPrivilegeTotal: 0,
    userPrivilegeList: null,
    updateUserPrivilegeRow: null,
    showUpdateUserPrivilegeDialog: false,
    addUserPrivilegeRow: null,
    showAddUserPrivilegeDialog: false,
    userPrivilegePageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 主播特权 ————————————————————————————

    hostPrivilegeColumns: [
      {
        key: "id",
        title: "特权ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "特权名称",
        dataIndex: "name",
      },
      {
        key: "desr",
        title: "特权描述",
        dataIndex: "desr",
      },
      {
        key: "identification",
        title: "标识",
        dataIndex: "identification",
      },
      {
        key: "lineLogo",
        title: "特权彩图",
        dataIndex: "lineLogo",
        render: (lineLogo, row) => {
          if (lineLogo != null && lineLogo != "") {
            return <Image width={50} height={50} src={lineLogo} />;
          }
        },
      },
      {
        key: "offLineLogo",
        title: "特权黑图",
        dataIndex: "offLineLogo",
        render: (offLineLogo, row) => {
          if (offLineLogo != null && offLineLogo != "") {
            return <Image width={50} height={50} src={offLineLogo} />;
          }
        },
      },
      {
        key: "isStart",
        title: "是否启用",
        dataIndex: "isStart",
        render: (isStart, row) => {
          if(isStart === 0) {
            return "未启用"
          } else if(isStart === 1) {
            return "启用";
          }
        }
      },
      {
        key: "type",
        title: "特权类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "用户特权"
          } else if(type === 2) {
            return "财富特权";
          } else if(type === 3) {
            return "主播特权";
          } else if(type === 4) {
            return "贵族特权";
          } else if(type === 5) {
            return "魅力特权";
          } 
        }
      },
      {
        key: "grade",
        title: "达标等级",
        dataIndex: "grade",
      },
      {
        key: "sort",
        title: "排序",
        dataIndex: "sort",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateHostPrivilegeRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    hostPrivilegeTotal: 0,
    hostPrivilegeList: null,
    updateHostPrivilegeRow: null,
    showUpdateHostPrivilegeDialog: false,
    addHostPrivilegeRow: null,
    showAddHostPrivilegeDialog: false,
    hostPrivilegePageParam:{
      pageNum: 1,
      pageSize: 10,
    },


    // ———————————————————— 财富特权 ————————————————————————————
    wealthPrivilegeColumns: [
      {
        key: "id",
        title: "特权ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "特权名称",
        dataIndex: "name",
      },
      {
        key: "desr",
        title: "特权描述",
        dataIndex: "desr",
      },
      {
        key: "identification",
        title: "标识",
        dataIndex: "identification",
      },
      {
        key: "lineLogo",
        title: "特权彩图",
        dataIndex: "lineLogo",
        render: (lineLogo, row) => {
          if (lineLogo != null && lineLogo != "") {
            return <Image width={50} height={50} src={lineLogo} />;
          }
        },
      },
      {
        key: "offLineLogo",
        title: "特权黑图",
        dataIndex: "offLineLogo",
        render: (offLineLogo, row) => {
          if (offLineLogo != null && offLineLogo != "") {
            return <Image width={50} height={50} src={offLineLogo} />;
          }
        },
      },
      {
        key: "isStart",
        title: "是否启用",
        dataIndex: "isStart",
        render: (isStart, row) => {
          if(isStart === 0) {
            return "未启用"
          } else if(isStart === 1) {
            return "启用";
          }
        }
      },
      {
        key: "type",
        title: "特权类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "用户特权"
          } else if(type === 2) {
            return "财富特权";
          } else if(type === 3) {
            return "主播特权";
          } else if(type === 4) {
            return "贵族特权";
          } else if(type === 5) {
            return "魅力特权";
          } 
        }
      },
      {
        key: "grade",
        title: "达标等级",
        dataIndex: "grade",
      },
      {
        key: "sort",
        title: "排序",
        dataIndex: "sort",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateWealthPrivilegeRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    wealthPrivilegeTotal: 0,
    wealthPrivilegeList: null,
    updateWealthPrivilegeRow: null,
    showUpdateWealthPrivilegeDialog: false,
    addWealthPrivilegeRow: null,
    showAddWealthPrivilegeDialog: false,
    wealthPrivilegePageParam:{
      pageNum: 1,
      pageSize: 10,
    },

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 用户特权 ————————————————————————————
    this.getGradePrivilege1();
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 用户特权 ————————————————————————————
  // 用户特权分页
  userPrivilegeChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        userPrivilegePageParam: pageParam,
      });

      var userPrivilegeParam = {
        type: 1,
        pageParam: pageParam,
      };
      gradePrivilegeService.getGradePrivilegePage(userPrivilegeParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            userPrivilegeList: res.retValue.list,
            userPrivilegeTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshUserPrivilegeData = () => {
    let { history } = this.props;
    //console.log("refreshUserPrivilegeData");

    var userPrivilegeParam = {
      type: 1,
      pageParam: this.state.userPrivilegePageParam,
    };
    gradePrivilegeService.getGradePrivilegePage(userPrivilegeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          userPrivilegeList: res.retValue.list,
          userPrivilegeTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateUserPrivilegeDialog = () => {
    this.setState({ showUpdateUserPrivilegeDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddUserPrivilegeDialog = () => {
    this.setState({ showAddUserPrivilegeDialog: false });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateUserPrivilegeRowData = (row) => {
    let { history } = this.props;
    var gradeParam = {
      type: 1,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.updateUserPrivilegeRow = {
          userGradeList: res.retValue,
          id: row.id,
          name: row.name,
          desr: row.desr,
          identification: row.identification,
          lineLogo: row.lineLogo,
          offLineLogo: row.offLineLogo,
          isStart: row.isStart,
          type: row.type,
          grade: row.grade,
          sort: row.sort,
        };
  
        this.setState({ showUpdateUserPrivilegeDialog: true });
      }    
      
    });
  };

  // ———————————————————— 主播特权 ————————————————————————————
  // 用户特权分页
  hostPrivilegeChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        hostPrivilegePageParam: pageParam,
      });

      var hostPrivilegeParam = {
        type: 3,
        pageParam: pageParam,
      };
      gradePrivilegeService.getGradePrivilegePage(hostPrivilegeParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            hostPrivilegeList: res.retValue.list,
            hostPrivilegeTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshHostPrivilegeData = () => {
    let { history } = this.props;
    //console.log("refreshHostPrivilegeData");

    var hostPrivilegeParam = {
      type: 3,
      pageParam: this.state.hostPrivilegePageParam,
    };
    gradePrivilegeService.getGradePrivilegePage(hostPrivilegeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          hostPrivilegeList: res.retValue.list,
          hostPrivilegeTotal: res.retValue.total,
        });
      }    

    });
  };

  // 隐藏分成方案弹窗
  hideUpdateHostPrivilegeDialog = () => {
    this.setState({ showUpdateHostPrivilegeDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddHostPrivilegeDialog = () => {
    this.setState({ showAddHostPrivilegeDialog: false });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateHostPrivilegeRowData = (row) => {
    let { history } = this.props;
    var gradeParam = {
      type: 3,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.updateHostPrivilegeRow = {
          hostGradeList: res.retValue,
          id: row.id,
          name: row.name,
          desr: row.desr,
          identification: row.identification,
          lineLogo: row.lineLogo,
          offLineLogo: row.offLineLogo,
          isStart: row.isStart,
          type: row.type,
          grade: row.grade,
          sort: row.sort,
        };
  
        this.setState({ showUpdateHostPrivilegeDialog: true });
      }    
      
    });
  };

  // ———————————————————— 财富特权 ————————————————————————————
   // 财富特权
   wealthPrivilegeChangePage = (page, pageSize) => {
    let { history } = this.props;
    //console.log("changePage 进入");

    this.formRef.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        wealthPrivilegePageParam: pageParam,
      });

      var wealthPrivilegeParam = {
        type: 2,
        pageParam: pageParam,
      };
      gradePrivilegeService.getGradePrivilegePage(wealthPrivilegeParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            wealthPrivilegeList: res.retValue.list,
            wealthPrivilegeTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshWealthPrivilegeData = () => {
    let { history } = this.props;
    //console.log("refreshHostPrivilegeData");

    var wealthPrivilegeParam = {
      type: 2,
      pageParam: this.state.wealthPrivilegePageParam,
    };
    gradePrivilegeService.getGradePrivilegePage(wealthPrivilegeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          wealthPrivilegeList: res.retValue.list,
          wealthPrivilegeTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateWealthPrivilegeDialog = () => {
    this.setState({ showUpdateWealthPrivilegeDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddWealthPrivilegeDialog = () => {
    this.setState({ showAddWealthPrivilegeDialog: false });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateWealthPrivilegeRowData = (row) => {
    let { history } = this.props;
    var gradeParam = {
      type: 2,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.updateWealthPrivilegeRow = {
          wealthGradeList: res.retValue,
          id: row.id,
          name: row.name,
          desr: row.desr,
          identification: row.identification,
          lineLogo: row.lineLogo,
          offLineLogo: row.offLineLogo,
          isStart: row.isStart,
          type: row.type,
          grade: row.grade,
          sort: row.sort,
        };
  
        this.setState({ showUpdateWealthPrivilegeDialog: true });
      }    
      
    });
  };


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getGradePrivilege1();
    } else if(key == "2") {
      this.getGradePrivilege2();
    } else if(key == "3") {
      this.getGradePrivilege3();
    }
  }

  /**
   * 用户特权
   */
  getGradePrivilege1 = () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var userPrivilegeParam = {
      type: 1,
      pageParam: pageParam,
    };
    gradePrivilegeService.getGradePrivilegePage(userPrivilegeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          userPrivilegeList: res.retValue.list,
          userPrivilegeTotal: res.retValue.total,
        });
      }    
      
    });
  }

  /**
   * 主播特权
   */
  getGradePrivilege2 = () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var hostPrivilegeParam = {
      type: 3,
      pageParam: pageParam,
    };
    gradePrivilegeService.getGradePrivilegePage(hostPrivilegeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          hostPrivilegeList: res.retValue.list,
          hostPrivilegeTotal: res.retValue.total,
        });
      }    
      
    });
  }

  /**
   * 财富特权
   */
  getGradePrivilege3 = () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var wealthPrivilegeParam = {
      type: 2,
      pageParam: pageParam,
    };
    gradePrivilegeService.getGradePrivilegePage(wealthPrivilegeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          wealthPrivilegeList: res.retValue.list,
          wealthPrivilegeTotal: res.retValue.total,
        });
      }    
      
    });
  }


  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/hierarchical_privilege">等级特权</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            // ———————————————————— 用户特权 ——————————————————————
            <TabPane tab="用户特权" key="1">

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.userPrivilegeList}
                columns={this.state.userPrivilegeColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.userPrivilegeTotal,
                  defaultCurrent: 1,
                  onChange: this.userPrivilegeChangePage,
                }}
              ></Table>

              <UpdateUserPrivilege
                rowData={this.state.updateUserPrivilegeRow}
                close={this.hideUpdateUserPrivilegeDialog}
                visible={this.state.showUpdateUserPrivilegeDialog}
                refreshData={this.refreshUserPrivilegeData}
              ></UpdateUserPrivilege>
            </TabPane>
            
            // ———————————————————— 主播特权 ————————————————————————————
            <TabPane tab="主播特权" key="2">
              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.hostPrivilegeList}
                columns={this.state.hostPrivilegeColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.hostPrivilegeTotal,
                  defaultCurrent: 1,
                  onChange: this.hostPrivilegeChangePage,
                }}
              ></Table>

              <UpdateHostPrivilege
                rowData={this.state.updateHostPrivilegeRow}
                close={this.hideUpdateHostPrivilegeDialog}
                visible={this.state.showUpdateHostPrivilegeDialog}
                refreshData={this.refreshHostPrivilegeData}
              ></UpdateHostPrivilege>

            </TabPane>
            
            // —————————————————————— 财富特权 ————————————————————————
            <TabPane tab="财富特权" key="3">
              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.wealthPrivilegeList}
                columns={this.state.wealthPrivilegeColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.wealthPrivilegeTotal,
                  defaultCurrent: 1,
                  onChange: this.wealthPrivilegeChangePage,
                }}
              ></Table>

              <UpdateWealthPrivilege
                rowData={this.state.updateWealthPrivilegeRow}
                close={this.hideUpdateWealthPrivilegeDialog}
                visible={this.state.showUpdateWealthPrivilegeDialog}
                refreshData={this.refreshWealthPrivilegeData}
              ></UpdateWealthPrivilege>

            </TabPane>
          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}
export default HierarchicalPrivilege;
