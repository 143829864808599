import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {
    /**
     * 更新用户资料审核开关
     * @param {*} userCheckConfigParam 
     * @returns 
     */
    updateUserCheckConfig(userCheckConfigParam) {
        //console.log("data=" + JSON.stringify(data));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/anchorCheckConfig/updateUserCheckConfig",
        data: userCheckConfigParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


    /**
     * 查询用户资料审核开关配置
     * @returns 
     */
    getUserCheckConfig() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/anchorCheckConfig/getUserCheckConfig",
        data: null,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 更新动态配置
     * @param {*} dynamicConfigParam 
     * @returns 
     */
     updateDynamicConfig(dynamicConfigParam) {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/anchorCheckConfig/updateDynamicConfig",
            data: dynamicConfigParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
     * 查询用户资料审核开关配置
     * @returns 
     */
     getDynamicConfig() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/anchorCheckConfig/getDynamicConfig",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },
}