import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import adminUserCashAccountService from '../../../Service/MyAgent/adminUserCashAccountService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { GetLoginUserId, Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class AddWithdrawalAccount extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var userId = GetLoginUserId();
          var adminUserCashAccount = {
            uid: userId,
            type: values.type,
            account: values.account,
            name: values.name,
          }
          adminUserCashAccountService.insertOrUpdateCashAccount(adminUserCashAccount)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="类型" labelCol={{span:10}} name="type" initialValue={1} style={{ width: 420 }}>
                <Select style={{ width: 220 }} defaultValue={1}>
                  <Option value={1}>支付宝</Option>
                </Select>
            </Form.Item>

            <Form.Item label="支付宝账号" labelCol={{span:10}} name="account" style={{ width: 420 }}>
                <Input placeholder="支付宝账号" />
            </Form.Item>

            <Form.Item label="真实姓名" labelCol={{span:10}} name="name" style={{ width: 420 }}>
                <Input placeholder="真实姓名" />
            </Form.Item>
            
        </Form>
      </Modal>
    )
  }
}

export default AddWithdrawalAccount