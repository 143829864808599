import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取支付记录
   * @param {*} 
   * @returns
   */
   getPayRecordPage(payRecordParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/payRecord/getPayRecordPage",
      data: payRecordParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

    
}