import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,Popconfirm ,Table,Avatar} from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import usersVideoCommentsService from '../../../Service/Dynamic/usersVideoCommentsService';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class ShowDynamicComment extends Component {
  formRef = React.createRef();

  state={
    // ———————————————————— 拨打记录 ————————————————————————————
    commentColumns: [{
        key: 'id',
        title: 'id',
        dataIndex: 'id'
    },{
      key: 'uid',
      title: '评论者UID',
      dataIndex: 'uid'
    },{
      key: 'userName',
      title: '评论者名称',
      dataIndex: 'userName'
    },{
      key: 'avatar',
      title: '评论者头像',
      dataIndex: 'avatar',
      render: (avatar, row) => {
        if(avatar != null && avatar != "") {
          return <Avatar shape="square" size={55} src={avatar}></Avatar>
        }
      }
    },{
      key: 'uMobile',
      title: '评论者手机号',
      dataIndex: 'uMobile'
    },{
      key: 'videoid',
      title: '动态id',
      dataIndex: 'videoid'
    },{
      key: 'content',
      title: '评论内容',
      dataIndex: 'content'
    },{
      key: 'addtime',
      title: '评论时间',
      dataIndex: 'addtime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteComment(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    commentTotal: 0,
    commentList: null,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
  }

  // 礼物关联分页
  commentChangePage=(page, pageSize) => {
      this.props.refreshCommentData(page, pageSize, this.props.rowData.videoid);
  }

  // 根据Id删除评论
  deleteComment=(id) => {
      let { history } = this.props;
      let usersVideoCommentsParam = {
        id : id
      };
      usersVideoCommentsService.deleteComment(usersVideoCommentsParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.props.refreshCommentData(1, 10, this.props.rowData.videoid);
          message.success('删除成功！');
        }
        
      })

  }


  render () {
    return (
      <Modal
        title="查看评论"
        destroyOnClose
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        width={1000}
        footer={[
          null, null,
        ]}
      >
        <Table 
          bordered
          style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
          dataSource={this.props.rowData != null ? this.props.rowData.commentList : null}
          columns={this.state.commentColumns}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          pagination = {{ total: this.props.rowData != null ? this.props.rowData.commentTotal : null, defaultCurrent: 1, onChange: this.commentChangePage}}
        >
        </Table>
      </Modal>
    )
  }
}

export default ShowDynamicComment