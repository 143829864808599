import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import adminRushToTalkConfigService from '../../../Service/1vs1Mgr/adminRushToTalkConfigService';
import liveConfigService from '../../../Service/1vs1Mgr/liveConfigService';
import Service from '../../../Service';
import AddTalkConfig from './AddTalkConfig';
import UpdateTalkConfig from './UpdateTalkConfig';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class beggingChat extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state={
    // ———————————————————— 求聊话费设置 ————————————————————————————
    talkConfigColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'chatType',
      title: '话费类型',
      dataIndex: 'chatType',
      render: (chatType, row) => {
        if(chatType === 1) {
          return "视频聊天"
        } else if(chatType === 2) {
          return "语音聊天";
        } 
      }
    },{
      key: 'telephoneMoney',
      title: '话费(金币/分钟)',
      dataIndex: 'telephoneMoney'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateTalkConfigDialog:true, updateTalkConfigRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteConfig(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    talkConfigTotal: 0,
    talkConfigList: [],
    updateTalkConfigRow: null,
    showUpdateTalkConfigDialog: false,
    addTalkConfigRow: null,
    showAddTalkConfigDialog: false,
    talkConfigPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 求聊功能设置 ————————————————————————————
    askConfig: null,
    oooAskChatList:[],
    pushMusicList:[],
    oooAskWaitList:[],

    // ———————————————————— 全局求聊弹窗 ————————————————————————————
    globalChatConfig: null,
    showAvatarList:[],    
    
    // ———————————————————— 选聊功能设置 ————————————————————————————
    selectChatConfig: null,
    oooSpeedDatingList:[],
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 求聊话费设置 ————————————————————————————
    this.getAdminRushToTalkConfig();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 求聊话费设置 ————————————————————————————

  // 关闭添加用户弹框
  hideAddTalkConfigDialog=() => {
    this.setState({showAddTalkConfigDialog: false});
  }

  // 关闭编辑用户弹框
  hideUpdateTalkConfigDialog=() => {
    this.setState({showUpdateTalkConfigDialog: false});
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  talkConfigChangePage(page, pageSize) {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      talkConfigPageParam: pageParam,
    });

    var adminRushToTalkConfigParam={
      pageParam: pageParam
    }

    adminRushToTalkConfigService.getConfigPage(adminRushToTalkConfigParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({talkConfigList: res.retValue.list, talkConfigTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新导航数据
   */
  refreshTalkConfigData=() => {
      let { history } = this.props;

      var adminRushToTalkConfigParam={
        pageParam: this.state.talkConfigPageParam
      }

      adminRushToTalkConfigService.getConfigPage(adminRushToTalkConfigParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({talkConfigList: res.retValue.list, talkConfigTotal: res.retValue.total});
          }
        }
      )
  }

  // 根据Id删除标签
  deleteConfig=(id) => {
    let { history } = this.props;
      let adminRushToTalkConfigParam = {
        id : id
      };
      adminRushToTalkConfigService.deleteConfig(adminRushToTalkConfigParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          this.refreshTalkConfigData();
          message.success('删除成功！');
        }
        
      })

  }

// ———————————————————— 求聊功能设置 ————————————————————————————

   // 图片修改
   handleChangeFileImage=(e) => {
    if (e.file.status === 'uploading') {
        this.state.loading=true;
        return;
    }
    if (e.file.status === 'done') {
        //console.log("test="+e.file.response);
        this.state.loading=false;
        //this.state.imageUrl=e.file.response;
        return e.file.response;
      }
}

// 上传图片前置校验
beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

 // 上传图片
 doImgUpload = (options) => {
  const { onSuccess, onError, file, onProgress } = options;
  
  Service.uploadQiniu(file)
  .then((observable) => {
    observable.subscribe({
      error: () => {
          console.log('上传失败');
      },
      complete: (res) => {
        //console.log('上传完成，res='+JSON.stringify(res));
        var url = Service.getDoamin() + res.key;
        //console.log('上传成功：' + url);
        onProgress({percent: 100});
        onSuccess(url, file); 
      },
    });
  })

};

/**
 * 上传按钮
 */
uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );



handleOooAskChatChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
    this.setState({ oooAskChatList: [...fileList] });
};

doRemove = (file) => {
  console.log("file=" + JSON.stringify(file));
}

handlePreview = (file) => {
    const imageUrl = file.url || file.thumbUrl || '';

    this.state.previewImage = imageUrl;
    this.state.previewVisible = true;
};

handleCancel = () => {
    this.state.previewVisible = false;
};

normFile = (e) => {
    //console.log("normFile e=" + JSON.stringify(e)); // file 是当前正在上传的 单个 img
    var fileList = e.fileList;
    var fileListString = "";
    if(fileList.length <= 0) {
        return fileListString;
    }
    for(var i=0; i< fileList.length;i++) {
        var file = fileList[i];
        fileListString = fileListString + file.response;
        if(i < fileList.length-1) {
            fileListString = fileListString + ",";
        }
    }
    return fileListString;
};

// 上传视频前置校验
beforeVideoUpload(file) {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
        message.error('video must smaller than 5MB!');
    }
    return isLt5M;
}

// 上传语音前置校验
beforeAudioUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('viaudiodeo must smaller than 2MB!');
    }
    return isLt2M;
}


/**
   * 求聊配置提交
   */
 handleBeggingChatSubmit=() => {
  //console.log("changePage 进入");

  this.formRef2.current.validateFields()
  .then(values => {

      var oooLiveConfig = values;

      liveConfigService.updateConfig(oooLiveConfig)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            console.log("修改失败")
            message.info("修改失败,"+res.message);  
            // 重置关闭对话框
            //this.hanldeCloseModal();
          } else {
            message.info('修改成功！');
            // 重置关闭对话框
            //this.hanldeCloseModal();
          }
  
        }
      )
  })

}



// ———————————————————— 全局求聊弹窗 ————————————————————————————

/**
 * 全局求聊配置提交
 */
 handleGlobalChatSubmit=() => {
  //console.log("changePage 进入");

  this.formRef3.current.validateFields()
  .then(values => {

      var oooLiveConfig = values;

      liveConfigService.updateConfig(oooLiveConfig)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            console.log("修改失败")
            message.info("修改失败,"+res.message);  
            // 重置关闭对话框
            //this.hanldeCloseModal();
          } else {
            message.info('修改成功！');
            // 重置关闭对话框
            //this.hanldeCloseModal();
          }
  
        }
      )
  })

} 

handlePushMusicChange = ({ file, fileList }) => {
  this.setState({ pushMusicList: [...fileList] });
};

handleOooAskWaitChange = ({ file, fileList }) => {
  this.setState({ oooAskWaitList: [...fileList] });
};


// ———————————————————— 选聊功能设置 ————————————————————————————

/**
 * 选聊功能提交
 */
 handleSelectChatSubmit=() => {
  //console.log("changePage 进入");

  this.formRef4.current.validateFields()
  .then(values => {

      var oooLiveConfig = values;

      liveConfigService.updateConfig(oooLiveConfig)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            console.log("修改失败")
            message.info("修改失败,"+res.message);  
            // 重置关闭对话框
            //this.hanldeCloseModal();
          } else {
            message.info('修改成功！');
            // 重置关闭对话框
            //this.hanldeCloseModal();
          }
  
        }
      )
  })

} 

handleShowAvatarChange = ({ file, fileList }) => {
  this.setState({ showAvatarList: [...fileList] });
};


handleOooSpeedDatingChange = ({ file, fileList }) => {
  this.setState({ oooSpeedDatingList: [...fileList] });
};



/**
 * tab切换
 * @param {*} key 
 */
onTabChanged = (key) => {
  //console.log("onTabChanged key="+key);
  if(key == "1") {
    this.getAdminRushToTalkConfig();
  } else if(key == "2") {
    this.getLiveConfig1();
  } else if(key == "3") {
    this.getLiveConfig2();
  } else if(key == "4") {
    this.getLiveConfig3();
  }
}

/**
 * 求聊话费设置
 */
getAdminRushToTalkConfig = () => {
  let { history } = this.props;
  var pageParam = { 
    pageNum: 1,
    pageSize: 10
  }

  var adminRushToTalkConfigParam={
    pageParam: pageParam
  }

  adminRushToTalkConfigService.getConfigPage(adminRushToTalkConfigParam)
  .then(
    res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({talkConfigList: res.retValue.list, talkConfigTotal: res.retValue.total});
      }
    }
  )
}

  /**
   * 求聊功能设置
   */
  getLiveConfig1=() => {
    let { history } = this.props;

    liveConfigService.getConfig()
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.formRef2.current.setFieldsValue({
              id: res.retValue.id,
              callPriceUu: res.retValue.callPriceUu,
              oooAskChat: res.retValue.oooAskChat,
              pushMusic: res.retValue.pushMusic,
              oooAskWait: res.retValue.oooAskWait,
            });

            this.setState({oooAskChatList: [{
              url : res.retValue.oooAskChat,
              thumbUrl: res.retValue.oooAskChat,
            }]});

            this.setState({pushMusicList: [{
              url : res.retValue.pushMusic,
              thumbUrl: res.retValue.pushMusic,
              name: res.retValue.pushMusic,
            }]});


            this.setState({oooAskWaitList: [{
              url : res.retValue.oooAskWait,
              thumbUrl: res.retValue.oooAskWait,
            }]});

            this.setState({askConfig: res.retValue});
            
          }
          
        }
      )
  }

  /**
   * 全局求聊弹窗 
   */
  getLiveConfig2=() => {
    let { history } = this.props;

    liveConfigService.getConfig()
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            
            // ———————————————————— 全局求聊弹窗 ————————————————————————————
            this.formRef3.current.setFieldsValue({
              id: res.retValue.id,
              globalChatWindow: res.retValue.globalChatWindow,
              loginPopUp: res.retValue.loginPopUp,
              userPopUpNum: res.retValue.userPopUpNum,
              popUpInterval: res.retValue.popUpInterval,
              showTitle: res.retValue.showTitle,
              showDescribe: res.retValue.showDescribe,
              showAvatar: res.retValue.showAvatar,
            });

            this.setState({showAvatarList: [{
              url : res.retValue.showAvatar,
              thumbUrl: res.retValue.showAvatar,
            }]});

            this.setState({globalChatConfig: res.retValue});
    
          }
          
        }
      )
  }

  /**
   * 选聊功能设置
   */
  getLiveConfig3=() => {
    let { history } = this.props;

    liveConfigService.getConfig()
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
    
            // ———————————————————— 选聊功能设置 ————————————————————————————
            this.formRef4.current.setFieldsValue({
              id: res.retValue.id,
              multiplayerRule: res.retValue.multiplayerRule,
              oooSpeedDating: res.retValue.oooSpeedDating,
            });

            this.setState({oooSpeedDatingList: [{
              url : res.retValue.oooSpeedDating,
              thumbUrl: res.retValue.oooSpeedDating,
            }]});

            this.setState({selectChatConfig: res.retValue});
          }
          
        }
      )
  }

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/begging_chat">求聊抢聊</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 求聊话费设置 ————————————————————————————
            <TabPane tab="求聊话费设置" key="1">
              <Form
                name="TalkConfigListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '0px 0px 0px 0px'}}
              >
                <Row gutter={24}>

                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddTalkConfigDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.talkConfigList}
                columns={this.state.talkConfigColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.talkConfigTotal, defaultCurrent: 1, onChange: this.talkConfigChangePage}}
              >
              </Table>
              <AddTalkConfig close={this.hideAddTalkConfigDialog} visible={this.state.showAddTalkConfigDialog} refreshData={this.refreshTalkConfigData}></AddTalkConfig>
              <UpdateTalkConfig rowData={this.state.updateTalkConfigRow} close={this.hideUpdateTalkConfigDialog} visible={this.state.showUpdateTalkConfigDialog} refreshData={this.refreshTalkConfigData}></UpdateTalkConfig>
              
            </TabPane>
            
            // ———————————————————— 求聊功能设置 ——————————————————————
            <TabPane tab="求聊功能设置" key="2">
              <Form 
                layout="horizontal"
                labelCol={{span:4}}
                wrapperCol={{span: 20}}
                ref={this.formRef2}
                style={{ margin: '20px 0px 0px 100px'}}
                initialValues={this.state.askConfig}
              >
                <Form.Item label="id" name="id" hidden>
                    <Input placeholder="id" />
                </Form.Item>

                <Form.Item label="一对一求聊背景图" name="oooAskChat" 
                    labelCol={{span:10}}  style={{ width: 390 }}
                    valuePropName="file"
                    getValueFromEvent={this.handleChangeFileImage}
                    >
                    <Upload
                        accept="image/*"
                        name="fiavatarle"
                        listType="picture-card" 
                        onChange={this.handleOooAskChatChange}
                        customRequest={this.doImgUpload}
                        beforeUpload={this.beforeUpload}
                        defaultFileList={this.state.oooAskChatList}
                        fileList={this.state.oooAskChatList}
                    >
                        {this.uploadButton}
                    </Upload>
                </Form.Item>


                <Form.Item label="求聊音乐" name="pushMusic" 
                  labelCol={{span:10}}  style={{ width: 390 }}
                  valuePropName="file"
                  getValueFromEvent={this.handleChangeFileImage}
                  >
                    <Upload
                        accept="audio/*"
                        name="fiavatarle"
                        onChange={this.handlePushMusicChange}
                        customRequest={this.doImgUpload}
                        beforeUpload={this.beforeAudioUpload}
                        defaultFileList={this.state.pushMusicList}
                        fileList={this.state.pushMusicList}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item label="一对一求聊背景图" name="oooAskWait" 
                    labelCol={{span:10}}  style={{ width: 390 }}
                    valuePropName="file"
                    getValueFromEvent={this.handleChangeFileImage}
                    >
                    <Upload
                        accept="image/*"
                        name="fiavatarle"
                        listType="picture-card" 
                        onChange={this.handleOooAskWaitChange}
                        customRequest={this.doImgUpload}
                        beforeUpload={this.beforeUpload}
                        defaultFileList={this.state.oooAskWaitList}
                        fileList={this.state.oooAskWaitList}
                    >
                        {this.uploadButton}
                    </Upload>
                </Form.Item>  

                <Form.Item label="用户之间通话价格" name="callPriceUu" labelCol={{span:10}}  style={{ width: 390 }}>
                    <Input placeholder="用户之间通话价格" />
                </Form.Item>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleBeggingChatSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>

            </Form>

            
            </TabPane>

            // —————————————————————— 全局求聊弹窗 ————————————————————————
            <TabPane tab="全局求聊弹窗" key="3">
                <Form
                  name="GlobalChatForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.globalChatConfig}
                >

                    <Form.Item label="id" name="id" hidden>
                        <Input placeholder="id" />
                    </Form.Item>

                    <Form.Item label="全局求聊弹窗(仅男用户)" name="globalChatWindow" labelCol={{span:10}}  style={{ width: 450 }}>
                      <Radio.Group name="globalChatWindow" defaultValue={0}>
                        <Radio value={0}>关闭</Radio>
                        <Radio value={1}>开启</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item label="登录后多久后弹出(秒)" name="loginPopUp" labelCol={{span:10}}  style={{ width: 390 }}>
                        <Input placeholder="登录后多久后弹出(秒)" />
                    </Form.Item>

                    <Form.Item label="每天用户弹出次数" name="userPopUpNum" labelCol={{span:10}}  style={{ width: 390 }} >
                        <Input placeholder="每天用户弹出次数" />
                    </Form.Item>

                    <Form.Item label="弹出间隔(秒)" name="popUpInterval" labelCol={{span:10}}  style={{ width: 390 }} >
                        <Input placeholder="弹出间隔(秒)" />
                    </Form.Item>

                    <Form.Item label="展示头像" name="showAvatar" 
                        labelCol={{span:10}}  style={{ width: 390 }}
                        valuePropName="file"
                        getValueFromEvent={this.handleChangeFileImage}
                        >
                        <Upload
                            accept="image/*"
                            name="fiavatarle"
                            listType="picture-card" 
                            onChange={this.handleShowAvatarChange}
                            customRequest={this.doImgUpload}
                            beforeUpload={this.beforeUpload}
                            defaultFileList={this.state.showAvatarList}
                            fileList={this.state.showAvatarList}

                        >
                            {this.uploadButton}
                        </Upload>
                    </Form.Item> 

                    <Form.Item label="展示标题" name="showTitle" labelCol={{span:10}}  style={{ width: 390 }} >
                        <Input placeholder="展示标题" />
                    </Form.Item>

                    <Form.Item label="展示描述" name="showDescribe" labelCol={{span:10}}  style={{ width: 390 }} >
                        <Input placeholder="展示描述" />
                    </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleGlobalChatSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
              
            </TabPane>

            // —————————————————————— 选聊功能设置 ————————————————————————
            <TabPane tab="选聊功能设置" key="4">
                <Form
                  name="SelectChatForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef4}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.selectChatConfig}
                >

                    <Form.Item label="id" name="id" hidden>
                        <Input placeholder="id" />
                    </Form.Item>

                    <Form.Item label="一对一速配背景图" name="oooSpeedDating" 
                        labelCol={{span:10}}  style={{ width: 390 }}
                        valuePropName="file"
                        getValueFromEvent={this.handleChangeFileImage}
                        >
                        <Upload
                            accept="image/*"
                            name="fiavatarle"
                            listType="picture-card" 
                            onChange={this.handleOooSpeedDatingChange}
                            customRequest={this.doImgUpload}
                            beforeUpload={this.beforeUpload}
                            defaultFileList={this.state.oooSpeedDatingList}
                            fileList={this.state.oooSpeedDatingList}

                        >
                            {this.uploadButton}
                        </Upload>
                    </Form.Item> 

                    <Form.Item label="速配说明" name="multiplayerRule" labelCol={{span:10}}  style={{ width: 390 }} >
                        <Input placeholder="速配说明" />
                    </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleSelectChatSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>

            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default beggingChat;