import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import Service from '../../../Service/index';
import versionManageService from '../../../Service/BasicInformation/versionManageService';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class AppVersionMgr extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={

    // ———————————————————— 安卓版本管理 ————————————————————————————
    androidVersionManage: null,
    apkUrl:null,

    // ———————————————————— IOS版本管理 ————————————————————————————
    iosVersionManage: null,
    ipaUrl:null,
    iosIconUrlList: null,

      

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 安卓版本管理 ————————————————————————————
    this.getVersionManage1();
       
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 安卓版本管理 ————————————————————————————
  handleAndroidVersionManageSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef.current.validateFields()
    .then(values => {

        var adminVersionManage = values;
        versionManageService.updateConfig(adminVersionManage)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          console.log("handleChangeFileImage="+e.file.response);
          this.state.loading=false;

          return e.file.response;
        }
  }


  // APK修改
  handleChangeFileApk=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          console.log("handleChangeFileApk="+e.file.response);
          this.state.loading=false;

          this.formRef.current.setFieldsValue({
            apkUrl: e.file.response,
          });
          return e.file.response;
        }
  }

  // IPA包修改
  handleChangeFileIpa=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          console.log("handleChangeFileIpa="+e.file.response);
          this.state.loading=false;

          this.formRef.current.setFieldsValue({
            ipaPackUrl: e.file.response,
          });
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传app
  doUploadApp = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadApp(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
        next:(res) => {
          //console.log('next，res='+JSON.stringify(res));
          onProgress({percent: res.total.percent});
        },
      });
    })
  };

  // 上传app
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })
  
  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {

  };

  // 上传APK前置校验
  beforeAppUpload(file) {
    const isLt200M = file.size / 1024 / 1024 < 200;
    if (!isLt200M) {
        message.error('video must smaller than 200MB!');
    }
    return isLt200M;
  }


  // ———————————————————— IOS版本管理 ————————————————————————————

  handleIosVersionManageSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {

        var adminVersionManage = values;
        versionManageService.updateConfig(adminVersionManage)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }

  handleIosIconUrlChange = ({ file, fileList }) => {
    this.setState({ iosIconUrlList: [...fileList] });
  };

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getVersionManage1();
    } else if(key == "2") {
      this.getVersionManage2();
    }
  }

  /**
   * 安卓版本管理
   */
  getVersionManage1 = () => {
    let { history } = this.props;
    var versionManageParam = {
      id: 1
    }
    versionManageService.getConfig(versionManageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));

          this.formRef.current.setFieldsValue({
            id: res.retValue.id,
            isConstraintApk: res.retValue.isConstraintApk,
            apkVersion: res.retValue.apkVersion,
            apkUrl: res.retValue.apkUrl,
            apkVersionCode: res.retValue.apkVersionCode,
            apkDesc: res.retValue.apkDesc,
          });
          this.setState({androidVersionManage: res.retValue});

        }
      }
    ) 
  }

  /**
   * IOS版本管理
   */
  getVersionManage2 = () => {
    let { history } = this.props;
    var versionManageParam = {
      id: 1
    }
    versionManageService.getConfig(versionManageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
  
          this.formRef2.current.setFieldsValue({
            id: res.retValue.id,
            isConstraintIpa: res.retValue.isConstraintIpa,
            ipaDesc: res.retValue.ipaDesc,
            iosOpenInstall: res.retValue.iosOpenInstall,
            ipaVersion: res.retValue.ipaVersion,
            ipaVersionCode: res.retValue.ipaVersionCode,
            ipaUrl: res.retValue.ipaUrl,
            ipaPackUrl: res.retValue.ipaPackUrl,
            iosPackName: res.retValue.iosPackName,
            iosApplicationName: res.retValue.iosApplicationName,
            ipaPlistUrl: res.retValue.ipaPlistUrl,
            iosIconUrl: res.retValue.iosIconUrl,

          });

          this.setState({iosIconUrlList: [{
            url : res.retValue.iosIconUrl,
            thumbUrl: res.retValue.iosIconUrl,
          }]});

          this.setState({iosVersionManage: res.retValue});
        }
        
      }
    )
  }
  


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/app_version_mgr">APP版本管理</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // —————————————————————— 安卓版本管理 ————————————————————————
            <TabPane tab="安卓版本管理" key="1">
              <Form
                  name="AndroidVersionManageForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.androidVersionManage}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="安卓强制更新" name="isConstraintApk" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="isConstraintApk" defaultValue={0}>
                          <Radio value={0}>非强制</Radio>
                          <Radio value={1}>强制</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="安卓APK文件" name="apkUrl" labelCol={{span:5}} style={{ width: 840 }} >
                          <Input placeholder="安卓APK文件"  />
                      </Form.Item>

                      <Form.Item label="上传安卓APK文件" name="apkUrlUpload" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileApk}
                          >
                          <Upload
                              accept="apk/*"
                              name="fiavatarle"
                              onChange={this.handleChange}
                              customRequest={this.doUploadApp}
                              beforeUpload={this.beforeAppUpload}
                          >
                              <Button icon={<UploadOutlined />}>Click to Upload</Button>
                          </Upload>
                      </Form.Item>

                      <Form.Item label="安卓版本号" name="apkVersion" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="安卓版本号"  />
                      </Form.Item>

                      <Form.Item label="安卓版本code" name="apkVersionCode" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="安卓版本code"  />
                      </Form.Item>

                      <Form.Item label="安卓更新说明" name="apkDesc" labelCol={{span:10}} style={{ width: 420 }} >
                          <TextArea placeholder="安卓更新说明"  />
                      </Form.Item>

                    
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleAndroidVersionManageSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— IOS版本管理 ————————————————————————
            <TabPane tab="IOS版本管理" key="2">
              <Form
                  name="IosVersionManageForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.iosVersionManage}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="苹果强制更新" name="isConstraintIpa" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="isConstraintIpa" defaultValue={0}>
                          <Radio value={0}>非强制</Radio>
                          <Radio value={1}>强制</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="苹果更新说明" name="ipaDesc" labelCol={{span:10}} style={{ width: 420 }} >
                          <TextArea placeholder="苹果更新说明"  />
                      </Form.Item>

                      <Form.Item label="下载方式" name="iosOpenInstall" labelCol={{span:10}} style={{ width: 420 }} initialValue={0}>
                        <Radio.Group name="iosOpenInstall" defaultValue={0}>
                          <Radio value={0}>苹果或其他</Radio>
                          <Radio value={1}>OTA在线安装</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item dependencies={['iosOpenInstall']} noStyle>
                          {({ getFieldValue }) => {
                              const type = getFieldValue('iosOpenInstall')

                              if (type === 0) {
                              return (
                                  <>
                                  <Form.Item label="苹果版本号" name="ipaVersion" labelCol={{span:10}} style={{ width: 420 }}>
                                      <Input />
                                  </Form.Item>
                                  <Form.Item label="苹果版本code" name="ipaVersionCode" labelCol={{span:10}} style={{ width: 420 }}>
                                      <Input />
                                  </Form.Item>

                                  <Form.Item label="苹果下载地址" name="ipaUrl" labelCol={{span:10}} style={{ width: 420 }}>
                                      <Input />
                                  </Form.Item>
                                  </>
                              )
                              }

                              if (type === 1) {
                              return (
                                  <>
                                  <Form.Item label="苹果包地址" name="ipaPackUrl" labelCol={{span:5}} style={{ width: 840 }} >
                                      <Input placeholder="苹果包地址"  />
                                  </Form.Item>

                                  <Form.Item label="上传苹果包" name="ipaPackUrlUpload" 
                                      valuePropName="file"
                                      labelCol={{span:10}} 
                                      style={{ width: 420 }}
                                      getValueFromEvent={this.handleChangeFileIpa}
                                      >
                                      <Upload
                                          accept="apk/*"
                                          name="fiavatarle"
                                          onChange={this.handleChange}
                                          customRequest={this.doUploadApp}
                                          beforeUpload={this.beforeAppUpload}
                                      >
                                          <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                      </Upload>
                                  </Form.Item>

                                  <Form.Item label="应用图标地址" name="iosIconUrl" 
                                        valuePropName="file"
                                        labelCol={{span:10}} 
                                        style={{ width: 420 }}
                                        getValueFromEvent={this.handleChangeFileImage}
                                        >
                                        <Upload
                                            accept="image/*"
                                            name="fiavatarle"
                                            listType="picture-card" 
                                            onChange={this.handleIosIconUrlChange}
                                            customRequest={this.doImgUpload}
                                            beforeUpload={this.beforeUpload}
                                            defaultFileList={this.state.iosIconUrlList}
                                            fileList={this.state.iosIconUrlList}
                                        >
                                            {this.uploadButton}
                                        </Upload>
                                    </Form.Item>

                                    <Form.Item label="苹果包名" name="iosPackName" labelCol={{span:5}} style={{ width: 840 }} >
                                        <Input placeholder="苹果包名"  />
                                    </Form.Item>

                                    <Form.Item label="苹果应用名称" name="iosApplicationName" labelCol={{span:5}} style={{ width: 840 }} >
                                        <Input placeholder="苹果应用名称"  />
                                    </Form.Item>

                                    <Form.Item label="苹果版本号" name="ipaVersion" labelCol={{span:5}} style={{ width: 840 }} >
                                        <Input placeholder="苹果版本号"  />
                                    </Form.Item>

                                    <Form.Item label="苹果版本code" name="ipaVersionCode" labelCol={{span:5}} style={{ width: 840 }} >
                                        <Input placeholder="苹果版本code"  />
                                    </Form.Item>

                                    <Form.Item label="苹果Plist地址" name="ipaPlistUrl" labelCol={{span:5}} style={{ width: 840 }} >
                                        <Input placeholder="苹果Plist地址"  />
                                    </Form.Item>

                                  </>
                              )
                              }

                              return null
                          }}
                      </Form.Item>

                    
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleIosVersionManageSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default AppVersionMgr;