import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio 
  , Row, Col, Avatar, Tabs, Image} from 'antd';
import appUserService from '../../../Service/UserMgr/appUserService';
import userAuthService from '../../../Service/UserMgr/userAuthService';
import { StickyContainer, Sticky } from 'react-sticky';
import { LoadingOutlined,PlusOutlined,UploadOutlined } from '@ant-design/icons';
import VideoPlay from './VideoPlay';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TabPane } = Tabs;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);

class UpdateRole extends Component {
  formRef = React.createRef();

  state={
      fileList:[],
      videoRow: null,
      showVideoDialog: false,
      showVideoButton: false,
      anchorStarList: this.props.rowData != null ? this.props.rowData.anchorStarList : null,
  }

  showPlayVideoButton =() => {
    if(this.props.rowData == null 
      || this.props.rowData.videoUrl == null
      || this.props.rowData.videoUrl == "" ) {
      return false;
    }
    //console.log("showPlayVideoButton = true");

    return true;
  }

  /**
   * 播放视频
   */
  handlePlayVideo=() => {
    if(this.props.rowData == null 
      || this.props.rowData.video == null
      || this.props.rowData.video == "" ) {
      return ;
    }
    this.state.videoRow = {
      videoUrl : this.props.rowData.video ,
    }
  
    //console.log("videoRow=" + JSON.stringify(this.state.videoRow))
    this.setState({showVideoDialog:true })
  }

  /**
   * 图片数组处理
   */
  arrayHandle=() => {
      this.state.fileList = [];
      //console.log('arrayHandle portrait=' +  this.props.rowData.portrait);
      if(this.props.rowData.poster == null || this.props.rowData.poster == "") {
          return this.state.fileList;
      }
      var strArray = this.props.rowData.poster.split(',');
      if(strArray.length <= 0) {
          return this.state.fileList;
      }
      //console.log('arrayHandle strArray=' +  JSON.stringify(strArray));

      for(var i=0; i< strArray.length;i++) {
          var urlString = strArray[i];
          var file = {
              uid: i,
              thumbUrl: urlString,
              url: urlString,
              response: urlString,
              status: 'done',
          }
          this.state.fileList = [...this.state.fileList, file];
      }
      //console.log('arrayHandle fileList=' +  JSON.stringify(this.state.fileList));

      return this.state.fileList;

  }

  // 关闭用户审核弹框
  hideVideoDialog=() => {
    this.setState({showVideoDialog: false});
  }
  

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log("handleSubmit= " + JSON.stringify(values));

          var userAuthParam = {
            uid: values.uid,
            status: values.status,
            starId: values.starId,
            reason: values.reason,
          }
          
          userAuthService.updateUserAuth(userAuthParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  }



  render () {
    return (
      <Modal
        destroyOnClose
        visible={this.props.visible}
        width={1000}
        onCancel={this.hanldeCloseModal}
        footer={[
          null, null,
        ]}
      >
  
        <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            onFinish={this.handleSubmit}
        >

          <Row gutter={24} align="bottom">
            <Col span={6} key={1}>
              <Form.Item name="avatar">
                <Avatar shape="square" size={55} src={this.props.rowData ? this.props.rowData.avatar : null}></Avatar>
              </Form.Item>
            </Col>
            <Col span={6} key={2}>
              <Form.Item label="用户UID" labelCol={{span: 7}} name="uid" >
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={3}>
              <Form.Item label="用户名" labelCol={{span: 7}} name="username">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={17}>
              <Form.Item label="性别" labelCol={{span: 7}} name="sexString">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={4}>
              <Form.Item label="手机号码" labelCol={{span: 7}} name="mobile">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={5}>
              <Form.Item label="用户等级" labelCol={{span: 7}} name="userGrade">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={6}>
              <Form.Item label="主播等级" labelCol={{span: 7}} name="anchorGrade">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={7}>
              <Form.Item label="财富等级" labelCol={{span: 7}} name="wealthGrade">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={8}>
              <Form.Item label="贵族等级" labelCol={{span: 7}} name="nobleGrade">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={9}>
              <Form.Item label="微信号" labelCol={{span: 6}} name="wechat">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={10}>
              <Form.Item label="生日" labelCol={{span: 4}} name="birthday">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={11}>
              <Form.Item label="星座" labelCol={{span: 4}} name="constellation">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={12}>
              <Form.Item label="职业" labelCol={{span: 4}} name="vocation">
                  <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={13}>
              <Form.Item label="身高" labelCol={{span: 4}} name="height">
                  <Input bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={14}>
              <Form.Item label="体重" labelCol={{span: 4}} name="weight">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={15}>
              <Form.Item label="上级用户" labelCol={{span: 7}} name="pid">
                 <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
            <Col span={6} key={16}>
              <Form.Item label="上级代理" labelCol={{span: 7}} name="agentId">
                  <Input  bordered={false} readOnly/>
              </Form.Item>
            </Col>
          </Row>


          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar}>
            
            // ———————————————————— 真人照片 ——————————————————————
            <TabPane tab="真人照片" key="1">

                <Form.Item label="真人照片" name="realPersonUrl" >
                    <Image
                      width={200}
                      src={this.props.rowData ? this.props.rowData.realPersonUrl : null}
                    />
                </Form.Item>

            </TabPane>

            // ———————————————————— 认证资料 ——————————————————————
            <TabPane tab="认证资料" key="2">
                <Form.Item label="真实姓名" name="realName" >
                    <Input bordered={false} readOnly />
                </Form.Item>

                <Form.Item label="身份证号" name="cerNo" >
                    <Input bordered={false} readOnly />
                </Form.Item>

                <Form.Item label="正面照片" name="frontView" >
                    <Image
                      width={200}
                      src={this.props.rowData ? this.props.rowData.frontView : null}
                    />
                </Form.Item>

                <Form.Item label="反面照片" name="backView" >
                    <Image
                      width={200}
                      src={this.props.rowData ? this.props.rowData.backView : null}
                    />
                </Form.Item>

                <Form.Item label="手持身份证" name="handsetView" >
                    <Image
                      width={200}
                      src={this.props.rowData ? this.props.rowData.handsetView : null}
                    />
                </Form.Item>

            </TabPane>

            // —————————————————————— 形象信息 ————————————————————————
            <TabPane tab="形象信息" key="3">
                <Form.Item label="视频接听收费" name="videoCoin" >
                    <Input bordered={false} readOnly />
                </Form.Item>

                <Form.Item label="语音接听收费" name="voiceCoin" >
                    <Input bordered={false} readOnly />
                </Form.Item>

                <Form.Item label="展示图片" name="poster" 
                    valuePropName="filelist"
                    getValueFromEvent={this.normFile}
                    >
                    <Upload
                        accept="image/*"
                        data={file => ({
                            image_file: file
                        })}
                        listType="picture-card"
                        onPreview={this.handlePreview}
                        onChange={this.handleChange}
                        customRequest={this.doImgUpload}
                        beforeUpload={this.beforeUpload}
                        defaultFileList={this.arrayHandle}
                        showUploadList={{showRemoveIcon:false}}
                    >
                    </Upload>
                </Form.Item>

                <Form.Item label="展示视频" name="videoUrl" >
                    <Button type="primary" onClick={this.handlePlayVideo} style={{ marginRight: '15px' }}>
                        观看
                    </Button>
                </Form.Item>
                <VideoPlay rowData={this.state.videoRow} close={this.hideVideoDialog} visible={this.state.showVideoDialog}></VideoPlay>

            </TabPane>

            // ———————————————— 审核 ————————————————
            <TabPane tab="审核" key="4">

              <Form.Item label="主播星级" name="starId"
                initialValue={this.props.rowData != null ? this.props.rowData.anchorStarList[0].id : null} rules={ [
                  {
                      required: true,
                      message: '请选择主播星级!',
                  }
                  ]
              }>
                <Select style={{ width: 220 }} defaultValue={this.props.rowData != null ? this.props.rowData.anchorStarList[0].id : null}>
                {this.props.rowData == null ? null : this.props.rowData.anchorStarList.map((item)=>
                  <Option key={item.id} value={item.id}>{item.starName}</Option>
                )}
                </Select>
              </Form.Item>

              <Form.Item label="认证审核状态" name="status" initialValue={0} rules={ [
                    {
                        required: true,
                        message: '请选择审核状态!',
                    }
                    ]
                }>
                  <Radio.Group defaultValue={0}>
                    <Radio value={0}>审核通过</Radio>
                    <Radio value={1}>待审核</Radio>
                    <Radio value={2}>审核拒绝</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="备注" name="reason" >
                  <Input.TextArea  />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  保存
                </Button>
              </Form.Item>
              


            </TabPane>

          </Tabs>


        </Form>
      </Modal>
    )
  }
}

export default UpdateRole