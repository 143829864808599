import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {
    /**
     * 分页查询禁用列表
     * @param {*} disableUserParam 
     * @returns 
     */
     getDisableUserPage(disableUserParam) {
        //console.log("disableUserParam=" + JSON.stringify(disableUserParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/userForbidden/getDisableUserPage",
            data: disableUserParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },
}