import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import systemAdminService from '../../../Service/SystemInit/systemAdminService';
import InputNumber from 'rc-input-number';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class IMMgr extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
    showCacheRow: null,
    showCacheDialog: false,

  }


  // 第一次渲染后调用
  componentDidMount() {

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }


  /**
   * 清除用户所有会话
   */
  deleteContact=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要清除吗？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        this.formRef.current.validateFields()
        .then(values => {
          console.log(JSON.stringify(values));
    
          var systemInitParam = {
            userId: values.userId,
          };
          systemAdminService.deleteContact(systemInitParam)
          .then(res => {
            console.log("res=" + JSON.stringify(res));

            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("创建失败,"+res.message);  
            } else {
              message.success('创建成功！');
            }
            
          })
        })
      }
    });
  }


  /**
   * 撤回用户所有消息
   */
  msgWithdraw=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要清除吗？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        this.formRef2.current.validateFields()
        .then(values => {
          console.log(JSON.stringify(values));
    
          var systemInitParam = {
            userId: values.userId,
          };
          systemAdminService.msgWithdraw(systemInitParam)
          .then(res => {
            console.log("res=" + JSON.stringify(res));

            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("创建失败,"+res.message);  
            } else {
              message.success('创建成功！');
            }
            
          })
        })
      }
    });
  }
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/im_mgr">消息管理</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>   

            <Form
              name="IMForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 20px 200px'}}
            >
              <Row gutter={24}>
                  <Col span={7} key={1}>
                    <Form.Item label="用户Id:" name="userId">
                        <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col span={3} key={2} style={{ textAlign: 'right' }}>
                    <Button type="danger" onClick={this.deleteContact} style={{ marginRight: '15px' }}>
                      删除用户所有会话
                    </Button>
                  </Col>
              </Row>
            </Form>  

            <Form
              name="CloudStorageListForm"
              className="ant-advanced-search-form"
              ref={this.formRef2}
              style={{ margin: '20px 0px 20px 200px'}}
            >
              <Row gutter={24}>
                  <Col span={7} key={1}>
                    <Form.Item label="用户Id:" name="userId">
                        <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col span={3} key={2} style={{ textAlign: 'right' }}>
                    <Button type="danger" onClick={this.msgWithdraw} style={{ marginRight: '15px' }}>
                      删除用户所有消息
                    </Button>
                  </Col>
              </Row>
            </Form>  

        </StickyContainer>
        
      </div>
    );
  }
}
export default IMMgr;