import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 获取统计数据
   * @param {*} agentDataStatisticsParam
   * @returns
   */
  getAgentDataStatistics(agentDataStatisticsParam) {
      //console.log("agentDataStatisticsParam=" + JSON.stringify(agentDataStatisticsParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/agentDataStatistics/getAgentDataStatistics",
        data: agentDataStatisticsParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


      /**
   * 获取统计数据
   * @param {*} agentDataStatisticsParam
   * @returns
   */
  getAgentDataStatisticsAdmin(agentDataStatisticsParam) {
    //console.log("agentDataStatisticsParam=" + JSON.stringify(agentDataStatisticsParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/agentDataStatistics/getAgentDataStatisticsAdmin",
      data: agentDataStatisticsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 获取统计数据
   * @param {*} agentDataStatisticsParam
   * @returns
   */
  getAgentDataStatisticsAgent(agentDataStatisticsParam) {
    //console.log("agentDataStatisticsParam=" + JSON.stringify(agentDataStatisticsParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/agentDataStatistics/getAgentDataStatisticsAgent",
      data: agentDataStatisticsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}