import axios from 'axios';

export default {
    /**
     * 接口处理
     * @param {*} data 
     * @returns 
     */
  login(param) {

    return axios({
        method: 'post',
        url: '/admin/loginAdmin/login',
        data: param
    }).then(
        res => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        }
    );
},


}