import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber } from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import { Logout } from '../../../Common/Auth';
import userService from '../../../Service/Admin/userService';


const { Option } = Select;
const { TextArea } = Input;

class UpdateProxyPassword extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var agentUser = {
            id : values.id,
            password : values.password,
          }
          userService.insertOrUpdateUser(agentUser)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  }


  render () {
    return (
      <Modal
        title="编辑"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="密码" name="password" labelCol={{span:10}} style={{ width: 420 }} rules={ [
                    {
                        pattern: /\w{6,20}/gi,
                        message: '请输入6-20个字符!',
                    },
                    {
                        required: true,
                        message: '请输入密码!',
                    }
                    ]
                }>
                <Input.Password prefix={<SafetyOutlined />} 
                    placeholder="密码"
                />
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default UpdateProxyPassword