import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import adminUserCashrecordService from '../../../Service/Admin/adminUserCashrecordService';


import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class UpdateWithdrawal extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var adminUserCashrecord = values;
          adminUserCashrecordService.updateAuditStatus(adminUserCashrecord)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("审核失败")
                message.info("审核失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('审核成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


  render () {
    return (
      <Modal
        title="审核"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="type" name="type" hidden>
                <Input placeholder="type" />
            </Form.Item>

            <Form.Item label="用户ID" name="uid" labelCol={{span:10}} style={{ width: 420 }} >
                <Input placeholder="uid" disabled />
            </Form.Item>

            <Form.Item label="状态" labelCol={{span:10}} name="status" style={{ width: 420 }} initialValue={0}>
                <Select style={{ width: 220 }} defaultValue={0}>
                  <Option value={0}>审核中</Option>
                  <Option value={1}>审核通过</Option>
                  <Option value={2}>审核拒绝</Option>
                </Select>
            </Form.Item>

            <Form.Item label="审核原因" labelCol={{span:10}} name="reason" style={{ width: 420 }}>
                <TextArea  />
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default UpdateWithdrawal