import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

   /**
     * 更新短视频配置
     * @param {*} adminShortVideoConfig 
     * @returns 
     */
   updateShortVideoConfig(adminShortVideoConfig) {
        //console.log("videoSettingParam=" + JSON.stringify(videoSettingParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/shortVideoConfigAdmin/updateShortVideoConfig",
            data: adminShortVideoConfig,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
     * 查询短视频配置
     * @returns 
     */
    getShortVideoConfig() {
        //console.log("data=" + JSON.stringify(data));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/shortVideoConfigAdmin/getShortVideoConfig",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },
}