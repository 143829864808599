import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页查询分成比例
   * @param {*} dealScaleParam
   * @returns
   */
   getDealScalePage(dealScaleParam) {
      //console.log("dealScaleParam=" + JSON.stringify(dealScaleParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/dealScale/getDealScalePage",
        data: dealScaleParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


    /**
     * 更新分成方案
     * @param {*} appDealScale
     * @returns
     */
    insertOrUpdateDealScale(appDealScale) {
      //console.log("insertOrUpdateDealScale appDealScale=" + JSON.stringify(appDealScale));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/dealScale/insertOrUpdateDealScale",
        data: appDealScale,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
}