import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, 
  InputNumber, View} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import finTranRecordService from '../../../Service/FinanceMgr/finTranRecordService';






const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class RevenueExpenseDetails extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state={
    // ———————————————————— 金币明细 ————————————————————————————
    goldCoinColumns: [{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'uidAvatar',
      title: '用户信息',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'tranType2',
      title: '交易类型2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 == 10) {
          return "充值";
        } else if(tranType2 == 12) {
          return "奖励收益";
        } else if(tranType2 == 50) {
          return "推荐佣金";
        } else if(tranType2 == 13) {
          return "兑换金币";
        } else if(tranType2 == 14) {
          return "游戏中奖";
        } else if(tranType2 == 20) {
          return "打赏";
        } else if(tranType2 == 24) {
          return "守护";
        } else if(tranType2 == 25) {
          return "粉丝团";
        } else if(tranType2 == 26) {
          return "发送弹幕";
        } else if(tranType2 == 27) {
          return "购买贵族";
        } else if(tranType2 == 28) {
          return "付费通话";
        } else if(tranType2 == 29) {
          return "购买道具";
        } else if(tranType2 == 200) {
          return "游戏消费";
        } else if(tranType2 == 201) {
          return "红包";
        } else if(tranType2 == 202) {
          return "查看联系方式";
        } else if(tranType2 == 204) {
          return "购买SVIP";
        } else if(tranType2 == 205) {
          return "房间费用";
        } else if(tranType2 == 206) {
          return "付费私信";
        } else if(tranType2 == 208) {
          return "购买贵宾席";
        } else if(tranType2 == 209) {
          return "游戏抽奖";
        } else if(tranType2 == 60) {
          return "提现";
        } else if(tranType2 == 222) {
          return "扩列信息";
        } else {
          return tranType2;
        }  
      }
    },{
      key: 'tranType3',
      title: '交易类型3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 == 1001) {
          return "线上充值";
        } else if(tranType3 == 1101) {
          return "人工充值";
        } else if(tranType3 == 1108) {
          return "后台充值";
        } else if(tranType3 == 1102) {
          return "扣除金币";
        } else if(tranType3 == 1103) {
          return "游戏奖励";
        } else if(tranType3 == 1201) {
          return "首充奖励";
        } else if(tranType3 == 1202) {
          return "新手大礼包";
        } else if(tranType3 == 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 == 1204) {
          return "等级礼包";
        } else if(tranType3 == 1205) {
          return "连续登录奖励";
        } else if(tranType3 == 1206) {
          return "签到奖励";
        } else if(tranType3 == 1207) {
          return "充值奖励";
        } else if(tranType3 == 1301) {
          return "佣金兑换金币";
        } else if(tranType3 == 1302) {
          return "映票兑换金币";
        } else if(tranType3 == 2003) {
          return "聊天打赏";
        } else if(tranType3 == 2004) {
          return "通话打赏";
        } else if(tranType3 == 2005) {
          return "个人主页打赏";
        } else if(tranType3 == 2401) {
          return "守护";
        } else if(tranType3 == 2501) {
          return "加入粉丝团";
        } else if(tranType3 == 2701) {
          return "购买贵族";
        } else if(tranType3 == 2801) {
          return "视频通话";
        } else if(tranType3 == 2802) {
          return "语音通话";
        }  else if(tranType3 == 2803) {
          return "用户间通话";
        }  else if(tranType3 == 20202) {
          return "查看微信号";
        }  else if(tranType3 == 20203) {
          return "付费私信";
        }  else if(tranType3 == 5001) {
          return "注册佣金";
        }  else if(tranType3 == 5002) {
          return "主播认证佣金";
        }  else if(tranType3 == 1406) {
          return "签到佣金";
        }  else if(tranType3 == 5003) {
          return "通话佣金";
        }  else if(tranType3 == 5004) {
          return "打赏佣金";
        } else if(tranType3 == 5005) {
          return "补发佣金";
        } else if(tranType3 == 5006) {
          return "用户充值";
        } else if(tranType3 == 5007) {
          return "购买贵族佣金";
        } else if(tranType3 == 5008) {
          return "购买SVIP佣金";
        } else if(tranType3 == 6001) {
          return "用户佣金提现";
        } else if(tranType3 == 6002) {
          return "代理佣金提现";
        } else if(tranType3 == 6003) {
          return "主播收益提现";
        } else if(tranType3 == 6004) {
          return "公会佣金提现";
        } else if(tranType3 == 20401) {
          return "购买SVIP";
        } else if(tranType3 == 22201) {
          return "扩列信息发布";
        } else if(tranType3 == 22202) {
          return "扩列信息刷新";
        } else if(tranType3 == 22203) {
          return "扩列信息撤回";
        } else {
          return tranType3;
        }     
      }
    },{
      key: 'coinChange',
      title: '变动金币',
      dataIndex: 'coinChange'
    },{
      key: 'afterCoin',
      title: '余金币',
      dataIndex: 'afterCoin'
    },{
      key: 'toUidAvatar',
      title: '主播信息',
      dataIndex: 'toUidAvatar',

      align: 'center',
      render: (toUidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.toUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={toUidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.toUidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.toUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks'
    }],
    goldCoinTotal: 0,
    goldCoinList: [],
    goldCoinPageParam: {
      currencyType: 2,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },


    // ———————————————————— 映票明细 ————————————————————————————
    
    ticketColumns: [{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'uidAvatar',
      title: '用户信息',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
              <div div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }

    },{
      key: 'tranType2',
      title: '交易类型2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 == 10) {
          return "充值";
        } else if(tranType2 == 12) {
          return "奖励收益";
        } else if(tranType2 == 50) {
          return "推荐佣金";
        } else if(tranType2 == 13) {
          return "兑换金币";
        } else if(tranType2 == 14) {
          return "游戏中奖";
        } else if(tranType2 == 20) {
          return "打赏";
        } else if(tranType2 == 24) {
          return "守护";
        } else if(tranType2 == 25) {
          return "粉丝团";
        } else if(tranType2 == 26) {
          return "发送弹幕";
        } else if(tranType2 == 27) {
          return "购买贵族";
        } else if(tranType2 == 28) {
          return "付费通话";
        } else if(tranType2 == 29) {
          return "购买道具";
        } else if(tranType2 == 200) {
          return "游戏消费";
        } else if(tranType2 == 201) {
          return "红包";
        } else if(tranType2 == 202) {
          return "查看联系方式";
        } else if(tranType2 == 204) {
          return "购买SVIP";
        } else if(tranType2 == 205) {
          return "房间费用";
        } else if(tranType2 == 206) {
          return "付费私信";
        } else if(tranType2 == 208) {
          return "购买贵宾席";
        } else if(tranType2 == 209) {
          return "游戏抽奖";
        } else if(tranType2 == 222) {
          return "扩列信息";
        } else if(tranType2 == 60) {
          return "提现";
        } else {
          return tranType2;
        }  
      }
    },{
      key: 'tranType3',
      title: '交易类型3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 == 1001) {
          return "线上充值";
        } else if(tranType3 == 1101) {
          return "人工充值";
        } else if(tranType3 == 1108) {
          return "后台充值";
        } else if(tranType3 == 1102) {
          return "扣除金币";
        } else if(tranType3 == 1103) {
          return "游戏奖励";
        } else if(tranType3 == 1201) {
          return "首充奖励";
        } else if(tranType3 == 1202) {
          return "新手大礼包";
        } else if(tranType3 == 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 == 1204) {
          return "等级礼包";
        } else if(tranType3 == 1205) {
          return "连续登录奖励";
        } else if(tranType3 == 1206) {
          return "签到奖励";
        } else if(tranType3 == 1207) {
          return "充值奖励";
        } else if(tranType3 == 1301) {
          return "佣金兑换金币";
        } else if(tranType3 == 1302) {
          return "映票兑换金币";
        } else if(tranType3 == 2003) {
          return "聊天打赏";
        } else if(tranType3 == 2004) {
          return "通话打赏";
        } else if(tranType3 == 2005) {
          return "个人主页打赏";
        } else if(tranType3 == 2401) {
          return "守护";
        } else if(tranType3 == 2501) {
          return "加入粉丝团";
        } else if(tranType3 == 2701) {
          return "购买贵族";
        } else if(tranType3 == 2801) {
          return "视频通话";
        } else if(tranType3 == 2802) {
          return "语音通话";
        }  else if(tranType3 == 2803) {
          return "用户间通话";
        }  else if(tranType3 == 20202) {
          return "查看微信号";
        }  else if(tranType3 == 20203) {
          return "付费私信";
        }  else if(tranType3 == 5001) {
          return "注册佣金";
        }  else if(tranType3 == 5002) {
          return "主播认证佣金";
        }  else if(tranType3 == 1406) {
          return "签到佣金";
        }  else if(tranType3 == 5003) {
          return "通话佣金";
        }  else if(tranType3 == 5004) {
          return "打赏佣金";
        } else if(tranType3 == 5005) {
          return "补发佣金";
        } else if(tranType3 == 5006) {
          return "用户充值";
        } else if(tranType3 == 5007) {
          return "购买贵族佣金";
        } else if(tranType3 == 5008) {
          return "购买SVIP佣金";
        } else if(tranType3 == 6001) {
          return "用户佣金提现";
        } else if(tranType3 == 6002) {
          return "代理佣金提现";
        } else if(tranType3 == 6003) {
          return "主播收益提现";
        } else if(tranType3 == 6004) {
          return "公会佣金提现";
        } else if(tranType3 == 20401) {
          return "购买SVIP";
        } else if(tranType3 == 22201) {
          return "扩列信息发布";
        } else if(tranType3 == 22202) {
          return "扩列信息刷新";
        } else if(tranType3 == 22203) {
          return "扩列信息撤回";
        } else {
          return tranType3;
        }     
      }
    },{
      key: 'fromUidAvatar',
      title: '来源用户信息',
      dataIndex: 'fromUidAvatar',
      align: 'center',
      render: (fromUidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.fromUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={fromUidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.fromUidUsername}</div>
              <div div style={{ marginTop: '3px'}}>{row.fromUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'ticketChange',
      title: '变动映票',
      dataIndex: 'ticketChange'
    },{
      key: 'afterTicket',
      title: '余映票',
      dataIndex: 'afterTicket'
    },{
      key: 'toUidAvatar',
      title: '收益用户信息',
      dataIndex: 'toUidAvatar',
      align: 'center',
      render: (toUidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.toUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={toUidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.toUidUsername}</div>
              <div div style={{ marginTop: '3px'}}>{row.toUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks'
    }],
    ticketTotal: 0,
    ticketList: [],
    ticketPageParam: {
      currencyType: 3,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },
    
    // ———————————————————— 人民币明细 ————————————————————————————
    
    RMBColumns: [{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'uidAvatar',
      title: '用户图片',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
              <div div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'tranType2',
      title: '交易类型2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 == 10) {
          return "充值";
        } else if(tranType2 == 12) {
          return "奖励收益";
        } else if(tranType2 == 50) {
          return "推荐佣金";
        } else if(tranType2 == 13) {
          return "兑换金币";
        } else if(tranType2 == 14) {
          return "游戏中奖";
        } else if(tranType2 == 20) {
          return "打赏";
        } else if(tranType2 == 24) {
          return "守护";
        } else if(tranType2 == 25) {
          return "粉丝团";
        } else if(tranType2 == 26) {
          return "发送弹幕";
        } else if(tranType2 == 27) {
          return "购买贵族";
        } else if(tranType2 == 28) {
          return "付费通话";
        } else if(tranType2 == 29) {
          return "购买道具";
        } else if(tranType2 == 200) {
          return "游戏消费";
        } else if(tranType2 == 201) {
          return "红包";
        } else if(tranType2 == 202) {
          return "查看联系方式";
        } else if(tranType2 == 204) {
          return "购买SVIP";
        } else if(tranType2 == 205) {
          return "房间费用";
        } else if(tranType2 == 206) {
          return "付费私信";
        } else if(tranType2 == 208) {
          return "购买贵宾席";
        } else if(tranType2 == 209) {
          return "游戏抽奖";
        } else if(tranType2 == 222) {
          return "扩列信息";
        } else if(tranType2 == 60) {
          return "提现";
        } else {
          return tranType2;
        }  
      }
    },{
      key: 'tranType3',
      title: '交易类型3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 == 1001) {
          return "线上充值";
        } else if(tranType3 == 1101) {
          return "人工充值";
        } else if(tranType3 == 1108) {
          return "后台充值";
        } else if(tranType3 == 1102) {
          return "扣除金币";
        } else if(tranType3 == 1103) {
          return "游戏奖励";
        } else if(tranType3 == 1201) {
          return "首充奖励";
        } else if(tranType3 == 1202) {
          return "新手大礼包";
        } else if(tranType3 == 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 == 1204) {
          return "等级礼包";
        } else if(tranType3 == 1205) {
          return "连续登录奖励";
        } else if(tranType3 == 1206) {
          return "签到奖励";
        } else if(tranType3 == 1207) {
          return "充值奖励";
        } else if(tranType3 == 1301) {
          return "佣金兑换金币";
        } else if(tranType3 == 1302) {
          return "映票兑换金币";
        } else if(tranType3 == 2003) {
          return "聊天打赏";
        } else if(tranType3 == 2004) {
          return "通话打赏";
        } else if(tranType3 == 2005) {
          return "个人主页打赏";
        } else if(tranType3 == 2401) {
          return "守护";
        } else if(tranType3 == 2501) {
          return "加入粉丝团";
        } else if(tranType3 == 2701) {
          return "购买贵族";
        } else if(tranType3 == 2801) {
          return "视频通话";
        } else if(tranType3 == 2802) {
          return "语音通话";
        }  else if(tranType3 == 2803) {
          return "用户间通话";
        }  else if(tranType3 == 20202) {
          return "查看微信号";
        }  else if(tranType3 == 20203) {
          return "付费私信";
        }  else if(tranType3 == 5001) {
          return "注册佣金";
        }  else if(tranType3 == 5002) {
          return "主播认证佣金";
        }  else if(tranType3 == 1406) {
          return "签到佣金";
        }  else if(tranType3 == 5003) {
          return "通话佣金";
        }  else if(tranType3 == 5004) {
          return "打赏佣金";
        } else if(tranType3 == 5005) {
          return "补发佣金";
        } else if(tranType3 == 5006) {
          return "用户充值";
        } else if(tranType3 == 5007) {
          return "购买贵族佣金";
        } else if(tranType3 == 5008) {
          return "购买SVIP佣金";
        } else if(tranType3 == 6001) {
          return "用户佣金提现";
        } else if(tranType3 == 6002) {
          return "代理佣金提现";
        } else if(tranType3 == 6003) {
          return "主播收益提现";
        } else if(tranType3 == 6004) {
          return "公会佣金提现";
        } else if(tranType3 == 20401) {
          return "购买SVIP";
        } else if(tranType3 == 22201) {
          return "扩列信息发布";
        } else if(tranType3 == 22202) {
          return "扩列信息刷新";
        } else if(tranType3 == 22203) {
          return "扩列信息撤回";
        } else {
          return tranType3;
        }  
      }
    },{
      key: 'moneyChange',
      title: '人民币金币',
      dataIndex: 'moneyChange'
    },{
      key: 'afterMoney',
      title: '余金币',
      dataIndex: 'afterMoney'
    },{
      key: 'toUidAvatar',
      title: '对方用户',
      dataIndex: 'toUidAvatar',
      align: 'center',
      render: (toUidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.toUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={toUidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.toUidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.toUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'fromUidAvatar',
      title: '来源用户',
      dataIndex: 'fromUidAvatar',
      align: 'center',
      render: (fromUidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.fromUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={fromUidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.fromUidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.fromUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks'
    }],
    RMBTotal: 0,
    RMBList: [],
    RMBPageParam: {
      currencyType: 1,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },

    // ———————————————————— 补发佣金 ————————————————————————————
    reissue: null,
   
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 金币明细 ————————————————————————————
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var finTranRecordParam={
        currencyType: 2,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({goldCoinList: res.retValue.list, goldCoinTotal: res.retValue.total});
          }
        }
      )


    // ———————————————————— 映票明细 ————————————————————————————

    var finTranRecordParam={
      currencyType: 3,
      pageParam: pageParam
    }

    finTranRecordService.getFinTranRecordPage(finTranRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({ticketList: res.retValue.list, ticketTotal: res.retValue.total});
        }
      }
    )

    // ———————————————————— 人民币明细 ————————————————————————————

    var finTranRecordParam={
      currencyType: 1,
      pageParam: pageParam
    }

    finTranRecordService.getFinTranRecordPage(finTranRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({RMBList: res.retValue.list, RMBTotal: res.retValue.total});
        }
      }
    )

    // ———————————————————— 补发佣金 ————————————————————————————

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 金币明细 ————————————————————————————

  // 关闭更新
  hideUpdateGoldCoinDialog=() => {
    this.setState({showUpdateGoldCoinDialog: false});
  }

  // 关闭添加
  hideAddGoldCoinDialog=() => {
    this.setState({showAddGoldCoinDialog: false});
  }


  // 获取列表
  handleGetGoldCoinPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }


      var finTranRecordParam={
        uid: values.uid,
        fromUid: values.fromUid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        currencyType: 2,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({goldCoinList: res.retValue.list, goldCoinTotal: res.retValue.total});
          }
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  goldCoinChangePage=(page, pageSize)=>{
    let { history } = this.props;

    this.formRef.current.validateFields()
    .then((values) => {

      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finTranRecordParam={
        uid: values.uid,
        fromUid: values.fromUid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        currencyType: 2,
        pageParam: pageParam
      }

      this.setState({
        goldCoinPageParam: finTranRecordParam,
      });

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({goldCoinList: res.retValue.list, goldCoinTotal: res.retValue.total});
          }
        }
      )
    })
  }

  /**
   * 刷新
   */
  refreshGoldCoinData=() => {
     let { history } = this.props;

      var finTranRecordParam = this.state.goldCoinPageParam

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({goldCoinList: res.retValue.list, goldCoinTotal: res.retValue.total});
          }
        }
      )
  }


  // ———————————————————— 映票明细 ————————————————————————————
  // 关闭更新
  hideUpdateTicketDialog=() => {
    this.setState({showUpdateTicketDialog: false});
  }

  // 关闭添加
  hideAddTicketDialog=() => {
    this.setState({showAddTicketDialog: false});
  }


  // 获取列表
  handleGetTicketPage=() =>{
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finTranRecordParam={
        uid: values.uid,
        fromUid: values.fromUid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        currencyType: 3,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({ticketList: res.retValue.list, ticketTotal: res.retValue.total});
          }
        }
      )
      
    })
  
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  ticketChangePage=(page, pageSize)=>{
    let { history } = this.props;

    this.formRef2.current.validateFields()
    .then((values) => {

      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finTranRecordParam={
        uid: values.uid,
        fromUid: values.fromUid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        currencyType: 3,
        pageParam: pageParam
      }

      this.setState({
        ticketPageParam: finTranRecordParam,
      });

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({ticketList: res.retValue.list, ticketTotal: res.retValue.total});
          }
        }
      )
    })
  }

  /**
   * 刷新
   */
  refreshTicketData=() => {
      let { history } = this.props;

      var finTranRecordParam= this.state.finTranRecordParam;

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({ticketList: res.retValue.list, ticketTotal: res.retValue.total});
          }
        }
      )
  }
  
  // ———————————————————— 人民币明细 ————————————————————————————
  // 关闭更新
  hideUpdateRMBDialog=() => {
    this.setState({showUpdateRMBDialog: false});
  }

  // 关闭添加
  hideAddRMBDialog=() => {
    this.setState({showAddRMBDialog: false});
  }


  // 获取列表
  handleGetRMBPage=() =>{
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }


      var finTranRecordParam={
        uid: values.uid,
        fromUid: values.fromUid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        currencyType: 1,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({RMBList: res.retValue.list, RMBTotal: res.retValue.total});
          }
        }
      )
      
    })
    
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  RMBChangePage=(page, pageSize)=>{
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {

      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finTranRecordParam={
        uid: values.uid,
        fromUid: values.fromUid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        currencyType: 1,
        pageParam: pageParam
      }

      this.setState({
        RMBPageParam: finTranRecordParam,
      });

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({RMBList: res.retValue.list, RMBTotal: res.retValue.total});
          }
        }
      )
    })
  }

  /**
   * 刷新
   */
  refreshRMBData=() => {
      let { history } = this.props;

      var finTranRecordParam=this.state.RMBPageParam;

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({RMBList: res.retValue.list, RMBTotal: res.retValue.total});
          }
        }
      )
  }

  // ———————————————————— 补发佣金 ————————————————————————————

 /**
   * 一对一
   */
  handleReissueSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {
        
        var finTranRecord = {
          uid : values.uid,
          toUid : values.uid,
          fromUid : values.uid,
          commissionRelatedUid : values.uid,
          tranType1 : values.tranType1,
          tranType2 : values.tranType2,
          tranType3 : values.tranType3,
          currencyType : values.currencyType,
          moneyChange : values.moneyChange,
          remarks : values.remarks,
        };

        finTranRecordService.insertFinTranRecord(finTranRecord)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("提交失败")
              message.info("提交失败,"+res.message);  
              // 重置关闭对话框
              this.hanldeCloseModal();
            } else {
              message.info('提交成功！');
              // 重置关闭对话框
              this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  // 关闭操作
  hanldeCloseModal = () => {
    // 重置表单
    this.formRef.current.resetFields();
  }


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getfinTranRecord1();
    } else if(key == "2") {
      this.getfinTranRecord2();
    } else if(key == "3") {
      this.getfinTranRecord3();
    }
  }

  /**
   * 金币明细
   */
  getfinTranRecord1=()=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var finTranRecordParam={
      currencyType: 2,
      pageParam: pageParam
    }

    finTranRecordService.getFinTranRecordPage(finTranRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({goldCoinList: res.retValue.list, goldCoinTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 映票明细
   */
  getfinTranRecord2=()=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var finTranRecordParam={
      currencyType: 3,
      pageParam: pageParam
    }

    finTranRecordService.getFinTranRecordPage(finTranRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({ticketList: res.retValue.list, ticketTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 人民币明细
   */
  getfinTranRecord3=()=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var finTranRecordParam={
      currencyType: 1,
      pageParam: pageParam
    }

    finTranRecordService.getFinTranRecordPage(finTranRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({RMBList: res.retValue.list, RMBTotal: res.retValue.total});
        }
      }
    )
  }
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/revenue_expense_details">用户收支明细</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 金币明细 ————————————————————————————
            <TabPane tab="金币明细" key="1">
              <Form
                name="GoldCoinForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="来源用户UID" name="fromUid">
                          <Input placeholder="来源用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="收益用户UID" name="toUid">
                          <Input placeholder="收益用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="交易时间" name="sendTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={5} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetGoldCoinPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.goldCoinList}
                columns={this.state.goldCoinColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.goldCoinTotal, defaultCurrent: 1, onChange: this.goldCoinChangePage}}
              >
              </Table>
              
            </TabPane>
            
            // ———————————————————— 映票明细 ——————————————————————
            <TabPane tab="映票明细" key="2">
              <Form
                name="TicketForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="来源用户UID" name="fromUid">
                          <Input placeholder="来源用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="收益用户UID" name="toUid">
                          <Input placeholder="收益用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="交易时间" name="sendTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={5} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetTicketPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.ticketList}
                columns={this.state.ticketColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.ticketTotal, defaultCurrent: 1, onChange: this.ticketChangePage}}
              >
              </Table>
            
            </TabPane>

            // ———————————————————— 人民币明细 ——————————————————————
            <TabPane tab="人民币明细" key="3">
              <Form
                name="RMBForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="来源用户UID" name="fromUid">
                          <Input placeholder="来源用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="收益用户UID" name="toUid">
                          <Input placeholder="收益用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="交易时间" name="sendTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={5} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetRMBPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.RMBList}
                columns={this.state.RMBColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.RMBTotal, defaultCurrent: 1, onChange: this.RMBChangePage}}
              >
              </Table>
            
            </TabPane>

            // ———————————————————— 补发佣金 ——————————————————————
            <TabPane tab="补发佣金" key="4">
              <Form
                  name="ReissueForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef4}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.reissue}
                >
                      <Form.Item label="tranType1" name="tranType1" initialValue={41} hidden>
                          <Input placeholder="tranType1" />
                      </Form.Item>

                      <Form.Item label="tranType2" name="tranType2" initialValue={50} hidden>
                          <Input placeholder="tranType2" />
                      </Form.Item>

                      <Form.Item label="tranType3" name="tranType3" initialValue={5005} hidden>
                          <Input placeholder="tranType3" />
                      </Form.Item>

                      <Form.Item label="货币类型" name="currencyType" initialValue={1} hidden>
                          <Input placeholder="货币类型" />
                      </Form.Item>

                      <Form.Item label="用户UID" name="uid" labelCol={{span:10}}  style={{ width: 390 }}>
                          <Input placeholder="用户UID" />
                      </Form.Item>

                      <Form.Item label="变化的佣金(元)" name="moneyChange" labelCol={{span:10}}  style={{ width: 390 }}>
                          <InputNumber />
                      </Form.Item>

                      <Form.Item label="备注" name="remarks" labelCol={{span:10}} style={{ width: 390 }}>
                          <TextArea  />
                      </Form.Item>
  
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleReissueSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default RevenueExpenseDetails;