import React, { Component } from 'react'
import { Modal, Checkbox, Row, Col, message, Radio , Form} from 'antd'
import service from '../../../Service';
import { formateDate2String } from '../../../Common/Helper';
import userRoleService from '../../../Service/Admin/userRoleService';
import { Logout } from '../../../Common/Auth';
import { toBeInTheDOM } from '@testing-library/jest-dom/dist/matchers';


class SetRole extends Component {
  formRef = React.createRef();
  state = {
    userRoles: [],      // 当前用户已经管理的所有角色中间表数据
    checkedRole: null,   // 当前选中的角色。
    checkedValue: null,  // 当前选中的角色值。
  }

  // 页面渲染
  async componentDidMount() {

    // this.props.data  => 当前设置角色的用户信息
    let id = this.props.data ? this.props.data.id : null;

    let userRolesData = await userRoleService.getRelListByUserId(id);
    if(userRolesData.retValue != null) {
        this.setState({userRoles: userRolesData.retValue});
    }
    //console.log("———————————— userRolesData="+JSON.stringify(userRolesData));

    let checkedRole = null;
    let checkedValue = null;
    // 给已经默认设置了关联的角色添加到 allCheckedRole数组中去。
    if(userRolesData.retValue.length > 0) {
      userRolesData.retValue.forEach(userRole => {
            if(userRole.selected) {
              checkedRole = userRole;
              checkedValue = userRole.roleId
            }
        })
    }
    //console.log("———————————— checkedRole="+JSON.stringify(checkedRole));
    this.formRef.current.setFieldsValue({
      radioGroup: JSON.stringify(checkedRole),
    })
    this.setState({checkedRole: checkedRole});
    this.setState({checkedValue: checkedValue});

  }

  
  handleChangeRadio = (e) => {
    //console.log('e.target.checked :', e.target.value);
    // console.log('role :', role);
    let checkedRole = null;
    if(e.target.value != null && e.target.value != "") {
      checkedRole = JSON.parse(e.target.value);
    }
    this.setState({checkedRole: checkedRole});
  }
  
  /**
   * 提交设置关联
   */
  handleSubmitSetRole = () => {
    let { history } = this.props;

    // console.log(this.state.allCheckedRole);
    let { checkedRole, userRoles } = this.state;
    // 判断要添加的
    // 最终的选中的role集合中不在原来的关联表中存在，就是添加的新关联。

    let userRoleParam = {
      userId : checkedRole.userId,
      roleId : checkedRole.roleId,
      roleCode: checkedRole.roleCode,
    }

    userRoleService.updateRelByUserId(userRoleParam)
    .then(
      res => {
        //console.log("res=" + JSON.stringify(res));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.error('设置失败！');
        } else {
          message.info('设置成功');
          this.props.close();
        }
      }
    )
  }
  render () {
    return (
      <Modal
        title="用户设置角色"
        destroyOnClose
        okText="设置"
        cancelText="取消"
        onCancel={() => this.props.close()}
        visible={this.props.visible}
        onOk={this.handleSubmitSetRole}
      >
        <h3>给用户：{this.props.data ? this.props.data.name : null}  设置角色</h3>
        <hr/>
        <Form
            name="messageSettingsForm"
            layout="horizontal"
            ref={this.formRef}
          >
				  <Form.Item name="radioGroup" key={1}>
            <Radio.Group onChange={this.handleChangeRadio} name="radioGroup">
                {
                  this.state.userRoles.map(userRole => {
                    
                    return (
                        <Radio value={JSON.stringify(userRole)}>{userRole.roleName}</Radio>
                    );
                  })
                }
            </Radio.Group>
          </Form.Item>
			  </Form>
      </Modal>
    )
  }
}

export default SetRole