import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import BraftEditor from 'braft-editor'
import violationRecordsService from '../../../Service/BasicInformation/violationRecordsService';
import monitoringSettingService from '../../../Service/BasicInformation/monitoringSettingService';

import VideoPlay from './VideoPlay'

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class AutoYellowIdentify extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
    // ———————————————————— 自动鉴黄记录 ————————————————————————————
    violationRecordsColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'avatar',
      title: '违规用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.userId}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.userName}</div>
              <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'roomId',
      title: '违规房间号',
      dataIndex: 'roomId'
    },{
      key: 'showId',
      title: '违规房间标识',
      dataIndex: 'showId'
    },{
      key: 'videoId',
      title: '短视频Id',
      dataIndex: 'videoId'
    },{
      key: 'imageUrl',
      title: '违规图片地址',
      dataIndex: 'imageUrl',
      render: (imageUrl, row) => {
        if(imageUrl != null && imageUrl != "") {
          // return <Image
          // width={55}
          // height={55}
          // src={imageUrl}
        ///>
          return imageUrl;
        }
      }
    },{
      key: 'videoUrl',
      title: '违规视频',
      dataIndex: 'videoUrl',
      render: (videoUrl, row) => {
        if(videoUrl != null && videoUrl != "") {
          //console.log("videoUrl="+videoUrl)
          // return <Button type="primary" onClick={() => this.handlePlayVideo(videoUrl)} style={{ marginRight: '15px' }}>
          //           观看
          //       </Button>
          return videoUrl
        }
      }

    },{
      key: 'processingMethod',
      title: '违规处理方式',
      dataIndex: 'processingMethod'
    },{
      key: 'addDate',
      title: '添加时间',
      dataIndex: 'addDate'
    },{
      key: 'taskId',
      title: '任务id',
      dataIndex: 'taskId'
    }],
    violationRecordsTotal: 0,
    violationRecordsList: [],

    videoRow: null,
    showVideoDialog: false,
    showVideoButton: false,

    // ———————————————————— 自动鉴黄设置 ————————————————————————————
    
    monitoringSetting: null,
    
    
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 自动鉴黄记录 ————————————————————————————
    this.getViolationRecords();
   
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 自动鉴黄记录 ————————————————————————————

  // 关闭更新
  hideUpdateViolationRecordsDialog=() => {
    this.setState({showUpdateViolationRecordsDialog: false});
  }

  // 关闭添加
  hideAddViolationRecordsDialog=() => {
    this.setState({showAddViolationRecordsDialog: false});
  }


  // 获取列表
  handleGetViolationRecordsPage=() =>{
    let { history } = this.props;

    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var violationRecordsParam={
        pageParam: pageParam
      }

      violationRecordsService.getViolationRecordsPage(violationRecordsParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({violationRecordsList: res.retValue.list, violationRecordsTotal: res.retValue.total});
          }
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  violationRecordsChangePage=(page, pageSize) => {
    let { history } = this.props;

    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    var violationRecordsParam={
      pageParam: pageParam
    }

    violationRecordsService.getViolationRecordsPage(violationRecordsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({violationRecordsList: res.retValue.list, violationRecordsTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshViolationRecordsData=() => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var violationRecordsParam={
        pageParam: pageParam
      }

      violationRecordsService.getViolationRecordsPage(violationRecordsParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({violationRecordsList: res.retValue.list, violationRecordsTotal: res.retValue.total});
          }
        }
      )
  }

  /**
   * 播放视频
   */
  handlePlayVideo=(videoUrl) => {
    if(videoUrl == null 
      || videoUrl == "" ) {
      return ;
    }
    this.state.videoRow = {
      videoUrl : videoUrl ,
    }
  
    //console.log("videoRow=" + JSON.stringify(this.state.videoRow))
    this.setState({showVideoDialog:true })
  }

  // 关闭弹框
  hideVideoDialog=() => {
    this.setState({showVideoDialog: false});
  }

  // ———————————————————— 自动鉴黄设置 ————————————————————————————
    
  handleMonitoringSettingSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;

    this.formRef2.current.validateFields()
    .then(values => {

        var monitoringSetting = values;
        monitoringSettingService.updateConfig(monitoringSetting)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }
    

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getViolationRecords();
    } else if(key == "2") {
      this.getMonitoringSetting();
    } 
  }
  
  /**
   * 自动鉴黄记录
   */
  getViolationRecords=()=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var violationRecordsParam={
      pageParam: pageParam
    }

    violationRecordsService.getViolationRecordsPage(violationRecordsParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({violationRecordsList: res.retValue.list, violationRecordsTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 自动鉴黄记录
   */
  getMonitoringSetting=()=> {
    let { history } = this.props;
    monitoringSettingService.getConfig()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            id: res.retValue.id,
            isEnableMonitoring: res.retValue.isEnableMonitoring,
            accessKeyId: res.retValue.accessKeyId,
            secret: res.retValue.secret,
            monitoringInterval: res.retValue.monitoringInterval,
            monitoringTipNumber: res.retValue.monitoringTipNumber,
            monitoringTipDuration: res.retValue.monitoringTipDuration,
          });
          this.setState({monitoringSetting: res.retValue})
        }
      }
    )  
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/auto_yellow_identify">自动鉴黄</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 自动鉴黄记录 ————————————————————————————
            <TabPane tab="自动鉴黄记录" key="1">
              

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.violationRecordsList}
                columns={this.state.violationRecordsColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.violationRecordsTotal, defaultCurrent: 1, onChange: this.violationRecordsChangePage}}
              >
              </Table>
              <VideoPlay rowData={this.state.videoRow} close={this.hideVideoDialog} visible={this.state.showVideoDialog}></VideoPlay>

            </TabPane>
            
            
            // ———————————————————— 自动鉴黄设置 ————————————————————————————
            <TabPane tab="自动鉴黄设置" key="2">
              <Form
                  name="MonitoringSettingForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.monitoringSetting}
                >
                  <Form.Item label="id" name="id" initialValue={1} hidden>
                      <Input placeholder="id" />
                  </Form.Item>

                  <Form.Item label="是否开启鉴黄" labelCol={{span:10}} name="isEnableMonitoring" style={{ width: 420 }} initialValue={0} >
                      <Radio.Group name="isEnableMonitoring" defaultValue={0}>
                          <Radio value={0}>不开启</Radio>
                          <Radio value={1}>开启</Radio>
                      </Radio.Group>
                  </Form.Item>

                  <Form.Item label="阿里云AccessKeyID" name="accessKeyId" labelCol={{span:10}} style={{ width: 420 }}>
                      <Input  />
                  </Form.Item>

                  <Form.Item label="阿里云AccessKeySecret" name="secret" labelCol={{span:10}} style={{ width: 420 }}>
                      <Input  />
                  </Form.Item>

                  <Row>
                    <Form.Item label="鉴黄间隔（秒）" name="monitoringInterval" labelCol={{span:10}} style={{ width: 420 }}>
                        <InputNumber  />
                    </Form.Item>
                    最少15秒，低于15秒按15秒计算
                  </Row>

                  <Row>
                    <Form.Item label="鉴黄提示次数" name="monitoringTipNumber" labelCol={{span:10}} style={{ width: 420 }}>
                        <InputNumber  />
                    </Form.Item>
                    0:检测到鉴黄，就执行关播操作 大于1000:只做提示
                  </Row>

                  <Form.Item label="提示遮罩显示时长" name="monitoringTipDuration" labelCol={{span:10}} style={{ width: 420 }}>
                      <InputNumber  />
                  </Form.Item>
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleMonitoringSettingSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
              
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default AutoYellowIdentify;