import React, {Component, PropTypes} from 'react';
//import AddUserFrm from './AddUserFrm';
import { Form, Input, Select, Modal, Upload, Button, message} from 'antd';
import { UserOutlined,MailOutlined,SafetyOutlined,PhoneOutlined } from '@ant-design/icons';
import { GetLoginToken } from '../../../Common/Auth';
import Service from '../../../Service/index';
import userService from '../../../Service/Admin/userService';
import { Logout } from '../../../Common/Auth';



class AddUser extends Component {
    formRef = React.createRef();
    // 提交操作
    handleSubmit =() => {
        let { history } = this.props;
        this.formRef.current.validateFields()
        .then(values => {
           //console.log(JSON.stringify(values));
           let userParam = values;
           userService.insertOrUpdateUser(userParam)
           .then(res => {
                if(res == null || res.retCode != 200) {
                    if(res.retCode == 10001) {
                        // 清除登录信息
                        Logout();
                        // 跳转到登录页面
                        history.push('/login');
                        return;
                    }
                    console.log("添加失败")
                    message.info("添加失败,"+res.message);  
                    // 重置关闭对话框
                    this.hanldeCloseModal();
                } else {
                    message.info('添加成功！');
                    // 重置关闭对话框
                    this.hanldeCloseModal();
                    // 刷新数据
                    this.props.refreshData();
                }
                
            })
           
        })

    }

    // 取消操作
    hanldeCloseModal = () => {
        this.props.close();
        // 重置表单
        this.formRef.current.resetFields();
    }

    // 头像图片修改
    handleChangeFileAvatar=(e) => {
        if(e.file.response) {
            //console.log(e.file.response);
            return e.file.response;
        }
        return null;
    }

    // 上传前置校验
    beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

 // 上传图片
 doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };


  render() {
    return (
      <Modal 
        title="添加用户信息"
        okText="确定"
        cancelText="取消"
        visible={this.props.visible} 
        onCancel={this.hanldeCloseModal}
        onOk={this.handleSubmit}
      >
        <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
        >
            <Form.Item label="用户名" name="name" rules={ [
                    {
                        pattern: /\w{5,20}/gi,
                        message: '请输入5-20个字符!',
                    },
                    {
                        required: true,
                        message: '请输入用户名!',
                    }
                    ]
                }>
                <Input prefix={<UserOutlined />} 
                    placeholder="用户名"
                />
            </Form.Item>

            <Form.Item label="密码" name="password" rules={ [
                    {
                        pattern: /\w{6,20}/gi,
                        message: '请输入6-20个字符!',
                    },
                    {
                        required: true,
                        message: '请输入密码!',
                    }
                    ]
                }>
                <Input.Password prefix={<SafetyOutlined />} 
                    placeholder="密码"
                />
            </Form.Item>

            <Form.Item label="用户头像" name="avatar" 
                getValueFromEvent={this.handleChangeFileAvatar}
                >
                <Upload
                    accept="image/*"
                    action='/applicationApi/apiFunctionHandler'
                    headers={{Authorization: GetLoginToken()}}
                    name="file"
                    listType="picture" 
                    onChange={this.handleChangeFileAvatar}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                >
                    <Button>上传头像</Button>
                </Upload>
            </Form.Item>

            <Form.Item label="邮箱" name="email" rules={ [
                    {
                        type: 'email',
                        message: '请输入正确的邮箱格式!',
                    },
                    {
                        required: true,
                        message: '请输入邮箱!',
                    }
                    ]
                }>
                <Input prefix={<MailOutlined />} 
                    placeholder="邮箱"
                />
            </Form.Item>

            <Form.Item label="昵称" name="anonymous" rules={ [
                    {
                        min: 2,
                        message: '请输入大于2个字符!',
                    },
                    {
                        required: true,
                        message: '请输入昵称!',
                    }
                    ]
                }>
                <Input prefix={<UserOutlined />} 
                    placeholder="昵称"
                />
            </Form.Item>

            <Form.Item label="电话" name="phone" rules={ [
                    {
                        pattern: /\d{11}/gi,
                        message: '请输入11个字符!',
                    }
                    ]
                }>
                <Input prefix={<PhoneOutlined />} 
                    placeholder="电话"
                />
            </Form.Item>

        </Form>
      </Modal>
    );
  }
}

export default AddUser;