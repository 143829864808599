import React, { Component, Fragment } from 'react'
import {  message } from 'antd';
import { Route } from 'react-router-dom';
import { getLoginUserAllPer, Logout } from '../../Common/Auth';

class AuthRoute extends Component {
  state = {
    authorized: false
  }
  componentDidMount() {
    let { history } = this.props;

    getLoginUserAllPer()
      .then(res => {
        //console.log("getLoginUserAllPer res="+ JSON.stringify(res));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          res.retValue.forEach(element => {
            //console.log("element="+ JSON.stringify(element) + ",this.props.per =" + this.props.per);
            if(element.code == this.props.per) {
              this.setState({authorized:true});
            }
          });
        }

    });
  }

  UNSAFE_componentWillReceiveProps() {
    let { history } = this.props;

    // 判断当前传来的属性是谁，然后判断当前登录用户是否拥有此路由的权限
    // 如果有权限，返回route对象
    // 没有权限返回 null
    // per属性是当前路由对应的权限数据的id
    // this.props.per
    // 拿到当前登录用户的所有的权限
    // console.log(this.state.authorized);
    getLoginUserAllPer()
      .then(res => {
        //console.log("getLoginUserAllPer="+ res);
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          res.retValue.forEach(element => {
            //console.log("element="+ JSON.stringify(element));
            if(element.code == this.props.per) {
              this.setState({authorized:true});
            }
          });
        }
      })
  }

  render () {
    return (
      <Fragment>
        {
          this.state.authorized ?
          <Route {...this.props} ></Route>
          : 
          <Route path={this.props.path} render={()=> (<h3>没有权限</h3>)}></Route>
        }
      </Fragment>
    )
  }
}

export default AuthRoute