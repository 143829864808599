import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取提现规则
   * @param {*} 
   * @returns
   */
   getWithdrawalRulesPage(withdrawalRulesParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/withdrawalRules/getWithdrawalRulesPage",
      data: withdrawalRulesParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入更新提现规则
   * @param {*} 
   * @returns
   */
  insertOrUpdateWithdrawalRules(appWithdrawalRules) {
      //console.log("commonWordsParam=" + JSON.stringify());
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/withdrawalRules/insertOrUpdateWithdrawalRules",
        data: appWithdrawalRules,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
  },

  /**
   * 删除提现规则
   * @param {*} 
   * @returns
   */
  deleteWithdrawalRules(withdrawalRulesParam) {
      //console.log("commonWordsParam=" + JSON.stringify());
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/withdrawalRules/deleteWithdrawalRules",
        data: withdrawalRulesParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
  },
}