import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select } from 'antd';
import dealScalePlanService from '../../../Service/UserMgr/dealScalePlanService';
import { Logout } from '../../../Common/Auth';

class AddDealScalePlan extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var dealScalePlanParam = values;
          dealScalePlanService.createDealScalePlan(dealScalePlanParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  }


  render () {
    return (
      <Modal
        title="编辑"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="方案名称" name="planName">
                <Input placeholder="方案名称"/>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddDealScalePlan