import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取配置
   * @param {*} improveQuicklyConfigParam
   * @returns
   */
   getConfigPage(improveQuicklyConfigParam) {
      //console.log("improveQuicklyConfigParam=" + JSON.stringify(improveQuicklyConfigParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/improveQuicklyConfig/getConfigPage",
        data: improveQuicklyConfigParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入或更新配置
   * @param {*} appImproveQuicklyConfig
   * @returns
   */
   insertOrUpdateConfig(appImproveQuicklyConfig) {
        //console.log("appImproveQuicklyConfig=" + JSON.stringify(appImproveQuicklyConfig));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/improveQuicklyConfig/insertOrUpdateConfig",
        data: appImproveQuicklyConfig,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}