import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import improveQuicklyConfig from "../../../Service/GradeMgr/improveQuicklyConfig";
import AddHostLevelConfig from './AddHostLevelConfig';
import AddUserLevelConfig from './AddUserLevelConfig';
import AddWealthLevelConfig from './AddWealthLevelConfig';
import UpdateHostLevelConfig from './UpdateHostLevelConfig';
import UpdateUserLevelConfig from './UpdateUserLevelConfig';
import UpdateWealthLevelConfig from './UpdateWealthLevelConfig';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class MedalMgr extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  state = {
    // ———————————————————— 用户等级配置 ————————————————————————————

    userLevelConfigColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "logo",
        title: "图标",
        dataIndex: "logo",
        render: (logo, row) => {
          if (logo != null && logo != "") {
            return <Image width={50} height={50} src={logo} />;
          }
        },
      },
      {
        key: "sort",
        title: "排序值",
        dataIndex: "sort",
      },
      {
        key: "desr",
        title: "描述",
        dataIndex: "desr",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateUserLevelConfigRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    userLevelConfigTotal: 0,
    userLevelConfigList: null,
    updateUserLevelConfigRow: null,
    showUpdateUserLevelConfigDialog: false,
    addUserLevelConfigRow: null,
    showAddUserLevelConfigDialog: false,
    userLevelConfigPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 财富等级配置 ————————————————————————————

    wealthLevelConfigColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "logo",
        title: "图标",
        dataIndex: "logo",
        render: (logo, row) => {
          if (logo != null && logo != "") {
            return <Image width={50} height={50} src={logo} />;
          }
        },
      },
      {
        key: "sort",
        title: "排序值",
        dataIndex: "sort",
      },
      {
        key: "desr",
        title: "描述",
        dataIndex: "desr",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateWealthLevelConfigRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    wealthLevelConfigTotal: 0,
    wealthLevelConfigList: null,
    updateWealthLevelConfigRow: null,
    showUpdateWealthLevelConfigDialog: false,
    addWealthLevelConfigRow: null,
    showAddWealthLevelConfigDialog: false,
    wealthLevelConfigPageParam:{
      pageNum: 1,
      pageSize: 10,
    },


    // ———————————————————— 主播等级配置 ————————————————————————————
    hostLevelConfigColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "logo",
        title: "图标",
        dataIndex: "logo",
        render: (logo, row) => {
          if (logo != null && logo != "") {
            return <Image width={50} height={50} src={logo} />;
          }
        },
      },
      {
        key: "sort",
        title: "排序值",
        dataIndex: "sort",
      },
      {
        key: "desr",
        title: "描述",
        dataIndex: "desr",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateHostLevelConfigRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    hostLevelConfigTotal: 0,
    hostLevelConfigList: null,
    updateHostLevelConfigRow: null,
    showUpdateHostLevelConfigDialog: false,
    addHostLevelConfigRow: null,
    showAddHostLevelConfigDialog: false,
    hostLevelConfigPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 用户等级配置 ————————————————————————————
    this.getImproveQuicklyConfig1();
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 用户等级配置 ————————————————————————————
  // 用户等级配置分页
  userLevelConfigChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        userLevelConfigPageParam: pageParam,
      });

      var userLevelConfigParam = {
        type: 1,
        pageParam: pageParam,
      };
      improveQuicklyConfig.getConfigPage(userLevelConfigParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            userLevelConfigList: res.retValue.list,
            userLevelConfigTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshUserLevelConfigData = () => {
    //console.log("refreshUserLevelConfigData");
    let { history } = this.props;

    var userLevelConfigParam = {
      type: 1,
      pageParam: this.state.userLevelConfigPageParam,
    };
    improveQuicklyConfig.getConfigPage(userLevelConfigParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          userLevelConfigList: res.retValue.list,
          userLevelConfigTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateUserLevelConfigDialog = () => {
    this.setState({ showUpdateUserLevelConfigDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddUserLevelConfigDialog = () => {
    this.setState({ showAddUserLevelConfigDialog: false });
  };

  /**
   * 获取添加用户等级数据
   * @param {*} options
   */
  getAddUserLevelConfigRowData = () => {

      this.setState({ showAddUserLevelConfigDialog: true });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateUserLevelConfigRowData = (row) => {
      this.state.updateUserLevelConfigRow = row;
      this.setState({ showUpdateUserLevelConfigDialog: true });

  };

  // ———————————————————— 财富等级配置  ————————————————————————————
  // 财富等级配置分页
  wealthLevelConfigChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        wealthLevelConfigPageParam: pageParam,
      });

      var wealthLevelConfigParam = {
        type: 2,
        pageParam: pageParam,
      };
      improveQuicklyConfig.getConfigPage(wealthLevelConfigParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            wealthLevelConfigList: res.retValue.list,
            wealthLevelConfigTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshWealthLevelConfigData = () => {
    //console.log("refreshWealthMedalData");
    let { history } = this.props;

    var wealthLevelConfigParam = {
      type: 2,
      pageParam: this.state.wealthLevelConfigPageParam,
    };
    improveQuicklyConfig.getConfigPage(wealthLevelConfigParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          wealthLevelConfigList: res.retValue.list,
          wealthLevelConfigTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateWealthLevelConfigDialog = () => {
    this.setState({ showUpdateWealthLevelConfigDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddWealthLevelConfigDialog = () => {
    this.setState({ showAddWealthLevelConfigDialog: false });
  };

  /**
   * 获取添加用户勋章数据
   * @param {*} options
   */
  getAddWealthLevelConfigRowData = () => {

      this.setState({ showAddWealthLevelConfigDialog: true });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateWealthLevelConfigRowData = (row) => {
      this.state.updateWealthLevelConfigRow = row;
      this.setState({ showUpdateWealthLevelConfigDialog: true });
  };

  // ———————————————————— 主播等级配置 ————————————————————————————
  // 主播等级配置分页
  hostLevelConfigChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields().then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        hostLevelConfigPageParam: pageParam,
      });

      var hostLevelConfigParam = {
        type: 3,
        pageParam: pageParam,
      };
      improveQuicklyConfig.getConfigPage(hostLevelConfigParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            hostLevelConfigList: res.retValue.list,
            hostLevelConfigTotal: res.retValue.total,
          });
        }    
        
      });
    });
  };

  // 刷新数据
  refreshHostLevelConfigData = () => {
   //console.log("refreshUserMedalData");
   let { history } = this.props;

    var hostLevelConfigParam = {
      type: 3,
      pageParam: this.state.hostLevelConfigPageParam,
    };
    improveQuicklyConfig.getConfigPage(hostLevelConfigParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          hostLevelConfigList: res.retValue.list,
          hostLevelConfigTotal: res.retValue.total,
        });
      }    
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateHostLevelConfigDialog = () => {
    this.setState({ showUpdateHostLevelConfigDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddHostLevelConfigDialog = () => {
    this.setState({ showAddHostLevelConfigDialog: false });
  };

  /**
   * 获取添加用户勋章数据
   * @param {*} options
   */
  getAddHostLevelConfigRowData = () => {
    this.setState({ showAddHostLevelConfigDialog: true });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateHostLevelConfigRowData = (row) => {
    this.state.updateHostLevelConfigRow = row;
    this.setState({ showUpdateHostLevelConfigDialog: true });
  };


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getImproveQuicklyConfig1();
    } else if(key == "2") {
      this.getImproveQuicklyConfig2();
    } else if(key == "3") {
      this.getImproveQuicklyConfig3();
    } 
  }

  /**
   * 用户等级配置
   */
  getImproveQuicklyConfig1 = () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var userLevelConfigParam = {
      type: 1,
      pageParam: pageParam,
    };
    improveQuicklyConfig.getConfigPage(userLevelConfigParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          userLevelConfigList: res.retValue.list,
          userLevelConfigTotal: res.retValue.total,
        });
      }    
      
    });
  }

  /**
   * 财富等级配置
   */
  getImproveQuicklyConfig2 = () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var wealthLevelConfigParam = {
      type: 2,
      pageParam: pageParam,
    };
    improveQuicklyConfig.getConfigPage(wealthLevelConfigParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          wealthLevelConfigList: res.retValue.list,
          wealthLevelConfigTotal: res.retValue.total,
        });
      }    
    
    });
  }

  /**
   * 主播等级配置
   */
  getImproveQuicklyConfig3 = () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var hostLevelConfigParam = {
      type: 3,
      pageParam: pageParam,
    };
    improveQuicklyConfig.getConfigPage(hostLevelConfigParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          hostLevelConfigList: res.retValue.list,
          hostLevelConfigTotal: res.retValue.total,
        });
      }    
      
    });
  }

  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/medal_mgr">快速提速等级配置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            // ———————————————————— 用户等级配置 ——————————————————————
            <TabPane tab="用户等级配置" key="1">
              <Form
                name="userLevelConfigListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: "20px 0px 0px 100px" }}
              >
                <Row gutter={24}>
                  <Col span={14} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      onClick={this.getAddUserLevelConfigRowData} >
                      + 新增
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.userLevelConfigList}
                columns={this.state.userLevelConfigColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.userLevelConfigTotal,
                  defaultCurrent: 1,
                  onChange: this.userLevelConfigChangePage,
                }}
              ></Table>
              <AddUserLevelConfig
                rowData={this.state.addUserLevelConfigRow}
                close={this.hideAddUserLevelConfigDialog}
                visible={this.state.showAddUserLevelConfigDialog}
                refreshData={this.refreshUserLevelConfigData}
              ></AddUserLevelConfig>
              <UpdateUserLevelConfig
                rowData={this.state.updateUserLevelConfigRow}
                close={this.hideUpdateUserLevelConfigDialog}
                visible={this.state.showUpdateUserLevelConfigDialog}
                refreshData={this.refreshUserLevelConfigData}
              ></UpdateUserLevelConfig>
            </TabPane>
            
            // ———————————————————— 财富等级配置 ————————————————————————————
            <TabPane tab="财富等级配置" key="2">
              <Form
                name="wealthLevelConfigListForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
                style={{ margin: "20px 0px 0px 100px" }}
              >
                <Row gutter={24}>
                  <Col span={14} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      onClick={this.getAddWealthLevelConfigRowData} >
                      + 新增
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.wealthLevelConfigList}
                columns={this.state.wealthLevelConfigColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.wealthLevelConfigTotal,
                  defaultCurrent: 1,
                  onChange: this.wealthLevelConfigChangePage,
                }}
              ></Table>
              <AddWealthLevelConfig
                rowData={this.state.addWealthLevelConfigRow}
                close={this.hideAddWealthLevelConfigDialog}
                visible={this.state.showAddWealthLevelConfigDialog}
                refreshData={this.refreshWealthLevelConfigData}
              ></AddWealthLevelConfig>
              <UpdateWealthLevelConfig
                rowData={this.state.updateWealthLevelConfigRow}
                close={this.hideUpdateWealthLevelConfigDialog}
                visible={this.state.showUpdateWealthLevelConfigDialog}
                refreshData={this.refreshWealthLevelConfigData}
              ></UpdateWealthLevelConfig>


            </TabPane>
            
            // —————————————————————— 主播等级配置 ————————————————————————
            <TabPane tab="主播等级配置" key="3">
              <Form
                name="hostLevelConfigListForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
                style={{ margin: "20px 0px 0px 100px" }}
              >
                <Row gutter={24}>
                  <Col span={14} style={{ textAlign: "right" }}>
                    <Button
                      type="primary"
                      onClick={this.getAddHostLevelConfigRowData} >
                      + 新增
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.hostLevelConfigList}
                columns={this.state.hostLevelConfigColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.hostLevelConfigTotal,
                  defaultCurrent: 1,
                  onChange: this.hostLevelConfigChangePage,
                }}
              ></Table>
              <AddHostLevelConfig
                rowData={this.state.addHostLevelConfigRow}
                close={this.hideAddHostLevelConfigDialog}
                visible={this.state.showAddHostLevelConfigDialog}
                refreshData={this.refreshHostLevelConfigData}
              ></AddHostLevelConfig>
              <UpdateHostLevelConfig
                rowData={this.state.updateHostLevelConfigRow}
                close={this.hideUpdateHostLevelConfigDialog}
                visible={this.state.showUpdateHostLevelConfigDialog}
                refreshData={this.refreshHostLevelConfigData}
              ></UpdateHostLevelConfig>

            </TabPane>
          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}
export default MedalMgr;
