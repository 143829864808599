import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import VideoPlay from '../../UserMgr/HostUser/VideoPlay';
import shortVideoService from "../../../Service/ShortVideo/shortVideoService";

import AddShortVideo from "./AddShortVideo";

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class VideoList extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 短视频列表 ————————————————————————————

    shortVideoColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "thumb",
        title: "封页图",
        dataIndex: "thumb",
        render: (thumb, row) => {
          if (thumb != null && thumb != "") {
            return <Image width={55} height={65} src={thumb} />;
          }
        },
      },
      {
        key: "content",
        title: "标题",
        dataIndex: "content",
      },
      {
        key: 'videoUrl',
        title: '视频',
        dataIndex: 'videoUrl',
        render: (videoUrl, row) => {
          if(videoUrl != null && videoUrl != "") {
  
            return <Button type="primary" 
                            onClick={() => this.handlePlayVideo(videoUrl)}
                            style={{ marginRight: '15px' }}>
                  观看
                </Button>
          }
        }
      },
      {
        key: "username",
        title: "用户名",
        dataIndex: "username",
      },
      {
        key: "avatar",
        title: "用户图片",
        dataIndex: "avatar",
      },
      {
        key: "mobile",
        title: "用户手机",
        dataIndex: "mobile",
      },
      {
        key: "classifyName",
        title: "分类",
        dataIndex: "classifyName",
      },
      {
        key: "type",
        title: "类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "视频"
          } else if(type === 2) {
            return "图片";
          } 
        }
      },
      {
        key: "isPrivate",
        title: "是否私密",
        dataIndex: "isPrivate",
        render: (isPrivate, row) => {
          if(isPrivate === 0) {
            return "正常"
          } else if(isPrivate === 1) {
            return "私密";
          } 
        }
      },
      {
        key: "internalRecommended",
        title: "是否推荐",
        dataIndex: "internalRecommended",
        render: (internalRecommended, row) => {
          if(internalRecommended === 0) {
            return "不推荐"
          } else if(internalRecommended === 1) {
            return "推荐";
          } 
        }
      },
      {
        key: "isWeek",
        title: "本周必看",
        dataIndex: "isWeek",
        render: (isWeek, row) => {
          if(isWeek === 0) {
            return "是"
          } else if(isWeek === 1) {
            return "否";
          } 
        }
      },
      {
        key: "shareNum",
        title: "分享数量",
        dataIndex: "shareNum",
      },
      {
        key: "status",
        title: "状态",
        dataIndex: "status",
        render: (status, row) => {
          if(status === 0) {
            return "未审核"
          } else if(status === 1) {
            return "通过";
          } else if(status === 2) {
            return "拒绝";
          } 
        }
      },
      {
        key: "isEnableMonitoring",
        title: "鉴黄状态",
        dataIndex: "isEnableMonitoring",
        render: (isEnableMonitoring, row) => {
          if(isEnableMonitoring === 0) {
            return "未开启"
          } else if(isEnableMonitoring === 1) {
            return "鉴黄中";
          } else if(isEnableMonitoring === 2) {
            return "鉴黄已通过";
          } 
        }
      },
      {
        key: "addtime",
        title: "发布时间",
        dataIndex: "addtime",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateShortVideoRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteShortVideo(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ],
    shortVideoTotal: 0,
    shortVideoList: null,
    shortVideoPageParam: {
      virtualOrNot: 0,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },

    videoRow: null,
    showVideoDialog: false,
    showVideoButton: false,
    addShortVideoRow: null,
    showAddShortVideoDialog: false,

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    this.handleGetShortVideoPagePage();


  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 短视频列表 ————————————————————————————

  /**
   * 播放视频
   */
  handlePlayVideo=(href) => {
  
    //console.log("href=" + href)
    this.setState({videoRow: {
        videoUrl : href ,
    }});
    this.setState({showVideoDialog:true })
  }

  // 关闭弹窗
  hideVideoDialog=() => {
    this.setState({showVideoDialog: false});
  }



  // 根据Id删除
  deleteShortVideo=(id) => {
      let { history } = this.props;
      let appShortVideo = {
        id : id
      };
      shortVideoService.deleteShortVideo(appShortVideo)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshShortVideoData();
          message.success('删除成功！');
        }                  
        
      })
  }

  // 分页获取
  handleGetShortVideoPagePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var appShortVideo = {
        content: values.content,
        userId: values.userId,
        status: values.status,
        isRecommend: values.isRecommend,
        virtualOrNot: 0,
        pageParam : pageParam,
      }

      shortVideoService.getShortVideoPage(appShortVideo)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            shortVideoList: res.retValue.list,
            shortVideoTotal: res.retValue.total,
          });
        }                  
        
      });
      
    })

  }

  // 分页
  shortVideoChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var appShortVideo = {
        content: values.content,
        userId: values.userId,
        status: values.status,
        isRecommend: values.isRecommend,
        virtualOrNot: 0,
        pageParam : pageParam,
      }

      this.setState({
        shortVideoPageParam: appShortVideo,
      });

      shortVideoService.getShortVideoPage(appShortVideo)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            shortVideoList: res.retValue.list,
            shortVideoTotal: res.retValue.total,
          });
        }                  
        
      });
    });
  };

  // 刷新数据
  refreshShortVideoData = () => {
    let { history } = this.props;
    //console.log("refreshshortVideoData");
  
    var appShortVideo = this.state.shortVideoPageParam
    shortVideoService.getShortVideoPage(appShortVideo)
      .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          shortVideoList: res.retValue.list,
          shortVideoTotal: res.retValue.total,
        });
      }                  

    });
  };


  /**
   * 获取添加贵族价格数据
   * @param {*} options
   */
  getAddShortVideoRowData = () => {
      let { history } = this.props;
      
      this.setState({ showAddShortVideoDialog: true });
  };

  // 隐藏
  hideAddShortVideoDialog = () => {
    this.setState({ showAddShortVideoDialog: false });
  };



  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/video_list">短视频列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
            <Form
              name="videoListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 0px'}}
            >
              <Row gutter={24}>
                  <Col span={8} key={1}>
                    <Form.Item label="标题" name="content">
                        <Input placeholder="标题"/>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={2}>
                    <Form.Item label="用户UID" name="userId">
                        <Input placeholder="用户UID"/>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={3}>
                    <Form.Item label="状态" name="status" initialValue=''>
                      <Select style={{ width: 220 }} defaultValue=''>
                          <Option value=''>全部</Option>
                          <Option value={0}>未审核</Option>
                          <Option value={1}>通过</Option>
                          <Option value={2}>拒绝</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={4}>
                    <Form.Item label="是否推荐" name="internalRecommended" initialValue=''>
                      <Select style={{ width: 220 }} defaultValue=''>
                          <Option value=''>全部</Option>
                          <Option value={0}>不推荐</Option>
                          <Option value={1}>推荐</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} key={5} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetShortVideoPagePage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                    <Button type="primary" onClick={this.getAddShortVideoRowData}  >
                        + 新增
                    </Button>
                  </Col>
              </Row>

            </Form>

            <Table
              bordered
              style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
              dataSource={this.state.shortVideoList}
              columns={this.state.shortVideoColumns}
              rowKey="id"
              scroll={{ x: "max-content" }}
              pagination={{
                total: this.state.shortVideoTotal,
                defaultCurrent: 1,
                onChange: this.shortVideoChangePage,
              }}
            ></Table>
            <VideoPlay rowData={this.state.videoRow} close={this.hideVideoDialog} visible={this.state.showVideoDialog}></VideoPlay>

            <AddShortVideo
              close={this.hideAddShortVideoDialog}
              visible={this.state.showAddShortVideoDialog}
              refreshData={this.refreshShortVideoData}
            ></AddShortVideo>

        </StickyContainer>
      </div>
    );
  }
}
export default VideoList;
 
