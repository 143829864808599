import React, { Component } from 'react'
import { Row, Col, Checkbox, message, Modal, Tree, Form } from 'antd';
import { formateDate2String } from '../../../Common/Helper';
import { blue } from '@ant-design/colors';
import rolePrivilege from '../../../Service/Admin/rolePrivilege';
import { Logout } from '../../../Common/Auth';

/**
 * 设置角色权限
 */
class SetRolePer extends Component {
  formRef = React.createRef();
  state = {
    allCheckedPer: [],  // 最终用户所有选中的权限
    storageCheckedPer:[], // 存储数据库用到的，比allCheckedPer多出Pid
    treeDataList: [],  //  角色权限数据
    roleId: null,     // 角色Id
    pidMap: null,     // pid索引
  }
  async componentDidMount() {
    // 加载所有的权限数据
    let roleId = this.props.data ? this.props.data.id : null;

    let rolePerData = await rolePrivilege.getRelListByRoleId(roleId);

    let allCheckedPer = [];
    let levelOneRolePers = [];
    let levelTwoRolePers = [];
    var treeDataList = [];
    var pidMap = new Map();

    // 给已经默认设置了关联的角色添加到 allCheckedRole数组中去。
    if(rolePerData.retValue.length > 0) {
      // 区分一级和二级权限
      rolePerData.retValue.forEach(rolePer => {
          if(rolePer.selected && rolePer.level == 2) {
            allCheckedPer.push(rolePer.privilegeId);
          }
          if(rolePer.level == 1) {
            levelOneRolePers.push(rolePer);
          } else if(rolePer.level == 2){
            levelTwoRolePers.push(rolePer);
          }
          // pid索引
          pidMap.set(rolePer.privilegeId, rolePer.pid);
      })

      // 二级权限归属
      levelOneRolePers.forEach(rolePerOne => {
        var privilegeIdOne = rolePerOne.privilegeId;
        var oneDataNode = {
          title : rolePerOne.des,
          key : rolePerOne.privilegeId,
          children: null,
        };

        var levelOneList = [];
        levelTwoRolePers.forEach(rolePerTwo => {
          var privilegeIdTwo = rolePerTwo.privilegeId;
          var pid = rolePerTwo.pid

          if(pid == privilegeIdOne) {
            var twoDataNode = {
              title : rolePerTwo.des,
              key : rolePerTwo.privilegeId
            }
            levelOneList.push(twoDataNode);
          }
        })
        oneDataNode.children = levelOneList;
        treeDataList.push(oneDataNode);
      });
    }
    //console.log("——————————pidMap="+pidMap.size);
    //console.log("——————————pidMap="+pidMap.get(104));
    //console.log("——————————allCheckedPer="+JSON.stringify(allCheckedPer));
    //console.log("——————————treeDataList="+JSON.stringify(treeDataList));
    this.setState({pidMap: pidMap});
    this.setState({roleId: roleId});
    this.setState({allCheckedPer: allCheckedPer});
    this.setState({treeDataList: treeDataList});
  }

  /**
   * 提交参数
   */
  handleSubmitSetRolePer = () => {
    let { history } = this.props;
    // console.log(12234);
    let { storageCheckedPer, roleId } = this.state;
    // 添加的
    let rolePerParamList = [];
    storageCheckedPer.forEach(privilegeId => {
      let rolePerParam = {
        roleId : roleId,
        privilegeId : privilegeId
      }
      rolePerParamList.push(rolePerParam);
    })

    rolePrivilege.updateRelByRoleId(rolePerParamList)
    .then(
      res => {
        //console.log("res=" + JSON.stringify(res));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.error('设置失败！');
        } else {
          message.info('设置成功');
          this.props.close();
        }
      }
    )
  }

  /**
   * 选择
   * @param {*} checkedKeys 
   * @param {*} info 
   */
  onCheck = (checkedKeys, info) => {
    //console.log('onCheck checkedKeys=' + checkedKeys );
    let allCheckedKeys = checkedKeys.concat(info.halfCheckedKeys);//将父节点拼接到子节点
    //console.log('onCheck allCheckedKeys=' + allCheckedKeys );
    this.setState({allCheckedPer: checkedKeys});
    this.setState({storageCheckedPer: allCheckedKeys});
  }

  render () {

    return (
      <Modal
        title="角色设置权限"
        destroyOnClose
        okText="设置"
        cancelText="取消"
        onCancel={() => this.props.close()}
        visible={this.props.visible}
        onOk={this.handleSubmitSetRolePer}
      >
          <div>
            <h3>给角色：<span style={{color: blue[5]}}>{this.props.data.name} </span>设置权限</h3>
            <hr />
              <Tree
                checkable
                defaultExpandAll={true}
                checkedKeys={this.state.allCheckedPer}
                onCheck={this.onCheck}
                treeData={this.state.treeDataList}
              />
          </div>
      </Modal>
    )
  }
}

export default SetRolePer