import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import qxUserService from '../../../Service/Qx/qxUserService';

import UpdateQxUser from './UpdateQxUser';
import AddQxUser from './AddQxUser';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class QxUserList extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 动态列表 ————————————————————————————
    qxUserListColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'qxUserId',
      title: '企信用户Id',
      dataIndex: 'qxUserId'
    },{
      key: 'qxName',
      title: '企微名',
      dataIndex: 'qxName'
    },{
      key: 'mobile',
      title: '手机号',
      dataIndex: 'mobile'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        if(row.status === 3) {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateQxUserListDialog:true, updateQxUserListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteQxUser(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
        } else {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateQxUserListDialog:true, updateQxUserListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteQxUser(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
        }
          
      }
    }],
    qxUserListTotal: 0,
    qxUserList: null,
    updateQxUserListRow: null,
    showUpdateQxUserListDialog: false,
    showAddQxUserListDialog: false,
    qxUserListPageParam:{
      userId : null,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 获取等级礼物 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var qxUserListPageParam = {
      userId : null,
      pageParam : pageParam,
    }

    this.setState({
      qxUserListPageParam: qxUserListPageParam,
    });

    qxUserService.getQxUserPage(qxUserListPageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({qxUserList: res.retValue.list, qxUserListTotal: res.retValue.total});
          }
        }
      )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 列表 ————————————————————————————

  

  // 获取列表
  handleGetQxUserListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
     // console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var qxUserListPageParam = {
        userId : values.userId,
        title: values.title,
        type: values.type,
        secondType: values.secondType,
        status: values.status,
        pageParam : pageParam,
      }

      this.setState({
        qxUserListPageParam: qxUserListPageParam,
      });

      qxUserService.getQxUserPage(qxUserListPageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({qxUserList: res.retValue.list, qxUserListTotal: res.retValue.total});
            }
          }
        )
      
    })

  }

  // 分页
  qxUserListChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var qxUserListPageParam = {
          userId : values.userId,
          pageParam : pageParam,
        }

        this.setState({
          qxUserListPageParam: qxUserListPageParam,
        });

        qxUserService.getQxUserPage(qxUserListPageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({qxUserList: res.retValue.list, qxUserListTotal: res.retValue.total});
            }
          }
        )
    })
  }

  // 刷新数据
  refreshQxUserListData=() => {
    let { history } = this.props;
    //console.log("refreshQxUserListData");

    var appQxUser = this.state.qxUserListPageParam;

    qxUserService.getQxUserPage(appQxUser)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({qxUserList: res.retValue.list, qxUserListTotal: res.retValue.total});
        }
      }
    )
  }
 


  
  // 根据Id删除
  deleteQxUser=(id) => {
      let { history } = this.props;
      let appQxUser = {
        id : id
      };
      qxUserService.deleteQxUser(appQxUser)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshQxUserListData();
          message.success('删除成功！');
        }
       
      })

  }

  // 隐藏
  hideUpdateQxUserListDialog=() => {
    this.setState({showUpdateQxUserListDialog: false});
  }

  // 隐藏
  hideAddQxUserListDialog=() => {
    this.setState({showAddQxUserListDialog: false});
  }
   
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/qxUser_list">扩列列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="QxUserListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >

                <Row gutter={24}>
                  <Col span={6} key={4}>
                      <Form.Item label="关键词" name="searchKey">
                          <Input placeholder="关键词" />
                      </Form.Item>
                    </Col>
                
                    <Col span={3} key={6} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetQxUserListPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                    <Col span={3} key={7} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddQxUserListDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.qxUserList}
                columns={this.state.qxUserListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.qxUserListTotal, defaultCurrent: 1, onChange: this.qxUserListChangePage}}
              >
              </Table>
              <AddQxUser close={this.hideAddQxUserListDialog} visible={this.state.showAddQxUserListDialog} refreshData={this.refreshQxUserListData}></AddQxUser>
              <UpdateQxUser rowData={this.state.updateQxUserListRow} close={this.hideUpdateQxUserListDialog} visible={this.state.showUpdateQxUserListDialog} refreshData={this.refreshQxUserListData}></UpdateQxUser>

        </StickyContainer>
        
      </div>
    );
  }
}
export default QxUserList;