import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
  InputNumber,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import ossFileRecordService from "../../../Service/SystemSetting/ossFileRecordService";

import ShowCloudStorage from "./ShowCloudStorage";


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class CloudStorageFiles extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 用户特权 ————————————————————————————

    cloudStorageColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "fileName",
        title: "文件名称",
        dataIndex: "fileName",
      },
      {
        key: "fileUrl",
        title: "查看文件",
        dataIndex: "fileUrl",
        render: (fileUrl, row) => {
          if(fileUrl != null || fileUrl != '') {
            return (
              <div>
                 <Button 
                  onClick={() => this.setState({showCloudStorageDialog:true, showCloudStorageRow: row })}
                  style={{marginRight:'5px'}} 
                  type="primary">查看 
                </Button>
              </div>
            )     
          }   
      }
      },
      {
        key: "fileExt",
        title: "扩展名",
        dataIndex: "fileExt",
      },
      {
        key: "createTime",
        title: "创建时间",
        dataIndex: "createTime",
      },
      {
        key: "isDel",
        title: "是否删除",
        dataIndex: "isDel",
        render: (isDel, row) => {
          if(isDel === 0) {
            return "未删除"
          } else if(isDel === 1) {
            return "删除";
          }
        }
      },
      {
        key: "fileSize",
        title: "文件大小",
        dataIndex: "fileSize",
      },
      {
        key: "fileSize",
        title: "文件大小",
        dataIndex: "fileSize",
        render: (fileSize, row) => {
          if(fileSize != null) {
            var size = fileSize / 1024 / 1024;
            size = size.toFixed(2); 
            size = size + " MB";
            return size;
          }
        }
      },{
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
            return (
              <div>
                 <Popconfirm
                    onConfirm={() => {
                      //message.info(row.id);
                      this.deleteCloudStorage(row.id);
                    }}
                    onText="确认"
                    cancelText="取消"
                    title="您确认要删除吗？"
                  >
                      <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
                  </Popconfirm>
              </div>
            )        
        }
      }
    ],
    cloudStorageTotal: 0,
    cloudStorageList: null,
    showCloudStorageRow: null,
    showCloudStorageDialog: false,
    cloudStoragePageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 支付记录 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var ossFileRecordParam = {
      pageParam: pageParam,
    };
    ossFileRecordService.getOssFileRecordPage(ossFileRecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          cloudStorageList: res.retValue.list,
          cloudStorageTotal: res.retValue.total,
        });
      }                  
      
    });

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 支付记录 ————————————————————————————
  // 获取列表
  handleGetCloudStoragePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var ossFileRecordParam = {
        startTime: timeStart,
        endTime: timeEnd,
        startFileSize: values.startFileSize,
        endFileSize: values.endFileSize,
        fileName: values.fileName,
        fileExt: values.fileExt,
        pageParam: pageParam,
      };
      ossFileRecordService.getOssFileRecordPage(ossFileRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            cloudStorageList: res.retValue.list,
            cloudStorageTotal: res.retValue.total,
          });
        }                  
        
      });
      
    })
  }



  // 分页
  cloudStorageChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        cloudStoragePageParam: pageParam,
      });

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var ossFileRecordParam = {
        startTime: timeStart,
        endTime: timeEnd,
        startFileSize: values.startFileSize,
        endFileSize: values.endFileSize,
        fileName: values.fileName,
        fileExt: values.fileExt,
        pageParam: pageParam,
      };
      ossFileRecordService.getOssFileRecordPage(ossFileRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            cloudStorageList: res.retValue.list,
            cloudStorageTotal: res.retValue.total,
          });
        }                  
        
      });
    });
  };

  // 刷新数据
  refreshCloudStorageData = () => {
    //console.log("refreshCloudStorageData");
    let { history } = this.props;

    var ossFileRecordParam = {
      pageParam: this.state.cloudStoragePageParam,
    };
    ossFileRecordService.getOssFileRecordPage(ossFileRecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          cloudStorageList: res.retValue.list,
          cloudStorageTotal: res.retValue.total,
        });
      }                  
      
    });
  };

  // 隐藏
  hideCloudStorageDialog=() => {
    this.setState({showCloudStorageDialog: false});
  }

  // 根据Id删除
  deleteCloudStorage=(id) => {
      let { history } = this.props;
      let ossFileRecordParam = {
        id : id
      };
      ossFileRecordService.deleteById(ossFileRecordParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshCloudStorageData();
          message.success('删除成功！');
        }                  
        
      })
  }


  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/cloud_storage_files">云存储文件</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
            <Form
              name="CloudStorageListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 0px'}}
            >
              <Row gutter={24}>
                  <Col span={8} key={1}>
                    <Form.Item label="创建时间" name="sendTime">
                        <RangePicker />
                    </Form.Item>
                  </Col>
                  <Col span={16} key={7}>
                    <Row >
                      <Form.Item label="文件大小" name="startFileSize">
                          <InputNumber />
                      </Form.Item>
                      -
                      <Form.Item name="endFileSize">
                          <InputNumber />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col span={8} key={2}>
                    <Form.Item label="文件名称" name="fileName">
                        <Input placeholder="文件名称"/>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={3}>
                    <Form.Item label="扩展名" name="fileExt">
                        <Input placeholder="扩展名"/>
                    </Form.Item>
                  </Col>
                  <Col span={6} key={5} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetCloudStoragePage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
              </Row>
            </Form>

            <Table
              bordered
              style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
              dataSource={this.state.cloudStorageList}
              columns={this.state.cloudStorageColumns}
              rowKey="id"
              scroll={{ x: "max-content" }}
              pagination={{
                total: this.state.cloudStorageTotal,
                defaultCurrent: 1,
                onChange: this.cloudStorageChangePage,
              }}
            ></Table>
            <ShowCloudStorage rowData={this.state.showCloudStorageRow} close={this.hideCloudStorageDialog} visible={this.state.showCloudStorageDialog} ></ShowCloudStorage>

        </StickyContainer>
      </div>
    );
  }
}
export default CloudStorageFiles;
