import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {


  /**
   * 插入更新话题
   * @param {*} appVideoTopic
   * @returns
   */
   insertOrUpdateUserVideo(appVideoTopic) {
    console.log("appVideoTopic=" + JSON.stringify(appVideoTopic));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/videoTopic/insertOrUpdateUserVideo",
      data: appVideoTopic,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 获取话题列表
   * @param {*} 
   * @returns
   */
   getTopicListPage(appVideoTopicParam) {
    //console.log("appVideoTopicParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/videoTopic/getTopicListPage",
      data: appVideoTopicParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除动态评论
   * @param {*} 
   * @returns
   */
   deleteUserVideo(appVideoTopicParam) {
    //console.log("appVideoTopicParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/videoTopic/deleteUserVideo",
      data: appVideoTopicParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}