import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal, Avatar, Popconfirm, Input } from 'antd';
import { Link } from 'react-router-dom';
import AddRole from './AddRole';
import EditRole from './EditRole';
import SetRolePer from './SetRolePer';
import roleService from '../../../Service/Admin/roleService';
import { Logout } from '../../../Common/Auth';



class RoleMgr extends Component {
  state={
    setRolePer: null,          // 当前设置角色的权限
    showSetRolePerDialog: false,
    editRoleRow: null,          // 当前编辑角色信息 
    showEditRoleDialog: false,  // 修改角色对话框
    selectRowKeys:[],
    showAddRoleDialog: false,  // 添加角色对话框
    unsubscribe: null,
    total: 0,
    datalist: null,
    searchKey: "",
    params: {pageNum:1, pageSize: 6},
    columns: [{
      key: 'id',
      title: '编号',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '角色名',
      dataIndex: 'name'
    },{
      key: 'roleCode',
      title: '角色编码',
      dataIndex: 'roleCode'
    },{
      key: 'des',
      title: '描述',
      dataIndex: 'des'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        return (
          <div>
            <Button 
              onClick={() => this.setState({showEditRoleDialog:true, editRoleRow: row })}
              style={{marginRight:'5px'}} 
              type="primary">编辑 
            </Button>
            <Popconfirm
              onConfirm={() => {
                //message.info(row.id);
                this.deleteRole(row.id);
              }}
              onText="确认"
              cancelText="取消"
              title="您确认要删除吗？"
            >
                <Button type="danger">删除</Button>
            </Popconfirm>
          </div>
        )
      }
    }],
    rolePageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  /**
   * 行选择操作
   */
  roleRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows);
      this.setState({selectRowKeys: selectedRowKeys});
    }
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // 发送请求后端，获取当前用户的列表数据
    // 方法一：axios直接请求后端进行封装
    let pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    let key = '';
    roleService.getRolePage(key, pageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({datalist: res.retValue.list, total: res.retValue.total});
        }
      }
    )

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }
  

  // 分页
  changePage=(page, pageSize) => {
    let { history } = this.props;

    let key = this.state.searchKey;
    // 修改参数
    this.setState(preState => {
      return {...preState, ...{params:{pageNum: page, pageSize: pageSize}, key: key}}
    }, 
    // 回调函数
    () => {
      let pageParam = this.state.params;
      this.setState({
        rolePageParam: pageParam,
      });
      roleService.getRolePage(key, pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({datalist: res.retValue.list, total: res.retValue.total});
          }
        }
      )
    });
  }

  // button样式
  buttonStyle={margin: '5px'};

  // 关闭添加用户弹框
  hideAddRoleDialog=() => {
    this.setState({showAddRoleDialog: false});
  }

  // 关闭编辑用户弹框
  hideEditRoleDialog=() => {
    this.setState({showEditRoleDialog: false});
  }

  // 关闭设置角色权限
  hideSetRolePerDialog = () => {
    this.setState({showSetRolePerDialog: false});
  }

  // 刷新数据
  refreshData=() => {
      let { history } = this.props;
      let pageParam = this.state.rolePageParam;
      let key = '';
      roleService.getRolePage(key, pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({datalist: res.retValue.list, total: res.retValue.total});
          }
        }
      )

  }

  // 删除数据
  handleDelete=()=> {
      let { history } = this.props;
      // 拿到所有要删除的数据
      //console.log(JSON.stringify(this.state.selectRowKeys));
      // 确认是否需要删除
      if(this.state.selectRowKeys.length <= 0 ) {
        message.warn('请选择要删除的数据！');
        return ;
      }
      Modal.confirm({
        title: '您确认要删除吗？',
        okText: '删除',
        cancelText: '取消',
        onOk: () => {
          // 调用后端删除
          let idList = this.state.selectRowKeys;
          
          roleService.deleteRoleByIdList(idList)
          .then(res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("删除失败,"+res.message);  
            } else {
              // 刷新数据
              this.refreshData();
              message.success('删除成功！');
            }
          
          })

        }
      });

  }

  // 根据Id删除用户
  deleteRole=(id) => {
      let { history } = this.props;
      let idList = [id];

      roleService.deleteRoleByIdList(idList)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshData();
          message.success('删除成功！');
        }

      })

  }

  // 设置角色
  handleSetRolePer=() => {
    if(this.state.selectRowKeys.length > 1 || this.state.selectRowKeys.length <=0) {
      message.error('请选中一个角色进行设置权限');
      return;
    }
    let setRolePerId = this.state.selectRowKeys[0];
    let setRolePer = this.state.datalist.find(item => item.id === setRolePerId);
    this.setState({showSetRolePerDialog: true, setRolePer: setRolePer});
  }

  render() {
    return (
      <div className="admin-usermgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/role_mgr">角色管理</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
        <Button onClick={() => this.setState({showAddRoleDialog:true})} style={this.buttonStyle} type="primary">添加</Button>
        <Button onClick={ this.handleDelete } style={this.buttonStyle} type="danger">删除</Button>
        <Button onClick={ this.handleSetRolePer } style={this.buttonStyle} type="primary">设置角色权限</Button>

        <Input.Search 
          placeholder="搜索"
          onSearch={(value) => {
            // 第一步： 先把搜索的参数放到 state中
            this.setState(preState => {
              preState.searchKey = value
            },() => {
              // 第二步： 从新请求数据
              this.changePage(1,10);
            });

          }}
          enterButton
          style={{margin: '5px', width: '300px'}}
        /> 

        <Table 
          bordered
          style={{backgroundColor: '#FEFEFE'}}
          dataSource={this.state.datalist}
          columns={this.state.columns}
          rowSelection={this.roleRowSelection}
          rowKey="id"
          pagination = {{ total: this.state.total, pageSize: 10, defaultCurrent: 1, onChange: this.changePage}}
        >
        </Table>
        <AddRole close={this.hideAddRoleDialog} visible={this.state.showAddRoleDialog} refreshData={this.refreshData}></AddRole>
        <EditRole rowData={this.state.editRoleRow} close={this.hideEditRoleDialog} visible={this.state.showEditRoleDialog} refreshData={this.refreshData}></EditRole>
        {
          this.state.showSetRolePerDialog ?
            <SetRolePer data={this.state.setRolePer} close={this.hideSetRolePerDialog} visible={this.state.showSetRolePerDialog} />
            :
            null
        }
      </div>
    );
  }
}

export default RoleMgr;