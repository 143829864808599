import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 更新支付宝配置
   * @param {*} cfgPayWay
   * @returns
   */
   updateConfig(cfgPayWay) {
      //console.log("cfgPayWay=" + JSON.stringify(cfgPayWay));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/payWayHmy/updateConfig",
        data: cfgPayWay,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取支付宝配置
   * @param {*} 
   * @returns
   */
   getConfig(payWayParam) {
    //console.log("payWayParam=" + JSON.stringify(payWayParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/payWayHmy/getConfig",
      data: payWayParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}