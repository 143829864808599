import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取云存储文件
   * @param {*} 
   * @returns
   */
  getOssFileRecordPage(ossFileRecordParam) {
    //console.log("smsSendRecordParam=" + JSON.stringify(ossFileRecordParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/ossFileRecord/getOssFileRecordPage",
      data: ossFileRecordParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

    /**
   * 分页获取云存储文件
   * @param {*} 
   * @returns
   */
    deleteById(ossFileRecordParam) {
        //console.log("smsSendRecordParam=" + JSON.stringify(ossFileRecordParam));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/ossFileRecord/deleteById",
            data: ossFileRecordParam,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },
}