import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 插入守护
   * @param {*} appGuard
   * @returns
   */
  insertOrUpdateGuard(appGuard) {
      //console.log("appGuardUsersParam=" + JSON.stringify(appGuardUsersParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/guard/insertOrUpdateGuard",
        data: appGuard,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取守护列表
   * @param {*} appGuard
   * @returns
   */
  getGuardPage(appGuard) {
      //console.log("appGuardUsersParam=" + JSON.stringify(appGuardUsersParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/guard/getGuardPage",
        data: appGuard,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 删除
   * @param {*} appGuard
   * @returns
   */
  deleteGuard(appGuard) {
    //console.log("appGuardUsersParam=" + JSON.stringify(appGuard));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/guard/deleteGuard",
      data: appGuard,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },



}