import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, Statistic} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import financialStatisticsHmyService from '../../../Service/FinanceMgr/financialStatisticsHmyService';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class FinancialStatisticsHmy extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  state={
    // ———————————————————— 今日 ————————————————————————————
    todayData: null,

    // ———————————————————— 昨日 ————————————————————————————
    yesterdayData: null,


    // ———————————————————— 本周 ————————————————————————————
    weekData: null,


    // ———————————————————— 上周 ————————————————————————————
    lastweekData: null,


    // ———————————————————— 本月 ————————————————————————————
    monthData: null,


    // ———————————————————— 上月 ————————————————————————————
    lastmonthData: null,
  }


  // 第一次渲染后调用
  componentDidMount() {
      let { history } = this.props;

    // ———————————————————— 今日 ————————————————————————————
    this.getTodayData();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 免费通话时长设置 ————————————————————————————

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "today") {
      this.getTodayData();
    } else if(key == "yesterday") {
      this.getYesterdayData();
    } else if(key == "week") {
      this.getWeekData();
    } else if(key == "lastweek") {
      this.getLastweekData();
    } else if(key == "month") {
      this.getMonthData();
    } else if(key == "lastmonth") {
      this.getLastmonthData();
    }
  }

  /**
   * 获取今天数据
   */
  getTodayData=()=> {
    let { history } = this.props;
    var todayParam = {
      type: "today"
    }

    financialStatisticsHmyService.getRechargeStatistics(todayParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef.current.setFieldsValue({
            registeredNumber_1: res.retValue.realRegisteredNumber,
            xiaomiRegisteredNumber_1: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_1: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_1: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_1: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_1: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_1: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_1: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_1: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_1: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_1: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_1: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_1: res.retValue.rongyaoRegisteredNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }

  /**
   * 获取昨天数据
   */
  getYesterdayData=()=> {
    let { history } = this.props;
    var yesterdayParam = {
      type: "yesterday"
    }

    financialStatisticsHmyService.getRechargeStatistics(yesterdayParam)
    .then(
      res => {
        //console.log("yesterday retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            registeredNumber_2: res.retValue.realRegisteredNumber,
            xiaomiRegisteredNumber_2: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_2: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_2: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_2: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_2: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_2: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_2: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_2: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_2: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_2: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_2: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_2: res.retValue.rongyaoRegisteredNumber,
          });
          // this.formRef.current.setFieldsValue(res.retValue);
        }
      
      }
    )   
  }

  /**
   * 获取本周数据
   */
  getWeekData=()=> {
    let { history } = this.props;
    var weekParam = {
      type: "week"
    }

    financialStatisticsHmyService.getRechargeStatistics(weekParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));

        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef3.current.setFieldsValue({
            registeredNumber_3: res.retValue.realRegisteredNumber,
            xiaomiRegisteredNumber_3: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_3: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_3: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_3: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_3: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_3: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_3: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_3: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_3: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_3: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_3: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_3: res.retValue.rongyaoRegisteredNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    ) 
  }

  /**
   * 获取上周数据
   */
  getLastweekData=()=> {
    let { history } = this.props;
    var lastweekParam = {
      type: "lastweek"
    }

    financialStatisticsHmyService.getRechargeStatistics(lastweekParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef4.current.setFieldsValue({
            registeredNumber_4: res.retValue.realRegisteredNumber,
            xiaomiRegisteredNumber_4: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_4: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_4: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_4: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_4: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_4: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_4: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_4: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_4: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_4: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_4: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_4: res.retValue.rongyaoRegisteredNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }        
      }
    )   
  }

  /**
   * 获取本月数据
   */
  getMonthData=()=> {
    let { history } = this.props;
    var monthParam = {
      type: "month"
    }

    financialStatisticsHmyService.getRechargeStatistics(monthParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef5.current.setFieldsValue({
            registeredNumber_5: res.retValue.realRegisteredNumber,
            xiaomiRegisteredNumber_5: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_5: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_5: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_5: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_5: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_5: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_5: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_5: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_5: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_5: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_5: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_5: res.retValue.rongyaoRegisteredNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }

  /**
   * 获取上月数据
   */
  getLastmonthData=()=> {
    let { history } = this.props;
    var lastmonthParam = {
      type: "lastmonth"
    }

    financialStatisticsHmyService.getRechargeStatistics(lastmonthParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef6.current.setFieldsValue({
            registeredNumber_6: res.retValue.realRegisteredNumber,
            xiaomiRegisteredNumber_6: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_6: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_6: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_6: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_6: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_6: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_6: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_6: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_6: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_6: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_6: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_6: res.retValue.rongyaoRegisteredNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/financial_statistics_hmy">财务统计-华米云</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="today" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 今日统计 ————————————————————————————
            <TabPane tab="今日统计" key="today">
              <Form
                name="TodayStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_1" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_1">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_1">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_1">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_1">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_1">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_1">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_1">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_1">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_1">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_1">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_1">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_1">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

            </TabPane>
            
            // ———————————————————— 昨日统计 ——————————————————————
            <TabPane tab="昨日统计" key="yesterday">
            <Form
                name="YesterdayStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_2" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_2">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_2">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_2">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_2">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_2">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_2">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_2">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_2">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_2">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_2">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_2">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_2">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
              
                </Row>
              </Form>
            
            </TabPane>

            // —————————————————————— 本周统计 ————————————————————————
            <TabPane tab="本周统计" key="week">
              <Form
                name="weekStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_3" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_3">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_3">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_3">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_3">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_3">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_3">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_3">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_3">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_3">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_3">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_3">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_3">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>

                </Row>
              </Form>
               
            </TabPane>

            // —————————————————————— 上周统计 ————————————————————————
            <TabPane tab="上周统计" key="lastweek">
              <Form
                name="lastweekStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef4}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_4" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_4">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_4">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_4">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_4">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_4">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_4">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_4">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_4">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_4">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_4">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_4">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_4">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
              
                </Row>
              </Form>
            </TabPane>

            // —————————————————————— 本月统计 ————————————————————————
            <TabPane tab="本月统计" key="month">
              <Form
                name="monthStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef5}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_5" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_5">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_5">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_5">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_5">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_5">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_5">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_5">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_5">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_5">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_5">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_5">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_5">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>

            // —————————————————————— 上月统计 ————————————————————————
            <TabPane tab="上月统计" key="lastmonth">
              <Form
                name="lastmonthStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef6}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_6" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_6">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_6">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_6">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_6">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_6">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_6">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_6">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_6">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_6">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_6">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_6">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_6">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                
                </Row>
              </Form>
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default FinancialStatisticsHmy;