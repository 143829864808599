import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取贵族价格
   * @param {*} vipParam
   * @returns
   */
   getVipPage(vipParam) {
      //console.log("vipParam=" + JSON.stringify(vipParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/vip/getVipPage",
        data: vipParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新贵族价格
   * @param {*} appVip
   * @returns
   */
   insertOrUpdateVip(appVip) {
        //console.log("appVip=" + JSON.stringify(appVip));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/vip/insertOrUpdateVip",
        data: appVip,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

  /**
   * 获取启用的贵族列表
   * @param {*} vipParam
   * @returns
   */
  getOnlineVipList() {
    //console.log("vipParam=" + JSON.stringify(vipParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/vip/getOnlineVipList",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 赠送VIP
   * @returns 
   */
  giveVip(usersVipParam) {
    //console.log("vipParam=" + JSON.stringify(vipParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/vip/giveVip",
      data: usersVipParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}