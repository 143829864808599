import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import kuolieService from '../../../Service/Kuolie/kuolieService';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class ReviewKuolie extends Component {
  formRef = React.createRef();
  state={
    loading: false,
    imageUrl: null,
    fileList: [],
    previewOpen: null,
    previewImage: null,
    previewTitle: null,
    previewVisible: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appKuolie = values;
          kuolieService.insertOrUpdateKuolie(appKuolie)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
 
  }


   // 图片修改
   handleChangeFileImage=(e) => {
    if (e.file.status === 'uploading') {
        this.state.loading=true;
        return;
    }
    if (e.file.status === 'done') {
        //console.log("test="+e.file.response);
        this.state.loading=false;
        //this.state.imageUrl=e.file.response;
        return e.file.response;
      }
}

// 上传前置校验
beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

// 上传图片
doImgUpload = (options) => {
  const { onSuccess, onError, file, onProgress } = options;

  Service.uploadQiniu(file)
  .then((observable) => {
    observable.subscribe({
      error: () => {
          console.log('上传失败');
      },
      complete: (res) => {
        //console.log('上传完成，res='+JSON.stringify(res));
        var url = Service.getDoamin() + res.key;
        //console.log('上传成功：' + url);
        onProgress({percent: 100});
        onSuccess(url, file); 
      },
    });
  })
};

handleChange = ({ file, fileList }) => {
  //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
  //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
};

/**
 * 上传按钮
 */
uploadButton = (
  <div>
    {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
    <div style={{ marginTop: 8 }}>Upload</div>
  </div>
);



  render () {
    return (
      <Modal
        title="更新"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={700}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="审核状态" name="status" initialValue={0} labelCol={{span:10}} style={{ width: 520 }} rules={ [
                    {
                        required: true,
                        message: '请选择审核状态!',
                    }
                    ]
                }>
                  <Radio.Group defaultValue={3}>
                    <Radio value={1}>审核通过</Radio>
                    <Radio value={3}>待审核</Radio>
                    <Radio value={2}>审核拒绝</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="备注" name="reason" labelCol={{span:10}} style={{ width: 520 }} >
                <TextArea style={{ height: 250 }} />
            </Form.Item>
          
        </Form>
      </Modal>
    )
  }
}

export default ReviewKuolie