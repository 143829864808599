import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
   getUserDataReviewList(userDataReviewParam) {
    //console.log("data=" + JSON.stringify(userDataReviewParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/userDataReview/getUserDataReviewList",
      data: userDataReviewParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
   updateUserDataReview(userDataReviewParam) {
    //console.log("insertOrUpdateUser appUser=" + JSON.stringify(appUser));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        method: "post",
        url: "/admin/userDataReview/updateUserDataReview",
        data: userDataReviewParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
}