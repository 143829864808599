import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import kuolieService from '../../../Service/Kuolie/kuolieService';

import UpdateKuolie from './UpdateKuolie';
import AddKuolie from './AddKuolie';
import ReviewKuolie from './ReviewKuolie';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class KuolieList extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 动态列表 ————————————————————————————
    kuolieListColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'userId',
      title: '用户Id',
      dataIndex: 'userId'
    },{
      key: 'title',
      title: '标题',
      dataIndex: 'title'
    },{
      key: 'icon',
      title: '封页图片',
      dataIndex: 'icon',
      render: (icon, row) => {
        var info = (
            <>
              <div style={{ float: 'left', marginRight: '5px'}}>
                <Image width={55} height={55} src={icon} />
              </div>
            </>
        );
        return info;
      }
    },{
      key: 'type',
      title: '一级分类',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 'person') {
          return "个人"
        } else if(type === 'group') {
          return "交友群";
        } else {
          return type;
        }
      }
    },{
      key: 'secondType',
      title: '二级分类',
      dataIndex: 'secondType',
      render: (secondType, row) => {
        if(secondType === 'male') {
          return "优质男"
        } else if(secondType === 'female') {
          return "优质女";
        } else if(secondType === 'wxGroup') {
          return "微信群";
        } else if(secondType === 'qqGroup') {
          return "qq群";
        } else {
          return secondType;
        }
      }
    },{
      key: 'status',
      title: '状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "下线"
        } else if(status === 1) {
          return "上线";
        } else if(status === 2) {
          return "审核拒绝";
        } else if(status === 3) {
          return "审核中";
        } else if(status === 4) {
          return "草稿";
        } else {
          return status;
        }
      }
    },{
      key: 'addtime',
      title: '添加时间',
      dataIndex: 'addtime'
    },{
      key: 'updatetime',
      title: '更新时间',
      dataIndex: 'updatetime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        if(row.status === 3) {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showReviewKuolieListDialog:true, reviewKuolieListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">审核
              </Button>
              <Button 
                onClick={() => this.setState({showUpdateKuolieListDialog:true, updateKuolieListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteKuolie(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
        } else {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateKuolieListDialog:true, updateKuolieListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteKuolie(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
        }
          
      }
    }],
    kuolieListTotal: 0,
    kuolieList: null,
    updateKuolieListRow: null,
    showUpdateKuolieListDialog: false,
    showAddKuolieListDialog: false,
    kuolieListPageParam:{
      userId : null,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },
    reviewKuolieListRow: null,
    showReviewKuolieListDialog: false,
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 获取等级礼物 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var kuolieListPageParam = {
      userId : null,
      pageParam : pageParam,
    }

    this.setState({
      kuolieListPageParam: kuolieListPageParam,
    });

    kuolieService.getKuoliePage(kuolieListPageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({kuolieList: res.retValue.list, kuolieListTotal: res.retValue.total});
          }
        }
      )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 列表 ————————————————————————————

  

  // 获取列表
  handleGetKuolieListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
     // console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var kuolieListPageParam = {
        userId : values.userId,
        title: values.title,
        type: values.type,
        secondType: values.secondType,
        status: values.status,
        pageParam : pageParam,
      }

      this.setState({
        kuolieListPageParam: kuolieListPageParam,
      });

      kuolieService.getKuoliePage(kuolieListPageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({kuolieList: res.retValue.list, kuolieListTotal: res.retValue.total});
            }
          }
        )
      
    })

  }

  // 分页
  kuolieListChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var kuolieListPageParam = {
          userId : values.userId,
          pageParam : pageParam,
        }

        this.setState({
          kuolieListPageParam: kuolieListPageParam,
        });

        kuolieService.getKuoliePage(kuolieListPageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({kuolieList: res.retValue.list, kuolieListTotal: res.retValue.total});
            }
          }
        )
    })
  }

  // 刷新数据
  refreshKuolieListData=() => {
    let { history } = this.props;
    //console.log("refreshKuolieListData");

    var appKuolie = this.state.kuolieListPageParam;

    kuolieService.getKuoliePage(appKuolie)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({kuolieList: res.retValue.list, kuolieListTotal: res.retValue.total});
        }
      }
    )
  }
 


  
  // 根据Id删除
  deleteKuolie=(id) => {
      let { history } = this.props;
      let appKuolie = {
        id : id
      };
      kuolieService.deleteKuolie(appKuolie)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshKuolieListData();
          message.success('删除成功！');
        }
       
      })

  }

  // 隐藏
  hideUpdateKuolieListDialog=() => {
    this.setState({showUpdateKuolieListDialog: false});
  }

  // 隐藏
  hideAddKuolieListDialog=() => {
    this.setState({showAddKuolieListDialog: false});
  }
   
  // 隐藏
  hideReviewKuolieListDialog=() => {
    this.setState({showReviewKuolieListDialog: false});
  }
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/kuolie_list">扩列列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="KuolieListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户ID" name="userId">
                          <Input placeholder="用户id" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="一级分类" name="type">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value="person">个人</Option>
                              <Option value="group">交友群</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="二级分类" name="secondType">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value="male">优质男</Option>
                              <Option value="female">优质女</Option>
                              <Option value="wxGroup">微信群</Option>
                              <Option value="qqGroup">qq群</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={6} key={4}>
                      <Form.Item label="标题" name="title">
                          <Input placeholder="标题" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={5}>
                      <Form.Item label="状态" name="status">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>下线</Option>
                              <Option value={1}>上线</Option>
                              <Option value={2}>审核拒绝</Option>
                              <Option value={3}>审核中</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={3} key={6} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetKuolieListPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                    <Col span={3} key={7} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddKuolieListDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.kuolieList}
                columns={this.state.kuolieListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.kuolieListTotal, defaultCurrent: 1, onChange: this.kuolieListChangePage}}
              >
              </Table>
              <AddKuolie close={this.hideAddKuolieListDialog} visible={this.state.showAddKuolieListDialog} refreshData={this.refreshKuolieListData}></AddKuolie>
              <UpdateKuolie rowData={this.state.updateKuolieListRow} close={this.hideUpdateKuolieListDialog} visible={this.state.showUpdateKuolieListDialog} refreshData={this.refreshKuolieListData}></UpdateKuolie>
              <ReviewKuolie rowData={this.state.reviewKuolieListRow} close={this.hideReviewKuolieListDialog} visible={this.state.showReviewKuolieListDialog} refreshData={this.refreshKuolieListData}></ReviewKuolie>


        </StickyContainer>
        
      </div>
    );
  }
}
export default KuolieList;