import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import finSysTranRecordService from '../../../Service/Operation/finSysTranRecordService';




const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class PlatformRevenue extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 平台收益 ————————————————————————————

    platformRevenueColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'tranType1',
      title: '交易类型1',
      dataIndex: 'tranType1',
      render: (tranType1, row) => {
        if(tranType1 == 53) {
          return "平台收益";
        } 
      }
    },{
      key: 'tranType2',
      title: '交易类型2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 == 10) {
          return "充值";
        } else if(tranType2 == 12) {
          return "奖励收益";
        } else if(tranType2 == 50) {
          return "推荐佣金";
        } else if(tranType2 == 13) {
          return "兑换金币";
        } else if(tranType2 == 14) {
          return "游戏中奖";
        } else if(tranType2 == 20) {
          return "打赏";
        } else if(tranType2 == 24) {
          return "守护";
        } else if(tranType2 == 25) {
          return "粉丝团";
        } else if(tranType2 == 26) {
          return "发送弹幕";
        } else if(tranType2 == 27) {
          return "购买贵族";
        } else if(tranType2 == 28) {
          return "付费通话";
        } else if(tranType2 == 29) {
          return "购买道具";
        } else if(tranType2 == 200) {
          return "游戏消费";
        } else if(tranType2 == 201) {
          return "红包";
        } else if(tranType2 == 202) {
          return "查看联系方式";
        } else if(tranType2 == 204) {
          return "购买SVIP";
        } else if(tranType2 == 205) {
          return "房间费用";
        } else if(tranType2 == 206) {
          return "付费私信";
        } else if(tranType2 == 208) {
          return "购买贵宾席";
        } else if(tranType2 == 209) {
          return "游戏抽奖";
        } else if(tranType2 == 60) {
          return "提现";
        } else {
          return tranType2;
        }  
      }
    },{
      key: 'tranType3',
      title: '交易类型3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 == 1001) {
          return "线上充值";
        } else if(tranType3 == 1101) {
          return "人工充值";
        } else if(tranType3 == 1108) {
          return "后台充值";
        } else if(tranType3 == 1102) {
          return "扣除金币";
        } else if(tranType3 == 1103) {
          return "游戏奖励";
        } else if(tranType3 == 1201) {
          return "首充奖励";
        } else if(tranType3 == 1202) {
          return "新手大礼包";
        } else if(tranType3 == 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 == 1204) {
          return "等级礼包";
        } else if(tranType3 == 1205) {
          return "连续登录奖励";
        } else if(tranType3 == 1206) {
          return "签到奖励";
        } else if(tranType3 == 1207) {
          return "充值奖励";
        } else if(tranType3 == 1301) {
          return "佣金兑换金币";
        } else if(tranType3 == 1302) {
          return "映票兑换金币";
        } else if(tranType3 == 2003) {
          return "聊天打赏";
        } else if(tranType3 == 2004) {
          return "通话打赏";
        } else if(tranType3 == 2005) {
          return "个人主页打赏";
        } else if(tranType3 == 2401) {
          return "守护";
        } else if(tranType3 == 2501) {
          return "加入粉丝团";
        } else if(tranType3 == 2701) {
          return "购买贵族";
        } else if(tranType3 == 2801) {
          return "视频通话";
        } else if(tranType3 == 2802) {
          return "语音通话";
        }  else if(tranType3 == 2803) {
          return "用户间通话";
        }  else if(tranType3 == 20202) {
          return "查看微信号";
        }  else if(tranType3 == 20203) {
          return "付费私信";
        }  else if(tranType3 == 5001) {
          return "注册佣金";
        }  else if(tranType3 == 5002) {
          return "主播认证佣金";
        }  else if(tranType3 == 1406) {
          return "签到佣金";
        }  else if(tranType3 == 5003) {
          return "通话佣金";
        }  else if(tranType3 == 5004) {
          return "打赏佣金";
        } else if(tranType3 == 5005) {
          return "补发佣金";
        } else if(tranType3 == 5006) {
          return "用户充值";
        } else if(tranType3 == 5007) {
          return "购买贵族佣金";
        } else if(tranType3 == 5008) {
          return "购买SVIP佣金";
        } else if(tranType3 == 6001) {
          return "用户佣金提现";
        } else if(tranType3 == 6002) {
          return "代理佣金提现";
        } else if(tranType3 == 6003) {
          return "主播收益提现";
        } else if(tranType3 == 6004) {
          return "公会佣金提现";
        } else if(tranType3 == 20401) {
          return "购买SVIP";
        } else {
          return tranType3;
        }     
      }
    },{
      key: 'ticketChange',
      title: '映票变动',
      dataIndex: 'ticketChange'
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks'
    }],
    platformRevenueTotal: 0,
    platformRevenueList: [],

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 平台收益 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var operatingExpensesParam = {
      tranType1: 53,
      pageParam: pageParam,
    };
    finSysTranRecordService.getOperatingExpensesPage(operatingExpensesParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          platformRevenueList: res.retValue.list,
          platformRevenueTotal: res.retValue.total,
        });
      }
      
    });

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 平台收益 ————————————————————————————
  // 获取等级礼物
  handleGetPlatformRevenuePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var operatingExpensesParam = {
        tranType1: 53,
        startTime: timeStart,
        endTime: timeEnd,
        tranType3: values.tranType3,
        pageParam: pageParam,
      };
      finSysTranRecordService.getOperatingExpensesPage(operatingExpensesParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            platformRevenueList: res.retValue.list,
            platformRevenueTotal: res.retValue.total,
          });
        }
        
      });
      
    })

  }



  // 贵族特权分页
  platformRevenueChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var operatingExpensesParam = {
        tranType1: 53,
        startTime: timeStart,
        endTime: timeEnd,
        tranType3: values.tranType3,
        pageParam: pageParam,
      };
      finSysTranRecordService.getOperatingExpensesPage(operatingExpensesParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            platformRevenueList: res.retValue.list,
            platformRevenueTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshPlatformRevenueData = () => {
    let { history } = this.props;
    console.log("refreshPlatformRevenueData");
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };

    var operatingExpensesParam = {
      tranType1: 53,
      pageParam: pageParam,
    };
    finSysTranRecordService.getOperatingExpensesPage(operatingExpensesParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          platformRevenueList: res.retValue.list,
          platformRevenueTotal: res.retValue.total,
        });
      }
      
    });
  };



  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/platform_revenue">平台收益</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
            <Form
              name="PlatformRevenueListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 0px'}}
            >
              <Row gutter={24}>
                  <Col span={8} key={4}>
                    <Form.Item label="交易时间" name="sendTime">
                        <RangePicker />
                    </Form.Item>
                  </Col>
                  <Col span={8} key={3}>
                    <Form.Item label="交易类别3" name="tranType3">
                        <Select style={{ width: 220 }} defaultValue="全部">
                            <Option >全部</Option>
                            <Option value={1001}>线上充值</Option>
                            <Option value={1101}>人工充值</Option>
                            <Option value={1108}>后台充值</Option>
                            <Option value={1102}>扣除金币</Option>
                            <Option value={1103}>游戏奖励</Option>
                            <Option value={1201}>首充奖励</Option>
                            <Option value={1202}>新手大礼包</Option>
                            <Option value={1203}>购买贵族赠送金币收益</Option>
                            <Option value={1204}>等级礼包</Option>
                            <Option value={1205}>连续登录奖励</Option>
                            <Option value={1206}>签到奖励</Option>
                            <Option value={1207}>充值奖励</Option>
                            <Option value={1301}>佣金兑换金币</Option>
                            <Option value={1302}>映票兑换金币</Option>
                            <Option value={2003}>聊天打赏</Option>
                            <Option value={2004}>通话打赏</Option>
                            <Option value={2005}>个人主页打赏</Option>
                            <Option value={2401}>守护</Option>
                            <Option value={2501}>加入粉丝团</Option>
                            <Option value={2701}>购买贵族</Option>
                            <Option value={2801}>视频通话</Option>
                            <Option value={2802}>语音通话</Option>
                            <Option value={2803}>用户间通话</Option>
                            <Option value={20202}>查看微信号</Option>
                            <Option value={20203}>付费私信</Option>
                            <Option value={5001}>注册佣金</Option>
                            <Option value={5002}>主播认证佣金</Option>
                            <Option value={1406}>签到佣金</Option>
                            <Option value={5003}>通话佣金</Option>
                            <Option value={5004}>打赏佣金</Option>
                            <Option value={5005}>补发佣金</Option>
                            <Option value={5006}>用户充值</Option>
                            <Option value={5007}>购买贵族佣金</Option>
                            <Option value={5008}>购买SVIP佣金</Option>
                            <Option value={6001}>用户佣金提现</Option>
                            <Option value={6002}>代理佣金提现</Option>
                            <Option value={6003}>主播收益提现</Option>
                            <Option value={6004}>公会佣金提现</Option>
                            <Option value={20401}>购买SVIP</Option>
                        </Select>
                    </Form.Item>
                  </Col>

                  <Col span={6} key={5} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetPlatformRevenuePage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
              </Row>
            </Form>

            <Table
              bordered
              style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
              dataSource={this.state.platformRevenueList}
              columns={this.state.platformRevenueColumns}
              rowKey="id"
              scroll={{ x: "max-content" }}
              pagination={{
                total: this.state.platformRevenueTotal,
                defaultCurrent: 1,
                onChange: this.platformRevenueChangePage,
              }}
            ></Table>

        </StickyContainer>
      </div>
    );
  }
}
export default PlatformRevenue;
