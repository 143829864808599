import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import finTranRecordService from '../../../Service/FinanceMgr/finTranRecordService';






const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class ConsumptionRecord extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 打赏记录 ————————————————————————————
    rewardColumns: [{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'uidAvatar',
      title: '用户信息',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
              <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'tranType2',
      title: '交易类型2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 == 10) {
          return "充值";
        } else if(tranType2 == 12) {
          return "奖励收益";
        } else if(tranType2 == 50) {
          return "推荐佣金";
        } else if(tranType2 == 13) {
          return "兑换金币";
        } else if(tranType2 == 14) {
          return "游戏中奖";
        } else if(tranType2 == 20) {
          return "打赏";
        } else if(tranType2 == 24) {
          return "守护";
        } else if(tranType2 == 25) {
          return "粉丝团";
        } else if(tranType2 == 26) {
          return "发送弹幕";
        } else if(tranType2 == 27) {
          return "购买贵族";
        } else if(tranType2 == 28) {
          return "付费通话";
        } else if(tranType2 == 29) {
          return "购买道具";
        } else if(tranType2 == 200) {
          return "游戏消费";
        } else if(tranType2 == 201) {
          return "红包";
        } else if(tranType2 == 202) {
          return "查看联系方式";
        } else if(tranType2 == 204) {
          return "购买SVIP";
        } else if(tranType2 == 205) {
          return "房间费用";
        } else if(tranType2 == 206) {
          return "付费私信";
        } else if(tranType2 == 208) {
          return "购买贵宾席";
        } else if(tranType2 == 209) {
          return "游戏抽奖";
        } else if(tranType2 == 60) {
          return "提现";
        } else {
          return tranType2;
        }  
      }
    },{
      key: 'tranType3',
      title: '交易类型3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 == 1001) {
          return "线上充值";
        } else if(tranType3 == 1101) {
          return "人工充值";
        } else if(tranType3 == 1108) {
          return "后台充值";
        } else if(tranType3 == 1102) {
          return "扣除金币";
        } else if(tranType3 == 1103) {
          return "游戏奖励";
        } else if(tranType3 == 1201) {
          return "首充奖励";
        } else if(tranType3 == 1202) {
          return "新手大礼包";
        } else if(tranType3 == 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 == 1204) {
          return "等级礼包";
        } else if(tranType3 == 1205) {
          return "连续登录奖励";
        } else if(tranType3 == 1206) {
          return "签到奖励";
        } else if(tranType3 == 1207) {
          return "充值奖励";
        } else if(tranType3 == 1301) {
          return "佣金兑换金币";
        } else if(tranType3 == 1302) {
          return "映票兑换金币";
        } else if(tranType3 == 2003) {
          return "聊天打赏";
        } else if(tranType3 == 2004) {
          return "通话打赏";
        } else if(tranType3 == 2005) {
          return "个人主页打赏";
        } else if(tranType3 == 2401) {
          return "守护";
        } else if(tranType3 == 2501) {
          return "加入粉丝团";
        } else if(tranType3 == 2701) {
          return "购买贵族";
        } else if(tranType3 == 2801) {
          return "视频通话";
        } else if(tranType3 == 2802) {
          return "语音通话";
        }  else if(tranType3 == 2803) {
          return "用户间通话";
        }  else if(tranType3 == 20202) {
          return "查看微信号";
        }  else if(tranType3 == 20203) {
          return "付费私信";
        }  else if(tranType3 == 5001) {
          return "注册佣金";
        }  else if(tranType3 == 5002) {
          return "主播认证佣金";
        }  else if(tranType3 == 1406) {
          return "签到佣金";
        }  else if(tranType3 == 5003) {
          return "通话佣金";
        }  else if(tranType3 == 5004) {
          return "打赏佣金";
        } else if(tranType3 == 5005) {
          return "补发佣金";
        } else if(tranType3 == 5006) {
          return "用户充值";
        } else if(tranType3 == 5007) {
          return "购买贵族佣金";
        } else if(tranType3 == 5008) {
          return "购买SVIP佣金";
        } else if(tranType3 == 6001) {
          return "用户佣金提现";
        } else if(tranType3 == 6002) {
          return "代理佣金提现";
        } else if(tranType3 == 6003) {
          return "主播收益提现";
        } else if(tranType3 == 6004) {
          return "公会佣金提现";
        } else if(tranType3 == 20401) {
          return "购买SVIP";
        } else {
          return tranType3;
        }     
      }
    },{
      key: 'consumptionCoin',
      title: '消费金额',
      dataIndex: 'consumptionCoin'
    },{
      key: 'toUidAvatar',
      title: '主播信息',
      dataIndex: 'toUidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.toUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.toUidUsername}</div>
              <div style={{ marginTop: '3px'}}>{row.toUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks'
    }],
    rewardTotal: 0,
    rewardList: [],


    // ———————————————————— 付费通话记录 ————————————————————————————
    
    paidCallRecordColumns: [{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'id',
      title: 'id',
      dataIndex: 'id'
    },{
      key: 'uidAvatar',
      title: '用户信息',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'tranType2',
      title: '交易类型2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 == 10) {
          return "充值";
        } else if(tranType2 == 12) {
          return "奖励收益";
        } else if(tranType2 == 50) {
          return "推荐佣金";
        } else if(tranType2 == 13) {
          return "兑换金币";
        } else if(tranType2 == 14) {
          return "游戏中奖";
        } else if(tranType2 == 20) {
          return "打赏";
        } else if(tranType2 == 24) {
          return "守护";
        } else if(tranType2 == 25) {
          return "粉丝团";
        } else if(tranType2 == 26) {
          return "发送弹幕";
        } else if(tranType2 == 27) {
          return "购买贵族";
        } else if(tranType2 == 28) {
          return "付费通话";
        } else if(tranType2 == 29) {
          return "购买道具";
        } else if(tranType2 == 200) {
          return "游戏消费";
        } else if(tranType2 == 201) {
          return "红包";
        } else if(tranType2 == 202) {
          return "查看联系方式";
        } else if(tranType2 == 204) {
          return "购买SVIP";
        } else if(tranType2 == 205) {
          return "房间费用";
        } else if(tranType2 == 206) {
          return "付费私信";
        } else if(tranType2 == 208) {
          return "购买贵宾席";
        } else if(tranType2 == 209) {
          return "游戏抽奖";
        } else if(tranType2 == 60) {
          return "提现";
        } else {
          return tranType2;
        }  
      }
    },{
      key: 'tranType3',
      title: '交易类型3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 == 1001) {
          return "线上充值";
        } else if(tranType3 == 1101) {
          return "人工充值";
        } else if(tranType3 == 1108) {
          return "后台充值";
        } else if(tranType3 == 1102) {
          return "扣除金币";
        } else if(tranType3 == 1103) {
          return "游戏奖励";
        } else if(tranType3 == 1201) {
          return "首充奖励";
        } else if(tranType3 == 1202) {
          return "新手大礼包";
        } else if(tranType3 == 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 == 1204) {
          return "等级礼包";
        } else if(tranType3 == 1205) {
          return "连续登录奖励";
        } else if(tranType3 == 1206) {
          return "签到奖励";
        } else if(tranType3 == 1207) {
          return "充值奖励";
        } else if(tranType3 == 1301) {
          return "佣金兑换金币";
        } else if(tranType3 == 1302) {
          return "映票兑换金币";
        } else if(tranType3 == 2003) {
          return "聊天打赏";
        } else if(tranType3 == 2004) {
          return "通话打赏";
        } else if(tranType3 == 2005) {
          return "个人主页打赏";
        } else if(tranType3 == 2401) {
          return "守护";
        } else if(tranType3 == 2501) {
          return "加入粉丝团";
        } else if(tranType3 == 2701) {
          return "购买贵族";
        } else if(tranType3 == 2801) {
          return "视频通话";
        } else if(tranType3 == 2802) {
          return "语音通话";
        }  else if(tranType3 == 2803) {
          return "用户间通话";
        }  else if(tranType3 == 20202) {
          return "查看微信号";
        }  else if(tranType3 == 20203) {
          return "付费私信";
        }  else if(tranType3 == 5001) {
          return "注册佣金";
        }  else if(tranType3 == 5002) {
          return "主播认证佣金";
        }  else if(tranType3 == 1406) {
          return "签到佣金";
        }  else if(tranType3 == 5003) {
          return "通话佣金";
        }  else if(tranType3 == 5004) {
          return "打赏佣金";
        } else if(tranType3 == 5005) {
          return "补发佣金";
        } else if(tranType3 == 5006) {
          return "用户充值";
        } else if(tranType3 == 5007) {
          return "购买贵族佣金";
        } else if(tranType3 == 5008) {
          return "购买SVIP佣金";
        } else if(tranType3 == 6001) {
          return "用户佣金提现";
        } else if(tranType3 == 6002) {
          return "代理佣金提现";
        } else if(tranType3 == 6003) {
          return "主播收益提现";
        } else if(tranType3 == 6004) {
          return "公会佣金提现";
        } else if(tranType3 == 20401) {
          return "购买SVIP";
        } else {
          return tranType3;
        }     
      }
    },{
      key: 'sceneId1',
      title: '房间号',
      dataIndex: 'sceneId1'
    },{
      key: 'remarks',
      title: '通话时长',
      dataIndex: 'remarks'
    },{
      key: 'consumptionCoin',
      title: '消费金币',
      dataIndex: 'consumptionCoin'
    },{
      key: 'afterCoin',
      title: '余金币',
      dataIndex: 'afterCoin'
    },{
      key: 'toUidAvatar',
      title: '主播信息',
      dataIndex: 'toUidAvatar',
      align: 'center',
      render: (toUidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.toUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={toUidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.toUidUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.toUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    }],
    paidCallRecordTotal: 0,
    paidCallRecordList: [],
    
    // ———————————————————— 查看联系方式 ————————————————————————————
    
    viewContactColumns: [{
      key: 'createTime',
      title: '交易时间',
      dataIndex: 'createTime'
    },{
      key: 'uidAvatar',
      title: '用户信息',
      dataIndex: 'uidAvatar',
      align: 'center',
      render: (uidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={uidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.uidUsername}</div>
              <div style={{ marginTop: '3px'}}>{row.uidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'tranType2',
      title: '交易类型2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 == 10) {
          return "充值";
        } else if(tranType2 == 12) {
          return "奖励收益";
        } else if(tranType2 == 50) {
          return "推荐佣金";
        } else if(tranType2 == 13) {
          return "兑换金币";
        } else if(tranType2 == 14) {
          return "游戏中奖";
        } else if(tranType2 == 20) {
          return "打赏";
        } else if(tranType2 == 24) {
          return "守护";
        } else if(tranType2 == 25) {
          return "粉丝团";
        } else if(tranType2 == 26) {
          return "发送弹幕";
        } else if(tranType2 == 27) {
          return "购买贵族";
        } else if(tranType2 == 28) {
          return "付费通话";
        } else if(tranType2 == 29) {
          return "购买道具";
        } else if(tranType2 == 200) {
          return "游戏消费";
        } else if(tranType2 == 201) {
          return "红包";
        } else if(tranType2 == 202) {
          return "查看联系方式";
        } else if(tranType2 == 204) {
          return "购买SVIP";
        } else if(tranType2 == 205) {
          return "房间费用";
        } else if(tranType2 == 206) {
          return "付费私信";
        } else if(tranType2 == 208) {
          return "购买贵宾席";
        } else if(tranType2 == 209) {
          return "游戏抽奖";
        } else if(tranType2 == 60) {
          return "提现";
        } else {
          return tranType2;
        }  
      }
    },{
      key: 'tranType3',
      title: '交易类型3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 == 1001) {
          return "线上充值";
        } else if(tranType3 == 1101) {
          return "人工充值";
        } else if(tranType3 == 1108) {
          return "后台充值";
        } else if(tranType3 == 1102) {
          return "扣除金币";
        } else if(tranType3 == 1103) {
          return "游戏奖励";
        } else if(tranType3 == 1201) {
          return "首充奖励";
        } else if(tranType3 == 1202) {
          return "新手大礼包";
        } else if(tranType3 == 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 == 1204) {
          return "等级礼包";
        } else if(tranType3 == 1205) {
          return "连续登录奖励";
        } else if(tranType3 == 1206) {
          return "签到奖励";
        } else if(tranType3 == 1207) {
          return "充值奖励";
        } else if(tranType3 == 1301) {
          return "佣金兑换金币";
        } else if(tranType3 == 1302) {
          return "映票兑换金币";
        } else if(tranType3 == 2003) {
          return "聊天打赏";
        } else if(tranType3 == 2004) {
          return "通话打赏";
        } else if(tranType3 == 2005) {
          return "个人主页打赏";
        } else if(tranType3 == 2401) {
          return "守护";
        } else if(tranType3 == 2501) {
          return "加入粉丝团";
        } else if(tranType3 == 2701) {
          return "购买贵族";
        } else if(tranType3 == 2801) {
          return "视频通话";
        } else if(tranType3 == 2802) {
          return "语音通话";
        }  else if(tranType3 == 2803) {
          return "用户间通话";
        }  else if(tranType3 == 20202) {
          return "查看微信号";
        }  else if(tranType3 == 20203) {
          return "付费私信";
        }  else if(tranType3 == 5001) {
          return "注册佣金";
        }  else if(tranType3 == 5002) {
          return "主播认证佣金";
        }  else if(tranType3 == 1406) {
          return "签到佣金";
        }  else if(tranType3 == 5003) {
          return "通话佣金";
        }  else if(tranType3 == 5004) {
          return "打赏佣金";
        } else if(tranType3 == 5005) {
          return "补发佣金";
        } else if(tranType3 == 5006) {
          return "用户充值";
        } else if(tranType3 == 5007) {
          return "购买贵族佣金";
        } else if(tranType3 == 5008) {
          return "购买SVIP佣金";
        } else if(tranType3 == 6001) {
          return "用户佣金提现";
        } else if(tranType3 == 6002) {
          return "代理佣金提现";
        } else if(tranType3 == 6003) {
          return "主播收益提现";
        } else if(tranType3 == 6004) {
          return "公会佣金提现";
        } else if(tranType3 == 20401) {
          return "购买SVIP";
        } else {
          return tranType3;
        }     
      }
    },{
      key: 'consumptionCoin',
      title: '消费金额',
      dataIndex: 'consumptionCoin'
    },{
      key: 'afterCoin',
      title: '余金币',
      dataIndex: 'afterCoin'
    },{
      key: 'toUidAvatar',
      title: '主播信息',
      dataIndex: 'toUidAvatar',
      align: 'center',
      render: (toUidAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
              <div>{row.toUid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
              <Image width={55} height={55} src={toUidAvatar} />
            </div>
            <div style={{ float: 'left' }}>
              <div style={{ marginTop: '3px'}}>{row.toUidUsername}</div>
              <div style={{ marginTop: '3px'}}>{row.toUidMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks'
    }],
    viewContactTotal: 0,
    viewContactList: [],
      
  }


  // 第一次渲染后调用
  componentDidMount() {
      let { history } = this.props;

    // ———————————————————— 打赏记录 ————————————————————————————
    this.getFinTranRecord1();
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 打赏记录 ————————————————————————————

  // 关闭更新
  hideUpdateRewardDialog=() => {
    this.setState({showUpdateRewardDialog: false});
  }

  // 关闭添加
  hideAddRewardDialog=() => {
    this.setState({showAddRewardDialog: false});
  }


  // 获取列表
  handleGetRewardPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finTranRecordParam={
        tranType1: 12,
        tranType2: 20,
        uid: values.uid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({rewardList: res.retValue.list, rewardTotal: res.retValue.total});
          }
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  rewardChangePage=(page, pageSize)=>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finTranRecordParam={
        tranType1: 12,
        tranType2: 20,
        uid: values.uid,
        toUid: values.toUid,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({rewardList: res.retValue.list, rewardTotal: res.retValue.total});
          }
        }
      )
      
    })

  }

  /**
   * 刷新
   */
  refreshRewardData=() => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var finTranRecordParam={
        tranType1: 12,
        tranType2: 20,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({rewardList: res.retValue.list, rewardTotal: res.retValue.total});
          }
        }
      )
  }


  // ———————————————————— 付费通话记录 ————————————————————————————
  
   // 关闭更新
   hideUpdatePaidCallRecordDialog=() => {
      this.setState({showUpdatePaidCallRecordDialog: false});
    }

    // 关闭添加
    hideAddPaidCallRecordDialog=() => {
      this.setState({showAddPaidCallRecordDialog: false});
    }


    // 获取列表
    handleGetPaidCallRecordPage=() =>{
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
        console.log(JSON.stringify(values));
        var pageParam = { 
          pageNum: 1,
          pageSize: 10
        }

        var finTranRecordParam={
          tranType1: 12,
          tranType2: 28,
          uid: values.uid,
          toUid: values.toUid,
          pageParam: pageParam
        }

        finTranRecordService.getFinTranRecordPage(finTranRecordParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({paidCallRecordList: res.retValue.list, paidCallRecordTotal: res.retValue.total});
            }
          }
        )
        
      })
 
    }


    /**
     * 分页查询
     * @param {*} page 
     * @param {*} pageSize 
     */
    paidCallRecordChangePage=(page, pageSize)=>{
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
        console.log(JSON.stringify(values));
        var pageParam = { 
          pageNum: page,
          pageSize: pageSize
        }

        var finTranRecordParam={
          tranType1: 12,
          tranType2: 28,
          uid: values.uid,
          toUid: values.toUid,
          pageParam: pageParam
        }

        finTranRecordService.getFinTranRecordPage(finTranRecordParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({paidCallRecordList: res.retValue.list, paidCallRecordTotal: res.retValue.total});
            }
          }
        )
        
      })
  
    }

    /**
     * 刷新
     */
    refreshPaidCallRecordData=() => {
        let { history } = this.props;
        var pageParam = { 
          pageNum: 1,
          pageSize: 10
        }

        var finTranRecordParam={
          tranType1: 12,
          tranType2: 28,
          pageParam: pageParam
        }

        finTranRecordService.getFinTranRecordPage(finTranRecordParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({paidCallRecordList: res.retValue.list, paidCallRecordTotal: res.retValue.total});
            }
          }
        )
    }
  
  // ———————————————————— 查看联系方式 ————————————————————————————
  
  // 关闭更新
  hideUpdateViewContactDialog=() => {
    this.setState({showUpdateViewContactDialog: false});
  }

  // 关闭添加
  hideAddViewContactDialog=() => {
    this.setState({showAddViewContactDialog: false});
  }


  // 获取列表
  handleGetViewContactPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var finTranRecordParam={
        tranType1: 12,
        tranType2: 202,
        uid: values.uid,
        toUid: values.toUid,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({viewContactList: res.retValue.list, viewContactTotal: res.retValue.total});
          }
        }
      )
      
    })
 
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  viewContactChangePage=(page, pageSize)=>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      var finTranRecordParam={
        tranType1: 12,
        tranType2: 202,
        uid: values.uid,
        toUid: values.toUid,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({viewContactList: res.retValue.list, viewContactTotal: res.retValue.total});
          }
        }
      )
      
    })

  }

  /**
   * 刷新
   */
  refreshViewContactData=() => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var finTranRecordParam={
        tranType1: 12,
        tranType2: 202,
        pageParam: pageParam
      }

      finTranRecordService.getFinTranRecordPage(finTranRecordParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({viewContactList: res.retValue.list, viewContactTotal: res.retValue.total});
          }
        }
      )
  }

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getFinTranRecord1();
    } else if(key == "2") {
      this.getFinTranRecord2();
    } else if(key == "3") {
      this.getFinTranRecord3();
    } 
  }

  /**
   * 打赏记录
   */
  getFinTranRecord1=()=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var finTranRecordParam={
      tranType1: 12,
      tranType2: 20,
      pageParam: pageParam
    }

    finTranRecordService.getFinTranRecordPage(finTranRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({rewardList: res.retValue.list, rewardTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 付费通话记录
   */
  getFinTranRecord2=()=> {
    let { history } = this.props;

    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var paidCallRecordParam={
      tranType1: 12,
      tranType2: 28,
      pageParam: pageParam
    }

    finTranRecordService.getFinTranRecordPage(paidCallRecordParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({paidCallRecordList: res.retValue.list, paidCallRecordTotal: res.retValue.total});
        }
      }
    )

  }

  getFinTranRecord3=()=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var viewContactParam={
      tranType1: 12,
      tranType2: 202,
      pageParam: pageParam
    }

    finTranRecordService.getFinTranRecordPage(viewContactParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({viewContactList: res.retValue.list, viewContactTotal: res.retValue.total});
        }
      }
    )
  }



  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/consumption_record">消费记录</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 打赏记录 ————————————————————————————
            <TabPane tab="打赏记录" key="1">
              <Form
                name="RewardForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="主播UID" name="toUid">
                          <Input placeholder="主播UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="交易时间" name="sendTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={5} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetRewardPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.rewardList}
                columns={this.state.rewardColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.rewardTotal, defaultCurrent: 1, onChange: this.rewardChangePage}}
              >
              </Table>
              
            </TabPane>
            
            // ———————————————————— 付费通话记录 ——————————————————————
            <TabPane tab="付费通话记录" key="2">
              <Form
                name="PaidCallRecordForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="主播UID" name="toUid">
                          <Input placeholder="主播UID" />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={5} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetPaidCallRecordPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.paidCallRecordList}
                columns={this.state.paidCallRecordColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.paidCallRecordTotal, defaultCurrent: 1, onChange: this.paidCallRecordChangePage}}
              >
              </Table>
            
            </TabPane>

            // ———————————————————— 查看联系方式 ——————————————————————
            <TabPane tab="查看联系方式" key="3">
              <Form
                name="ViewContactForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="主播UID" name="toUid">
                          <Input placeholder="主播UID" />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={5} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetViewContactPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.viewContactList}
                columns={this.state.viewContactColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.viewContactTotal, defaultCurrent: 1, onChange: this.viewContactChangePage}}
              >
              </Table>
            
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default ConsumptionRecord;