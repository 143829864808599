import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import adminUserCashrecordService from '../../../Service/Admin/adminUserCashrecordService';

import UpdateWithdrawal from './UpdateWithdrawal';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class ProxyWithdrawalWithdrawalList extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 代理列表 ————————————————————————————
    userProxyWithdrawalColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'agentName',
      title: '代理名',
      dataIndex: 'agentName'
    },{
      key: 'uid',
      title: '代理ID',
      dataIndex: 'uid',
    },{
      key: 'money',
      title: '提现金额',
      dataIndex: 'money',
    },{
      key: 'service',
      title: '服务费',
      dataIndex: 'service',
    },{
      key: 'actualMoney',
      title: '实际到账金额',
      dataIndex: 'actualMoney',
    },{
      key: 'status',
      title: '状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status == 0) {
          return "审核中"
        } else if (status == 1) {
          return "审核通过"
        } else if (status == 2) {
          return "审核拒绝"
        }   
      }
    },{
      key: 'addtime',
      title: '申请时间',
      dataIndex: 'addtime',
    },{
      key: 'uptime',
      title: '审核时间',
      dataIndex: 'uptime',
    },{
      key: 'type',
      title: '账号类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type == 1) {
          return "支付宝"
        } else if (type == 2) {
          return "微信"
        } else if (type == 3) {
          return "银行卡"
        }   
      }
    },{
      key: 'account',
      title: '账号ID',
      dataIndex: 'account',
    },{
      key: 'name',
      title: '姓名',
      dataIndex: 'name',
    },{
      key: 'remarks',
      title: '备注',
      dataIndex: 'remarks',
    },{
      key: 'cashOutStatus',
      title: '转出状态',
      dataIndex: 'cashOutStatus',
      render: (cashOutStatus, row) => {
        if(cashOutStatus == 0) {
          return "未转出"
        } else if (cashOutStatus == 1) {
          return "转出成功"
        } else if (cashOutStatus == 2) {
          return "转出失败"
        } else if (cashOutStatus == 3) {
          return "转出中"
        }  
      }
    },{
      key: 'del',
      title: '审核',
      dataIndex: 'del',
      render: (del, row) => {
        if(row.status == 0 ) {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateWithdrawalDialog:true, updateWithdrawalRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">审核 
              </Button>
            </div>
          )    
        }    
      }
    }],
    userProxyWithdrawalTotal: 0,
    userProxyWithdrawalList: null,
    updateWithdrawalRow: null,
    showUpdateWithdrawalDialog: false,
    userProxyWithdrawalPageParam: {
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      },
    },
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 代理列表 ————————————————————————————
    
    this.handleGetAgentPage();
  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 代理列表 ————————————————————————————

  // 获取代理用户
  handleGetAgentPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var userProxyWithdrawalParam = {
        uid : values.uid,
        status : values.status,
        pageParam : pageParam,
      }
      adminUserCashrecordService.getAdminUserCashrecordPage(userProxyWithdrawalParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userProxyWithdrawalList: res.retValue.list, userProxyWithdrawalTotal: res.retValue.total});
          }                            
        }
      )
      
    })
  }


  // 用户分页
  userProxyWithdrawalChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var userProxyWithdrawalParam = {
          uid : values.uid,
          status : values.status,
          pageParam : pageParam,
        }

        this.setState({
          userProxyWithdrawalPageParam: userProxyWithdrawalParam,
        });

        adminUserCashrecordService.getAdminUserCashrecordPage(userProxyWithdrawalParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({userProxyWithdrawalList: res.retValue.list, userProxyWithdrawalTotal: res.retValue.total});
            }                            
          }
        )
    })
  }

  // 刷新数据
  refreshProxyWithdrawalData=() => {
    //console.log("refreshProxyWithdrawalData");
    let { history } = this.props;

    var userProxyWithdrawalParam = this.state.userProxyWithdrawalPageParam;
    adminUserCashrecordService.getAdminUserCashrecordPage(userProxyWithdrawalParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userProxyWithdrawalList: res.retValue.list, userProxyWithdrawalTotal: res.retValue.total});
        }                            
      }
    )
  }

  // 隐藏
  hideUpdateWithdrawalDialog=() => {
    this.setState({showUpdateWithdrawalDialog: false});
  }
  

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/proxy_withdrawal_list">代理提现申请列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>            
              <Form
                name="ProxyWithdrawalListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="代理ID" name="uid">
                          <Input placeholder="代理ID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="审核状态" name="status">
                          <Select defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>审核中</Option>
                              <Option value={1}>审核通过</Option>
                              <Option value={2}>审核拒绝</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    
                    <Col span={6} key={3} >
                      <Button type="primary" onClick={this.handleGetAgentPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userProxyWithdrawalList}
                columns={this.state.userProxyWithdrawalColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.userProxyWithdrawalTotal, defaultCurrent: 1, onChange: this.userProxyWithdrawalChangePage}}
              >
              </Table>
              <UpdateWithdrawal rowData={this.state.updateWithdrawalRow} close={this.hideUpdateWithdrawalDialog} visible={this.state.showUpdateWithdrawalDialog} refreshData={this.refreshProxyWithdrawalData}></UpdateWithdrawal>

        </StickyContainer>
        
      </div>
    );
  }
}
export default ProxyWithdrawalWithdrawalList;