import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal, Avatar, Popconfirm, Input } from 'antd';
import { Link } from 'react-router-dom';
import AddPer from './AddPer';
import EditPer from './EditPer';
import privilegeService from '../../../Service/Admin/privilegeService';
import { Logout } from '../../../Common/Auth';


class PerMgr extends Component {
  state={
    editPerRow: null,          // 当前编辑角色信息 
    showEditPerDialog: false,  // 修改角色对话框
    selectRowKeys:[],
    showAddPerDialog: false,  // 添加角色对话框
    unsubscribe: null,
    total: 0,
    datalist: null,
    searchKey: "",
    params: {pageNum:1, pageSize: 6},
    columns: [{
      key: 'id',
      title: '编号',
      dataIndex: 'id'
    },{
      key: 'des',
      title: '权限名',
      dataIndex: 'des'
    },{
      key: 'code',
      title: '权限码',
      dataIndex: 'code'
    },{
      key: 'type',
      title: '权限类型',
      dataIndex: 'type'
    },{
      key: 'pid',
      title: '父ID',
      dataIndex: 'pid'
    },{
      key: 'level',
      title: '层级',
      dataIndex: 'level'
    },{
      key: 'icon',
      title: '图标',
      dataIndex: 'icon'
    },{
      key: 'orderNum',
      title: '排序',
      dataIndex: 'orderNum'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        return (
          <div>
            <Button 
              onClick={() => this.setState({showEditPerDialog:true, editPerRow: row })}
              style={{marginRight:'5px'}} 
              type="primary">编辑 
            </Button>
            <Popconfirm
              onConfirm={() => {
                //message.info(row.id);
                this.deletePer(row.id);
              }}
              onText="确认"
              cancelText="取消"
              title="您确认要删除吗？"
            >
                <Button type="danger">删除</Button>
            </Popconfirm>
          </div>
        )
      }
    }],
    per1PageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }

  /**
   * 行选择操作
   */
  perRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows);
      this.setState({selectRowKeys: selectedRowKeys});
    }
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // 发送请求后端，获取当前用户的列表数据
    // 方法一：axios直接请求后端进行封装
    let pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    let key = '';
    let level = 1;
    console.log("componentDidMount 进入");
    privilegeService.getPrivilegePage(key, pageParam, level)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({datalist: res.retValue.list, total: res.retValue.total});
        }
      
      }
    )
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }
  

  // 分页
  changePage=(page, pageSize) => {
    let { history } = this.props;
    console.log("changePage 进入");
    let key = this.state.searchKey;
    // 修改参数
    this.setState(preState => {
      return {...preState, ...{params:{pageNum: page, pageSize: pageSize}, key: key}}
    }, 
    // 回调函数
    () => {
      let pageParam = this.state.params;
      let level = 1;
      this.setState({
        per1PageParam: pageParam,
      });
      privilegeService.getPrivilegePage(key, pageParam, level)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({datalist: res.retValue.list, total: res.retValue.total});
          }
        }
      )
    });
  }

  // button样式
  buttonStyle={margin: '5px'};

  // 关闭添加用户弹框
  hideAddPerDialog=() => {
    this.setState({showAddPerDialog: false});
  }

  // 关闭编辑用户弹框
  hideEditPerDialog=() => {
    this.setState({showEditPerDialog: false});
  }

  // 刷新数据
  refreshData=() => {
    let { history } = this.props;
    //console.log("refreshData 进入");
      let pageParam = this.state.per1PageParam;
      let key = '';
      let level = 1;
      privilegeService.getPrivilegePage(key, pageParam, level)
      .then(
        res => {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          this.setState({datalist: res.retValue.list, total: res.retValue.total});
        }
      )
  }

  // 删除数据
  handleDelete=()=> {
      let { history } = this.props;
      // 拿到所有要删除的数据
      //console.log(JSON.stringify(this.state.selectRowKeys));
      // 确认是否需要删除
      if(this.state.selectRowKeys.length <= 0 ) {
        message.warn('请选择要删除的数据！');
        return ;
      }
      Modal.confirm({
        title: '您确认要删除吗？',
        okText: '删除',
        cancelText: '取消',
        onOk: () => {
          // 调用后端删除
          let idList = this.state.selectRowKeys;

          privilegeService.deletePrivilegeByIdList(idList)
          .then(res => {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            this.state.selectRowKeys =[]; 
            // 刷新数据
            this.refreshData();
            message.success('删除成功！');
          })
        }
      });

  }

  // 根据Id删除用户
  deletePer=(id) => {
      let { history } = this.props;
      let idList = [id];
      privilegeService.deletePrivilegeByIdList(idList)
      .then(res => {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        // 刷新数据
        this.refreshData();
        message.success('删除成功！');
      })

  }


  render() {
    return (
      <div className="admin-usermgr">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/per_mgr">权限管理1</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
        <Button onClick={() => this.setState({showAddPerDialog:true})} style={this.buttonStyle} type="primary">添加</Button>
        <Button onClick={ this.handleDelete } style={this.buttonStyle} type="danger">删除</Button>

        <Input.Search 
          placeholder="搜索"
          onSearch={(value) => {
            // 第一步： 先把搜索的参数放到 state中
            this.setState(preState => {
              preState.searchKey = value
            },() => {
              // 第二步： 从新请求数据
              this.changePage(1,10);
            });

          }}
          enterButton
          style={{margin: '5px', width: '300px'}}
        /> 

        <Table 
          bordered
          style={{backgroundColor: '#FEFEFE'}}
          dataSource={this.state.datalist}
          columns={this.state.columns}
          rowSelection={this.perRowSelection}
          rowKey="id"
          pagination = {{ total: this.state.total, pageSize: 10, defaultCurrent: 1, onChange: this.changePage}}
        >
        </Table>
        <AddPer close={this.hideAddPerDialog} visible={this.state.showAddPerDialog} refreshData={this.refreshData}></AddPer>
        <EditPer rowData={this.state.editPerRow} close={this.hideEditPerDialog} visible={this.state.showEditPerDialog} refreshData={this.refreshData}></EditPer>
      </div>
    );
  }
}

export default PerMgr;