import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,InputNumber } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined, SafetyOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import userService from '../../../Service/Admin/userService';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class AddProxy extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          console.log(JSON.stringify(values));
          var agentUser = {
            name : values.name,
            password : values.password,
            anonymous : values.anonymous,
            comment : values.comment,
            phone : values.phone,
            chargePerc : values.chargePerc,
            iosChargePerc : values.iosChargePerc,
            nobleShareRatio : values.nobleShareRatio,
            incomePerc : values.incomePerc,
            status : values.status,
            roleCode : values.roleCode,
            pid: values.pid
          }
          userService.insertOrUpdateUser(agentUser)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="代理类型" labelCol={{span:10}} name="roleCode" style={{ width: 420 }} initialValue="secondAgent">
                <Select style={{ width: 220 }} defaultValue={1}>
                    <Option value="secondAgent">二级代理</Option>
                </Select>
            </Form.Item>

            <Form.Item label="上级代理id" labelCol={{span:10}} name="pid" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写上级代理id!',
                }
                ]
              }>
                <Input placeholder="上级代理id" />
            </Form.Item>


            <Form.Item label="登录账号" labelCol={{span:10}} name="name" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写登录账号',
                }
                ]
              }>
                <Input placeholder="登录账号" />
            </Form.Item>

            <Form.Item label="密码" name="password" labelCol={{span:10}} style={{ width: 420 }} rules={ [
                    {
                        pattern: /\w{6,20}/gi,
                        message: '请输入6-20个字符!',
                    },
                    {
                        required: true,
                        message: '请输入密码!',
                    }
                    ]
                }>
                <Input.Password prefix={<SafetyOutlined />} 
                    placeholder="密码"
                />
            </Form.Item>

            <Form.Item label="代理名称" labelCol={{span:10}} name="anonymous" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写代理名称!',
                }
                ]
              }>
                <Input placeholder="代理名称" />
            </Form.Item>

            <Form.Item label="联系人" labelCol={{span:10}} name="comment" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写联系人!',
                }
                ]
              }>
                <Input placeholder="联系人" />
            </Form.Item>

            <Form.Item label="手机号" labelCol={{span:10}} name="phone" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写手机号!',
                }
                ]
              }>
                <Input placeholder="手机号" />
            </Form.Item>

            <Form.Item label="充值佣金比例" labelCol={{span:10}} name="chargePerc" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写充值佣金比例!',
                }
                ]
              }>
                <InputNumber placeholder="充值佣金比例" />
            </Form.Item>

            <Form.Item label="苹果内购充值佣金比例" labelCol={{span:10}} name="iosChargePerc" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写苹果内购充值佣金比例!',
                }
                ]
              }>
                <InputNumber placeholder="苹果内购充值佣金比例" />
            </Form.Item>

            <Form.Item label="贵族分成比例" labelCol={{span:10}} name="nobleShareRatio" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写贵族分成比例!',
                }
                ]
              }>
                <InputNumber placeholder="贵族分成比例" />
            </Form.Item>

            <Form.Item label="收益佣金比例" labelCol={{span:10}} name="incomePerc" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写收益佣金比例!',
                }
                ]
              }>
                <InputNumber placeholder="收益佣金比例" />
            </Form.Item>

          
            <Form.Item label="是否启用" labelCol={{span:10}} name="status" style={{ width: 420 }} initialValue={1}>
                <Select style={{ width: 220 }} defaultValue={1}>
                    <Option value={1}>启用</Option>
                    <Option value={2}>禁用</Option>
                </Select>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddProxy