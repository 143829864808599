import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取接听记录
   * @param {*} liveRoomRecordParam
   * @returns
   */
   getAnswerRecordPage(liveRoomRecordParam) {
      //console.log("liveRoomRecordParam=" + JSON.stringify(liveRoomRecordParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/liveRoomRecord/getAnswerRecordPage",
        data: liveRoomRecordParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 分页获取拨打记录
   * @param {*} liveRoomRecordParam
   * @returns
   */
   getDialRecordPage(liveRoomRecordParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify(liveRoomRecordParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/liveRoomRecord/getDialRecordPage",
      data: liveRoomRecordParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 分页获取记录
   * @param {*} liveRoomRecordParam
   * @returns
   */
   getLiveRoomRecordPage(liveRoomRecordParam) {
      //console.log("liveRoomRecordParam=" + JSON.stringify(liveRoomRecordParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/liveRoomRecord/getLiveRoomRecordPage",
        data: liveRoomRecordParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

}