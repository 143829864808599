import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import chargeRulesService from "../../../Service/FinanceMgr/chargeRulesService";
import textDescriptionService from "../../../Service/NobleMgr/textDescriptionService";
import giftPackService from "../../../Service/Operation/giftPackService";
import nobLiveGiftService from '../../../Service/GradeMgr/nobLiveGiftService';
import appGuardService from '../../../Service/UserMgr/appGuardService';


import AddAndroidRecharge from './AddAndroidRecharge';
import UpdateAndroidRecharge from './UpdateAndroidRecharge';
import UpdateAndroidTextDescription from './UpdateAndroidTextDescription';

import AddIosRecharge from './AddIosRecharge';
import UpdateIosRecharge from './UpdateIosRecharge';
import UpdateIosTextDescription from './UpdateIosTextDescription';

import ShowGiftPack from './ShowGiftPack';
import AddGiftPack from './AddGiftPack';

import AddGuard from './AddGuard';
import UpdateGuard from './UpdateGuard';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class RechargeSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state = {
    // ———————————————————— 安卓充值设置 ————————————————————————————

    androidRechargeColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderno",
        title: "排序",
        dataIndex: "orderno",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "appType",
        title: "充值类型",
        dataIndex: "appType",
        render: (appType, row) => {
          if(appType === 1) {
            return "安卓"
          } else if(appType === 2) {
            return "ios";
          }
        }
      },
      {
        key: "coin",
        title: "金币数量",
        dataIndex: "coin",
      },
      {
        key: "money",
        title: "安卓价格",
        dataIndex: "money",
      },
      {
        key: "discountMoney",
        title: "安卓折扣价格",
        dataIndex: "discountMoney",
      },
      {
        key: "dicountDesr",
        title: "安卓折扣描述",
        dataIndex: "dicountDesr",
      },
      {
        key: "give",
        title: "赠送金币",
        dataIndex: "give",
      },
      {
        key: "isTip",
        title: "是否启用",
        dataIndex: "isTip",
        render: (isTip, row) => {
          if(isTip === 0) {
            return "不启用"
          } else if(isTip === 1) {
            return "启用";
          }
        }
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateAndroidRechargeDialog:true, updateAndroidRechargeRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteAndroidRecharge(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    androidRechargeTotal: 0,
    androidRechargeList: null,
    updateAndroidRechargeRow: null,
    showUpdateAndroidRechargeDialog: false,
    showAddAndroidRechargeDialog:false,
    androidTextDescriptionRow: null,
    showUpdateAndroidTextDescriptionDialog: false,
    androidRechargePageParam:{
      appType: 1,
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },

    // ———————————————————— 苹果充值设置 ————————————————————————————
    
    iosRechargeColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderno",
        title: "排序",
        dataIndex: "orderno",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "appType",
        title: "充值类型",
        dataIndex: "appType",
        render: (appType, row) => {
          if(appType === 1) {
            return "安卓"
          } else if(appType === 2) {
            return "ios";
          }
        }
      },
      {
        key: "coin",
        title: "金币数量",
        dataIndex: "coin",
      },
      {
        key: "moneyIos",
        title: "苹果价格",
        dataIndex: "moneyIos",
      },
      {
        key: "discountMoneyIos",
        title: "苹果折扣价格",
        dataIndex: "discountMoneyIos",
      },
      {
        key: "iosDicountDesr",
        title: "苹果折扣描述",
        dataIndex: "iosDicountDesr",
      },
      {
        key: "productId",
        title: "苹果项目ID",
        dataIndex: "productId",
      },
      {
        key: "give",
        title: "赠送金币",
        dataIndex: "give",
      },
      {
        key: "isTip",
        title: "是否启用",
        dataIndex: "isTip",
        render: (isTip, row) => {
          if(isTip === 0) {
            return "不启用"
          } else if(isTip === 1) {
            return "启用";
          }
        }
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateIosRechargeDialog:true, updateIosRechargeRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteIosRecharge(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    iosRechargeTotal: 0,
    iosRechargeList: null,
    updateIosRechargeRow: null,
    showUpdateIosRechargeDialog: false,
    showAddIosRechargeDialog:false,
    iosTextDescriptionRow: null,
    showUpdateIosTextDescriptionDialog: false,
    iosRechargePageParam:{
      appType: 2,
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },

    // ———————————————————— 首充礼包配置 ————————————————————————————

    firstGiftBagColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "orderno",
        title: "排序",
        dataIndex: "orderno",
      },
      {
        key: "name",
        title: "名称",
        dataIndex: "name",
      },
      {
        key: "appType",
        title: "充值类型",
        dataIndex: "appType",
        render: (appType, row) => {
          if(appType === 1) {
            return "安卓"
          } else if(appType === 2) {
            return "ios";
          }
        }
      },
      {
        key: "coin",
        title: "金币数量",
        dataIndex: "coin",
      },
      {
        key: "money",
        title: "价格",
        dataIndex: "money",
        render: (money, row) => {
          if(row.appType === 1) {
            return money;
          } else if(row.appType === 2) {
            return row.moneyIos;
          }
        }
      },
      {
        key: "isTip",
        title: "是否启用",
        dataIndex: "isTip",
        render: (isTip, row) => {
          if(isTip === 0) {
            return "不启用"
          } else if(isTip === 1) {
            return "启用";
          }
        }
      },
      {
        key: 'query',
        title: '礼包内容',
        dataIndex: 'query',
        render: (query, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.showFirstGiftBag(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">礼包内容
              </Button>
            </div>
          )
        }
      },
      {
        key: 'add',
        title: '添加礼包',
        dataIndex: 'add',
        render: (add, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getAddGiftPackRowData(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">添加礼包
              </Button>
            </div>
          )
        }
      }
    ],
    firstGiftBagTotal: 0,
    firstGiftBagList: null,
    showFirstGiftBagDialog: false,
    showFirstGiftBagRow: null,
    addGiftPackRow: null,
    showAddGiftPackDialog:false,
    firstGiftBagPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 守护设置 ————————————————————————————

    guardColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "守护名称",
        dataIndex: "name",
      },
      {
        key: "guardType",
        title: "守护类型",
        dataIndex: "guardType",
        render: (guardType, row) => {
          if(guardType === 1) {
            return "普通"
          } else if(guardType === 2) {
            return "尊贵";
          }
        }
      },
      {
        key: "payType",
        title: "支付类型",
        dataIndex: "payType",
        render: (payType, row) => {
          if(payType === 1) {
            return "金币"
          } else if(payType === 2) {
            return "RMB";
          } else if(payType === 3) {
            return "人民币/金币";
          }
        }
      },
      {
        key: "coin",
        title: "守护价格",
        dataIndex: "coin",
      },
      {
        key: "androidPrice",
        title: "安卓价格",
        dataIndex: "androidPrice",
      },
      {
        key: "iosPrice",
        title: "ios价格",
        dataIndex: "iosPrice",
      },
      {
        key: "iosId",
        title: "苹果项目id",
        dataIndex: "iosId",
      },
      {
        key: "length",
        title: "时长",
        dataIndex: "length",
      },
      {
        key: "sort",
        title: "排序",
        dataIndex: "sort",
      },
      {
        key: "state",
        title: "是否启用",
        dataIndex: "state",
        render: (state, row) => {
          if(state === 0) {
            return "启用"
          } else if(state === 1) {
            return "禁用";
          }
        }
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateGuardDialog:true, updateGuardRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteGuard(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    guardTotal: 0,
    guardList: null,
    updateGuardRow: null,
    showUpdateGuardDialog: false,
    showAddGuardDialog:false,
    androidTextDescriptionRow: null,
    showUpdateAndroidTextDescriptionDialog: false,
    guardPageParam:{
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },
  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 安卓充值设置 ————————————————————————————
    this.getChargeRules1();
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 安卓充值设置 ————————————————————————————
  
  // 分页
  androidRechargeChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var chargeRulesParam = {
        appType: 1,
        pageParam: pageParam,
      };

      this.setState({
        androidRechargePageParam: chargeRulesParam,
      });
      chargeRulesService.getChargeRulesPage(chargeRulesParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            androidRechargeList: res.retValue.list,
            androidRechargeTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshAndroidRechargeData = () => {
    //console.log("refreshAndroidRechargeData");
    let { history } = this.props;

    var chargeRulesParam = this.state.androidRechargePageParam;
    chargeRulesService.getChargeRulesPage(chargeRulesParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          androidRechargeList: res.retValue.list,
          androidRechargeTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateAndroidRechargeDialog=() => {
    this.setState({showUpdateAndroidRechargeDialog: false});
  }

  // 关闭添加
  hideAddAndroidRechargeDialog=() => {
    this.setState({showAddAndroidRechargeDialog: false});
  }

  // 关闭添加
  hideUpdateAndroidTextDescriptionDialog=() => {
    this.setState({showUpdateAndroidTextDescriptionDialog: false});
  }

  // 根据Id删除
  deleteAndroidRecharge=(id) => {
    let { history } = this.props;
    let chargeRulesParam = {
      id : id
    };
    chargeRulesService.deleteChargeRules(chargeRulesParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshAndroidRechargeData();
        message.success('删除成功！');
      }
      
    })

  }

  // 获取充值文本
  getAndroidTextDescription = () => {
    let { history } = this.props;
    //console.log("getAndroidTextDescription");

    var textDescription = {
      promptType: 1,
      osType: 1
    };

    textDescriptionService.getText(textDescription)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          androidTextDescriptionRow: res.retValue,
          showUpdateAndroidTextDescriptionDialog: true,
        });
      }
      
    });
  };

  // ———————————————————— 苹果充值设置 ————————————————————————————

  // 分页
  iosRechargeChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var chargeRulesParam = {
        appType: 2,
        pageParam: pageParam,
      };

      this.setState({
        iosRechargePageParam: chargeRulesParam,
      });

      chargeRulesService.getChargeRulesPage(chargeRulesParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            iosRechargeList: res.retValue.list,
            iosRechargeTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshIosRechargeData = () => {
    //console.log("refreshIosRechargeData");
    let { history } = this.props;

    var chargeRulesParam = this.state.iosRechargePageParam;

    chargeRulesService.getChargeRulesPage(chargeRulesParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          iosRechargeList: res.retValue.list,
          iosRechargeTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateIosRechargeDialog=() => {
    this.setState({showUpdateIosRechargeDialog: false});
  }

  // 关闭添加
  hideAddIosRechargeDialog=() => {
    this.setState({showAddIosRechargeDialog: false});
  }

  // 关闭添加
  hideUpdateIosTextDescriptionDialog=() => {
    this.setState({showUpdateIosTextDescriptionDialog: false});
  }

  // 根据Id删除
  deleteIosRecharge=(id) => {
    let { history } = this.props;
    let chargeRulesParam = {
      id : id
    };
    chargeRulesService.deleteChargeRules(chargeRulesParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshIosRechargeData();
        message.success('删除成功！');
      }
      
    })

  }

  // 获取充值文本
  getIosTextDescription = () => {
    let { history } = this.props;
    //console.log("getIosTextDescription");

    var textDescription = {
      promptType: 1,
      osType: 2
    };

    textDescriptionService.getText(textDescription)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          androidTextDescriptionRow: res.retValue,
          showUpdateAndroidTextDescriptionDialog: true,
        });
      }
      
    });
  };


 // ———————————————————— 首充礼包配置 ————————————————————————————

 // 分页
 firstGiftBagChangePage = (page, pageSize) => {
  //console.log("changePage 进入");
  let { history } = this.props;
  var pageParam = {
    pageNum: page,
    pageSize: pageSize,
  };

  this.setState({
    firstGiftBagPageParam: pageParam,
  });

  var chargeRulesParam = {
    pageParam: pageParam,
  };
  chargeRulesService.getChargeRulesPage(chargeRulesParam)
  .then((res) => {
    if(res == null || res.retCode != 200) {
      if(res.retCode == 10001) {
        // 清除登录信息
        Logout();
        // 跳转到登录页面
        history.push('/login');
        return;
      }
      message.info("查询失败,"+res.message);  
    } else {
      this.setState({
        firstGiftBagList: res.retValue.list,
        firstGiftBagTotal: res.retValue.total,
      });
    }
    
  });
};

// 刷新数据
refreshFirstGiftBagData = () => {
  //console.log("refreshFirstGiftBagData");
  let { history } = this.props;

  var chargeRulesParam = {
    pageParam: this.state.firstGiftBagPageParam,
  };
  chargeRulesService.getChargeRulesPage(chargeRulesParam)
  .then((res) => {
    if(res == null || res.retCode != 200) {
      if(res.retCode == 10001) {
        // 清除登录信息
        Logout();
        // 跳转到登录页面
        history.push('/login');
        return;
      }
      message.info("查询失败,"+res.message);  
    } else {
      this.setState({
        firstGiftBagList: res.retValue.list,
        firstGiftBagTotal: res.retValue.total,
      });
    }
    
  });
};

// 关闭添加
hideFirstGiftBagDialog=() => {
  this.setState({showFirstGiftBagDialog: false});
}

// 关闭添加
hideAddGiftPackDialog=() => {
  this.setState({showAddGiftPackDialog: false});
}

// 查看礼包内容
showFirstGiftBag=(id) => {
  let { history } = this.props;
  var pageParam = {
    pageNum: 1,
    pageSize: 10,
  };

  var giftPackParam={
    packType: 2,
    rechargeId: id,
    pageParam: pageParam
  }

  giftPackService.getGiftPackPage(giftPackParam)
  .then(
    res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.state.showFirstGiftBagRow = {
          rechargeId: id,
          giftPackList : res.retValue.list,
          giftPackTotal: res.retValue.total
        };
  
        this.setState({ showFirstGiftBagDialog: true });
      }
      
    }
  )
}

/**
   * 礼包分页
   * @param {*} page 
   * @param {*} pageSize 
   * @param {*} videoid 
   */
 giftPackChangePage=(page, pageSize, rechargeId) => {
  //console.log("changePage 进入");
  let { history } = this.props;
  this.formRef3.current.validateFields()
  .then(values => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      }

      var giftPackParam={
        packType: 2,
        rechargeId: rechargeId,
        pageParam: pageParam
      }
    
      giftPackService.getGiftPackPage(giftPackParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.state.showFirstGiftBagRow = {
              rechargeId: rechargeId,
              giftPackList : res.retValue.list,
              giftPackTotal: res.retValue.total
            };
      
            this.setState({ showFirstGiftBagDialog: true });
          }
          
        }
      )
  })
}

  /**
   * 获取添加签到奖励
   * @param {*} options 
   */
  getAddGiftPackRowData = (id) => {
      let { history } = this.props;
      // 获取分配比例列表
      nobLiveGiftService.getGiftList()
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
              return ;
          }
          
          this.state.addGiftPackRow = {
            rechargeId: id,
            giftList: res.retValue
          }

          //console.log("addGiftPackRow=" + JSON.stringify(this.state.addGiftPackRow))
          this.setState({showAddGiftPackDialog:true })
        }
          
      })
      
  };

  // ———————————————————— 守护设置 ————————————————————————————
  
  // 分页
  guardChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var guardPageParam = {
        pageParam: pageParam
      }

      this.setState({
        guardPageParam: guardPageParam,
      });

      appGuardService.getGuardPage(guardPageParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            guardList: res.retValue.list,
            guardTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshGuardData = () => {
    //console.log("refreshGuardData");
    let { history } = this.props;

    var guardPageParam = this.state.guardPageParam;

    appGuardService.getGuardPage(guardPageParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          guardList: res.retValue.list,
          guardTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateGuardDialog=() => {
    this.setState({showUpdateGuardDialog: false});
  }

  // 关闭添加
  hideAddGuardDialog=() => {
    this.setState({showAddGuardDialog: false});
  }


  // 根据Id删除
  deleteGuard=(id) => {
    let { history } = this.props;
    let guardParam = {
      id : id
    };
    appGuardService.deleteGuard(guardParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshGuardData();
        message.success('删除成功！');
      }
      
    })

  }



   // ———————————————————— tab切换 ————————————————————————————
  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getChargeRules1();
    } else if(key == "2") {
      this.getChargeRules2();
    } else if(key == "3") {
      this.getChargeRules3();
    } else if(key == "4") {
      this.getGuard();
    }
  }

  /**
   * 安卓充值设置
   */
  getChargeRules1=()=>{
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var chargeRulesParam = {
      appType: 1,
      pageParam: pageParam,
    };
    chargeRulesService.getChargeRulesPage(chargeRulesParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          androidRechargeList: res.retValue.list,
          androidRechargeTotal: res.retValue.total,
        });
      }
      
    });
  }

  /**
   * 苹果充值设置
   */
  getChargeRules2=()=>{
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var iosChargeRulesParam = {
      appType: 2,
      pageParam: pageParam,
    };
    chargeRulesService.getChargeRulesPage(iosChargeRulesParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          iosRechargeList: res.retValue.list,
          iosRechargeTotal: res.retValue.total,
        });
      }
      
    });
  }

  /**
   * 首充礼包配置
   */
  getChargeRules3=()=>{
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var firstGiftBagParam = {
      pageParam: pageParam,
    };
    chargeRulesService.getChargeRulesPage(firstGiftBagParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          firstGiftBagList: res.retValue.list,
          firstGiftBagTotal: res.retValue.total,
        });
      }
      
    });
  }

  /**
   * 守护配置
   */
    getGuard=()=>{
      let { history } = this.props;
      var pageParam = {
        pageNum: 1,
        pageSize: 10,
      };
      var guardPageParam = {
        pageParam: pageParam
      }

      this.setState({
        guardPageParam: guardPageParam,
      });
      appGuardService.getGuardPage(guardPageParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            guardList: res.retValue.list,
            guardTotal: res.retValue.total,
          });
        }
        
      });
    }

  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/recharge_settings">充值设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // ———————————————————— 安卓充值设置 ————————————————————————————
            <TabPane tab="安卓充值设置" key="1">
              <Form
                name="AndroidRechargeListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={2} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.getAndroidTextDescription}  >
                        充值页面文字说明
                      </Button>
                    </Col>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddAndroidRechargeDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.androidRechargeList}
                columns={this.state.androidRechargeColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.androidRechargeTotal,
                  defaultCurrent: 1,
                  onChange: this.androidRechargeChangePage,
                }}
              ></Table>
              <AddAndroidRecharge close={this.hideAddAndroidRechargeDialog} visible={this.state.showAddAndroidRechargeDialog} refreshData={this.refreshAndroidRechargeData}></AddAndroidRecharge>
              <UpdateAndroidRecharge rowData={this.state.updateAndroidRechargeRow} close={this.hideUpdateAndroidRechargeDialog} visible={this.state.showUpdateAndroidRechargeDialog} refreshData={this.refreshAndroidRechargeData}></UpdateAndroidRecharge>
              <UpdateAndroidTextDescription rowData={this.state.androidTextDescriptionRow} close={this.hideUpdateAndroidTextDescriptionDialog} visible={this.state.showUpdateAndroidTextDescriptionDialog} ></UpdateAndroidTextDescription>
            </TabPane>

            // ———————————————————— 苹果充值设置 ————————————————————————————
            <TabPane tab="苹果充值设置" key="2">
              <Form
                name="IosRechargeListForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={2} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.getIosTextDescription}  >
                        充值页面文字说明
                      </Button>
                    </Col>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddIosRechargeDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.iosRechargeList}
                columns={this.state.iosRechargeColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.iosRechargeTotal,
                  defaultCurrent: 1,
                  onChange: this.iosRechargeChangePage,
                }}
              ></Table>
              <AddIosRecharge close={this.hideAddIosRechargeDialog} visible={this.state.showAddIosRechargeDialog} refreshData={this.refreshIosRechargeData}></AddIosRecharge>
              <UpdateIosRecharge rowData={this.state.updateIosRechargeRow} close={this.hideUpdateIosRechargeDialog} visible={this.state.showUpdateIosRechargeDialog} refreshData={this.refreshIosRechargeData}></UpdateIosRecharge>
              <UpdateIosTextDescription rowData={this.state.iosTextDescriptionRow} close={this.hideUpdateIosTextDescriptionDialog} visible={this.state.showUpdateIosTextDescriptionDialog} ></UpdateIosTextDescription>
            </TabPane>
         

            // ———————————————————— 首充礼包配置 ————————————————————————————
            <TabPane tab="首充礼包配置" key="3">

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.firstGiftBagList}
                columns={this.state.firstGiftBagColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.firstGiftBagTotal,
                  defaultCurrent: 1,
                  onChange: this.firstGiftBagChangePage,
                }}
              ></Table>
              <ShowGiftPack rowData={this.state.showFirstGiftBagRow} close={this.hideFirstGiftBagDialog} visible={this.state.showFirstGiftBagDialog} refreshGiftPackData={this.giftPackChangePage} ></ShowGiftPack>
              <AddGiftPack rowData={this.state.addGiftPackRow} close={this.hideAddGiftPackDialog} visible={this.state.showAddGiftPackDialog} ></AddGiftPack>

            </TabPane>

            // ———————————————————— 守护设置 ————————————————————————————
            <TabPane tab="守护设置" key="4">
              <Form
                name="GuardListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddGuardDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.guardList}
                columns={this.state.guardColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.guardTotal,
                  defaultCurrent: 1,
                  onChange: this.guardChangePage,
                }}
              ></Table>
              <AddGuard close={this.hideAddGuardDialog} visible={this.state.showAddGuardDialog} refreshData={this.refreshGuardData}></AddGuard>
              <UpdateGuard rowData={this.state.updateGuardRow} close={this.hideUpdateGuardDialog} visible={this.state.showUpdateGuardDialog} refreshData={this.refreshGuardData}></UpdateGuard>
            </TabPane>

          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}
export default RechargeSettings;
