import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取系统公告
   * @param {*} sysNoticParam
   * @returns
   */
   getSysNoticPage(sysNoticParam) {
      //console.log("feedBackParam=" + JSON.stringify(sysNoticParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/sysNotic/getSysNoticPage",
        data: sysNoticParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新系统公告
   * @param {*} sysNotic
   * @returns
   */
   insertOrUpdateSysNotic(sysNotic) {
        //console.log("sysNotic=" + JSON.stringify(sysNotic));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/sysNotic/insertOrUpdateSysNotic",
        data: sysNotic,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除系统公告
     * @param {*} sysNoticParam 
     * @returns 
     */
     deleteSysNotic(sysNoticParam) {
        //console.log("sysNoticParam=" + JSON.stringify(sysNoticParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/sysNotic/deleteSysNotic",
        data: sysNoticParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}