import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,InputNumber } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import shortVideoService from "../../../Service/ShortVideo/shortVideoService";
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class AddVirtualAdsShortVideo extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appShortVideo = {
            type: values.type,
            content: values.content,
            userId: values.userId,
            thumb: values.thumb,
            videoUrl: values.videoUrl,
            videoTime: values.videoTime,
            likes: values.likes,
            looks: values.looks,
            fees: values.fees,
            comments: values.comments,
            classifyId: values.classifyId,
            isPrivate: 0,
            internalRecommended: 0,
            isWeek: 1,
            status: 1,
            isEnableMonitoring: 2,
            virtualOrNot: 1,
          }
          shortVideoService.insertOrUpdateShortVideo(appShortVideo)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传
  doUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file, 3)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })
  
  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
  
  };

  // 上传视频前置校验
  beforeVideoUpload(file) {
    const isLtM = file.size / 1024 / 1024 < 20;
    if (!isLtM) {
        message.error('video must smaller than 20MB!');
    }
    return isLtM;
  } 

  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="类型" labelCol={{span:10}} name="type" style={{ width: 420 }} initialValue={1}>
                <Radio.Group name="type" defaultValue={1}>
                    <Radio value={1}>视频</Radio>
                    <Radio value={2}>图片</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="标题" labelCol={{span:10}} name="content" style={{ width: 420 }} >
                <Input placeholder="标题" />
            </Form.Item>

            <Form.Item label="用户id" labelCol={{span:10}} name="userId" style={{ width: 420 }} >
                <Input placeholder="用户id" />
            </Form.Item>

            <Form.Item label="封页图" labelCol={{span:8}}  name="thumb" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doUpload}
                    beforeUpload={this.beforeUpload}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="视频" name="videoUrl" 
                valuePropName="file"
                labelCol={{span:10}} 
                style={{ width: 420 }}
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="video/*"
                    name="fiavatarle"
                    onChange={this.handleChange}
                    customRequest={this.doUpload}
                    beforeUpload={this.beforeVideoUpload}
                >
                     <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item label="视频时长(秒)" labelCol={{span:10}} name="videoTime" style={{ width: 420 }} >
                <InputNumber />
            </Form.Item>

            <Form.Item label="点赞数" labelCol={{span:10}} name="likes" style={{ width: 420 }} >
                <InputNumber />
            </Form.Item>

            <Form.Item label="观看次数" labelCol={{span:10}} name="looks" style={{ width: 420 }} >
                <InputNumber />
            </Form.Item>

            <Form.Item label="付费次数" labelCol={{span:10}} name="fees" style={{ width: 420 }} >
                <InputNumber />
            </Form.Item>

            <Form.Item label="评论数量" labelCol={{span:10}} name="comments" style={{ width: 420 }} >
                <InputNumber />
            </Form.Item>

            <Form.Item label="分类" name="classifyId" labelCol={{span:10}} style={{ width: 420 }} initialValue={this.props.rowData != null ? this.props.rowData.classifyList[0].id : 0}>
                <Select style={{ width: 220 }} defaultValue={this.props.rowData != null ? this.props.rowData.classifyList[0].id : 0}>
                    { this.props.rowData != null ? this.props.rowData.classifyList.map((item,index)=>
                        <Option key={item.id} value={item.id}>{item.name}</Option>
                    ) : null}
                </Select>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddVirtualAdsShortVideo