import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
import inviteImgService from '../../../Service/BasicInformation/inviteImgService';
import invitationRuleDescriptionService from '../../../Service/BasicInformation/invitationRuleDescriptionService';

import AddInviteImg from './AddInviteImg';
import UpdateInviteImg from './UpdateInviteImg';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class InvitationSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
    // ———————————————————— 邀请码背景图设置 ————————————————————————————
    inviteImgColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'url',
      title: '背景图',
      dataIndex: 'url',
      render: (url, row) => {
        if(url != null && url != "") {
          return <Image
          width={64}
          height={100}
          src={url}
        />
        }
      }
    },{
      key: 'isTip',
      title: '是否启用',
      dataIndex: 'isTip',
      render: (isTip, row) => {
        if(isTip === 0) {
          return "不使用"
        } else if(isTip === 1) {
          return "使用";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateInviteImgDialog:true, updateInviteImgRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteInviteImg(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    inviteImgTotal: 0,
    inviteImgList: [],
    updateInviteImgRow: null,
    showUpdateInviteImgDialog: false,
    showAddInviteImgDialog:false,
    inviteImgPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
    

    // ———————————————————— 邀请规则说明 ————————————————————————————
    
    invitationRuleDescription: null,
    excerpt: BraftEditor.createEditorState(null)
    
    
  }


  // 第一次渲染后调用
  componentDidMount() {
      let { history } = this.props;

    // ———————————————————— 邀请码背景图设置 ————————————————————————————
    this.getInviteImg();
   

    invitationRuleDescriptionService.getConfig()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({invitationRuleDescription: res.retValue});
          this.setState({excerpt: res.retValue.excerpt});
        }

      }
    )   
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 邀请码背景图设置 ————————————————————————————

  // 关闭更新
  hideUpdateInviteImgDialog=() => {
    this.setState({showUpdateInviteImgDialog: false});
  }

  // 关闭添加
  hideAddInviteImgDialog=() => {
    this.setState({showAddInviteImgDialog: false});
  }


  // 获取列表
  handleGetInviteImgPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var inviteImgParam={
        pageParam: pageParam
      }

      inviteImgService.getInviteImgPage(inviteImgParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({inviteImgList: res.retValue.list, inviteImgTotal: res.retValue.total});
          }
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  inviteImgChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      inviteImgPageParam: pageParam,
    });

    var inviteImgParam={
      pageParam: pageParam
    }

    inviteImgService.getInviteImgPage(inviteImgParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({inviteImgList: res.retValue.list, inviteImgTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshInviteImgData=() => {
      let { history } = this.props;

      var inviteImgParam={
        pageParam: this.state.inviteImgPageParam
      }

      inviteImgService.getInviteImgPage(inviteImgParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({inviteImgList: res.retValue.list, inviteImgTotal: res.retValue.total});
          }
        }
      )
  }

  // 根据Id删除礼物
  deleteInviteImg=(id) => {
    let { history } = this.props;
    let inviteImgParam = {
      id : id
    };
    inviteImgService.deleteInviteImg(inviteImgParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshInviteImgData();
        message.success('删除成功！');
      }
      
    })

  }

  // ———————————————————— 邀请规则说明 ————————————————————————————
    
  handleInvitationRuleDescriptionSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {

        var invitationRuleDescription = {
            id: values.id,
            title: values.title,
            excerpt: this.state.excerpt,
        };
        invitationRuleDescriptionService.updateConfig(invitationRuleDescription)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  handleEditorExcerptChange = (content) => {
    console.log("content="+JSON.stringify(content.toHTML()));

    this.setState({
      excerpt: content.toHTML()
    })
  }
    
  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getInviteImg();
    } else if(key == "2") {
      this.getInvitationRuleDescription();
    } 
  }
  
  /**
   * 邀请码背景图设置
   */
  getInviteImg =() => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var inviteImgParam={
      pageParam: pageParam
    }

    inviteImgService.getInviteImgPage(inviteImgParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({inviteImgList: res.retValue.list, inviteImgTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 邀请规则说明
   */
  getInvitationRuleDescription =() => {
    let { history } = this.props;

    invitationRuleDescriptionService.getConfig()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            id: res.retValue.id,
            title: res.retValue.title,
            excerpt: res.retValue.excerpt,
          });
          //console.log("excerpt=" + BraftEditor.createEditorState(res.retValue.excerpt != null ? res.retValue.excerpt : null).toHTML());

          this.setState({invitationRuleDescription: res.retValue});
          this.setState({excerpt: res.retValue.excerpt});
        }

      }
    )   
  }

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/invitation_settings">邀请设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 邀请码背景设置 ————————————————————————————
            <TabPane tab="邀请码背景设置" key="1">
              <Form
                name="InviteImgForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddInviteImgDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.inviteImgList}
                columns={this.state.inviteImgColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.inviteImgTotal, defaultCurrent: 1, onChange: this.inviteImgChangePage}}
              >
              </Table>
              <AddInviteImg close={this.hideAddInviteImgDialog} visible={this.state.showAddInviteImgDialog} refreshData={this.refreshInviteImgData}></AddInviteImg>
              <UpdateInviteImg rowData={this.state.updateInviteImgRow} close={this.hideUpdateInviteImgDialog} visible={this.state.showUpdateInviteImgDialog} refreshData={this.refreshInviteImgData}></UpdateInviteImg>
              
            </TabPane>
            
            
            // ———————————————————— 邀请规则说明 ————————————————————————————
            <TabPane tab="邀请规则说明" key="2">
              
              <Form 
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  initialValues={this.state.invitationRuleDescription}
                  
              >
                  <Form.Item label="id" name="id" hidden>
                      <Input placeholder="id" />
                  </Form.Item>

                  <Form.Item label="标题" name="title" labelCol={{span:10}} style={{ width: 420 }}>
                      <Input placeholder="标题" />
                  </Form.Item>

                  <Form.Item label="内容" name="excerpt" labelCol={{span:5}} style={{ width: 840 }} >
                    <BraftEditor
                        defaultValue={BraftEditor.createEditorState(this.state.excerpt != null ? this.state.excerpt : null)}
                        value={this.state.excerpt}
                        onChange={this.handleEditorExcerptChange}
                        style={{ border: '1px solid #dedede', marginTop: 10 }}
                        placeholder="请输入正文内容"
                    />
                  </Form.Item>
              </Form>

              <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                <span onClick={ this.handleInvitationRuleDescriptionSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
              </div>
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default InvitationSettings;