import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,Popconfirm ,Table, InputNumber} from 'antd';
import gradeRewarReService from '../../../Service/GradeMgr/gradeRewarReService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class ShowGradeGiftRe extends Component {
  formRef = React.createRef();

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }

  // 提交操作
  handleSubmit =() => {
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        //console.log(JSON.stringify(values));
        var appGradeReWarRe = {};
        if(values.rewarType == 1) {
          appGradeReWarRe ={
            rewarType : values.rewarType,
            rewarSum : values.rewarSum,
            gradeId : values.gradeId,
          }
        } else if(values.rewarType == 3) {
          var idGiftname = values.idGiftname;
          var rewarId = null;
          var rewarName = null;
          if(idGiftname != null && idGiftname != "") {
            var arry = idGiftname.split("_");
            rewarId = arry[0];
            rewarName = arry[1];
          }
          appGradeReWarRe ={
            rewarType : values.rewarType,
            rewarSum : values.rewarSum,
            rewarId : rewarId,
            rewarName : rewarName,
            gradeId : values.gradeId,
          }
        }

        gradeRewarReService.insertGradeRewarRe(appGradeReWarRe)
        .then(res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("添加失败")
              message.info("添加失败,"+res.message);  
              // 重置关闭对话框
              this.hanldeCloseModal();
            } else {
              message.info('添加成功！');
              // 重置关闭对话框
              this.hanldeCloseModal();
            }
        })
    })

  }


  render () {
    return (
      <Modal
        title="添加礼包内容"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="gradeId" name="gradeId" hidden>
                <Input placeholder="gradeId" />
            </Form.Item>

            <Form.Item label="礼包类型" name="rewarType" initialValue={1}>
                <Radio.Group name="rewarType" defaultValue={1}>
                    <Radio value={1}>金币</Radio>
                    <Radio value={3}>礼物</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item dependencies={['rewarType']} noStyle>
                {({ getFieldValue }) => {
                  const type = getFieldValue('rewarType')

                  if (type === 1) {
                    return (
                      <>
                        <Form.Item label="金币数量" name="rewarSum">
                          <InputNumber  />
                        </Form.Item>
                      </>
                    )
                  }

                  if (type === 3) {
                    return (
                      <>
                        <Form.Item label="礼物名称" name="idGiftname" initialValue={this.props.rowData != null ? this.props.rowData.giftList[0].idGiftname : null} rules={ [
                                {
                                    required: true,
                                    message: '请选择礼物名称!',
                                }
                                ]
                            }>
                            <Select style={{ width: 220 }} defaultValue={this.props.rowData != null ? this.props.rowData.giftList[0].idGiftname : null}>
                                { this.props.rowData != null ? this.props.rowData.giftList.map((item)=>
                                    <Option value={item.idGiftname}>{item.giftnameNeedcoin}</Option>
                                ) : null}
                            </Select>
                        </Form.Item>
                        <Form.Item label="礼物数量" name="rewarSum">
                          <InputNumber  />
                        </Form.Item>
                      </>
                    )
                  }

                  return null
                }}
              </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default ShowGradeGiftRe