import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取新手大礼包
   * @param {*} adminGiftPackParam
   * @returns
   */
   getGiftPackPage(adminGiftPackParam) {
      //console.log("adminGiftPackParam=" + JSON.stringify(adminGiftPackParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminGiftPack/getGiftPackPage",
        data: adminGiftPackParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新新手大礼包
   * @param {*} adminGiftPack
   * @returns
   */
   insertOrUpdateGiftPack(adminGiftPack) {
        //console.log("adminGiftPack=" + JSON.stringify(adminGiftPack));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminGiftPack/insertOrUpdateGiftPack",
        data: adminGiftPack,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除新手大礼包
     * @param {*} adminGiftPackParam 
     * @returns 
     */
    deleteGiftPack(adminGiftPackParam) {
        //console.log("feedBackParam=" + JSON.stringify(adminGiftPackParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminGiftPack/deleteGiftPack",
        data: adminGiftPackParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}