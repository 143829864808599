import React, { Component } from 'react'
import { Form, Input, Upload, Button, message, Select } from 'antd';
import { GetLoginToken } from '../../../Common/Auth';
import { LoadingOutlined,PlusOutlined,UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';

const { Option } = Select;

class EditUserFrm extends Component {
    formRef = React.createRef();

    state={
        loading: false,
        avatarFileList: [
            {
                url: this.props.rowData.avatar,
                thumbUrl: this.props.rowData.avatar
            }
        ],
        fileList:[]
    }

    /**
     * 图片数组处理
     */
    arrayHandle=() => {
        this.state.fileList = [];
        //console.log('arrayHandle portrait=' +  this.props.rowData.portrait);
        if(this.props.rowData.portrait == null || this.props.rowData.portrait == "") {
            return this.state.fileList;
        }
        var strArray = this.props.rowData.portrait.split(',');
        if(strArray.length <= 0) {
            return this.state.fileList;
        }
        //console.log('arrayHandle strArray=' +  JSON.stringify(strArray));

        for(var i=0; i< strArray.length;i++) {
            var urlString = strArray[i];
            var file = {
                uid: i,
                thumbUrl: urlString,
                url: urlString,
                response: urlString,
                status: 'done',
            }
            this.state.fileList = [...this.state.fileList, file];
        }
        //console.log('arrayHandle fileList=' +  JSON.stringify(this.state.fileList));

        return this.state.fileList;

    }


    // 页面第一次渲染时执行
    componentDidMount=() => {
        //console.log('EditUserFrm data=' +  JSON.stringify(this.props.rowData));
        this.formRef.current.setFieldsValue(this.props.rowData);
    }

    // 图片修改
    handleChangeFileImage=(e) => {
        if (e.file.status === 'uploading') {
            this.state.loading=true;
            return;
        }
        if (e.file.status === 'done') {
            //console.log("test="+e.file.response);
            this.state.loading=false;
            return e.file.response;
          }
    }

    // 上传前置校验
    beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })
  };

    /**
     * 上传按钮
     */
    uploadButton = (
        <div>
          {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      );



    handleChange = ({ file, fileList }) => {
        //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
        //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
        

      };

    handlePreview = (file) => {
        var imageUrl = file.url || file.thumbUrl || '';

    };

    handleCancel = () => {
    };

    normFile = (e) => {
        //console.log("normFile e=" + JSON.stringify(e.fileList)); // file 是当前正在上传的 单个 img
        var fileList = e.fileList;
        var fileListString = "";
        if(fileList.length <= 0) {
            return fileListString;
        }
        for(var i=0; i< fileList.length;i++) {
            var file = fileList[i];
            fileListString = fileListString + file.response;
            if(i < fileList.length-1) {
                fileListString = fileListString + ",";
            }
        }
        return fileListString;
    };

    render () {
        return (
        <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
        >
            <Form.Item label="userid" name="userid" hidden>
                <Input placeholder="userid" />
            </Form.Item>

            <Form.Item label="账号类型" name="role" initialValue={0} rules={ [
                    {
                        required: true,
                        message: '请选择账号类型!',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={0}>
                  <Option value={0}>普通用户</Option>
                </Select>
            </Form.Item>

            <Form.Item label="用户LOGO" name="avatar" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                    defaultFileList={this.state.avatarFileList}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="用户昵称" name="username" rules={ [
                    {
                        min: 2,
                        message: '请输入大于2个字符!',
                    },
                    {
                        required: true,
                        message: '请输入用户昵称',
                    }
                    ]
                }>
                <Input placeholder="用户昵称" />
            </Form.Item>

            <Form.Item label="手机号" name="mobile">
                <Input  placeholder="手机号" />
            </Form.Item>

            <Form.Item label="用户性别" name="sex" initialValue={1} rules={ [
                    {
                        required: true,
                        message: '请选择权限类型',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={1}>
                  <Option value={1}>男</Option>
                  <Option value={2}>女</Option>
                </Select>
            </Form.Item>

            <Form.Item label="个性签名" name="signature">
                <Input placeholder="个性签名" />
            </Form.Item>

            <Form.Item label="账号状态" name="status" initialValue={0} rules={ [
                    {
                        required: true,
                        message: '请选择账号状态!',
                    }
                    ]
                }>
                <Select style={{ width: 220 }} defaultValue={0}>
                  <Option value={0}>正常</Option>
                </Select>
            </Form.Item>

            <Form.Item label="展示图片" name="portrait" 
                valuePropName="filelist"
                getValueFromEvent={this.normFile}
                >
                <Upload
                    accept="image/*"
                    data={file => ({
                        image_file: file
                    })}
                    listType="picture-card"
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                    defaultFileList={this.arrayHandle}
                >
                    {
                        this.state.fileList.length >= 6 ? null : <Button icon={<UploadOutlined />}> upload </Button>
                    }
                </Upload>
            </Form.Item>
        </Form>
        )
    }
}

export default EditUserFrm