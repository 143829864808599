import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import appUserService from '../../../Service/UserMgr/appUserService';
import userAuthService from '../../../Service/UserMgr/userAuthService';
import userForbiddenService from '../../../Service/UserMgr/userForbiddenService';
import dealScalePlanService from '../../../Service/UserMgr/dealScalePlanService';
import dealScaleService from '../../../Service/UserMgr/dealScaleService';
import anchorStarService from '../../../Service/UserMgr/anchorStarService';
import AddAuthor from './AddAuthor';
import EditAuthor from './EditAuthor';
import ResetPwd from '../OrdinaryUsers/ResetPwd';
import DisableUser from '../OrdinaryUsers/DisableUser';
import EnableUser from '../OrdinaryUsers/EnableUser';
import UpdateRole from './UpdateRole';
import UserAuth from './UserAuth';
import UserAuthInfo from './UserAuthInfo';
import UpdateDealScalePlan from './UpdateDealScalePlan';
import UpdateDealScale from './UpdateDealScale';
import AddDealScalePlan from './AddDealScalePlan';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class HostUser extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  state={
    // ———————————————————— 主播列表 ————————————————————————————

    showEditAnthorDialog: false,
    userSelectRowKeys:[],
    showAddAnthorDialog: false,
    anthortotal: 0,
    anthorlist: null,
    params: {pageNum:1, pageSize: 10},
    anthorColumns: [{
      key: 'avatar',
      title: '主播信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '主播性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 0) {
          return "未设置"
        } else if(sex === 1) {
          return "男";
        } else if(sex === 2) {
          return "女";
        }
      }
    },{
      key: 'coin',
      title: '剩余金币',
      dataIndex: 'coin'
    },{
      key: 'consumption',
      title: '累计消费金币',
      dataIndex: 'consumption'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'votestotal',
      title: '累计收益映票',
      dataIndex: 'votestotal'
    },{
      key: 'pid',
      title: '上级推荐人',
      dataIndex: 'pid'
    },{
      key: 'agentId',
      title: '上级代理',
      dataIndex: 'agentId'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'source',
      title: '注册来源',
      dataIndex: 'source'
    },{
      key: 'phoneFirm',
      title: '手机厂商',
      dataIndex: 'phoneFirm'
    },{
      key: 'phoneModel',
      title: '手机型号',
      dataIndex: 'phoneModel'
    },{
      key: 'appVersion',
      title: 'APP版本',
      dataIndex: 'appVersion'
    },{
      key: 'appVersionCode',
      title: 'APP版本code',
      dataIndex: 'appVersionCode'
    },{
      key: 'ipaddr',
      title: '最后登录IP',
      dataIndex: 'ipaddr'
    },{
      key: 'lastLoginTime',
      title: '最后登录时间',
      dataIndex: 'lastLoginTime'
    },{
      key: 'onlineStatus',
      title: '真实在线状态',
      dataIndex: 'onlineStatus',
      render: (onlineStatus, row) => {
        if(onlineStatus === 0) {
          return "离线"
        } else if(onlineStatus === 1) {
          return "在线";
        }
      }
    },{
      key: 'toDayOnlineTime',
      title: '今日在线时长',
      dataIndex: 'toDayOnlineTime'
    },{
      key: 'status',
      title: '账号状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "正常"
        } else if(status === 1) {
          return "禁用";
        }
      }
    },{
      key: 'province',
      title: '省份',
      dataIndex: 'province'
    },{
      key: 'city',
      title: '城市',
      dataIndex: 'city'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        if(row.status == 0) {
          return (
            <div>
              <Button 
                onClick={() => this.getEditAnthorRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Button 
                onClick={() => this.setState({showResetPwdDialog:true, resetPwdRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">重置密码 
              </Button>
              <Button 
                onClick={() => this.setState({showDisableAnthorDialog:true, disableAnthorRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">禁用 
              </Button>
              <Button 
                onClick={() => this.setState({showUpdateRoleDialog:true, updateRoleRow: row })}
                style={{marginRight:'5px'}} 
                type="primary"> 修改身份
              </Button>
            </div>
          )
        } else {
          return (
            <div>
              <Button 
                onClick={() => this.getEditAnthorRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Button 
                onClick={() => this.setState({showResetPwdDialog:true, resetPwdRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">重置密码 
              </Button>
              <Button 
                onClick={() => this.getDisableAnthorInfo(row.userid)}
                style={{marginRight:'5px'}} 
                type="primary">启用
              </Button>
              <Button 
                onClick={() => this.setState({showUpdateRoleDialog:true, updateRoleRow: row })}
                style={{marginRight:'5px'}} 
                type="primary"> 修改身份
              </Button>
            </div>
          )
        }
        
      }
    }],
    resetPwdRow: null,
    showResetPwdDialog: false,
    disableAnthorRow: null,
    showDisableAnthorDialog: false,
    enableAnthorRow: null,
    showEnableAnthorDialog: false,
    addAnthorRow: null,
    editAnthorRow: null,
    updateRoleRow: null,
    showUpdateRoleDialog: false,
    anthorPageParam:{
      pageNum: 1,
      pageSize: 10,
    },


    // ———————————————————— 主播认证 ————————————————————————————
    userAuthlist: null,
    userAuthtotal: 0,
    userAuthColumns: [{
      key: 'avatar',
      title: '主播信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'realName',
      title: '姓名',
      dataIndex: 'realName'
    },{
      key: 'cerNo',
      title: '身份证号',
      dataIndex: 'cerNo'
    },{
      key: 'extraInfo',
      title: '附加信息',
      dataIndex: 'extraInfo'
    },{
      key: 'status',
      title: '审核状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "审核通过"
        } else if(status === 1) {
          return "待审核";
        } else if(status === 2) {
          return "审核拒绝";
        } else if(status === -1) {
          return "资料提交中";
        }
      }
    },{
      key: 'addTime',
      title: '提交时间',
      dataIndex: 'addTime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
        if(row.status == 1) {
          return (
            <div>
              <Button 
                onClick={() => this.getUserAuthInfoRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">查看详情
              </Button>
              <Button 
                onClick={() => this.getUserAuthRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">审核
              </Button>
            
            </div>
          )
        } else {
          return (
            <div>
              <Button 
                onClick={() => this.getUserAuthInfoRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">查看详情
              </Button>
            </div>
          )
        }
        
      }
    }],
    userAuthRow: null,
    showUserAuthDialog: false,
    showUserAuthInfoDialog: false,
    userAuthPageParam:{
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },

    // ———————————————————— 禁用主播 ————————————————————————————
    
    disableUserColumns: [{
      key: 'avatar',
      title: '主播信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '主播性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 0) {
          return "未设置"
        } else if(sex === 1) {
          return "男";
        } else if(sex === 2) {
          return "女";
        }
      }
    },{
      key: 'role',
      title: '角色',
      dataIndex: 'role',
      render: (role, row) => {
        if(role === 0) {
          return "普通用户"
        } else if(role === 1) {
          return "主播";
        }
      }
    },{
      key: 'anchorGrade',
      title: '主播等级',
      dataIndex: 'anchorGrade'
    },{
      key: 'optName',
      title: '禁用人',
      dataIndex: 'optName'
    },{
      key: 'reason',
      title: '禁用原因',
      dataIndex: 'reason'
    },{
      key: 'createTime',
      title: '禁用时间',
      dataIndex: 'createTime'
    },{
      key: 'disableTypeInfo',
      title: '禁用类型',
      dataIndex: 'disableTypeInfo'
    },{
      key: 'num',
      title: '禁用天数',
      dataIndex: 'num'
    },{
      key: 'agentId',
      title: '上级代理',
      dataIndex: 'agentId'
    },{
      key: 'votes',
      title: '映票余额',
      dataIndex: 'votes'
    },{
      key: 'source',
      title: '注册来源',
      dataIndex: 'source'
    },{
      key: 'phoneFirm',
      title: '手机厂商',
      dataIndex: 'phoneFirm'
    },{
      key: 'phoneModel',
      title: '手机型号',
      dataIndex: 'phoneModel'
    },{
      key: 'appVersion',
      title: 'APP版本',
      dataIndex: 'appVersion'
    },{
      key: 'appVersionCode',
      title: 'APP版本code',
      dataIndex: 'appVersionCode'
    },{
      key: 'ipaddr',
      title: '最后登录IP',
      dataIndex: 'ipaddr'
    },{
      key: 'lastLoginTime',
      title: '最后登录时间',
      dataIndex: 'lastLoginTime'
    },{
      key: 'onlineStatus',
      title: '真实在线状态',
      dataIndex: 'onlineStatus'
    },{
      key: 'status',
      title: '账号状态',
      dataIndex: 'status'
    },{
      key: 'province',
      title: '省份',
      dataIndex: 'province'
    },{
      key: 'city',
      title: '城市',
      dataIndex: 'city'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getDisableAnthorInfo(row.userid)}
                style={{marginRight:'5px'}} 
                type="primary">启用
              </Button>
            </div>
          )        
      }
    }],
    disableUserTotal: 0,
    disableUserList: null,
    enableAnthor2Row: null,
    showEnableAnthor2Dialog: false,
    disableUserPageParam:{
      role: 1,
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },


    // ———————————————————— 主播分成方案 ————————————————————————————
    dealScalePlanColumns: [{
      key: 'id',
      title: '方案ID（ID最小的为默认方案）',
      dataIndex: 'id'
    },{
      key: 'planName',
      title: '方案名称',
      dataIndex: 'planName'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showDealScalePlanDialog:true, enableDealScalePlanRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Button 
                onClick={() => this.getDealScaleRow(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">分成比例 
              </Button>
            </div>
          )        
      }
    }],
    dealScalePlanTotal: 0,
    dealScalePlanList: null,
    showDealScalePlanDialog: false,
    showAddDealScalePlanDialog: false,
    enableDealScalePlanRow: null,
    enableDealScaleRow: null,
    showDealScaleDialog: false,
    dealScalePlanPageParam:{
      pageParam: {
        pageNum: 1,
        pageSize: 10,
      }
    },
    

    // ———————————————————— 主播星级列表 ———————————————————————————— 
    anchorStarColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'starName',
      title: '星级名称',
      dataIndex: 'starName'
    },{
      key: 'starGrade',
      title: '星级等级',
      dataIndex: 'starGrade'
    },{
      key: 'starIcon',
      title: '星级标签',
      dataIndex: 'starIcon',
      render: (starIcon, row) => {
        if(starIcon != null && starIcon != "") {
          if(row.starGrade <= 1) {
            return <Image
                width={50}
                height={50}
                src={starIcon}
              />
          } else if (row.starGrade == 2) {
            return <Image
                width={90}
                height={50}
                src={starIcon}
              />
          } else if (row.starGrade == 3) {
            return <Image
              width={130}
              height={50}
              src={starIcon}
            />
          } else if (row.starGrade == 4) {
            return <Image
              width={170}
              height={50}
              src={starIcon}
            />
          } else if (row.starGrade = 5) {
            return <Image
              width={200}
              height={50}
              src={starIcon}
            />
          }
        }
      }

    },{
      key: 'status',
      title: '状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "不启用"
        } else if(status === 1) {
          return "启用";
        }
      }
    }],
    anchorStarTotal: 0,
    anchorStarList: null,
    showAnchorStarDialog: false,
    enableAnchorStarRow: null,
    anchorStarPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 主播资料审核开关 ———————————————————————————— 
    

    // ———————————————————— 主播资料审核 ———————————————————————————— 
  

  }


  // 第一次渲染后调用
  componentDidMount() {
      let { history } = this.props;
      // ———————————————————— 主播列表 ————————————————————————————
      this.getAppUser();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 主播列表 ————————————————————————————


  // 获取主播用户
  handleGetAnchorUser=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
       //console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var anchorUserParam = {
        userid: values.userid,
        mobile: values.mobile,
        username: values.username,
        role: 1,
        onlineStatus: values.onlineStatus,
        status: values.state,
        pid: values.pid,
        coinStart: values.coinStart,
        coinEnd: values.coinEnd,
        ipaddr: values.ipaddr,
        pageParam: pageParam,
      }

      appUserService.getAnchorUserPage(anchorUserParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({anthorlist: res.retValue.list, anthortotal: res.retValue.total});
          }                  
        }
      )
       
    })
  }

  // 用户分页
  anthorChangePage=(page, pageSize) => {
      //console.log("changePage 进入");
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          var pageParam = {
            pageNum: page,
            pageSize: pageSize,
          }

          this.setState({
            anthorPageParam: pageParam,
          });

          var anchorUserParam = {
            userid: values.userid,
            mobile: values.mobile,
            username: values.username,
            role: 1,
            onlineStatus: values.onlineStatus,
            status: values.state,
            pid: values.pid,
            coinStart: values.coinStart,
            coinEnd: values.coinEnd,
            ipaddr: values.ipaddr,
            pageParam: pageParam,
          }
    
          appUserService.getAnchorUserPage(anchorUserParam)
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                message.info("查询失败,"+res.message);  
              } else {
                this.setState({anthorlist: res.retValue.list, anthortotal: res.retValue.total});
              }                  
            }
          )

      });

    }

  // 关闭添加用户弹框
  hideAddAnthorDialog=() => {
    this.setState({showAddAnthorDialog: false});
  }

  // 关闭编辑用户弹框
  hideEditAnthorDialog=() => {
    this.setState({showEditAnthorDialog: false});
  }

  // 关闭密码重置弹框
  hideResetPwdDialog=() => {
    this.setState({showResetPwdDialog: false});
  }

  // 关闭禁用弹窗
  hideDisableAnthorDialog=() => {
    this.setState({showDisableAnthorDialog: false});
  }

  // 启动禁用弹窗
  hideEnableAnthorDialog=() => {
    this.setState({showEnableAnthorDialog: false});
  }

  // 启动禁用弹窗
  hideUpdateRoleDialog=() => {
    this.setState({showUpdateRoleDialog: false});
  }

  // 刷新数据
  refreshAnthorData=() => {
      //console.log("refreshAnthorData进入");
      let { history } = this.props;

      var anchorUserParam = {
        role: 1,
        pageParam: this.state.anthorPageParam,
      }

      appUserService.getAnchorUserPage(anchorUserParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({anthorlist: res.retValue.list, anthortotal: res.retValue.total});
          }                  
        }
      )
  }

  // 根据Id删除用户
  deleteUser=(userid) => {
      let { history } = this.props;
      let admin = GetLoginUserInfo();
      let appUser = {
         userid : userid,
         adminid : admin.userId,
      };
      appUserService.deleteUser(appUser)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshUserData();
          message.success('删除成功！');
        }                  
        
      })
  }


  /**
   * 获取分成方案列表
   * @param {*} options 
   */
  getAddAnthorRowData = () => {
      let { history } = this.props;
      // 获取分配比例列表
      appUserService.getAnchorAdminConfig()
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          
          this.state.addAnthorRow = {
            dealScalePlanList : res.retValue.dealScalePlanList,
            anchorStarList: res.retValue.anchorStarList,
            liveChannelList: res.retValue.liveChannelList,
          }

          this.setState({showAddAnthorDialog:true})
        }                  
          
      })
     
  };


    /**
   * 获取分成方案列表
   * @param {*} options 
   */
    getEditAnthorRowData = (row) => {
      let { history } = this.props;
      // 获取分配比例列表
      appUserService.getAnchorAdminConfig()
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          
          this.state.editAnthorRow = {
            dealScalePlanList : res.retValue.dealScalePlanList,
            anchorStarList: res.retValue.anchorStarList,
            liveChannelList: res.retValue.liveChannelList,
            userid: row.userid,
            role: row.role,
            avatar: row.avatar,
            username: row.username,
            mobile: row.mobile,
            password: row.password,
            sex: row.sex,
            signature: row.signature,
            status: row.status,
            portrait: row.portrait,
            dealScalePlan: row.dealScalePlan,
            isRecommend: row.isRecommend,
            starId: row.starId,
            isOOOAccount: row.isOOOAccount,
            isShowHomePage: row.isShowHomePage,
            headNo: row.headNo,
            oooHomePageSortNo: row.oooHomePageSortNo,
            videoCoin: row.videoCoin,
            voiceCoin: row.voiceCoin,
            poster: row.poster,
            video: row.video,
            videoImg: row.videoImg,
            voice: row.voice,
          }

          //console.log("editAnthorRow=" + JSON.stringify(this.state.editAnthorRow))
          this.setState({showEditAnthorDialog:true })
        }                  
          
      })
     
  };
  


  getDisableAnthorInfo=(userid) => {
    //console.log("getDisableUserInfo 进入，userid="+userid);
    let { history } = this.props;
     var appUser = {
       userid : userid
     }
     appUserService.getDisableUserInfo(appUser)
     .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        //console.log("getDisableUserInfo res="+JSON.stringify(res));
        this.setState({showEnableAnthor2Dialog:true, enableAnthor2Row: res.retValue})
      }                  
       
     });
   }

  
  // ———————————————————————————— 主播认证 ——————————————————————————————————

  // 获取主播用户
  handleGetUserAuth=() =>{
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
       //console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var addTimeStart = null;
      var addTimeEnd = null;
      if(values.addTime != null) {
        addTimeStart = values.addTime[0].format('YYYY-MM-DD') + " 00:00:00";
        addTimeEnd = values.addTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var userAuthParam = {
        uid: values.uid,
        status: values.status,
        username: values.username,
        addTimeStart: addTimeStart,
        addTimeEnd: addTimeEnd,
        pageParam: pageParam,
      }

      userAuthService.getUserAuthPage(userAuthParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userAuthlist: res.retValue.list, userAuthtotal: res.retValue.total});
          }                  
        }
      )
       
    })
  }

  // 用户分页
  userAuthChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }
        
        var addTimeStart = null;
        var addTimeEnd = null;
        if(values.addTime != null) {
          addTimeStart = values.addTime[0].format('YYYY-MM-DD') + " 00:00:00";
          addTimeEnd = values.addTime[1].format('YYYY-MM-DD' + " 23:59:59");
        }
  
        var userAuthParam = {
          uid: values.uid,
          status: values.status,
          username: values.username,
          addTimeStart: addTimeStart,
          addTimeEnd: addTimeEnd,
          pageParam: pageParam,
        }

        this.setState({
          userAuthPageParam: userAuthParam,
        });

        userAuthService.getUserAuthPage(userAuthParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({userAuthlist: res.retValue.list, userAuthtotal: res.retValue.total});
            }                  
          }
        )

    });
  }

  // 关闭用户审核弹框
  hideUserAuthDialog=() => {
    this.setState({showUserAuthDialog: false});
  }

  // 关闭用户审核弹框
  hideUserAuthInfoDialog=() => {
    this.setState({showUserAuthInfoDialog: false});
  }

  // 刷新数据
  refreshUserAuthData=() => {
    //console.log("refreshUserAuthData进入");
    let { history } = this.props;
    

    var userAuthParam = {
      pageParam: this.state.userAuthPageParam,
    }

    userAuthService.getUserAuthPage(userAuthParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userAuthlist: res.retValue.list, userAuthtotal: res.retValue.total});
        }                  
      }
    )
  }


  /**
   * 获取用户审核详情数据
   * @param {*} options 
   */
  getUserAuthRowData = (row) => {
        let { history } = this.props;
        var userAuthParam = {
          uid: row.uid,
        }

        // 获取
        userAuthService.getUserAuthDetail(userAuthParam)
        .then((res) => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            if(res.retValue == null) {
              return ;
            }
            
            this.state.userAuthRow = res.retValue;

            //console.log("userAuthRow=" + JSON.stringify(this.state.userAuthRow))
            this.setState({showUserAuthDialog:true })
          }                  
            
        })
       
  };

    /**
   * 获取用户审核详情数据
   * @param {*} options 
   */
    getUserAuthInfoRowData = (row) => {
      let { history } = this.props;
      var userAuthParam = {
        uid: row.uid,
      }

      // 获取
      userAuthService.getUserAuthDetail(userAuthParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          
          this.state.userAuthRow = res.retValue;

          //console.log("userAuthRow=" + JSON.stringify(this.state.userAuthRow))
          this.setState({showUserAuthInfoDialog:true })
        }                  
          
      })
     
    };  


  // ———————————————————————————— 禁用主播 ——————————————————————————————————

  // 获取禁用用户
  handleGetDisableUserPage=() =>{
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var disableUserParam = {
        userId: values.userid,
        mobile: values.mobile,
        username: values.username,
        pid: values.pid,
        pageParam: pageParam,
        role: 1,
      }
      userForbiddenService.getDisableUserPage(disableUserParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({disableUserList: res.retValue.list, disableUserTotal: res.retValue.total});
          }                  
        }
      )
       
    })
  }


  // 用户分页
  disableUserChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var disableUserParam = {
          userId: values.userid,
          mobile: values.mobile,
          username: values.username,
          pid: values.pid,
          pageParam: pageParam,
          role: 1,
        }

        this.setState({
          disableUserPageParam: disableUserParam,
        });
        userForbiddenService.getDisableUserPage(disableUserParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({disableUserList: res.retValue.list, disableUserTotal: res.retValue.total});
            }                  
          }
        )
    });
  }


  // 刷新数据
  refreshAnthor2Data=() => {
      let { history } = this.props;
      //console.log("refreshAnthorData2进入");

      var disableUserParam = this.state.disableUserPageParam;
      userForbiddenService.getDisableUserPage(disableUserParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({disableUserList: res.retValue.list, disableUserTotal: res.retValue.total});
          }                  
        }
      )
  }

    // 启动禁用弹窗
    hideEnableAnthor2Dialog=() => {
      this.setState({showEnableAnthor2Dialog: false});
    }

  
  // ———————————————————————————— 主播分成方案 ——————————————————————————————————
  // 获取主播分成方案
  handleGetDealScalePlanPage=() =>{
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 20
      }

      var dealScalePlanParam = {
        planName: values.planName,
        pageParam: pageParam,
      }
      dealScalePlanService.getDealScalePlanPage(dealScalePlanParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({dealScalePlanList: res.retValue.list, dealScalePlanTotal: res.retValue.total});
          }                  
        }
      )
      
    })
  }


  // 用户分页
  dealScalePlanChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }
        
        var dealScalePlanParam = {
          planName: values.planName,
          pageParam: pageParam,
        }

        this.setState({
          dealScalePlanPageParam: dealScalePlanParam,
        });
        dealScalePlanService.getDealScalePlanPage(dealScalePlanParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({dealScalePlanList: res.retValue.list, dealScalePlanTotal: res.retValue.total});
            }                  
          }
        )
    });
  }

  // 刷新数据
  refreshDealScalePlanData=() => {
    //console.log("refreshDealScalePlanData进入");
    let { history } = this.props;

    var dealScalePlanParam = this.state.dealScalePlanPageParam;
    dealScalePlanService.getDealScalePlanPage(dealScalePlanParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({dealScalePlanList: res.retValue.list, dealScalePlanTotal: res.retValue.total});
        }                  
      }
    )
  }

  // 隐藏分成计划弹窗
  hideDealScalePlanDialog=() => {
    this.setState({showDealScalePlanDialog: false});
  }

  // 隐藏分成方案弹窗
  hideDealScaleDialog=() => {
    this.setState({showDealScaleDialog: false});
  }

  // 隐藏分成计划弹窗
  hideAddDealScalePlanDialog=() => {
    this.setState({showAddDealScalePlanDialog: false});
  }

  /**
   * 获取分成比例
   * @param {*} id 
   */
  getDealScaleRow=(id) => {
    //console.log("getDealScaleRow 进入，userid="+userid);
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    }
     var dealScaleParam = {
      planId : id,
      pageParam: pageParam,
     }
     dealScaleService.getDealScalePage(dealScaleParam)
     .then(res => {
        //console.log("getDealScaleRow res="+JSON.stringify(res));

        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          var rowData = {
            total: res.retValue.total,
            list: res.retValue.list,
            planId: id,
          }
          this.setState({showDealScaleDialog:true, enableDealScaleRow: rowData})
        }                  
       
     });
   }

  // 分成分页
  dealScaleChangePage=(dealScaleParam) => {
    //console.log("dealScaleChangePage 进入 dealScaleParam="+JSON.stringify(dealScaleParam));
    let { history } = this.props;
    dealScaleService.getDealScalePage(dealScaleParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        var rowData = {
            total: res.retValue.total,
            list: res.retValue.list,
            planId: dealScaleParam.planId,
        }
        this.setState({showDealScaleDialog:true, enableDealScaleRow: rowData})
      }                  
      
    });
  }

  // ———————————————————————————— 主播星级列表 ——————————————————————————————————
  // 获取主播分成方案
  handleGetAnchorStarPage=() =>{
    let { history } = this.props;
    this.formRef5.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 20
      }

      anchorStarService.getAnchorStarPage(pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({anchorStarList: res.retValue.list, anchorStarTotal: res.retValue.total});
          }                            
        }
      )
      
    })
  }


  // 用户分页
  anchorStarChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef5.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        this.setState({
          anchorStarPageParam: pageParam,
        });

        anchorStarService.getAnchorStarPage(pageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({anchorStarList: res.retValue.list, anchorStarTotal: res.retValue.total});
            }                            
          }
        )
    })
  }

  // 刷新数据
  refreshAnchorStarData=() => {
    //console.log("refreshAnchorStarData");
    let { history } = this.props;
    var pageParam = this.state.anchorStarPageParam;

    anchorStarService.getAnchorStarPage(pageParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({anchorStarList: res.retValue.list, anchorStarTotal: res.retValue.total});
        }                            
      }
    )
  }

  // 隐藏分成计划弹窗
  hideAnchorStarDialog=() => {
    this.setState({showAnchorStarDialog: false});
  }
 
  // ———————————————————————————— 主播资料审核开关 ——————————————————————————————————

 

  // ———————————————————————————— 主播资料审核 ——————————————————————————————————


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getAppUser();
    } else if(key == "2") {
      this.getUserAuth();
    } else if(key == "3") {
      this.getUserForbidden();
    } else if(key == "4") {
      this.getDealScalePlan();
    } else if(key == "5") {
      this.getAnchorStar();
    }
  }

  /**
   * 主播列表
   */
  getAppUser = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var anchorUserParam = {
      role: 1,
      pageParam: pageParam,
    }

    appUserService.getAnchorUserPage(anchorUserParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({anthorlist: res.retValue.list, anthortotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 主播认证
   */
  getUserAuth = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var userAuthParam = {
      pageParam: pageParam,
    }

    userAuthService.getUserAuthPage(userAuthParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userAuthlist: res.retValue.list, userAuthtotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 禁用主播
   */
  getUserForbidden = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var disableUserParam = {
      pageParam: pageParam,
      role: 1,
    }
    userForbiddenService.getDisableUserPage(disableUserParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({disableUserList: res.retValue.list, disableUserTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 主播分成方案
   */
  getDealScalePlan = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var dealScalePlanParam = {
      pageParam: pageParam,
    }
    dealScalePlanService.getDealScalePlanPage(dealScalePlanParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({dealScalePlanList: res.retValue.list, dealScalePlanTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 主播星级列表
   */
  getAnchorStar = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    anchorStarService.getAnchorStarPage(pageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({anchorStarList: res.retValue.list, anchorStarTotal: res.retValue.total});
          }                  
        }
      )
  }



 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/ordinary_users">主播用户</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            
            // ———————————————————— 主播列表 ——————————————————————
            <TabPane tab="主播列表" key="1">
              
              <Form
                name="anthorListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="UID" name="userid">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="手机号码" name="mobile">
                          <Input placeholder="手机号码" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="昵称" name="username">
                          <Input placeholder="昵称" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={5}>
                      <Form.Item label="账号状态" name="status">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>正常</Option>
                              <Option value={1}>禁用</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={6}>
                      <Form.Item label="在线状态" name="onlineStatus">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>离线</Option>
                              <Option value={1}>在线</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={8}>
                      <Form.Item label="上级id" name="pid">
                          <Input placeholder="上级id" />
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetAnchorUser} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                    <Button type="primary" onClick={this.getAddAnthorRowData}  >
                      + 新增主播
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.anthorlist}
                columns={this.state.anthorColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.anthortotal, defaultCurrent: 1, onChange: this.anthorChangePage}}
              >
              </Table>
              <AddAuthor rowData={this.state.addAnthorRow} close={this.hideAddAnthorDialog} visible={this.state.showAddAnthorDialog} refreshData={this.refreshAnthorData}></AddAuthor>
              <EditAuthor rowData={this.state.editAnthorRow} close={this.hideEditAnthorDialog} visible={this.state.showEditAnthorDialog} refreshData={this.refreshAnthorData}></EditAuthor>
              <ResetPwd rowData={this.state.resetPwdRow} close={this.hideResetPwdDialog} visible={this.state.showResetPwdDialog} ></ResetPwd>
              <DisableUser rowData={this.state.disableAnthorRow} close={this.hideDisableAnthorDialog} visible={this.state.showDisableAnthorDialog} refreshData={this.refreshAnthorData}></DisableUser>
              <EnableUser rowData={this.state.enableAnthorRow} close={this.hideEnableAnthorDialog} visible={this.state.showEnableAnthorDialog} refreshData={this.refreshAnthorData}></EnableUser>
              <UpdateRole rowData={this.state.updateRoleRow} close={this.hideUpdateRoleDialog} visible={this.state.showUpdateRoleDialog} refreshData={this.refreshAnthorData}></UpdateRole> 
            </TabPane>


            // ———————————————————— 主播认证 ————————————————————————————
            <TabPane tab="主播认证" key="2">
            
              <Form
                name="anthorAuthForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="用户UID" name="uid">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="认证审核状态" name="status">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1}>待审核</Option>
                              <Option value={2}>审核拒绝</Option>
                              <Option value={0}>审核通过</Option>
                              <Option value={-1}>资料提交中</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="提交时间" name="addTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetUserAuth} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userAuthlist}
                columns={this.state.userAuthColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.userAuthtotal, defaultCurrent: 1, onChange: this.userAuthChangePage}}
              >
              </Table>
              <UserAuth rowData={this.state.userAuthRow} close={this.hideUserAuthDialog} visible={this.state.showUserAuthDialog} refreshData={this.refreshUserAuthData}></UserAuth>
              <UserAuthInfo rowData={this.state.userAuthRow} close={this.hideUserAuthInfoDialog} visible={this.state.showUserAuthInfoDialog} refreshData={this.refreshUserAuthData}></UserAuthInfo>

              
            </TabPane>

            // —————————————————————— 禁用主播 ————————————————————————
            <TabPane tab="禁用主播" key="3">

              <Form
                name="disableUserListForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="UID" name="userid">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={2}>
                      <Form.Item label="手机号码" name="mobile">
                          <Input placeholder="手机号码" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="用户名" name="username">
                          <Input placeholder="用户名" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={8}>
                      <Form.Item label="上级id" name="pid">
                          <Input placeholder="上级id" />
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetDisableUserPage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE'}}
                dataSource={this.state.disableUserList}
                columns={this.state.disableUserColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.disableUserTotal, defaultCurrent: 1, onChange: this.disableUserChangePage}}
              >
              </Table>
              <EnableUser rowData={this.state.enableAnthor2Row} close={this.hideEnableAnthor2Dialog} visible={this.state.showEnableAnthor2Dialog} refreshData={this.refreshAnthor2Data}></EnableUser>

            </TabPane>

            // ———————————————— 主播分成方案 ————————————————
            <TabPane tab="主播分成方案" key="4">
              <Form
                name="dealScalePlanListForm"
                className="ant-advanced-search-form"
                ref={this.formRef4}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="方案名称" name="planName">
                          <Input placeholder="方案名称" />
                      </Form.Item>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetDealScalePlanPage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                    <Button 
                      onClick={() => this.setState({showAddDealScalePlanDialog: true})}
                      style={{marginRight:'15px'}} 
                      type="primary">+添加
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE'}}
                dataSource={this.state.dealScalePlanList}
                columns={this.state.dealScalePlanColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.dealScalePlanTotal, defaultCurrent: 1, onChange: this.dealScalePlanChangePage}}
              >
              </Table>
              <UpdateDealScalePlan rowData={this.state.enableDealScalePlanRow} close={this.hideDealScalePlanDialog} visible={this.state.showDealScalePlanDialog} refreshData={this.refreshDealScalePlanData}></UpdateDealScalePlan>
              <UpdateDealScale rowData={this.state.enableDealScaleRow} close={this.hideDealScaleDialog} visible={this.state.showDealScaleDialog} refreshData={this.dealScaleChangePage}></UpdateDealScale>
              <AddDealScalePlan  close={this.hideAddDealScalePlanDialog} visible={this.state.showAddDealScalePlanDialog} refreshData={this.refreshDealScalePlanData}></AddDealScalePlan>

            </TabPane>

            // —————————————————— 主播星级列表 ————————————————————
            <TabPane tab="主播星级列表" key="5">

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '20px'}}
                dataSource={this.state.anchorStarList}
                columns={this.state.anchorStarColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.anchorStarTotal, defaultCurrent: 1, onChange: this.anchorStarChangePage}}
              >
              </Table>
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default HostUser;