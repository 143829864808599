import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取企信用户
   * @param {*} param
   * @returns
   */
  getQxUserPage(param) {
      //console.log("param=" + JSON.stringify(param));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/qxUser/getQxUserPage",
        data: param,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新企信用户
   * @param {*} param
   * @returns
   */
  insertOrUpdateQxUser(param) {
        //console.log("param=" + JSON.stringify(param));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/qxUser/insertOrUpdateQxUser",
        data: param,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

  /**
   * 删除企信用户
   * @returns 
   */
  deleteQxUser(param) {
    //console.log("vipParam=" + JSON.stringify(vipParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/qxUser/deleteQxUser",
      data: param,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}