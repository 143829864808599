import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 更新版本
   * @param {*} adminVersionManage
   * @returns
   */
   updateConfig(adminVersionManage) {
      //console.log("dynamicAppeal=" + JSON.stringify(adminVersionManage));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/versionManageHmy/updateConfig",
        data: adminVersionManage,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取配置
   * @param {*} 
   * @returns
   */
   getConfig(versionManageParam) {
    //console.log("versionManageParam=" + JSON.stringify(versionManageParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/versionManageHmy/getConfig",
      data: versionManageParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}