import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取用户反馈
   * @param {*} signInParam
   * @returns
   */
   getSignInPage(signInParam) {
      //console.log("signInParam=" + JSON.stringify(signInParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/signIn/getSignInPage",
        data: signInParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新用户反馈
   * @param {*} appSignIn
   * @returns
   */
   insertOrUpdateSignIn(appSignIn) {
        //console.log("appSignIn=" + JSON.stringify(appSignIn));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/signIn/insertOrUpdateSignIn",
        data: appSignIn,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除用户反馈
     * @param {*} signInParam 
     * @returns 
     */
     deleteSignIn(signInParam) {
        //console.log("feedBackParam=" + JSON.stringify(signInParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/signIn/deleteSignIn",
        data: signInParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}