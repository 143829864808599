import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select } from 'antd';
import appUserService from '../../../Service/UserMgr/appUserService';
import { Logout } from '../../../Common/Auth';

class ResetPwd extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
      
          let appUser = values;
          appUserService.updateUserPwd(appUser)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
              }
          })
      })
  }


  render () {
    return (
      <Modal
        title="重置密码"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="userid" name="userid"  hidden>
                <Input placeholder="userid" />
            </Form.Item>

            <Form.Item label="用户密码" name="password" rules={ [
                    {
                        pattern: /\w{6,20}/gi,
                        message: '请输入6-20个字符!',
                    },
                    {
                        required: true,
                        message: '请输入密码!',
                    }
                    ]
                }>
                <Input.Password placeholder="密码"/>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default ResetPwd