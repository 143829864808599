import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import nobLiveGiftService from '../../../Service/GradeMgr/nobLiveGiftService';
import giftAmountSettingService from '../../../Service/GiftMgr/giftAmountSettingService';
import adminLiveConfigService from '../../../Service/UserMgr/adminLiveConfigService';


import AddGiftList from './AddGiftList';
import UpdateGiftList from './UpdateGiftList';
import AddGiftAmountSetting from './AddGiftAmountSetting';
import UpdateGiftAmountSetting from './UpdateGiftAmountSetting';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class GiftSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  state={
    // ———————————————————— 礼物列表 ————————————————————————————
    giftListColumns: [{
      key: 'id',
      title: '礼物ID',
      dataIndex: 'id'
    },{
      key: 'orderno',
      title: '排序',
      dataIndex: 'orderno'
    },{
      key: 'giftname',
      title: '礼物名称',
      dataIndex: 'giftname'
    },{
      key: 'type',
      title: '礼物类型',
      dataIndex: 'type'
    },{
      key: 'mark',
      title: '礼物标识',
      dataIndex: 'mark'
    },{
      key: 'needcoin',
      title: '价格(金币)',
      dataIndex: 'needcoin'
    },{
      key: 'gifticon',
      title: '礼物图标',
      dataIndex: 'gifticon',
      render: (gifticon, row) => {
        if(gifticon != null && gifticon != "") {
          return <Image
          width={55}
          height={55}
          src={gifticon}
        />
        }
      }
    },{
      key: 'swf',
      title: '动画地址',
      dataIndex: 'swf',
      render: (swf, row) => {
        if(swf != null && swf != "") {
          if(swf.includes(".svga")) {
            return ".svga";
          } else {
            return <Image
                width={55}
                height={55}
                src={swf}
              />
          }
        }
      }
    },{
      key: 'swftime',
      title: '动画时长',
      dataIndex: 'swftime'
    },{
      key: 'status',
      title: '是否开启',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 0) {
          return "显示"
        } else if(status === 1) {
          return "不显示";
        } 
      }
    },{
      key: 'addtime',
      title: '发布时间',
      dataIndex: 'addtime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateGiftListDialog:true, updateGiftListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteGiftList(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    giftListTotal: 0,
    giftListList: [],
    updateGiftListRow: null,
    showUpdateGiftListDialog: false,
    showAddGiftListDialog:false,
    giftListPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 送礼物设置 ————————————————————————————
    
    giftAmountSettingColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'serialNumber',
      title: '排序值',
      dataIndex: 'serialNumber'
    },{
      key: 'numberOfGifts',
      title: '数量',
      dataIndex: 'numberOfGifts'
    },{
      key: 'numberDescription',
      title: '数量描述',
      dataIndex: 'numberDescription'
    },{
      key: 'createTime',
      title: '创建时间',
      dataIndex: 'createTime'
    },{
      key: 'updateTime',
      title: '更新时间',
      dataIndex: 'updateTime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateGiftAmountSettingDialog:true, updateGiftAmountSettingRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteGiftAmountSetting(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    giftAmountSettingTotal: 0,
    giftAmountSettingList: [],
    updateGiftAmountSettingRow: null,
    showUpdateGiftAmountSettingDialog: false,
    showAddGiftAmountSettingDialog:false,
    giftAmountSettingPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
    
    // ———————————————————— 幸运礼物设置 ————————————————————————————
    luckyGiftSettings: null,
   
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 礼物列表 ————————————————————————————
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var giftParam={
        pageParam: pageParam
      }

      nobLiveGiftService.getGiftPage(giftParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({giftListList: res.retValue.list, giftListTotal: res.retValue.total});
          }    
        }
      )


    // ———————————————————— 送礼物设置 ————————————————————————————

    var giftAmountSettingParam={
      pageParam: pageParam
    }

    giftAmountSettingService.getGiftAmountSettingPage(giftAmountSettingParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({giftAmountSettingList: res.retValue.list, giftAmountSettingTotal: res.retValue.total});
        }    
      }
    )

    // ———————————————————— 幸运礼物设置 ————————————————————————————

    adminLiveConfigService.getLuckyGift()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({luckyGiftSettings: res.retValue});
        }    
      }
    )
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 礼物列表 ————————————————————————————

  // 关闭更新
  hideUpdateGiftListDialog=() => {
    this.setState({showUpdateGiftListDialog: false});
  }

  // 关闭添加
  hideAddGiftListDialog=() => {
    this.setState({showAddGiftListDialog: false});
  }


  // 获取列表
  handleGetGiftListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var giftParam={
        id: values.id,
        giftname: values.giftname,
        type: values.type,
        pageParam: pageParam
      }

      nobLiveGiftService.getGiftPage(giftParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({giftListList: res.retValue.list, giftListTotal: res.retValue.total});
          }    
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  giftListChangePage=(page, pageSize)=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }
    
    this.setState({
      giftListPageParam: pageParam,
    });

    var giftParam={
      pageParam: pageParam
    }

    nobLiveGiftService.getGiftPage(giftParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({giftListList: res.retValue.list, giftListTotal: res.retValue.total});
        }    
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshGiftListData=() => {
      let { history } = this.props;

      var giftParam={
        pageParam: this.state.giftListPageParam
      }
  
      nobLiveGiftService.getGiftPage(giftParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({giftListList: res.retValue.list, giftListTotal: res.retValue.total});
          }    
        }
      )
  }

  // 根据Id删除礼物
  deleteGiftList=(id) => {
    let { history } = this.props;
    let giftParam = {
      id : id
    };
    nobLiveGiftService.deleteGift(giftParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("删除失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshGiftListData();
        message.success('删除成功！');
      }    
      
    })

  }

  // ———————————————————— 送礼物设置 ————————————————————————————

  // 关闭更新
  hideUpdateGiftAmountSettingDialog=() => {
    this.setState({showUpdateGiftAmountSettingDialog: false});
  }

  // 关闭添加
  hideAddGiftAmountSettingDialog=() => {
    this.setState({showAddGiftAmountSettingDialog: false});
  }

  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  giftAmountSettingChangePage=(page, pageSize)=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      giftAmountSettingPageParam: pageParam,
    });

    var giftAmountSettingParam={
      pageParam: pageParam
    }

    giftAmountSettingService.getGiftAmountSettingPage(giftAmountSettingParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({giftAmountSettingList: res.retValue.list, giftAmountSettingTotal: res.retValue.total});
        }    
      }
    )
  }

  /**
   * 刷新送礼物设置
   */
  refreshGiftAmountSettingData=() => {
      let { history } = this.props;

      var giftAmountSettingParam={
        pageParam: this.state.giftAmountSettingPageParam
      }
  
      giftAmountSettingService.getGiftAmountSettingPage(giftAmountSettingParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({giftAmountSettingList: res.retValue.list, giftAmountSettingTotal: res.retValue.total});
          }    
        }
      )
  }

  // 根据Id删除
  deleteGiftAmountSetting=(id) => {
    let { history } = this.props;
    let giftAmountSettingParam = {
      id : id
    };
    giftAmountSettingService.deleteGiftAmountSetting(giftAmountSettingParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshGiftAmountSettingData();
        message.success('删除成功！');
      }    
    })

  }


  /**
   * 幸运礼物设置
   */
   handleLuckyGiftSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {

        var luckyGiftParam = values;
        adminLiveConfigService.updateLuckyGift(luckyGiftParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getNobLiveGift();
    } else if(key == "2") {
      this.getGiftAmountSetting();
    } else if(key == "3") {
      this.getAdminLiveConfig();
    }
  }

  /**
   * 礼物列表
   */
  getNobLiveGift = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var giftParam={
      pageParam: pageParam
    }

    nobLiveGiftService.getGiftPage(giftParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({giftListList: res.retValue.list, giftListTotal: res.retValue.total});
        }    
      }
    )
  }

  /**
   * 送礼物设置
   */
  getGiftAmountSetting = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var giftAmountSettingParam={
      pageParam: pageParam
    }

    giftAmountSettingService.getGiftAmountSettingPage(giftAmountSettingParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({giftAmountSettingList: res.retValue.list, giftAmountSettingTotal: res.retValue.total});
        }    
      }
    )
  }

  /**
   * 幸运礼物设置
   */
  getAdminLiveConfig = () => {
    let { history } = this.props;
    adminLiveConfigService.getLuckyGift()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {

          this.formRef3.current.setFieldsValue({
            isEnableLuckyGift: res.retValue.isEnableLuckyGift,
            luckyGiftPerc: res.retValue.luckyGiftPerc,
          });

          this.setState({luckyGiftSettings: res.retValue});
        }    
      }
    )
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/gift_settings">礼物设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 礼物列表 ————————————————————————————
            <TabPane tab="礼物列表" key="1">
              <Form
                name="GiftListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="礼物ID" name="id">
                          <Input placeholder="礼物ID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="礼物名称" name="giftname">
                          <Input placeholder="礼物名称" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="礼物类型" name="type">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>普通礼物</Option>
                              <Option value={1}>粉丝团礼物</Option>
                              <Option value={2}>守护礼物</Option>
                              <Option value={3}>贵族礼物</Option>
                              <Option value={4}>签到礼物</Option>
                              <Option value={5}>幸运礼物</Option>
                          </Select>
                      </Form.Item>
                    </Col>

                    <Col span={5} key={4} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetGiftListPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddGiftListDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.giftListList}
                columns={this.state.giftListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.giftListTotal, defaultCurrent: 1, onChange: this.giftListChangePage}}
              >
              </Table>
              <AddGiftList close={this.hideAddGiftListDialog} visible={this.state.showAddGiftListDialog} refreshData={this.refreshGiftListData}></AddGiftList>
              <UpdateGiftList rowData={this.state.updateGiftListRow} close={this.hideUpdateGiftListDialog} visible={this.state.showUpdateGiftListDialog} refreshData={this.refreshGiftListData}></UpdateGiftList>
              
            </TabPane>
            
            // ———————————————————— 送礼物设置 ——————————————————————
            <TabPane tab="送礼物设置" key="2">
              <Form
                name="GiftAmountSettingForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={5} key={4} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddGiftAmountSettingDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.giftAmountSettingList}
                columns={this.state.giftAmountSettingColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.giftAmountSettingTotal, defaultCurrent: 1, onChange: this.giftAmountSettingChangePage}}
              >
              </Table>
              <AddGiftAmountSetting close={this.hideAddGiftAmountSettingDialog} visible={this.state.showAddGiftAmountSettingDialog} refreshData={this.refreshGiftAmountSettingData}></AddGiftAmountSetting>
              <UpdateGiftAmountSetting rowData={this.state.updateGiftAmountSettingRow} close={this.hideUpdateGiftAmountSettingDialog} visible={this.state.showUpdateGiftAmountSettingDialog} refreshData={this.refreshGiftAmountSettingData}></UpdateGiftAmountSetting>
              
            
            </TabPane>

            // ———————————————————— 幸运礼物设置 ——————————————————————
            <TabPane tab="幸运礼物设置" key="3">
              <Form
                name="LuckyGiftForm"
                layout="horizontal"
                labelCol={{span:4}}
                wrapperCol={{span: 20}}
                ref={this.formRef3}
                style={{ margin: '20px 0px 0px 100px'}}
                initialValues={this.state.luckyGiftSettings}
              >

                    <Form.Item label="是否开启幸运礼物" name="isEnableLuckyGift" labelCol={{span:10}}  style={{ width: 450 }}>
                      <Radio.Group name="isEnableLuckyGift" defaultValue={0}>
                        <Radio value={0}>不开启</Radio>
                        <Radio value={1}>开启</Radio>
                      </Radio.Group>
                    </Form.Item>

                    <Form.Item label="幸运礼物计算比例" name="luckyGiftPerc" labelCol={{span:10}}  style={{ width: 450 }}>
                      <Input />
                    </Form.Item>

              </Form>

              <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                <span onClick={ this.handleLuckyGiftSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
              </div>
            
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default GiftSettings;