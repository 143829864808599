import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取鉴黄记录
   * @param {*} 
   * @returns
   */
   getViolationRecordsPage(violationRecordsParam) {
    //console.log("iolationRecordsParam=" + JSON.stringify(violationRecordsParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/violationRecords/getViolationRecordsPage",
      data: violationRecordsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}