import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import shortVideoService from "../../../Service/ShortVideo/shortVideoService";
import hotSortService from "../../../Service/ShortVideo/hotSortService";
import VideoPlay from '../../UserMgr/HostUser/VideoPlay';


import AddVirtualAdsShortVideo from './AddVirtualAdsShortVideo';
import UpdateVirtualAdsShortVideo from './UpdateVirtualAdsShortVideo';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class VideoAds extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state={
    // ———————————————————— 虚拟广告短视频 ————————————————————————————

    virtualAdsShortVideoColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "thumb",
        title: "封页图",
        dataIndex: "thumb",
        render: (thumb, row) => {
          if (thumb != null && thumb != "") {
            return <Image width={55} height={65} src={thumb} />;
          }
        },
      },
      {
        key: "content",
        title: "广告标题",
        dataIndex: "content",
      },
      {
        key: 'videoUrl',
        title: '视频',
        dataIndex: 'videoUrl',
        render: (videoUrl, row) => {
          if(videoUrl != null && videoUrl != "") {
  
            return <Button type="primary" 
                            onClick={() => this.handlePlayVideo(videoUrl)}
                            style={{ marginRight: '15px' }}>
                  观看
                </Button>
          }
        }
      },
      {
        key: "classifyName",
        title: "分类",
        dataIndex: "classifyName",
      },
      {
        key: "type",
        title: "类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "视频"
          } else if(type === 2) {
            return "图片";
          } 
        }
      },
      {
        key: "addtime",
        title: "发布时间",
        dataIndex: "addtime",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button 
                //onClick={() => this.setState({showUpdateVirtualAdsShortVideoDialog:true, updateVirtualAdsShortVideoRow: row })}
                onClick={() => this.getUpdateVirtualAdsShortVideoRowData(row)} 
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteVirtualAdsShortVideo(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          );
        },
      },
    ],
    virtualAdsShortVideoTotal: 0,
    virtualAdsShortVideoList: [],
    updateVirtualAdsShortVideoRow: null,
    showUpdateVirtualAdsShortVideoDialog: false,
    addVirtualAdsShortVideoRow: null,
    showAddVirtualAdsShortVideoDialog: false,
    virtualAdsShortVideoPageParam: {
      virtualOrNot: 1,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },
    videoRow: null,
    showVideoDialog: false,

    // ———————————————————— 短视频配置 ————————————————————————————
    videoSetting: null,
  

    // ———————————————————— 广告短视频设置 ————————————————————————————
    adsVideoSetting: null,


  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 短视频分类 ————————————————————————————
    this.handleGetVirtualAdsShortVideoPagePage();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 短视频分类 ————————————————————————————

  // 关闭添加用户弹框
  hideAddVirtualAdsShortVideoDialog=() => {
    this.setState({showAddVirtualAdsShortVideoDialog: false});
  }

  // 关闭编辑用户弹框
  hideUpdateVirtualAdsShortVideoDialog=() => {
    this.setState({showUpdateVirtualAdsShortVideoDialog: false});
  }

  // 根据Id删除
  deleteVirtualAdsShortVideo=(id) => {
      let { history } = this.props;
      let appShortVideo = {
        id : id
      };
      shortVideoService.deleteShortVideo(appShortVideo)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshVirtualAdsShortVideoData();
          message.success('删除成功！');
        }                  
        
      })
  }

  // 分页获取
  handleGetVirtualAdsShortVideoPagePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var appShortVideo={
        virtualOrNot: 1,
        pageParam: pageParam
      }
  
      shortVideoService.getShortVideoPage(appShortVideo)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({virtualAdsShortVideoList: res.retValue.list, virtualAdsShortVideoTotal: res.retValue.total});
        }                  
        
      });
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  virtualAdsShortVideoChangePage(page, pageSize) {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    var appShortVideo={
      virtualOrNot: 1,
      pageParam: pageParam
    }

    this.setState({
      virtualAdsShortVideoPageParam: appShortVideo,
    });

    shortVideoService.getShortVideoPage(appShortVideo)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({virtualAdsShortVideoList: res.retValue.list, virtualAdsShortVideoTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新导航数据
   */
  refreshVirtualAdsShortVideoData=() => {
      let { history } = this.props;

      var appShortVideo = this.state.virtualAdsShortVideoPageParam;

      shortVideoService.getShortVideoPage(appShortVideo)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({virtualAdsShortVideoList: res.retValue.list, virtualAdsShortVideoTotal: res.retValue.total});
          }
        }
      )
  }

  /**
   * 获取添加基础数据
   * @param {*} options 
   */
  getAddVirtualAdsShortVideoRowData = () => {
      let { history } = this.props;
      // 获取
      var hotSortParam={
        type: 6,
      }
      hotSortService.getHotSortList(hotSortParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          
          this.state.addVirtualAdsShortVideoRow = {
            classifyList: res.retValue
          }

          //console.log("addGiftPackRow=" + JSON.stringify(this.state.addGiftPackRow))
          this.setState({showAddVirtualAdsShortVideoDialog:true})
        }                  
          
      }) 
  };

  /**
   * 播放视频
   */
  handlePlayVideo=(href) => {

    //console.log("href=" + href)
    this.setState({videoRow: {
        videoUrl : href ,
    }});
    this.setState({showVideoDialog:true })
  }

  // 关闭弹窗
  hideVideoDialog=() => {
    this.setState({showVideoDialog: false});
  }


  /**
   * 获取更新基础数据
   * @param {*} options 
   */
  getUpdateVirtualAdsShortVideoRowData = (row) => {
      let { history } = this.props;
      // 获取
      var hotSortParam={
        type: 6,
      }
      hotSortService.getHotSortList(hotSortParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          if(res.retValue == null) {
            return ;
          }
          
          this.setState({updateVirtualAdsShortVideoRow:{
            classifyList: res.retValue,
            id: row.id,
            type: row.type,
            content: row.content,
            userId: row.userId,
            thumb: row.thumb,
            videoUrl: row.videoUrl,
            videoTime: row.videoTime,
            likes: row.likes,
            looks: row.looks,
            fees: row.fees,
            comments: row.comments,
            classifyId: row.classifyId,
          }})

          //console.log("addGiftPackRow=" + JSON.stringify(this.state.addGiftPackRow))
          this.setState({showUpdateVirtualAdsShortVideoDialog:true})
        }                  
          
      }) 
  };

// ———————————————————— 短视频设置 ————————————————————————————




// —————————————————————— 广告短视频设置 ————————————————————————




 
  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.handleGetVirtualAdsShortVideoPagePage();
    } else if(key == "2") {
      this.getAdsVideoSetting();
    } else if(key == "3") {
      this.getAdsVideoSetting();
    } 
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/video_ads">短视频设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 虚拟广告短视频 ————————————————————————————
            <TabPane tab="虚拟广告短视频" key="1">
              <Form
                name="virtualAdsShortVideoForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '10px 0px 0px 100px'}}
              >
                <Row gutter={24}>

                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.getAddVirtualAdsShortVideoRowData()}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.virtualAdsShortVideoList}
                columns={this.state.virtualAdsShortVideoColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.virtualAdsShortVideoTotal, defaultCurrent: 1, onChange: this.virtualAdsShortVideoChangePage}}
              >
              </Table>
              <AddVirtualAdsShortVideo rowData={this.state.addVirtualAdsShortVideoRow} close={this.hideAddVirtualAdsShortVideoDialog} visible={this.state.showAddVirtualAdsShortVideoDialog} refreshData={this.refreshVirtualAdsShortVideoData}></AddVirtualAdsShortVideo>
              <UpdateVirtualAdsShortVideo rowData={this.state.updateVirtualAdsShortVideoRow} close={this.hideUpdateVirtualAdsShortVideoDialog} visible={this.state.showUpdateVirtualAdsShortVideoDialog} refreshData={this.refreshVirtualAdsShortVideoData}></UpdateVirtualAdsShortVideo>
              <VideoPlay rowData={this.state.videoRow} close={this.hideVideoDialog} visible={this.state.showVideoDialog}></VideoPlay>

            </TabPane>
            
            // ———————————————————— 首页图标广告设置 ——————————————————————
            <TabPane tab="首页图标广告设置" key="2">
       
            
            </TabPane>

            // —————————————————————— 短视频广告设置 ————————————————————————
            <TabPane tab="短视频广告设置" key="3">
             
                
              
            </TabPane>

            // —————————————————————— 短视频广告模板设置 ————————————————————————
            <TabPane tab="短视频广告模板设置" key="4">
             
                
              
            </TabPane>



          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default VideoAds;