import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {
  /**
   * 获取分页
   * @param {*} adminUserCashrecord 
   * @returns 
   */
  getAdminUserCashrecordPage(adminUserCashrecord) {

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/adminUserCashrecord/getAdminUserCashrecordPage",
      data: adminUserCashrecord,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入
   * @param {*} adminUserCashrecord 
   * @returns 
   */
  insertAdminUserCashrecord(adminUserCashrecord) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/adminUserCashrecord/insertAdminUserCashrecord",
      data: adminUserCashrecord,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 更新审核信息
   * @param {*} adminUserCashrecord 
   * @returns 
   */
  updateAuditStatus(adminUserCashrecord) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/adminUserCashrecord/updateAuditStatus",
      data: adminUserCashrecord,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
};
