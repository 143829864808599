import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取充值明细
   * @param {*} 
   * @returns
   */
   getUsersChargePage(usersChargeParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/usersCharge/getUsersChargePage",
      data: usersChargeParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入充值记录
   * @param {*} 
   * @returns
   */
  insertUsersCharge(appUsersCharge) {
      //console.log("commonWordsParam=" + JSON.stringify());
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/usersCharge/insertUsersCharge",
        data: appUsersCharge,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
  },
}