import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import chargeRulesService from "../../../Service/FinanceMgr/chargeRulesService";
import ticketExchangeRuleService from "../../../Service/FinanceMgr/ticketExchangeRuleService";
import starPriceService from "../../../Service/FinanceMgr/starPriceService";
import anchorStarService from "../../../Service/UserMgr/anchorStarService";
import silverExchangeRuleService from "../../../Service/FinanceMgr/silverExchangeRuleService";



import AddTicketToCoin from './AddTicketToCoin';
import UpdateTicketToCoin from './UpdateTicketToCoin';
import AddWeChatPrice from './AddWeChatPrice';
import UpdateWeChatPrice from './UpdateWeChatPrice';
import AddVideoPrice from './AddVideoPrice';
import UpdateVideoPrice from './UpdateVideoPrice';
import AddVoicePrice from './AddVoicePrice';
import UpdateVoicePrice from './UpdateVoicePrice';
import AddSilverToCoin from './AddSilverToCoin';
import UpdateSilverToCoin from './UpdateSilverToCoin';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class PriceSetting extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state = {
    starRow: null,
    // ———————————————————— 映票兑换金币设置 ————————————————————————————

    ticketToCoinColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "ticketNum",
        title: "映票数量",
        dataIndex: "ticketNum",
      },
      {
        key: "coinNum",
        title: "金币数量",
        dataIndex: "coinNum",
      },
      {
        key: "sortNum",
        title: "排序",
        dataIndex: "sortNum",
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateTicketToCoinDialog:true, updateTicketToCoinRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteTicketToCoin(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    ticketToCoinTotal: 0,
    ticketToCoinList: null,
    updateTicketToCoinRow: null,
    showUpdateTicketToCoinDialog: false,
    showAddTicketToCoinDialog:false,
    ticketToCoinPageParam:{
      pageNum: 1,
      pageSize: 10,
    },


    // ———————————————————— 微信号价格列表 ————————————————————————————
    
    weChatPriceColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "starName",
        title: "星级名称",
        dataIndex: "starName",
        render: (starName, row) => {
            return starName;
        }
      },
      {
        key: "price",
        title: "微信号价格（金币）",
        dataIndex: "price",
      },
      {
        key: "status",
        title: "状态",
        dataIndex: "status",
        render: (status, row) => {
          if(status === 0) {
            return "不启用"
          } else if(status === 1) {
            return "启用";
          } 
        }
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => {
                  this.getStarRowByUpdateWeChatPrice(row);
                }}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteWeChatPrice(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    weChatPriceTotal: 0,
    weChatPriceList: null,
    updateWeChatPriceRow: null,
    showUpdateWeChatPriceDialog: false,
    showAddWeChatPriceDialog:false,
    weChatPricePageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 视频通话价格列表 ————————————————————————————

    videoPriceColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "starName",
        title: "星级名称",
        dataIndex: "starName",
        render: (starName, row) => {
            return starName;
        }
      },
      {
        key: "price",
        title: "视频通话价格（金币/min）",
        dataIndex: "price",
      },
      {
        key: "status",
        title: "状态",
        dataIndex: "status",
        render: (status, row) => {
          if(status === 0) {
            return "不启用"
          } else if(status === 1) {
            return "启用";
          } 
        }
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => {this.getStarRowByUpdateVideoPrice(row)}}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteVideoPrice(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    videoPriceTotal: 0,
    videoPriceList: null,
    updateVideoPriceRow: null,
    showUpdateVideoPriceDialog: false,
    showAddVideoPriceDialog:false,
    videoPricePageParam:{
      pageNum: 1,
      pageSize: 10,
    },
    
    // ———————————————————— 语音通话价格列表 ————————————————————————————

    voicePriceColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "starName",
        title: "星级名称",
        dataIndex: "starName",
        render: (starName, row) => {
            return starName;
        }
      },
      {
        key: "price",
        title: "语音通话价格（金币/min）",
        dataIndex: "price",
      },
      {
        key: "status",
        title: "状态",
        dataIndex: "status",
        render: (status, row) => {
          if(status === 0) {
            return "不启用"
          } else if(status === 1) {
            return "启用";
          } 
        }
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => {this.getStarRowByUpdateVoicePrice(row)}}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteVoicePrice(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    voicePriceTotal: 0,
    voicePriceList: null,
    updateVoicePriceRow: null,
    showUpdateVoicePriceDialog: false,
    showAddVoicePriceDialog:false,
    voicePricePageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 银币兑换金币设置 ————————————————————————————

    silverToCoinColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "silverNum",
        title: "银币数量",
        dataIndex: "silverNum",
      },
      {
        key: "coinNum",
        title: "金币数量",
        dataIndex: "coinNum",
      },
      {
        key: "sortNum",
        title: "排序",
        dataIndex: "sortNum",
      },
      {
        key: 'del',
        title: '编辑',
        dataIndex: 'del',
        render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateSilverToCoinDialog:true, updateSilverToCoinRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteSilverToCoin(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
        }
      }
    ],
    silverToCoinTotal: 0,
    silverToCoinList: null,
    updateSilverToCoinRow: null,
    showUpdateSilverToCoinDialog: false,
    showAddSilverToCoinDialog:false,
    silverToCoinPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 映票兑换金币设置 ————————————————————————————
    this.getTicketExchangeRule();
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }




  // ———————————————————— 映票兑换金币设置 ————————————————————————————
  
  // 分页
  ticketToCoinChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        ticketToCoinPageParam: pageParam,
      });

      var ticketExchangeRuleParam = {
        pageParam: pageParam,
      };
  
      ticketExchangeRuleService.getTicketExchangeRulePage(ticketExchangeRuleParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            ticketToCoinList: res.retValue.list,
            ticketToCoinTotal: res.retValue.total,
          });
        }
      });
    });
  };

  // 刷新数据
  refreshTicketToCoinData = () => {
    //console.log("refreshTicketToCoinData");
    let { history } = this.props;

    var ticketExchangeRuleParam = {
      pageParam: this.state.ticketToCoinPageParam,
    };

    ticketExchangeRuleService.getTicketExchangeRulePage(ticketExchangeRuleParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          ticketToCoinList: res.retValue.list,
          ticketToCoinTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateTicketToCoinDialog=() => {
    this.setState({showUpdateTicketToCoinDialog: false});
  }

  // 关闭添加
  hideAddTicketToCoinDialog=() => {
    this.setState({showAddTicketToCoinDialog: false});
  }

  // 关闭添加
  hideUpdateAndroidTextDescriptionDialog=() => {
    this.setState({showUpdateAndroidTextDescriptionDialog: false});
  }

  // 根据Id删除
  deleteTicketToCoin=(id) => {
    let { history } = this.props;
    let ticketExchangeRuleParam = {
      id : id
    };
    ticketExchangeRuleService.deleteTicketExchangeRule(ticketExchangeRuleParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshTicketToCoinData();
        message.success('删除成功！');
      }
      
    })

  }


  // ———————————————————— 微信号价格列表 ————————————————————————————
  
  // 分页
  weChatPriceChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        weChatPricePageParam: pageParam,
      });
      
      var starPriceParam = {
        type: 2,
        pageParam: pageParam,
      };
  
      starPriceService.getStarPricePage(starPriceParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            weChatPriceList: res.retValue.list,
            weChatPriceTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshWeChatPriceData = () => {
    let { history } = this.props;

      var starPriceParam = {
        type: 2,
        pageParam: this.state.weChatPricePageParam,
      };

      starPriceService.getStarPricePage(starPriceParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            weChatPriceList: res.retValue.list,
            weChatPriceTotal: res.retValue.total,
          });
        }
      });
  };


  // 关闭更新
  hideUpdateWeChatPriceDialog=() => {
    this.setState({showUpdateWeChatPriceDialog: false});
  }

  // 关闭添加
  hideAddWeChatPriceDialog=() => {
    this.setState({showAddWeChatPriceDialog: false});
  }

  // 关闭添加
  hideUpdateAndroidTextDescriptionDialog=() => {
    this.setState({showUpdateAndroidTextDescriptionDialog: false});
  }

  // 根据Id删除
  deleteWeChatPrice=(id) => {
    let { history } = this.props;
    let starPriceParam = {
      id : id
    };
    starPriceService.deleteStarPrice(starPriceParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshWeChatPriceData();
        message.success('删除成功！');
      }
    
    })

  }


  getStarRowByUpdateWeChatPrice = (row) => {
    let { history } = this.props;

    // 获取星级列表
    anchorStarService.getAnchorStarList().then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.starRow = {
          starList: res.retValue,
          data: row
        };
        this.setState({ showUpdateWeChatPriceDialog: true });
      }    
      
    });
  };

  getStarRowByAddWeChatPrice = () => {
    let { history } = this.props;

    // 获取星级列表
    anchorStarService.getAnchorStarList().then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.starRow = {
          starList: res.retValue,
        };
        this.setState({ showAddWeChatPriceDialog: true });
      }    
      
    });
  };

  // ———————————————————— 视频通话价格列表 ————————————————————————————

   // 分页
   videoPriceChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        videoPricePageParam: pageParam,
      });

      var starPriceParam = {
        type: 5,
        pageParam: pageParam,
      };
  
      starPriceService.getStarPricePage(starPriceParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            videoPriceList: res.retValue.list,
            videoPriceTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshVideoPriceData = () => {
    //console.log("refreshVideoPriceData");
    let { history } = this.props;

    var starPriceParam = {
      type: 5,
      pageParam: this.state.videoPricePageParam,
    };

    starPriceService.getStarPricePage(starPriceParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          videoPriceList: res.retValue.list,
          videoPriceTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateVideoPriceDialog=() => {
    this.setState({showUpdateVideoPriceDialog: false});
  }

  // 关闭添加
  hideAddVideoPriceDialog=() => {
    this.setState({showAddVideoPriceDialog: false});
  }

  // 关闭添加
  hideUpdateAndroidTextDescriptionDialog=() => {
    this.setState({showUpdateAndroidTextDescriptionDialog: false});
  }

  // 根据Id删除
  deleteVideoPrice=(id) => {
    let { history } = this.props;
    let starPriceParam = {
      id : id
    };
    starPriceService.deleteStarPrice(starPriceParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshVideoPriceData();
        message.success('删除成功！');
      }
      
    })

  }


  getStarRowByUpdateVideoPrice = (row) => {
    let { history } = this.props;

    // 获取星级列表
    anchorStarService.getAnchorStarList().then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.starRow = {
          starList: res.retValue,
          data: row
        };
        this.setState({ showUpdateVideoPriceDialog: true });
      }    
      
    });
  };

  getStarRowByAddVideoPrice = () => {
    let { history } = this.props;

    // 获取星级列表
    anchorStarService.getAnchorStarList().then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.starRow = {
          starList: res.retValue,
        };
        this.setState({ showAddVideoPriceDialog: true });
      }    
      
    });
  };
  
  // ———————————————————— 语音通话价格列表 ————————————————————————————

  // 分页
  voicePriceChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        voicePricePageParam: pageParam,
      });

      var voicePriceParam = {
        type: 6,
        pageParam: pageParam,
      };
  
      starPriceService.getStarPricePage(voicePriceParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            voicePriceList: res.retValue.list,
            voicePriceTotal: res.retValue.total,
          });
        }
        
      });
    });
  };

  // 刷新数据
  refreshVoicePriceData = () => {
    //console.log("refreshVoicePriceData");
    let { history } = this.props;

    var voicePriceParam = {
      type: 6,
      pageParam: this.state.voicePricePageParam,
    };

    starPriceService.getStarPricePage(voicePriceParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          voicePriceList: res.retValue.list,
          voicePriceTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateVoicePriceDialog=() => {
    this.setState({showUpdateVoicePriceDialog: false});
  }

  // 关闭添加
  hideAddVoicePriceDialog=() => {
    this.setState({showAddVoicePriceDialog: false});
  }

  // 关闭添加
  hideUpdateAndroidTextDescriptionDialog=() => {
    this.setState({showUpdateAndroidTextDescriptionDialog: false});
  }

  // 根据Id删除
  deleteVoicePrice=(id) => {
    let { history } = this.props;
    let starPriceParam = {
      id : id
    };
    starPriceService.deleteStarPrice(starPriceParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshVoicePriceData();
        message.success('删除成功！');
      }
    
    })

  }

  getStarRowByUpdateVoicePrice = (row) => {
    let { history } = this.props;

    // 获取星级列表
    anchorStarService.getAnchorStarList().then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.starRow = {
          starList: res.retValue,
          data: row
        };
        this.setState({ showUpdateVoicePriceDialog: true });
      }    
      
    });
  };

  getStarRowByAddVoicePrice = () => {
    let { history } = this.props;

    // 获取星级列表
    anchorStarService.getAnchorStarList().then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.starRow = {
          starList: res.retValue,
        };
        this.setState({ showAddVoicePriceDialog: true });
      }    
      
    });
  };


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getTicketExchangeRule();
    } else if(key == "2") {
      this.getStarPrice1();
    } else if(key == "3") {
      this.getStarPrice2();
    } else if(key == "4") {
      this.getStarPrice3();
    } else if(key == "5") {
      this.getSilverExchangeRule();
    }
  }

  /**
   * 映票兑换金币设置
   */
  getTicketExchangeRule=() => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var ticketExchangeRuleParam = {
      pageParam: pageParam,
    };

    ticketExchangeRuleService.getTicketExchangeRulePage(ticketExchangeRuleParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          ticketToCoinList: res.retValue.list,
          ticketToCoinTotal: res.retValue.total,
        });
      }
      
    });
  }

  /**
   * 微信号价格列表
   */
  getStarPrice1=() => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var videoPriceParam = {
      type: 2,
      pageParam: pageParam,
    };

    starPriceService.getStarPricePage(videoPriceParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          weChatPriceList: res.retValue.list,
          weChatPriceTotal: res.retValue.total,
        });
      }
      
    });
    
  }

  /**
   * 视频通话价格列表
   */
  getStarPrice2=() => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var videoPriceParam = {
      type: 5,
      pageParam: pageParam,
    };

    starPriceService.getStarPricePage(videoPriceParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          videoPriceList: res.retValue.list,
          videoPriceTotal: res.retValue.total,
        });
      }
      
    });
  }

  /**
   * 语音通话价格列表
   */
  getStarPrice3=() => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var voicePriceParam = {
      type: 6,
      pageParam: pageParam,
    };

    starPriceService.getStarPricePage(voicePriceParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          voicePriceList: res.retValue.list,
          voicePriceTotal: res.retValue.total,
        });
      }
      
    });
  }

    /**
   * 银币兑换金币设置
   */
    getSilverExchangeRule=() => {
      let { history } = this.props;
      var pageParam = {
        pageNum: 1,
        pageSize: 10,
      };
      var silverExchangeRuleParam = {
        pageParam: pageParam,
      };
  
      silverExchangeRuleService.getSilverExchangeRulePage(silverExchangeRuleParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            silverToCoinList: res.retValue.list,
            silverToCoinTotal: res.retValue.total,
          });
        }
        
      });
    }

  // ———————————————————— 银币兑换金币设置 ————————————————————————————
  
  // 分页
  silverToCoinChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        silverToCoinPageParam: pageParam,
      });

      var silverExchangeRuleParam = {
        pageParam: pageParam,
      };
  
      silverExchangeRuleService.getSilverExchangeRulePage(silverExchangeRuleParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            silverToCoinList: res.retValue.list,
            silverToCoinTotal: res.retValue.total,
          });
        }
      });
    });
  };

  // 刷新数据
  refreshSilverToCoinData = () => {
    //console.log("refreshSilverToCoinData");
    let { history } = this.props;

    var silverExchangeRuleParam = {
      pageParam: this.state.silverToCoinPageParam,
    };

    silverExchangeRuleService.getSilverExchangeRulePage(silverExchangeRuleParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          silverToCoinList: res.retValue.list,
          silverToCoinTotal: res.retValue.total,
        });
      }
      
    });
  };


  // 关闭更新
  hideUpdateSilverToCoinDialog=() => {
    this.setState({showUpdateSilverToCoinDialog: false});
  }

  // 关闭添加
  hideAddSilverToCoinDialog=() => {
    this.setState({showAddSilverToCoinDialog: false});
  }

  // 根据Id删除
  deleteSilverToCoin=(id) => {
    let { history } = this.props;
    let silverExchangeRuleParam = {
      id : id
    };
    silverExchangeRuleService.deleteSilverExchangeRule(silverExchangeRuleParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshSilverToCoinData();
        message.success('删除成功！');
      }
      
    })

  }


  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/recharge_settings">价格设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // ———————————————————— 映票兑换金币设置 ————————————————————————————
            <TabPane tab="映票兑换金币设置" key="1">
              <Form
                name="TicketToCoinListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddTicketToCoinDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.ticketToCoinList}
                columns={this.state.ticketToCoinColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.ticketToCoinTotal,
                  defaultCurrent: 1,
                  onChange: this.ticketToCoinChangePage,
                }}
              ></Table>
              <AddTicketToCoin close={this.hideAddTicketToCoinDialog} visible={this.state.showAddTicketToCoinDialog} refreshData={this.refreshTicketToCoinData} ></AddTicketToCoin>
              <UpdateTicketToCoin rowData={this.state.updateTicketToCoinRow} close={this.hideUpdateTicketToCoinDialog} visible={this.state.showUpdateTicketToCoinDialog} refreshData={this.refreshTicketToCoinData}></UpdateTicketToCoin>
            </TabPane>

            // ———————————————————— 微信号价格列表 ————————————————————————————
            <TabPane tab="微信号价格列表" key="2">
              <Form
                name="WeChatPriceListForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => {this.getStarRowByAddWeChatPrice()}}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.weChatPriceList}
                columns={this.state.weChatPriceColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.weChatPriceTotal,
                  defaultCurrent: 1,
                  onChange: this.weChatPriceChangePage,
                }}
              ></Table>
              <AddWeChatPrice close={this.hideAddWeChatPriceDialog} visible={this.state.showAddWeChatPriceDialog} refreshData={this.refreshWeChatPriceData} rowData={this.state.starRow}></AddWeChatPrice>
              <UpdateWeChatPrice rowData={this.state.starRow} close={this.hideUpdateWeChatPriceDialog} visible={this.state.showUpdateWeChatPriceDialog} refreshData={this.refreshWeChatPriceData}></UpdateWeChatPrice>
            </TabPane>
         
            // ———————————————————— 视频通话价格列表 ————————————————————————————
            <TabPane tab="视频通话价格列表" key="3">
              <Form
                name="VideoPriceListForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => {this.getStarRowByAddVideoPrice()}}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.videoPriceList}
                columns={this.state.videoPriceColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.videoPriceTotal,
                  defaultCurrent: 1,
                  onChange: this.videoPriceChangePage,
                }}
              ></Table>
              <AddVideoPrice rowData={this.state.starRow} close={this.hideAddVideoPriceDialog} visible={this.state.showAddVideoPriceDialog} refreshData={this.refreshVideoPriceData}></AddVideoPrice>
              <UpdateVideoPrice rowData={this.state.starRow} close={this.hideUpdateVideoPriceDialog} visible={this.state.showUpdateVideoPriceDialog} refreshData={this.refreshVideoPriceData}></UpdateVideoPrice>
          
            </TabPane>

            // ———————————————————— 语音通话价格列表 ————————————————————————————
            <TabPane tab="语音通话价格列表" key="4">
              <Form
                name="VoicePriceListForm"
                className="ant-advanced-search-form"
                ref={this.formRef4}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => {this.getStarRowByAddVoicePrice()}}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.voicePriceList}
                columns={this.state.voicePriceColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.voicePriceTotal,
                  defaultCurrent: 1,
                  onChange: this.voicePriceChangePage,
                }}
              ></Table>
              <AddVoicePrice rowData={this.state.starRow} close={this.hideAddVoicePriceDialog} visible={this.state.showAddVoicePriceDialog} refreshData={this.refreshVoicePriceData}></AddVoicePrice>
              <UpdateVoicePrice rowData={this.state.starRow} close={this.hideUpdateVoicePriceDialog} visible={this.state.showUpdateVoicePriceDialog} refreshData={this.refreshVoicePriceData}></UpdateVoicePrice>
          
            </TabPane>

            // ———————————————————— 银币兑换金币设置 ————————————————————————————
            <TabPane tab="银币兑换金币设置" key="5">
              <Form
                name="SilverToCoinListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddSilverToCoinDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.silverToCoinList}
                columns={this.state.silverToCoinColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.silverToCoinTotal,
                  defaultCurrent: 1,
                  onChange: this.silverToCoinChangePage,
                }}
              ></Table>
              <AddSilverToCoin close={this.hideAddSilverToCoinDialog} visible={this.state.showAddSilverToCoinDialog} refreshData={this.refreshSilverToCoinData} ></AddSilverToCoin>
              <UpdateSilverToCoin rowData={this.state.updateSilverToCoinRow} close={this.hideUpdateSilverToCoinDialog} visible={this.state.showUpdateSilverToCoinDialog} refreshData={this.refreshSilverToCoinData}></UpdateSilverToCoin>
            </TabPane>

          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}
export default PriceSetting;
