import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {
    /**
     * 获取兴趣标签分页
     * @param {*} tabInfoParam 
     * @returns 
     */
    getTagPage(tabInfoParam) {
        //console.log("tabInfoParam=" + JSON.stringify(tabInfoParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/tabInfo/getTagPage",
            data: tabInfoParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
   * 插入更新标签
   * @param {*} tabInfoParam
   * @returns
   */
    insertOrUpdateTab(tabInfoParam) {
        //console.log("insertOrUpdateTab appUser=" + JSON.stringify(appUser));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/tabInfo/insertOrUpdateTab",
            data: tabInfoParam,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
     * 根据id删除标签
     * @param {*} appTabInfo 
     * @returns 
     */
    deleteTab(appTabInfo) {
      //console.log("appTabInfo=" + JSON.stringify(appTabInfo));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/tabInfo/deleteTab",
        data: appTabInfo,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

}