import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import kuolieSettingsService from '../../../Service/Kuolie/kuolieSettingsService';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class KuolieSettings extends Component {
  formRef = React.createRef();
  state={
  

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 引流设置 ————————————————————————————
    //console.log("kuolieSettings 进入");

    kuolieSettingsService.getKuolieSettings()
      .then(
        res => {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          //console.log("retValue=" + JSON.stringify(res.retValue));
          this.formRef.current.setFieldsValue({
            id: res.retValue.id,
            reviewFlag: res.retValue.reviewFlag,
            publicPrice: res.retValue.publicPrice,
            refreshPrice: res.retValue.refreshPrice,
            browseNum: res.retValue.browseNum,
          })
        }
      )

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 引流设置 ————————————————————————————



  /**
   * 提交
   */
   handleKuolieSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {

        var appKuolieSettings = values;

        kuolieSettingsService.updateKuolieConfig(appKuolieSettings)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/kuolie_settings">扩列设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>            
            <Form
              name="messageSettingsForm"
              layout="horizontal"
              labelCol={{span:4}}
              wrapperCol={{span: 20}}
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 100px'}}
            >
                <Form.Item label="id" name="id" hidden>
                    <Input placeholder="id" />
                </Form.Item>

                <Form.Item label="是否需要审核" name="reviewFlag" labelCol={{span:10}}  style={{ width: 450 }}>
                  <Radio.Group name="reviewFlag" defaultValue={1}>
                    <Radio value={1}>需要</Radio>
                    <Radio value={0}>不需要</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label="发布价格（金币）" name="publicPrice" labelCol={{span:10}}  style={{ width: 450 }}>
                  <InputNumber />
                </Form.Item>

                <Form.Item label="刷新价格（金币）" name="refreshPrice" labelCol={{span:10}}  style={{ width: 450 }}>
                  <InputNumber />
                </Form.Item>

                <Form.Item label="浏览数倍数" name="browseNum" labelCol={{span:10}}  style={{ width: 450 }}>
                  <InputNumber />
                </Form.Item>
            </Form>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.handleKuolieSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
              <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
            </div>

            
        </StickyContainer>
        
      </div>
    );
  }
}
export default KuolieSettings;