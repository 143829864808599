import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select, Row, Col, Checkbox} from 'antd';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import appTabInfoService from '../../../Service/UserMgr/appTabInfoService';

const { Option } = Select;

class AddTag extends Component {
  formRef = React.createRef();

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));

          let tabInfoParam = {
            name : values.name,
            fontColor : values.fontColor,
            sort: values.sort,
            tabTypeId : values.tabTypeId,
          };

          appTabInfoService.insertOrUpdateTab(tabInfoParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  }



  render () {
    return (
      <Modal
        title="新增标签"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}            
        >
            <Form.Item label="名称" name="name" rules={ [
                    {
                        required: true,
                        message: '请输入名称',
                    }
                    ]
                }>
                <Input placeholder="名称" />
            </Form.Item>

            <Form.Item label="字体颜色" name="fontColor" >
                <Input placeholder="字体颜色" />
            </Form.Item>

            <Form.Item label="排序" name="sort" >
              <Input placeholder="排序"/>
            </Form.Item>

            <Form.Item label="类型" name="tabTypeId" initialValue={3}>
                <Select style={{ width: 220 }} defaultValue={3}>
                  <Option value={3}>自我描述</Option>
                </Select>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddTag