import React, {Component, PropTypes, useRef} from 'react';
import { Modal, message} from 'antd';
import EditUserFrm  from './EditUserFrm';
import appUserService from '../../../Service/UserMgr/appUserService';
import { Logout } from '../../../Common/Auth';

class EditUser extends Component {
    // 取消操作
    hanldeCloseModal = () => {
        this.props.close();
        // 重置表单
        this.refs.editUserFrm.formRef.current.resetFields();
    }

     // 提交操作
     handleSubmit =() => {
        let { history } = this.props;
        this.refs.editUserFrm.formRef.current.validateFields()
        .then(values => {
           //console.log(JSON.stringify(values));
           let appUser = values;
           appUserService.insertOrUpdateUser(appUser)
           .then(res => {
                if(res == null || res.retCode != 200) {
                  if(res.retCode == 10001) {
                    // 清除登录信息
                    Logout();
                    // 跳转到登录页面
                    history.push('/login');
                    return;
                  }
                  console.log("修改失败")
                  message.info("修改失败,"+res.message);  
                  // 重置关闭对话框
                  this.hanldeCloseModal();
                } else {
                  message.info('修改成功！');
                  // 重置关闭对话框
                  this.hanldeCloseModal();
                  // 刷新数据
                  this.props.refreshData();
                }
                
            })
          
        })
    }


  render() {
    return (
      <Modal 
        destroyOnClose
        title="修改用户信息"
        okText="修改"
        cancelText="取消"
        visible={this.props.visible} 
        onCancel={this.hanldeCloseModal}
        onOk={this.handleSubmit}
        width={800}
      >
        <EditUserFrm rowData={this.props.rowData} ref="editUserFrm"> </EditUserFrm>
      </Modal>
    );
  }
}

export default EditUser;