import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import Service from '../../../Service/index';
import siteInfoService from '../../../Service/SystemSetting/siteInfoService';
import imageConfigService from '../../../Service/BasicInformation/imageConfigService';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class BackgroundSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={

    // ———————————————————— 网站信息配置 ————————————————————————————
    siteInfo: null,

    // ———————————————————— 后台登录背景Logo配置 ————————————————————————————
    imageConfig: null, 

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 网站信息配置 ————————————————————————————

     this.getSiteInfo();
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 网站信息配置 ————————————————————————————
  handleSiteInfoSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {

        var cfgSiteInfo = values;
        siteInfoService.updateConfig(cfgSiteInfo)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }



  // ———————————————————— 后台登录背景Logo配置 ————————————————————————————

  handleImageConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {

        var appImageConfig = {
          id: values.id,
          loginImg: values.loginImg,
          loginLogo: values.loginLogo,
          loginImgHeight: values.loginImgHeight,
          adminIndexLogo: values.adminIndexLogo,
        };
        imageConfigService.updateConfig(appImageConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }

  // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          console.log("handleChangeFileImage="+e.file.response);
          this.state.loading=false;

          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })
  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
  
  };

  // loginImg修改
  handleChangeFileLoginImg=(e) => {
    if (e.file.status === 'uploading') {
        this.state.loading=true;
        return;
    }
    if (e.file.status === 'done') {
        //console.log("handleChangeFileApk="+e.file.response);
        this.state.loading=false;

        this.formRef.current.setFieldsValue({
          loginImg: e.file.response,
        });
        return e.file.response;
      }
  }


  // loginLogo修改
  handleChangeFileLoginLogo=(e) => {
    if (e.file.status === 'uploading') {
        this.state.loading=true;
        return;
    }
    if (e.file.status === 'done') {
        //console.log("handleChangeFileApk="+e.file.response);
        this.state.loading=false;

        this.formRef.current.setFieldsValue({
          loginLogo: e.file.response,
        });
        return e.file.response;
      }
  }

  // adminIndexLogo修改
  handleChangeFileAdminIndexLogo=(e) => {
    if (e.file.status === 'uploading') {
        this.state.loading=true;
        return;
    }
    if (e.file.status === 'done') {
        //console.log("handleChangeFileApk="+e.file.response);
        this.state.loading=false;

        this.formRef.current.setFieldsValue({
          adminIndexLogo: e.file.response,
        });
        return e.file.response;
      }
  }

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getSiteInfo();
    } else if(key == "2") {
      this.getImageConfig();
    }
  }

  /**
   * 网站信息配置
   */
  getSiteInfo = () => {
    let { history } = this.props;

    siteInfoService.getConfig()
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.formRef.current.setFieldsValue({
              id: res.retValue.id,
              siteName: res.retValue.siteName,
              siteUrl: res.retValue.siteUrl,
              copyRight: res.retValue.copyRight,
            });
          }                  
          
        }
      )   
  }

  /**
   * 后台登录背景Logo配置
   */
  getImageConfig = () => {
    let { history } = this.props;
    imageConfigService.getConfig()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            id: res.retValue.id,
            loginImg: res.retValue.loginImg,
            loginLogo: res.retValue.loginLogo,
            loginImgHeight: res.retValue.loginImgHeight,
            adminIndexLogo: res.retValue.adminIndexLogo,
          });
          this.setState({imageConfig: res.retValue})
        }                  
      }
    )  
  }

  handleLoginImgChange = ({ file, fileList }) => {
    this.formRef2.current.setFieldsValue({
      loginImg: file.response,
    });
  };
  
  handleLoginLogoChange = ({ file, fileList }) => {
    this.formRef2.current.setFieldsValue({
      loginLogo: file.response,
    });
  };

  handleAdminIndexLogoChange = ({ file, fileList }) => {
    this.formRef2.current.setFieldsValue({
      adminIndexLogo: file.response,
    });
  };


  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/app_version_mgr">后台设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // —————————————————————— 网站信息配置 ————————————————————————
            <TabPane tab="网站信息配置" key="1">
              <Form
                  name="SiteInfoForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.siteInfo}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="网站标题" name="siteName" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="网站标题"  />
                      </Form.Item>

                      <Form.Item label="网站网址" name="siteUrl" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="网站网址"  />
                      </Form.Item>

                      <Form.Item label="版权信息" name="copyRight" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="版权信息"  />
                      </Form.Item>                    
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleSiteInfoSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 后台登录背景Logo配置 ————————————————————————
            <TabPane tab="后台登录背景Logo配置" key="2">
              <Form
                  name="ImageConfigForm"
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.imageConfig}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Row gutter={20}>
                        <Col span={7} key={1}>
                          <Form.Item dependencies={['loginImg']} noStyle>
                              {({ getFieldValue }) => {
                                  const loginImg = getFieldValue('loginImg')
                                  return (
                                      <>
                                      <Form.Item label="管理后台登录页面背景" name="loginImgImage" labelCol={{span:10}} style={{ width: 420 }}>
                                        <Image width={55} height={55} src={loginImg} />
                                      </Form.Item>
                                      </>
                                  )
                                  return null
                              }}
                          </Form.Item>
                        </Col>
                        <Col span={13} key={2}>
                          <Row>
                            <Form.Item  name="loginImgUpload" 
                                valuePropName="file"
                                style={{ width: 200 }}
                                getValueFromEvent={this.handleChangeFileLoginImg}
                                >
                                <Upload
                                    accept="apk/*"
                                    name="fiavatarle"
                                    onChange={this.handleLoginImgChange}
                                    customRequest={this.doImgUpload}
                                    beforeUpload={this.beforeUpload}
                                    showUploadList={false}
                                >
                                    <Button icon={<UploadOutlined />}>选择文件</Button>
                                </Upload>
                            </Form.Item>
                          </Row>
                          <Row>
                            <Form.Item name="loginImg" labelCol={{span:10}} style={{ width: 840 }} >
                                <Input />
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>

                      <Row gutter={20}>
                        <Col span={7} key={1}>
                          <Form.Item dependencies={['loginLogo']} noStyle>
                              {({ getFieldValue }) => {
                                  const loginLogo = getFieldValue('loginLogo')
                                  return (
                                      <>
                                      <Form.Item label="管理后台登录页面logo" name="loginLogoImage" labelCol={{span:10}} style={{ width: 420 }}>
                                        <Image width={55} height={55} src={loginLogo} />
                                      </Form.Item>
                                      </>
                                  )
                                  return null
                              }}
                          </Form.Item>
                        </Col>
                        <Col span={13} key={2}>
                          <Row>
                            <Form.Item  name="loginLogoUpload" 
                                valuePropName="file"
                                style={{ width: 200 }}
                                getValueFromEvent={this.handleChangeFileLoginLogo}
                                >
                                <Upload
                                    accept="apk/*"
                                    name="fiavatarle"
                                    onChange={this.handleLoginLogoChange}
                                    customRequest={this.doImgUpload}
                                    beforeUpload={this.beforeUpload}
                                    showUploadList={false}
                                >
                                    <Button icon={<UploadOutlined />}>选择文件</Button>
                                </Upload>
                            </Form.Item>
                          </Row>
                          <Row>
                            <Form.Item name="loginLogo" labelCol={{span:10}} style={{ width: 840 }} >
                                <Input />
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>

                      <Form.Item label="管理后台登录页面logo高度" name="loginImgHeight" labelCol={{span:10}} style={{ width: 420 }}>
                          <Input />
                      </Form.Item>

                      <Row gutter={20}>
                        <Col span={7} key={1}>
                          <Form.Item dependencies={['adminIndexLogo']} noStyle>
                              {({ getFieldValue }) => {
                                  const adminIndexLogo = getFieldValue('adminIndexLogo')
                                  return (
                                      <>
                                      <Form.Item label="管理后台页面logo" name="adminIndexLogoImage" labelCol={{span:10}} style={{ width: 420 }}>
                                        <Image width={55} height={55} src={adminIndexLogo} />
                                      </Form.Item>
                                      </>
                                  )
                                  return null
                              }}
                          </Form.Item>
                        </Col>
                        <Col span={13} key={2}>
                          <Row>
                            <Form.Item  name="adminIndexLogoUpload" 
                                valuePropName="file"
                                style={{ width: 200 }}
                                getValueFromEvent={this.handleChangeFileAdminIndexLogo}
                                >
                                <Upload
                                    accept="apk/*"
                                    name="fiavatarle"
                                    onChange={this.handleAdminIndexLogoChange}
                                    customRequest={this.doImgUpload}
                                    beforeUpload={this.beforeUpload}
                                    showUploadList={false}
                                >
                                    <Button icon={<UploadOutlined />}>选择文件</Button>
                                </Upload>
                            </Form.Item>
                          </Row>
                          <Row>
                            <Form.Item name="adminIndexLogo" labelCol={{span:10}} style={{ width: 840 }} >
                                <Input />
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>

                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleImageConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default BackgroundSettings;