import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页用户绑定记录
   * @param {*} userBindSuperiorRecordParam
   * @returns
   */
   getRecordPage(userBindSuperiorRecordParam) {
      //console.log("userBindSuperiorRecordParam=" + JSON.stringify(userBindSuperiorRecordParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/userBindSuperiorRecord/getRecordPage",
        data: userBindSuperiorRecordParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

}