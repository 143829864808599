import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import appUserService from '../../../Service/UserMgr/appUserService';

import moment from 'moment'



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class InviteUserList extends Component {
  formRef = React.createRef();
  state={
    /*
    * 用户列表
    */
    editUserRow: null,             // 当前编辑产品信息 
    showEditUserDialog: false,     // 修改产品对话框
    userSelectRowKeys:[],
    showAddUserDialog: false,      // 添加产品对话框
    usertotal: 0,
    userlist: null,
    params: {pageNum:1, pageSize: 10},
    userColumns: [{
      key: 'agentId',
      title: '上级代理ID',
      dataIndex: 'agentId'
    },{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
		  align: 'center',
      render: (avatar, row) => {
        var info = (
            <>
              <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
                <div>{row.userid}</div>
              </div>
              <div style={{ float: 'left', marginRight: '5px'}}>
                <Image width={55} height={55} src={avatar} />
              </div>
              <div style={{ float: 'left' }}>
                <div style={{ marginTop: '18px'}}>{row.username}</div>
              </div>
            </>
        );
        return info;
      }
    },{
      key: 'sex',
      title: '用户性别',
      dataIndex: 'sex',
      render: (sex, row) => {
        if(sex === 0) {
          return "未设置"
        } else if(sex === 1) {
          return "男";
        } else if(sex === 2) {
          return "女";
        }
      }
    },{
      key: 'role',
      title: '角色',
      dataIndex: 'role',
      render: (role, row) => {
        if(role === 0) {
          return "普通用户"
        } else if(role === 1) {
          return "主播";
        }
      }
    },{
      key: 'coin',
      title: '剩余金币',
      dataIndex: 'coin'
    },{
      key: 'consumption',
      title: '累计消费金币',
      dataIndex: 'consumption'
    },{
      key: 'totalCharge',
      title: '累计充值金额',
      dataIndex: 'totalCharge'
    },{
      key: 'votestotal',
      title: '累计收益映票',
      dataIndex: 'votestotal'
    },{
      key: 'lastLoginTime',
      title: '最后登录时间',
      dataIndex: 'lastLoginTime'
    },{
      key: 'onlineStatus',
      title: '真实在线状态',
      dataIndex: 'onlineStatus',
      render: (onlineStatus, row) => {
        if(onlineStatus === 0) {
          return "离线"
        } else if(onlineStatus === 1) {
          return "在线";
        }
      }
    },{
      key: 'toDayOnlineTime',
      title: '今日在线时长',
      dataIndex: 'toDayOnlineTime',
    },{
      key: 'createTime',
      title: '注册时间',
      dataIndex: 'createTime',
    }],
    userByAgentPageParam: null,
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // 用户列表
    this.getAppUser1();
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  /**
   * 行选择操作
   */
  productRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows);
      this.setState({userSelectRowKeys: selectedRowKeys});
    }
  }

  // 获取普通用户
  handleGetOrdinaryUser=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
       //console.log(JSON.stringify(values));
       var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
      
      var userByAgentParam ={
        userid: values.userid,
        mobile: values.mobile,
        username: values.username,
        role: values.role,
        onlineStatus: values.onlineStatus,
        coinStart: values.coinStart,
        coinEnd: values.coinEnd,
        pageParam : pageParam,
        searchAgentId : values.searchAgentId
      }

      this.setState({
        userByAgentPageParam: userByAgentParam,
      });

      appUserService.getUserByAgentPage(userByAgentParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
          }                            
        }
      )
       
    })
  }

  // 用户分页
  userChangePage=(page, pageSize) => {
      let { history } = this.props;
      //console.log("changePage 进入");
      this.formRef.current.validateFields()
      .then(values => {
          var pageParam = {
            pageNum: page,
            pageSize: pageSize,
          }

          var userByAgentParam = this.state.userByAgentPageParam;
          userByAgentParam.pageParam = pageParam;
          appUserService.getUserByAgentPage(userByAgentParam)
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                message.info("查询失败,"+res.message);  
              } else {
                this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
              }                            
            }
          )
      });

    }

 
  /**
   * 用户列表
   */
  getAppUser1 = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var userByAgentParam ={
      pageParam : pageParam
    }

    this.setState({
      userByAgentPageParam: userByAgentParam,
    });

    appUserService.getUserByAgentPage(userByAgentParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userlist: res.retValue.list, usertotal: res.retValue.total});
        }                            
      }
    )
  }




  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/invite_user_list">邀请用户列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
              
              <Form
                name="userListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="UID" name="userid">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="昵称" name="username">
                          <Input placeholder="昵称" />
                      </Form.Item>
                    </Col>
                    <Col span={8} key={4}>
                      <Form.Item label="角色" name="role">
                          <Select style={{ width: 220 }} defaultValue="全部">
                            <Option >全部</Option>
                            <Option value={0}>普通用户</Option>
                            <Option value={1}>主播用户</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={8} key={6}>
                      <Form.Item label="在线状态" name="onlineStatus">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>离线</Option>
                              <Option value={1}>在线</Option>
                          </Select>
                      </Form.Item>
                    </Col>
                    <Col span={16} key={7}>
                      <Row >
                        <Form.Item label="金币余额" name="coinStart">
                            <Input placeholder="金币开始" />
                        </Form.Item>
                        -
                        <Form.Item name="coinEnd">
                            <Input placeholder="金币结束" />
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col span={8} key={3}>
                      <Form.Item label="代理ID" name="searchAgentId">
                          <Input placeholder="代理ID" />
                      </Form.Item>
                    </Col>
                    <Col span={14} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetOrdinaryUser} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', margin: '20px 0px 0px 0px'}}
                dataSource={this.state.userlist}
                columns={this.state.userColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.usertotal, defaultCurrent: 1, onChange: this.userChangePage}}
              >
              </Table>

        </StickyContainer>
        
      </div>
    );
  }
}
export default InviteUserList;