import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  getOrdinaryUserPage(ordinaryUserParam) {

    //console.log("data=" + JSON.stringify(data));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/getOrdinaryUser",
      data: ordinaryUserParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  insertOrUpdateUser(appUser) {
    //console.log("insertOrUpdateUser appUser=" + JSON.stringify(appUser));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/insertOrUpdateUser",
      data: appUser,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 根据id删除用户
   * @param {*} openUserIdList
   * @returns
   */
  deleteUser(appUser) {
    //console.log("appUser=" + JSON.stringify(appUser));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/logOutUserInBackground",
      data: appUser,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 更新用户密码
   * @param {*} appUser
   * @returns
   */
  updateUserPwd(appUser) {
    //console.log("appUser=" + JSON.stringify(appUser));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/updateUserPwd",
      data: appUser,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 禁用用户
   * @param {*} disableUserParam
   * @returns
   */
  disableUser(disableUserParam) {
   //console.log("disableUserParam=" + JSON.stringify(disableUserParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/disableUser",
      data: disableUserParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 禁用用户
   * @param {*} disableUserParam
   * @returns
   */
  getDisableUserInfo(appUser) {
    //console.log("getDisableUserInfo=" + JSON.stringify(appUser));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/getDisableUserInfo",
      data: appUser,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 启用用户
   * @param {*} enableUserParam
   * @returns
   */
  enableUser(enableUserParam) {
    //console.log("enableUserParam=" + JSON.stringify(enableUserParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/enableUser",
      data: enableUserParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  // —————————————————— 主播 ——————————————————————————
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  getAnchorUserPage(anchorUserParam) {
    //console.log("data=" + JSON.stringify(data));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/getAnchorUserPage",
      data: anchorUserParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 获取主播后台配置参数
   * @returns
   */
  getAnchorAdminConfig() {
    //console.log("data=" + JSON.stringify(data));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/getAnchorAdminConfig",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 用户绑定解绑
   * @param {*} bindSuperiorParam
   * @returns
   */
   userBindSuperior(bindSuperiorParam) {
      //console.log("appUser=" + JSON.stringify(appUser));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/appUser/userBindSuperior",
        data: bindSuperiorParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


    /**
     * 分页获取一对一用户列表
     * @param {*} onevsOneUserParam
     * @returns
     */
     getOnevsOneUserPage(onevsOneUserParam) {
      //console.log("onevsOneUserParam=" + JSON.stringify(onevsOneUserParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/appUser/getOnevsOneUserPage",
        data: onevsOneUserParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


    updateOnlineState(appUser) {
      //console.log("insertOrUpdateUser appUser=" + JSON.stringify(appUser));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/appUser/updateOnlineState",
        data: appUser,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

    updateUserRole(appUser) {
      //console.log("insertOrUpdateUser appUser=" + JSON.stringify(appUser));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/appUser/updateUserRole",
        data: appUser,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  // —————————————————— 代理 ——————————————————————————
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  getUserByAgentPage(userByAgentParam) {
    //console.log("data=" + JSON.stringify(data));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/getUserByAgentPage",
      data: userByAgentParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  // —————————————————— 客服 ——————————————————————————
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  getCustomerPage(customerParam) {
    //console.log("data=" + JSON.stringify(data));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/appUser/getCustomerPage",
      data: customerParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

};
