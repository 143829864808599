import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout} from "../../../Common/Auth";
import moment from "moment";
import gradePrivilegeService from "../../../Service/GradeMgr/gradePrivilegeService";
import gradeService from "../../../Service/GradeMgr/gradeService";

import UpdateNoblePrivilege from './UpdateNoblePrivilege';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class NoblePrivilege extends Component {
  formRef = React.createRef();
  state = {
    // ———————————————————— 用户特权 ————————————————————————————

    noblePrivilegeColumns: [
      {
        key: "id",
        title: "特权ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "特权名称",
        dataIndex: "name",
      },
      {
        key: "desr",
        title: "特权描述",
        dataIndex: "desr",
      },
      {
        key: "identification",
        title: "标识",
        dataIndex: "identification",
      },
      {
        key: "lineLogo",
        title: "特权彩图",
        dataIndex: "lineLogo",
        render: (lineLogo, row) => {
          if (lineLogo != null && lineLogo != "") {
            return <Image width={55} height={55} src={lineLogo} />;
          }
        },
      },
      {
        key: "isStart",
        title: "是否启用",
        dataIndex: "isStart",
        render: (isStart, row) => {
          if(isStart === 0) {
            return "未启用"
          } else if(isStart === 1) {
            return "启用";
          }
        }
      },
      {
        key: "type",
        title: "特权类型",
        dataIndex: "type",
        render: (type, row) => {
          if(type === 1) {
            return "用户特权"
          } else if(type === 2) {
            return "财富特权";
          } else if(type === 3) {
            return "主播特权";
          } else if(type === 4) {
            return "贵族特权";
          } else if(type === 5) {
            return "魅力特权";
          } 
        }
      },
      {
        key: "grade",
        title: "达标等级",
        dataIndex: "grade",
        render: (grade, row) => {
          if(grade === 1) {
            return "侯爵"
          } else if(grade === 2) {
            return "公爵";
          } else if(grade === 3) {
            return "国王";
          } else {
            return grade;
          }
        }
      },
      {
        key: "sort",
        title: "排序",
        dataIndex: "sort",
      },
      {
        key: "del",
        title: "编辑",
        dataIndex: "del",
        render: (del, row) => {
          return (
            <div>
              <Button
                onClick={() => this.getUpdateNoblePrivilegeRowData(row)}
                style={{ marginRight: "5px" }}
                type="primary"
              >
                编辑
              </Button>
            </div>
          );
        },
      },
    ],
    noblePrivilegeTotal: 0,
    noblePrivilegeList: null,
    updateNoblePrivilegeRow: null,
    showUpdateNoblePrivilegeDialog: false,
    addNoblePrivilegeRow: null,
    showAddNoblePrivilegeDialog: false,
    nobleGradeList: null,
    noblePrivilegePageParam:{
      pageNum: 1,
      pageSize: 10,
    },

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // 等级列表
    var gradeParam = {
      type: 4
    }

    gradeService.getGradeList(gradeParam)
    .then((res) => {
      if(res.retCode == 10001) {
        // 清除登录信息
        Logout();
        // 跳转到登录页面
        history.push('/login');
        return;
      }
      this.setState({
        nobleGradeList: res.retValue
      });
    });

    // ———————————————————— 贵族特权 ————————————————————————————
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var noblePrivilegeParam = {
      type: 4,
      pageParam: pageParam,
    };
    gradePrivilegeService.getGradePrivilegePage(noblePrivilegeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          noblePrivilegeList: res.retValue.list,
          noblePrivilegeTotal: res.retValue.total,
        });
      }                  

    });

  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 贵族特权 ————————————————————————————
  // 获取等级礼物
  handleGetNoblePrivilegePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var gradePrivilegeParam = {
        grade: values.grade,
        name: values.name,
        type: 4,
        pageParam : pageParam,
      }
      gradePrivilegeService.getGradePrivilegePage(gradePrivilegeParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            noblePrivilegeList: res.retValue.list,
            noblePrivilegeTotal: res.retValue.total,
          });
        }                  
        
      });
      
    })

  }



  // 贵族特权分页
  noblePrivilegeChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      this.setState({
        noblePrivilegePageParam: pageParam,
      });

      var noblePrivilegeParam = {
        type: 4,
        pageParam: pageParam,
      };
      gradePrivilegeService.getGradePrivilegePage(noblePrivilegeParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            noblePrivilegeList: res.retValue.list,
            noblePrivilegeTotal: res.retValue.total,
          });
        }                  
        
      });
    });
  };

  // 刷新数据
  refreshNoblePrivilegeData = () => {
    let { history } = this.props;
    //console.log("refreshNoblePrivilegeData");

    var noblePrivilegeParam = {
      type: 4,
      pageParam: this.state.noblePrivilegePageParam,
    };
    gradePrivilegeService.getGradePrivilegePage(noblePrivilegeParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          noblePrivilegeList: res.retValue.list,
          noblePrivilegeTotal: res.retValue.total,
        });
      }                  
      
    });
  };

  // 隐藏分成方案弹窗
  hideUpdateNoblePrivilegeDialog = () => {
    this.setState({ showUpdateNoblePrivilegeDialog: false });
  };

  // 隐藏分成方案弹窗
  hideAddNoblePrivilegeDialog = () => {
    this.setState({ showAddNoblePrivilegeDialog: false });
  };

  /**
   * 获取更新用户勋章数据
   * @param {*} options
   */
  getUpdateNoblePrivilegeRowData = (row) => {
    let { history } = this.props;
    var gradeParam = {
      type: 4,
    };
    // 获取分配比例列表
    gradeService.getGradeList(gradeParam).then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        if (res.retValue == null) {
          return;
        }
  
        this.state.updateNoblePrivilegeRow = {
          nobleGradeList: res.retValue,
          id: row.id,
          name: row.name,
          desr: row.desr,
          identification: row.identification,
          lineLogo: row.lineLogo,
          offLineLogo: row.offLineLogo,
          isStart: row.isStart,
          type: row.type,
          grade: row.grade,
          sort: row.sort,
        };
  
        this.setState({ showUpdateNoblePrivilegeDialog: true });
      }                  
     
    });
  };

  

  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/noble_privilege">贵族特权</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
            <Form
              name="noblePrivilegeListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 0px'}}
            >
              <Row gutter={24}>
                  <Col span={8} key={1}>

                    <Form.Item label="达到等级" name="grade" initialValue="">
                      <Select style={{ width: 220 }} defaultValue="">
                          <Option value="">全部</Option>

                          {this.state.nobleGradeList != null ? this.state.nobleGradeList.map((item)=>
                              <Option key={item.grade} value={item.grade}>{item.name}</Option>
                          ) : null}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8} key={2}>
                    <Form.Item label="特权名称" name="name">
                        <Input placeholder="特权名称"/>
                    </Form.Item>
                  </Col>
                  <Col span={6} key={4} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetNoblePrivilegePage} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
              </Row>
            </Form>

            <Table
              bordered
              style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
              dataSource={this.state.noblePrivilegeList}
              columns={this.state.noblePrivilegeColumns}
              rowKey="id"
              scroll={{ x: "max-content" }}
              pagination={{
                total: this.state.noblePrivilegeTotal,
                defaultCurrent: 1,
                onChange: this.noblePrivilegeChangePage,
              }}
            ></Table>

            <UpdateNoblePrivilege
              rowData={this.state.updateNoblePrivilegeRow}
              close={this.hideUpdateNoblePrivilegeDialog}
              visible={this.state.showUpdateNoblePrivilegeDialog}
              refreshData={this.refreshNoblePrivilegeData}
            ></UpdateNoblePrivilege>

        </StickyContainer>
      </div>
    );
  }
}
export default NoblePrivilege;
