import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  getPrivilegePage(key, pageParam, level) {
    let data = {
      key: key,
      level: level,
      pageParam: pageParam,
    };

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/privilege/getPrivilegePage",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  insertOrUpdatePrivilege(privilegeParam) {
    let data = {
      privilegeParam: privilegeParam,
    };

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/privilege/insertOrUpdatePrivilege",
      data: privilegeParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  deletePrivilegeById(id) {
    let data = {
      id: id,
    };

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/privilege/deletePrivilegeById",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  deletePrivilegeByIdList(idList) {

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/privilege/deletePrivilegeByIdList",
      data: idList,
    }).then((res) => {
      //onsole.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
};
