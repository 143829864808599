import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import userService from '../../../Service/Admin/userService';
import finStatementAgentService from '../../../Service/FinanceMgr/finStatementAgentService';
import agentDataStatisticsService from '../../../Service/MyAgent/agentDataStatisticsService';


import AddProxy from './AddProxy';
import UpdateProxy from './UpdateProxy';
import ShowDataStatistics from './ShowDataStatistics';
import ShowFinancialStatement from './ShowFinancialStatement';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class SecondAgent extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 代理列表 ————————————————————————————
    userProxyColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '登录账号',
      dataIndex: 'name'
    },{
      key: 'roleCode',
      title: '代理类型',
      dataIndex: 'roleCode',
      render: (roleCode, row) => {
        if(roleCode == 'agent') {
          return "一级代理"
        } else if(roleCode == 'specialAgent') {
          return "无提现代理";
        } else if(roleCode == 'secondAgent') {
          return "二级代理";
        } 
      }
    },{
      key: 'anonymous',
      title: '代理名称',
      dataIndex: 'anonymous',
    },{
      key: 'comment',
      title: '联系人',
      dataIndex: 'comment',
    },{
      key: 'phone',
      title: '手机号',
      dataIndex: 'phone',
    },{
      key: 'amount',
      title: '剩余可提现佣金',
      dataIndex: 'amount',
    },{
      key: 'totalPopularize',
      title: '累计推广佣金',
      dataIndex: 'totalPopularize',
    },{
      key: 'totalCash',
      title: '累计提现佣金',
      dataIndex: 'totalCash',
    },{
      key: 'popularizeUserNum',
      title: '推广用户数',
      dataIndex: 'popularizeUserNum',
    },{
      key: 'popularizeAnchorNum',
      title: '推广主播数',
      dataIndex: 'popularizeAnchorNum',
    },{
      key: 'coin',
      title: '累计充值金币',
      dataIndex: 'coin',
    },{
      key: 'chargePerc',
      title: '充值佣金比例',
      dataIndex: 'chargePerc',
    },{
      key: 'nobleShareRatio',
      title: '贵族分成比例',
      dataIndex: 'nobleShareRatio',
    },{
      key: 'incomePerc',
      title: '收益佣金比例',
      dataIndex: 'incomePerc',
    },{
      key: 'inviteCode',
      title: '邀请码',
      dataIndex: 'inviteCode',
    },{
      key: 'status',
      title: '启用状态',
      dataIndex: 'status',
      render: (status, row) => {
        if(status === 1) {
          return "启用"
        } else if(status === 2) {
          return "禁用";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getTodayData(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">查看数据 
              </Button>
              <Button 
                onClick={() => this.showFinancialStatement(row.id)}
                style={{marginRight:'5px'}} 
                type="primary">查看报表 
              </Button>
              <Button 
                onClick={() => this.setState({showUpdateProxyDialog:true, updateProxyRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteUser(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger">删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    userProxyTotal: 0,
    userProxyList: null,
    updateProxyRow: null,
    showUpdateProxyDialog: false,
    userProxyPageParam: {
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      },
    },
    showAddProxyDialog: false,  // 添加代理对话框
    showDataStatisticsRow: null,
    showDataStatisticsDialog: false,
    showFinancialStatementRow: null,
    showFinancialStatementDialog: false,
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 代理列表 ————————————————————————————
    
    this.handleGetAgentPage();
  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 代理列表 ————————————————————————————

  // 获取代理用户
  handleGetAgentPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var userProxyParam = {
        key : values.key,
        pageParam : pageParam,
      }
      userService.getSecondAgentPage(userProxyParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userProxyList: res.retValue.list, userProxyTotal: res.retValue.total});
          }                            
        }
      )
      
    })
  }


  // 用户分页
  userProxyChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var userProxyParam = {
          key : values.key,
          pageParam : pageParam,
        }

        this.setState({
          userProxyPageParam: userProxyParam,
        });

        userService.getSecondAgentPage(userProxyParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({userProxyList: res.retValue.list, userProxyTotal: res.retValue.total});
            }                            
          }
        )
    })
  }

  // 刷新数据
  refreshProxyData=() => {
    //console.log("refreshProxyData");
    let { history } = this.props;

    var userProxyParam = this.state.userProxyPageParam;
    userService.getSecondAgentPage(userProxyParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userProxyList: res.retValue.list, userProxyTotal: res.retValue.total});
        }                            
      }
    )
  }

  // 隐藏更新弹窗
  hideUpdateProxyDialog=() => {
    this.setState({showUpdateProxyDialog: false});
  }

  // 关闭添加代理弹框
  hideAddProxyDialog=() => {
    this.setState({showAddProxyDialog: false});
  }

  // 隐藏更新弹窗
  hideDataStatisticsDialog=() => {
    this.setState({showDataStatisticsDialog: false});
  }

  // 隐藏更新弹窗
  hideFinancialStatementDialog=() => {
    this.setState({showFinancialStatementDialog: false});
  }

  // 根据Id删除用户
  deleteUser=(userid) => {
      let { history } = this.props; 
      var userParam = {
        id: userid
      }
      userService.deleteSecondAgentById(userParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshProxyData();
          message.success('删除成功！');
        }                            
        
      })
  }



    // 获取报表列表
    showFinancialStatement= (id) =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finStatementParam = {
        agentId: id,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam : pageParam,
        type : 1,
      }
      finStatementAgentService.getFinStatementAgentPageByAgent(finStatementParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {


            var showFinancialStatementRow = {
              id: id,
              summaryStatementsList : res.retValue.list,
              summaryStatementsTotal: res.retValue.total
            };

            this.setState({ showFinancialStatementRow: showFinancialStatementRow });
      
            this.setState({ showFinancialStatementDialog: true });

          }
        }
      )
      
    })
  
  }



  /**
   * 获取今天数据
   */
  getTodayData=(id)=> {
    let { history } = this.props;

    var userId = id;
    var todayParam = {
      id: userId,
      type: "today"
    }

    agentDataStatisticsService.getAgentDataStatisticsAgent(todayParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          var dataStatistics = {
            id: id,
            popularizeUserNum_1: res.retValue.popularizeUserNum,
            rechargeTotalMoney_1: res.retValue.rechargeTotalMoney,
            rechargeCommission_1: res.retValue.rechargeCommission,
            nobleTotalMoney_1: res.retValue.nobleTotalMoney,
            nobleCommission_1: res.retValue.nobleCommission,
            totalAmount_1: res.retValue.totalAmount,
            popularizeAnchorNum_1: res.retValue.popularizeAnchorNum,
            anchorIncomeCoin_1: res.retValue.anchorIncomeCoin,
            incomeCommission_1: res.retValue.incomeCommission,
          };

          this.setState({ showDataStatisticsRow: dataStatistics });
          this.setState({ showDataStatisticsDialog: true });

        }
        
      }
    )   
  }

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/second_agent">下级代理列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>            
              <Form
                name="proxyListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={1}>
                      <Form.Item label="搜索关键词" name="key">
                          <Input placeholder="搜索关键词" />
                      </Form.Item>
                    </Col>

                    <Col span={3} key={2} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetAgentPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                  </Col>
                  <Col span={2} key={3} style={{ textAlign: 'right' }}>
                      <Button onClick={() => this.setState({showAddProxyDialog:true})} style={{ marginRight: '15px' }} type="primary">
                        添加
                      </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userProxyList}
                columns={this.state.userProxyColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.userProxyTotal, defaultCurrent: 1, onChange: this.userProxyChangePage}}
              >
              </Table>
              <AddProxy close={this.hideAddProxyDialog} visible={this.state.showAddProxyDialog} refreshData={this.refreshProxyData}></AddProxy>
              <UpdateProxy rowData={this.state.updateProxyRow} close={this.hideUpdateProxyDialog} visible={this.state.showUpdateProxyDialog} refreshData={this.refreshProxyData}></UpdateProxy>
              <ShowDataStatistics rowData={this.state.showDataStatisticsRow} close={this.hideDataStatisticsDialog} visible={this.state.showDataStatisticsDialog} ></ShowDataStatistics>
              <ShowFinancialStatement rowData={this.state.showFinancialStatementRow} close={this.hideFinancialStatementDialog} visible={this.state.showFinancialStatementDialog} ></ShowFinancialStatement>

        </StickyContainer>
        
      </div>
    );
  }
}
export default SecondAgent;