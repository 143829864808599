import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import { CSVDownload, CSVLink } from 'react-csv';
import moment from 'moment'
import finStatementService from '../../../Service/FinanceMgr/finStatementService';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class FinancialStatements extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 财务报表列表 ————————————————————————————
    financialStatementsColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'dayOfStatement',
      title: '统计日期',
      dataIndex: 'dayOfStatement',
      render: (dayOfStatement, row) => {
        if(dayOfStatement != null && dayOfStatement != "") {
          return moment(dayOfStatement).format('YYYY-MM-DD');
        }
      }
    },{
      key: 'registerUserCount',
      title: '注册人数',
      dataIndex: 'registerUserCount'
    },{
      key: 'realRegisteredCount',
      title: '自然人数',
      dataIndex: 'realRegisteredCount'
    },{
      key: 'xiaomiRegisteredCount',
      title: '小米',
      dataIndex: 'xiaomiRegisteredCount'
    },{
      key: 'vivoRegisteredCount',
      title: 'vivo',
      dataIndex: 'vivoRegisteredCount'
    },{
      key: 'huaweiRegisteredCount',
      title: '华为',
      dataIndex: 'huaweiRegisteredCount'
    },{
      key: 'oppoRegisteredCount',
      title: 'oppo',
      dataIndex: 'oppoRegisteredCount'
    },{
      key: 'meizuRegisteredCount',
      title: '魅族',
      dataIndex: 'meizuRegisteredCount'
    },{
      key: 'threeSixRegisteredCount',
      title: '360',
      dataIndex: 'threeSixRegisteredCount'
    },{
      key: 'aliRegisteredCount',
      title: '阿里',
      dataIndex: 'aliRegisteredCount'
    },{
      key: 'txRegisteredCount',
      title: '应用宝',
      dataIndex: 'txRegisteredCount'
    },{
      key: 'baiduRegisteredCount',
      title: '百度',
      dataIndex: 'baiduRegisteredCount'
    },{
      key: 'lianxiangRegisteredCount',
      title: '联想',
      dataIndex: 'lianxiangRegisteredCount'
    },{
      key: 'sanxinRegisteredCount',
      title: '三星',
      dataIndex: 'sanxinRegisteredCount'
    },{
      key: 'rongyaoRegisteredCount',
      title: '荣耀',
      dataIndex: 'rongyaoRegisteredCount'
    },{
      key: 'dau',
      title: '日活',
      dataIndex: 'dau'
    },{
      key: 'chatUserNumber',
      title: '聊天人数',
      dataIndex: 'chatUserNumber'
    },{
      key: 'videoUserNumber',
      title: '视频人数',
      dataIndex: 'videoUserNumber'
    },{
      key: 'rechargeCount',
      title: 'RMB消费次数',
      dataIndex: 'rechargeCount'
    },{
      key: 'firstRechargeCount',
      title: '首充人数',
      dataIndex: 'firstRechargeCount'
    },{
      key: 'rechargeUserCount',
      title: 'RMB消费人数',
      dataIndex: 'rechargeUserCount'
    },{
      key: 'rechargeAmount',
      title: 'RMB消费总金额（元）',
      dataIndex: 'rechargeAmount'
    },{
      key: 'videoChartMinute',
      title: '视频聊天分钟数',
      dataIndex: 'videoChartMinute'
    },{
      key: 'videoChartCoin',
      title: '视频聊天消费金币',
      dataIndex: 'videoChartCoin'
    },{
      key: 'giftCount',
      title: '礼物个数',
      dataIndex: 'giftCount'
    },{
      key: 'giftCoin',
      title: '礼物消费金币数',
      dataIndex: 'giftCoin'
    },{
      key: 'anchorCashAmount',
      title: '主播提现金额',
      dataIndex: 'anchorCashAmount'
    },{
      key: 'profit',
      title: '利润',
      dataIndex: 'profit'
    }],
    financialStatementsTotal: 0,
    financialStatementsList: null,
    selectedRows:[],

    csvData : [
      { firstname: "Ahmed", lastname: "Tomi", email: "ah@smthing.co.com" },
      { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
      { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
    ]
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 财务报表 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var finStatementParam = {
      pageParam : pageParam,
    }
    finStatementService.getFinStatementPage(finStatementParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({financialStatementsList: res.retValue.list, financialStatementsTotal: res.retValue.total});
        }
      }
    )

    // 导出数据准备
    var timeStart = this.getMonthStartDay();
    var timeEnd = this.getMonthLastDay();

    var finStatementParam2 = {
      startTime: timeStart,
      endTime: timeEnd,
    }
    finStatementService.getFinStatementList(finStatementParam2)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 导出数据处理
          this.dataRowHandle(res.retValue);
        }
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 财务报表 ————————————————————————————

  // 获取动态列表
  handleGetFinancialStatementsPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finStatementParam = {
        startTime: timeStart,
        endTime: timeEnd,
        pageParam : pageParam,
      }
      finStatementService.getFinStatementPage(finStatementParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({financialStatementsList: res.retValue.list, financialStatementsTotal: res.retValue.total});
          }
        }
      )

      // 导出数据准备
      if(timeStart == null || timeEnd == null) {
        var timeStart = this.getMonthStartDay();
        var timeEnd = this.getMonthLastDay();
      }
      var finStatementParam2 = {
        startTime: timeStart ,
        endTime: timeEnd,
      }
      finStatementService.getFinStatementList(finStatementParam2)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            // 导出数据处理
            this.dataRowHandle(res.retValue);
          }
        }
      )
      
    })
  
  }

  // 用户分页
  financialStatementsChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var timeStart = null;
        var timeEnd = null;
        if(values.sendTime != null) {
          timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
          timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
        }

        var finStatementParam = {
          startTime: timeStart,
          endTime: timeEnd,
          pageParam : pageParam,
        }
        finStatementService.getFinStatementPage(finStatementParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({financialStatementsList: res.retValue.list, financialStatementsTotal: res.retValue.total});
            }
          }
        )
    })
  }

  // 刷新数据
  refreshFinancialStatementsData=() => {
    let { history } = this.props;
    //console.log("refreshFinancialStatementsData");
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    }

    var finStatementParam = {
      pageParam : pageParam,
    }
    finStatementService.getFinStatementPage(finStatementParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({financialStatementsList: res.retValue.list, financialStatementsTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 导出数据处理
   * @param {*} contents 
   */
  dataRowHandle = (contents) => {
      var rows = [];
      contents.forEach((item)=>{
        var data = {
          "统计日期": item.dayOfStatement,
          "注册人数": item.registerUserCount,
          "自然人数": item.realRegisteredCount,
          "小米": item.xiaomiRegisteredCount,
          "vivo": item.vivoRegisteredCount,
          "华为": item.huaweiRegisteredCount,
          "oppo": item.oppoRegisteredCount,
          "魅族": item.meizuRegisteredCount,
          "360": item.threeSixRegisteredCount,
          "阿里": item.aliRegisteredCount,
          "应用宝": item.txRegisteredCount,
          "百度": item.baiduRegisteredCount,
          "联想": item.lianxiangRegisteredCount,
          "三星": item.sanxinRegisteredCount,
          "荣耀": item.rongyaoRegisteredCount,
          "日活": item.dau,
          "聊天人数": item.chatUserNumber,
          "视频人数": item.videoUserNumber,
          "RMB消费次数": item.rechargeCount,
          "首充人数": item.firstRechargeCount,
          "RMB消费人数": item.rechargeUserCount,
          "RMB消费总金额（元）": item.rechargeAmount,
          "视频聊天分钟数": item.videoChartMinute,
          "视频聊天消费金币": item.videoChartCoin,
          "礼物个数": item.giftCount,
          "礼物消费金币数": item.giftCoin,
          "主播提现金额": item.anchorCashAmount,
          "利润": item.profit,
        }
        rows.push(data);
      });

      this.setState({selectedRows: rows});
  }

  /**
   * 本月第一天
   * @returns 
   */
  getMonthStartDay = () => {
    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    var d = '01'
    m = m < 10 ? '0' + m : m; //月份补 0
    
    return [y,m,d].join("-") + " 00:00:00";
  };

  /**
   * 获取本月最后一天
   * @returns 
   */
  getMonthLastDay = () => {
    var y = new Date().getFullYear(); //获取年份
    var m = new Date().getMonth() + 1; //获取月份
    var d = new Date(y, m, 0).getDate(); //获取当月最后一日
    m = m < 10 ? '0' + m : m; //月份补 0
    d = d < 10 ? '0' + d : d; //日数补 0
 
    return [y,m,d].join("-")+ " 23:59:59";
  };


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/financial_statements">财务报表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="FinancialStatementsListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={8} key={3}>
                      <Form.Item label="统计时间" name="sendTime">
                          <RangePicker />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={4} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetFinancialStatementsPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <CSVLink data={this.state.selectedRows} >
                        导出报表
                      </CSVLink>
                      
                      
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.financialStatementsList}
                columns={this.state.financialStatementsColumns}
                rowSelection={this.userRowSelection}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.financialStatementsTotal, defaultCurrent: 1, onChange: this.financialStatementsChangePage}}
              >
              </Table>
            
        </StickyContainer>
        
      </div>
    );
  }
}
export default FinancialStatements;