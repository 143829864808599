import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import keywordManageService from '../../../Service/BasicInformation/keywordManageService';
import customerServiceSettingService from '../../../Service/BasicInformation/customerServiceSettingService';
import Service from '../../../Service/index';
import imageConfigService from '../../../Service/BasicInformation/imageConfigService';
import backpackManageService from '../../../Service/BasicInformation/backpackManageService';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class BasicSettings extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state={

    // ———————————————————— 关键词屏蔽 ————————————————————————————
    basicSettings: null,

    // ———————————————————— 客服设置 ————————————————————————————
    customerSettings: null,
    wechatCodeList: null,

    // ———————————————————— App下载背景图 ————————————————————————————
    imageConfig: null,
    downloadImgList: null,
    downloadBtnImgList: null,
    iosTipGifList: null,


    // ———————————————————— 系统背景图设置 ————————————————————————————
    backpackManage: null,
    videoLinkList: null,
    whoLooksAtMeList: null,

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 关键词屏蔽 ————————————————————————————
    this.getKeywordManage();
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }


// ———————————————————— 关键词屏蔽 ————————————————————————————
 
  handleBasicSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {

        var adminKeywordManage = values;
        keywordManageService.updateConfig(adminKeywordManage)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  // ———————————————————— 客服设置 ————————————————————————————
  handleCustomerSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
      console.log("handleCustomerSettingsSubmit values="+JSON.stringify(values));

        var customerServiceSetting = values;
        customerServiceSettingService.updateConfig(customerServiceSetting)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }


  // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {

  };

  handleWechatCodeChange = ({ file, fileList }) => {
    this.setState({ wechatCodeList: [...fileList] });
  };
  // ———————————————————— App下载背景图 ————————————————————————————
 
  handleImageConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
        console.log("handleImageConfigSubmit values="+JSON.stringify(values));

        var appImageConfig = values;
        imageConfigService.updateConfig(appImageConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }

  handleDownloadImgChange = ({ file, fileList }) => {
    this.setState({ downloadImgList: [...fileList] });
  };

  handleDownloadBtnImgChange = ({ file, fileList }) => {
    this.setState({ downloadBtnImgList: [...fileList] });
  };

  handleIosTipGifChange = ({ file, fileList }) => {
    this.setState({ iosTipGifList: [...fileList] });
  };
  // ———————————————————— 系统背景图设置 ————————————————————————————

  handleBackpackManageSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {
      console.log("handleBackpackManageSubmit values="+JSON.stringify(values));

        var appBackpackManage = values;
        backpackManageService.updateConfig(appBackpackManage)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }

  handleVideoLinkChange = ({ file, fileList }) => {
    this.setState({ videoLinkList: [...fileList] });
  };

  handleWhoLooksAtMeChange = ({ file, fileList }) => {
    this.setState({ whoLooksAtMeList: [...fileList] });
  };



  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getKeywordManage();
    } else if(key == "2") {
      this.getCustomerServiceSetting();
    } else if(key == "3") {
      this.getImageConfig();
    } else if(key == "4") {
      this.getBackpackManage();
    } 
  }

  /**
   * 关键词屏蔽
   */
  getKeywordManage = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    keywordManageService.getConfig()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef.current.setFieldsValue({
            id: res.retValue.id,
            keyword: res.retValue.keyword,
            nameUseStar: res.retValue.nameUseStar,
          });

          this.setState({basicSettings: res.retValue})

        }
        
      }
    )   
  }

  /**
   * 客服设置
   */
  getCustomerServiceSetting = () => {
    let { history } = this.props;
    customerServiceSettingService.getConfig()
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.formRef2.current.setFieldsValue({
              id: res.retValue.id,
              consumerHotline: res.retValue.consumerHotline,
              complaintTelephone: res.retValue.complaintTelephone,
              qq: res.retValue.qq,
              wechat: res.retValue.wechat,
              wechatCode: res.retValue.wechatCode,
            });

            this.setState({wechatCodeList: [{
              url : res.retValue.wechatCode,
              thumbUrl: res.retValue.wechatCode,
            }]});

            this.setState({customerSettings: res.retValue})

          }

        }
      ) 

  }

  /**
   * App下载背景图
   */
  getImageConfig = () => {
    let { history } = this.props;
    imageConfigService.getConfig()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef3.current.setFieldsValue({
            id: res.retValue.id,
            downloadBtnTop: res.retValue.downloadBtnTop,
            downloadBtnLeft: res.retValue.downloadBtnLeft,
            downImgScale: res.retValue.downImgScale,
            downloadImg: res.retValue.downloadImg,
            downloadBtnImg: res.retValue.downloadBtnImg,
            iosTipGif: res.retValue.iosTipGif,
          });

          this.setState({downloadImgList: [{
            url : res.retValue.downloadImg,
            thumbUrl: res.retValue.downloadImg,
          }]});

          this.setState({downloadBtnImgList: [{
            url : res.retValue.downloadBtnImg,
            thumbUrl: res.retValue.downloadBtnImg,
          }]});

          this.setState({iosTipGifList: [{
            url : res.retValue.iosTipGif,
            thumbUrl: res.retValue.iosTipGif,
          }]});

          this.setState({imageConfig: res.retValue})

        }

      }
    ) 
  }

  /**
   * 系统背景图设置
   */
  getBackpackManage = () => {
    let { history } = this.props;
    backpackManageService.getConfig()
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef4.current.setFieldsValue({
            id: res.retValue.id,
            videoLink: res.retValue.videoLink,
            whoLooksAtMe: res.retValue.whoLooksAtMe,
          });

          this.setState({videoLinkList: [{
            url : res.retValue.videoLink,
            thumbUrl: res.retValue.videoLink,
          }]});

          this.setState({whoLooksAtMeList: [{
            url : res.retValue.whoLooksAtMe,
            thumbUrl: res.retValue.whoLooksAtMe,
          }]});

          this.setState({backpackManage: res.retValue})

        }
        
      }
    )  
  }


  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/basic_settings">基础设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // —————————————————————— 基础设置 ————————————————————————
            <TabPane tab="基础设置" key="1">
              <Form
                  name="BasicSettingsForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.basicSettings}

                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="关键词" name="keyword" labelCol={{span:4}} style={{ width: 800 }} >
                          <TextArea  style={{ height: 200, marginBottom: 24 }} />
                      </Form.Item>

                      <Form.Item label="用户违规昵称使用*替换" labelCol={{span:10}} name="nameUseStar" style={{ width: 420 }} initialValue={0} >
                          <Radio.Group name="nameUseStar" defaultValue={0}>
                              <Radio value={0}>关闭</Radio>
                              <Radio value={1}>开启</Radio>
                          </Radio.Group>
                      </Form.Item>
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleBasicSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>

            </TabPane>

            // —————————————————————— 客服设置 ————————————————————————
            <TabPane tab="客服设置" key="2">
              <Form
                  name="CustomerSettingsForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.customerSettings}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="客服电话" name="consumerHotline" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="客服电话"  />
                      </Form.Item>

                      <Form.Item label="申诉电话" name="complaintTelephone" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="申诉电话"  />
                      </Form.Item>

                      <Form.Item label="客服QQ" name="qq" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="客服QQ"  />
                      </Form.Item>

                      <Form.Item label="客服微信" name="wechat" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input placeholder="客服微信"  />
                      </Form.Item>

                      <Form.Item label="客服微信二维码" name="wechatCode" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileImage}

                          >
                          <Upload
                              accept="image/*"
                              name="fiavatarle"
                              listType="picture-card" 
                              onChange={this.handleWechatCodeChange}
                              customRequest={this.doImgUpload}
                              beforeUpload={this.beforeUpload}
                              defaultFileList={this.state.wechatCodeList}
                              fileList={this.state.wechatCodeList}
                          >
                              {this.uploadButton}
                          </Upload>
                      </Form.Item>
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleCustomerSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— App下载背景图 ————————————————————————
            <TabPane tab="App下载背景图" key="3">
              <Form
                  name="ImageConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.imageConfig}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="下载页面背景图" name="downloadImg" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileImage}

                          >
                          <Upload
                              accept="image/*"
                              name="fiavatarle"
                              listType="picture-card" 
                              onChange={this.handleDownloadImgChange}
                              customRequest={this.doImgUpload}
                              beforeUpload={this.beforeUpload}
                              defaultFileList={this.state.downloadImgList}
                              fileList={this.state.downloadImgList}
                          >
                              {this.uploadButton}
                          </Upload>
                      </Form.Item>

                      <Form.Item label="下载按钮图片" name="downloadBtnImg" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileImage}

                          >
                          <Upload
                              accept="image/*"
                              name="fiavatarle"
                              listType="picture-card" 
                              onChange={this.handleDownloadBtnImgChange}
                              customRequest={this.doImgUpload}
                              beforeUpload={this.beforeUpload}
                              defaultFileList={this.state.downloadBtnImgList}
                              fileList={this.state.downloadBtnImgList}
                          >
                              {this.uploadButton}
                          </Upload>
                      </Form.Item>

                      <Form.Item label="下载按钮top位置(%)" name="downloadBtnTop" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input   />
                      </Form.Item>

                      <Form.Item label="下载按钮Left位置(%)" name="downloadBtnLeft" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input  />
                      </Form.Item>

                      <Form.Item label="下载按钮占屏幕宽度(%)" name="downImgScale" labelCol={{span:10}} style={{ width: 420 }} >
                          <Input  />
                      </Form.Item>

                      <Form.Item label="IOS下载提示" name="iosTipGif" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileImage}

                          >
                          <Upload
                              accept="image/*"
                              name="fiavatarle"
                              listType="picture-card" 
                              onChange={this.handleIosTipGifChange}
                              customRequest={this.doImgUpload}
                              beforeUpload={this.beforeUpload}
                              defaultFileList={this.state.iosTipGifList}
                              fileList={this.state.iosTipGifList}
                          >
                              {this.uploadButton}
                          </Upload>
                      </Form.Item>
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleImageConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 系统背景图设置 ————————————————————————
            <TabPane tab="系统背景图设置" key="4">
              <Form
                  name="BackpackManageForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef4}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.backpackManage}
                >

                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="视频连麦背景图" name="videoLink" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileImage}

                          >
                          <Upload
                              accept="image/*"
                              name="fiavatarle"
                              listType="picture-card" 
                              onChange={this.handleVideoLinkChange}
                              customRequest={this.doImgUpload}
                              beforeUpload={this.beforeUpload}
                              defaultFileList={this.state.videoLinkList}
                              fileList={this.state.videoLinkList}
                          >
                              {this.uploadButton}
                          </Upload>
                      </Form.Item>

                      <Form.Item label="谁看过我(开通贵族提示窗)" name="whoLooksAtMe" 
                          valuePropName="file"
                          labelCol={{span:10}} 
                          style={{ width: 420 }}
                          getValueFromEvent={this.handleChangeFileImage}

                          >
                          <Upload
                              accept="image/*"
                              name="fiavatarle"
                              listType="picture-card" 
                              onChange={this.handleWhoLooksAtMeChange}
                              customRequest={this.doImgUpload}
                              beforeUpload={this.beforeUpload}
                              defaultFileList={this.state.whoLooksAtMeList}
                              fileList={this.state.whoLooksAtMeList}
                          >
                              {this.uploadButton}
                          </Upload>
                      </Form.Item>
                    
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleBackpackManageSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default BasicSettings;