import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取提现账号
   * @param {*} adminUserCashAccount
   * @returns
   */
  getCashAccountPage(adminUserCashAccount) {
      console.log("adminUserCashAccount=" + JSON.stringify(adminUserCashAccount));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminUserCashAccount/getCashAccountPage",
        data: adminUserCashAccount,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新提现账号
   * @param {*} adminUserCashAccount
   * @returns
   */
  insertOrUpdateCashAccount(adminUserCashAccount) {
        //console.log("adsParam=" + JSON.stringify(adsParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminUserCashAccount/insertOrUpdateCashAccount",
        data: adminUserCashAccount,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除提现账号
     * @param {*} adminUserCashAccount 
     * @returns 
     */
    deleteCashAccount(adminUserCashAccount) {
        //console.log("adsParam=" + JSON.stringify(adsParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminUserCashAccount/deleteCashAccount",
        data: adminUserCashAccount,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


  /**
   * 获取提现账号列表
   * @param {*} adminUserCashAccount
   * @returns
   */
  getCashAccountList(adminUserCashAccount) {
    console.log("adminUserCashAccount=" + JSON.stringify(adminUserCashAccount));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/adminUserCashAccount/getCashAccountList",
      data: adminUserCashAccount,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}