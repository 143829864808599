import axios from "axios";
import Qs from 'qs';
import {GetLoginToken} from '../../Common/Auth.js';


export default {
  /**
   * 查询用户关联角色
   * @param {*} userid 
   * @returns 
   */
   getRelListByUserId(userid) {

    let data = {
      userid : userid
    }

    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/userRole/getRelListByUserId",
      data: Qs.stringify(data),
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

    /**
   * 更新用户角色
   * @param {*} data
   * @returns
   */
     updateRelByUserId(userRoleParam) {
      console.log("userRoleParam=" + JSON.stringify(userRoleParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/userRole/updateRelByUserId",
        data: userRoleParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
};
