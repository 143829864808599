import React, { Component } from 'react';
import { Layout } from 'antd';
import Top from '../../Components/Top';
import MenuBar from '../../Components/MenuBar';
import { Route, Switch } from 'react-router-dom';
import UserMgr from '../Admin/UserMgr';
import RoleMgr from '../Admin/RoleMgr';
import Per1Mgr from '../Admin/Per1Mgr';
import Per2Mgr from '../Admin/Per2Mgr';
import About from '../About';
import axios from 'axios';
import AuthRoute from '../../Components/AuthRoute';
import { GetLoginToken }  from '../../Common/Auth';
// 用户管理
import OrdinaryUsers from '../UserMgr/OrdinaryUsers';
import HostUser from '../UserMgr/HostUser';
import BindUser from '../UserMgr/BindUser';
import UserReports from '../UserMgr/UserReports';
import GuardList from '../UserMgr/GuardList';
import CustomerList from '../UserMgr/CustomerList';
import QxUser from '../UserMgr/QxUser';

// 等级管理
import UserLevel from '../GradeMgr/UserLevel';
import WealthLevel from '../GradeMgr/WealthLevel';
import HostLevel from '../GradeMgr/HostLevel';
import CharmLevel from '../GradeMgr/CharmLevel';
import MedalMgr from '../GradeMgr/MedalMgr';
import HierarchicalPrivilege from '../GradeMgr/HierarchicalPrivilege';
import GradeGift from '../GradeMgr/GradeGift';
import FastIncreaseLevel from '../GradeMgr/FastIncreaseLevel';
// 贵族管理
import NobleLevel from '../NobleMgr/NobleLevel';
import NoblePrivilege from '../NobleMgr/NoblePrivilege';
import NoblePrice from '../NobleMgr/NoblePrice';
import NobleList from '../NobleMgr/NobleList';
import NobleMedal from '../NobleMgr/NobleMedal';
import NoblePrivilegeFront from '../NobleMgr/NoblePrivilegeFront';

// 1vs1管理
import OnevsOneUser from '../1vs1Mgr/OnevsOneUser';
import OnevsOneSetting from '../1vs1Mgr/OnevsOneSetting';
import BeggingChat from '../1vs1Mgr/BeggingChat';
import OnevsOneMonitor from '../1vs1Mgr/OnevsOneMonitor';
import MusicManagement from '../1vs1Mgr/MusicManagement';
// 动态管理
import DynamicList from '../Dynamic/DynamicList';
import DynamicTopic from '../Dynamic/DynamicTopic';
import DynamicSettings from '../Dynamic/DynamicSettings';
import DynamicReporting from '../Dynamic/DynamicReporting';
// 私信
import MessageSettings from '../PrivateLetter/MessageSettings';
import SimulateMessage from '../PrivateLetter/SimulateMessage';
import CommonWords from '../PrivateLetter/CommonWords';
import MessageLogging from '../PrivateLetter/MessageLogging';
import Shortcut from '../PrivateLetter/Shortcut';


// 礼物
import GiftSettings from '../GiftMgr/GiftSettings';
// 运营
import OperationalBasis from '../Operation/OperationalBasis';
import BasicActivities from '../Operation/BasicActivities';
import SystemBulletin from '../Operation/SystemBulletin';
// 财务
import FinancialStatistics from '../FinanceMgr/FinancialStatistics';
import FinancialStatements from '../FinanceMgr/FinancialStatements';
import RevenueExpenseDetails from '../FinanceMgr/RevenueExpenseDetails';
import PaymentRecord from '../FinanceMgr/PaymentRecord';
import RechargeRecord from '../FinanceMgr/RechargeRecord';
import ConsumptionRecord from '../FinanceMgr/ConsumptionRecord';
import NoblePurchaseRecord from '../FinanceMgr/NoblePurchaseRecord';
import PlatformRevenue from '../FinanceMgr/PlatformRevenue';
import WithdrawalRecord from '../FinanceMgr/WithdrawalRecord';
import WithdrawalSettings from '../FinanceMgr/WithdrawalSettings';
import RechargeSettings from '../FinanceMgr/RechargeSettings';
import PriceSetting from '../FinanceMgr/PriceSetting';
import FinancialStatisticsHmy from '../FinanceMgr/FinancialStatisticsHmy';

// 基础信息设置
import BasicSettings from '../BasicInformation/BasicSettings';
import ProtocolSettings from '../BasicInformation/ProtocolSettings';
import AppVersionMgr from '../BasicInformation/AppVersionMgr';
import LoginSharingSettings from '../BasicInformation/LoginSharingSettings';
import InvitationSettings from '../BasicInformation/InvitationSettings';
import GlobalPopSettings from '../BasicInformation/GlobalPopSettings';
import AutoYellowIdentify from '../BasicInformation/AutoYellowIdentify';
import ProtocolSettingsHmy from '../BasicInformation/ProtocolSettingsHmy';
import AppVersionMgrHmy from '../BasicInformation/AppVersionMgrHmy';

// 系统设置
import CoinSettings from '../SystemSetting/CoinSettings';
import AuthDataSetting from '../SystemSetting/AuthDataSetting';
import BackgroundSettings from '../SystemSetting/BackgroundSettings';
import PaymentSettings from '../SystemSetting/PaymentSettings';
import ThreePartySetting from '../SystemSetting/ThreePartySetting';
import LogView from '../SystemSetting/LogView';
import CloudStorageFiles from '../SystemSetting/CloudStorageFiles';
import PaymentSettingsHmy from '../SystemSetting/PaymentSettingsHmy';
// 个人中心
import UserPassword from '../Admin/UserPassword';
// 系统初始化
import CacheMgr from '../SystemInit/CacheMgr';
import DBMgr from '../SystemInit/DBMgr';
import IMMgr from '../SystemInit/IMMgr';
// 短视频
import VideoList from '../VideoMgr/VideoList';
import VideoSetting from '../VideoMgr/VideoSetting';
import VideoAds from '../VideoMgr/VideoAds';
// 代理
import ProxyList from '../ProxyMgr/ProxyList';
import ProxyWithdrawalList from '../ProxyMgr/ProxyWithdrawalList';
import ProxySecondList from '../ProxyMgr/ProxySecondList';

// 我的代理
import AgentDataStatistics from '../MyAgent/AgentDataStatistics';
import AgentInfo from '../MyAgent/AgentInfo';
import WithdrawalAccountList from '../MyAgent/WithdrawalAccountList';
import MyWallet from '../MyAgent/MyWallet';
import AgentCommissionRecord from '../MyAgent/AgentCommissionRecord';
import InviteUserList from '../MyAgent/InviteUserList';
import AgentFinancialStatements from '../MyAgent/AgentFinancialStatements';
import SecondAgent from '../MyAgent/SecondAgent';

// 引流
import FlowList from '../FlowMgr/FlowList';
import FlowSettings from '../FlowMgr/FlowSettings';

// 扩列
import KuolieList from '../Kuolie/KuolieList';
import KuolieSettings from '../Kuolie/KuolieSettings';

// 赚金币
import AdmodSettings from '../Admod/AdmodSettings';
import AdmodStatistics from '../Admod/AdmodStatistics';



const { Header , Footer, Sider, Content} = Layout;
class Home extends Component {

  constructor(props) {
    super(props);
    // 设置当前用户请求的token
    axios.defaults.headers['Authorization'] = GetLoginToken();
    sessionStorage.removeItem('LOGIN_USER_PER');
  }

  render() {
    const {match} = this.props;
    //console.log('match.path='+match.path);

    return (
      <Layout style={{Height: '100vh'}}>
        <Header style={{color: '#fff', padding: '0 15px'}}>
          <Top history={this.props.history}></Top>
        </Header>
        <Layout>
          <Sider style={{backgroundColor: '#FFF'}}>
            <MenuBar history={this.props.history}></MenuBar>
          </Sider>
          <Content style={{padding: '15px'}}>
            <Switch>
              // 用户管理
              <AuthRoute per={2001} path={`${match.path}/ordinary_users`} component={OrdinaryUsers}></AuthRoute>
              <AuthRoute per={2002} path={`${match.path}/host_user`} component={HostUser}></AuthRoute>
              <AuthRoute per={2003} path={`${match.path}/bind_user`} component={BindUser}></AuthRoute>
              <AuthRoute per={2004} path={`${match.path}/user_reports`} component={UserReports}></AuthRoute>
              <AuthRoute per={2005} path={`${match.path}/guard_list`} component={GuardList}></AuthRoute>
              <AuthRoute per={2006} path={`${match.path}/customer_list`} component={CustomerList}></AuthRoute>
              <AuthRoute per={2007} path={`${match.path}/qxUser_list`} component={QxUser}></AuthRoute>

              // 等级管理
              <AuthRoute per={3001} path={`${match.path}/user_level`} component={UserLevel}></AuthRoute>
              <AuthRoute per={3002} path={`${match.path}/wealth_level`} component={WealthLevel}></AuthRoute>
              <AuthRoute per={3003} path={`${match.path}/host_level`} component={HostLevel}></AuthRoute>
              <AuthRoute per={3004} path={`${match.path}/charm_level`} component={CharmLevel}></AuthRoute>
              <AuthRoute per={3005} path={`${match.path}/medal_mgr`} component={MedalMgr}></AuthRoute>
              <AuthRoute per={3006} path={`${match.path}/hierarchical_privilege`} component={HierarchicalPrivilege}></AuthRoute>
              <AuthRoute per={3007} path={`${match.path}/grade_gift`} component={GradeGift}></AuthRoute>
              <AuthRoute per={3008} path={`${match.path}/fast_increase_level`} component={FastIncreaseLevel}></AuthRoute>

              // 贵族管理
              <AuthRoute per={4001} path={`${match.path}/noble_level`} component={NobleLevel}></AuthRoute>
              <AuthRoute per={4002} path={`${match.path}/noble_privilege`} component={NoblePrivilege}></AuthRoute>
              <AuthRoute per={4003} path={`${match.path}/noble_price`} component={NoblePrice}></AuthRoute>
              <AuthRoute per={4004} path={`${match.path}/noble_list`} component={NobleList}></AuthRoute>
              <AuthRoute per={4005} path={`${match.path}/noble_medal`} component={NobleMedal}></AuthRoute>
              <AuthRoute per={4005} path={`${match.path}/noble_medal`} component={NobleMedal}></AuthRoute>
              <AuthRoute per={4006} path={`${match.path}/noble_privilege_front`} component={NoblePrivilegeFront}></AuthRoute>

              // 1vs1管理
              <AuthRoute per={5001} path={`${match.path}/1vs1_user`} component={OnevsOneUser}></AuthRoute>
              <AuthRoute per={5002} path={`${match.path}/1vs1_setting`} component={OnevsOneSetting}></AuthRoute>
              <AuthRoute per={5003} path={`${match.path}/begging_chat`} component={BeggingChat}></AuthRoute>
              <AuthRoute per={5004} path={`${match.path}/1vs1_monitor`} component={OnevsOneMonitor}></AuthRoute>
              <AuthRoute per={5005} path={`${match.path}/music_management`} component={MusicManagement}></AuthRoute>

              // 动态管理
              <AuthRoute per={6001} path={`${match.path}/dynamic_list`} component={DynamicList}></AuthRoute>
              <AuthRoute per={6002} path={`${match.path}/dynamic_topic`} component={DynamicTopic}></AuthRoute>
              <AuthRoute per={6003} path={`${match.path}/dynamic_settings`} component={DynamicSettings}></AuthRoute>
              <AuthRoute per={6004} path={`${match.path}/dynamic_reporting`} component={DynamicReporting}></AuthRoute>

              // 私信管理
              <AuthRoute per={7001} path={`${match.path}/message_settings`} component={MessageSettings}></AuthRoute>
              <AuthRoute per={7002} path={`${match.path}/simulate_message`} component={SimulateMessage}></AuthRoute>
              <AuthRoute per={7003} path={`${match.path}/common_words`} component={CommonWords}></AuthRoute>
              <AuthRoute per={7004} path={`${match.path}/message_logging`} component={MessageLogging}></AuthRoute>
              <AuthRoute per={7005} path={`${match.path}/shortcut`} component={Shortcut}></AuthRoute>

              // 礼物管理
              <AuthRoute per={8001} path={`${match.path}/gift_settings`} component={GiftSettings}></AuthRoute>
              
              // 运营管理
              <AuthRoute per={9001} path={`${match.path}/operational_basis`} component={OperationalBasis}></AuthRoute>
              <AuthRoute per={9002} path={`${match.path}/basic_activities`} component={BasicActivities}></AuthRoute>
              <AuthRoute per={9004} path={`${match.path}/system_bulletin`} component={SystemBulletin}></AuthRoute>

              // 财务管理
              <AuthRoute per={10001} path={`${match.path}/financial_statistics`} component={FinancialStatistics}></AuthRoute>
              <AuthRoute per={10002} path={`${match.path}/financial_statements`} component={FinancialStatements}></AuthRoute>
              <AuthRoute per={10003} path={`${match.path}/revenue_expense_details`} component={RevenueExpenseDetails}></AuthRoute>
              <AuthRoute per={10004} path={`${match.path}/payment_record`} component={PaymentRecord}></AuthRoute>
              <AuthRoute per={10005} path={`${match.path}/recharge_record`} component={RechargeRecord}></AuthRoute>
              <AuthRoute per={10006} path={`${match.path}/consumption_record`} component={ConsumptionRecord}></AuthRoute>
              <AuthRoute per={10007} path={`${match.path}/noble_purchase_record`} component={NoblePurchaseRecord}></AuthRoute>
              <AuthRoute per={10009} path={`${match.path}/platform_revenue`} component={PlatformRevenue}></AuthRoute>
              <AuthRoute per={10010} path={`${match.path}/withdrawal_record`} component={WithdrawalRecord}></AuthRoute>
              <AuthRoute per={10011} path={`${match.path}/withdrawal_settings`} component={WithdrawalSettings}></AuthRoute>
              <AuthRoute per={10012} path={`${match.path}/recharge_settings`} component={RechargeSettings}></AuthRoute>
              <AuthRoute per={10013} path={`${match.path}/price_setting`} component={PriceSetting}></AuthRoute>
              <AuthRoute per={10014} path={`${match.path}/financial_statistics_hmy`} component={FinancialStatisticsHmy}></AuthRoute>


              // 基础信息设置
              <AuthRoute per={13001} path={`${match.path}/basic_settings`} component={BasicSettings}></AuthRoute>
              <AuthRoute per={13002} path={`${match.path}/protocol_settings`} component={ProtocolSettings}></AuthRoute>
              <AuthRoute per={13003} path={`${match.path}/app_version_mgr`} component={AppVersionMgr}></AuthRoute>
              <AuthRoute per={13004} path={`${match.path}/login_sharing_settings`} component={LoginSharingSettings}></AuthRoute>
              <AuthRoute per={13005} path={`${match.path}/invitation_settings`} component={InvitationSettings}></AuthRoute>
              <AuthRoute per={13006} path={`${match.path}/global_pop_settings`} component={GlobalPopSettings}></AuthRoute>
              <AuthRoute per={13007} path={`${match.path}/auto_yellow_identify`} component={AutoYellowIdentify}></AuthRoute>
              <AuthRoute per={13008} path={`${match.path}/protocol_settings_hmy`} component={ProtocolSettingsHmy}></AuthRoute>
              <AuthRoute per={13009} path={`${match.path}/app_version_mgr_hmy`} component={AppVersionMgrHmy}></AuthRoute>

              
              // 系统设置
              <AuthRoute per={14001} path={`${match.path}/coin_settings`} component={CoinSettings}></AuthRoute>
              <AuthRoute per={14002} path={`${match.path}/auth_data_setting`} component={AuthDataSetting}></AuthRoute>
              <AuthRoute per={14003} path={`${match.path}/background_settings`} component={BackgroundSettings}></AuthRoute>
              <AuthRoute per={14004} path={`${match.path}/payment_settings`} component={PaymentSettings}></AuthRoute>
              <AuthRoute per={14005} path={`${match.path}/three_party_setting`} component={ThreePartySetting}></AuthRoute>
              <AuthRoute per={14006} path={`${match.path}/log_view`} component={LogView}></AuthRoute>
              <AuthRoute per={14008} path={`${match.path}/payment_settings_hmy`} component={PaymentSettingsHmy}></AuthRoute>
              {/* <AuthRoute per={14007} path={`${match.path}/cloud_storage_files`} component={CloudStorageFiles}></AuthRoute> */}

              // 个人中心
              <AuthRoute per={15001} path={`${match.path}/user_password`} component={UserPassword}></AuthRoute>

              // 后台管理
              <AuthRoute per={16001} path={`${match.path}/user_mgr`} component={UserMgr}></AuthRoute>
              <AuthRoute per={16002} path={`${match.path}/role_mgr`} component={RoleMgr}></AuthRoute>
              <AuthRoute per={16003} path={`${match.path}/per1_mgr`} component={Per1Mgr}></AuthRoute>
              <AuthRoute per={16004} path={`${match.path}/per2_mgr`} component={Per2Mgr}></AuthRoute>

              // 系统初始化
              <AuthRoute per={17001} path={`${match.path}/cache_mgr`} component={CacheMgr}></AuthRoute>
              <AuthRoute per={17002} path={`${match.path}/db_mgr`} component={DBMgr}></AuthRoute>
              <AuthRoute per={17003} path={`${match.path}/im_mgr`} component={IMMgr}></AuthRoute>

              // 短视频列表
              <AuthRoute per={18001} path={`${match.path}/video_list`} component={VideoList}></AuthRoute>
              <AuthRoute per={18002} path={`${match.path}/video_setting`} component={VideoSetting}></AuthRoute>
              <AuthRoute per={18003} path={`${match.path}/video_ads`} component={VideoAds}></AuthRoute>

              // 代理列表
              <AuthRoute per={12001} path={`${match.path}/proxy_list`} component={ProxyList}></AuthRoute>
              <AuthRoute per={12002} path={`${match.path}/proxy_withdrawal_list`} component={ProxyWithdrawalList}></AuthRoute>
              <AuthRoute per={12003} path={`${match.path}/proxy_second_list`} component={ProxySecondList}></AuthRoute>

              
              // 我的代理
              <AuthRoute per={19001} path={`${match.path}/agent_data_statistics`} component={AgentDataStatistics}></AuthRoute>
              <AuthRoute per={19002} path={`${match.path}/agent_info`} component={AgentInfo}></AuthRoute>
              <AuthRoute per={19004} path={`${match.path}/withdrawal_account_list`} component={WithdrawalAccountList}></AuthRoute>
              <AuthRoute per={19005} path={`${match.path}/my_wallet`} component={MyWallet}></AuthRoute>
              <AuthRoute per={19006} path={`${match.path}/agent_commission_record`} component={AgentCommissionRecord}></AuthRoute>
              <AuthRoute per={19007} path={`${match.path}/invite_user_list`} component={InviteUserList}></AuthRoute>
              <AuthRoute per={19008} path={`${match.path}/agent_financial_statements`} component={AgentFinancialStatements}></AuthRoute>
              <AuthRoute per={19009} path={`${match.path}/second_agent`} component={SecondAgent}></AuthRoute>

              // 引流管理
              <AuthRoute per={20001} path={`${match.path}/flow_list`} component={FlowList}></AuthRoute>
              <AuthRoute per={20002} path={`${match.path}/flow_settings`} component={FlowSettings}></AuthRoute>

              // 扩列管理
              <AuthRoute per={21001} path={`${match.path}/kuolie_list`} component={KuolieList}></AuthRoute>
              <AuthRoute per={21002} path={`${match.path}/kuolie_settings`} component={KuolieSettings}></AuthRoute>

              // 赚金币管理
              <AuthRoute per={22001} path={`${match.path}/admod_statistics`} component={AdmodStatistics}></AuthRoute>
              <AuthRoute per={22002} path={`${match.path}/admod_settings`} component={AdmodSettings}></AuthRoute>


              <Route render={()=><h3>欢迎使用同城甜心交友管理系统</h3>}></Route>
            </Switch>
          </Content>

        </Layout>
        <Footer style={{backgroundColor: 'silver', height: '124px', padding: '0', lineHeight:'24px'}}></Footer>
      </Layout>
    );
  }
}

export default Home;