import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import adminMusicService from '../../../Service/1vs1Mgr/adminMusicService';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';


const { Option } = Select;
const { TextArea } = Input;

class AddMusic extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var userData = GetLoginUserInfo();
          var appMusic = {
            name: values.name,
            author: values.author,
            cover: values.cover,
            musicUrl: values.musicUrl,
            sort: values.sort,
            state: values.state,
            uploader: userData.userName,
            lastOperator: userData.userName,
          };
          adminMusicService.insertOrUpdateMusic(appMusic)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

 // 上传图片
 doImgUpload = (options) => {
  const { onSuccess, onError, file, onProgress } = options;
  
  Service.uploadQiniu(file)
  .then((observable) => {
    observable.subscribe({
      error: () => {
          console.log('上传失败');
      },
      complete: (res) => {
        //console.log('上传完成，res='+JSON.stringify(res));
        var url = Service.getDoamin() + res.key;
        //console.log('上传成功：' + url);
        onProgress({percent: 100});
        onSuccess(url, file); 
      },
    });
  })

};

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
    

  };


  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            
            <Form.Item label="歌曲名称" labelCol={{span:10}} name="name" style={{ width: 420 }}>
                <Input placeholder="歌曲名称" />
            </Form.Item>

            <Form.Item label="原创/作者" labelCol={{span:10}} name="author" style={{ width: 420 }}>
                <Input placeholder="原创/作者" />
            </Form.Item>

            <Form.Item label="歌曲封面" labelCol={{span:8}}  name="cover" 
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="歌曲文件" name="musicUrl" 
                labelCol={{span:10}}
                style={{ width: 420 }}
                valuePropName="file"
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="audio/*"
                    name="fiavatarle"
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeAudioUpload}
                >
                     <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item label="排序" labelCol={{span:10}} name="sort" style={{ width: 420 }}>
                <Input placeholder="排序" />
            </Form.Item>

            <Form.Item label="是否启用" labelCol={{span:10}} name="state" style={{ width: 420 }} initialValue={1}>
                <Select style={{ width: 220 }} defaultValue={1}>
                    <Option value={0}>禁用</Option>
                    <Option value={1}>启用</Option>
                </Select>
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default AddMusic