import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 更新扩列配置
   * @param {*} kuolieSettings
   * @returns
   */
  updateKuolieConfig(kuolieSettings) {
      //console.log("vipParam=" + JSON.stringify(vipParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/kuolieSettingsAdmin/updateKuolieConfig",
        data: kuolieSettings,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 获取扩列配置
   * @returns
   */
  getKuolieSettings() {
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/kuolieSettingsAdmin/getKuolieSettings",
        data: null,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


}