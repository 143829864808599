import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import userVideoService from '../../../Service/Dynamic/userVideoService';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class UpdateDynamicList extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appUserVideo = values;
          userVideoService.updateUserVideo(appUserVideo)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
 
  }


  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="上下架状态" name="status" >
                <Radio.Group name="status" defaultValue={1}>
                    <Radio value={1}>上架</Radio>
                    <Radio value={2}>下架</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="是否推荐" name="isRecommend" >
                <Radio.Group name="isRecommend" defaultValue={0}>
                    <Radio value={0}>不推荐</Radio>
                    <Radio value={1}>推荐中</Radio>
                </Radio.Group>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default UpdateDynamicList