import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select , Table} from 'antd';
import dealScalePlanService from '../../../Service/UserMgr/dealScalePlanService';
import dealScaleService from '../../../Service/UserMgr/dealScaleService';
import { Logout } from '../../../Common/Auth';

class UpdateDealScalePlan extends Component {
  formRef = React.createRef();

  state ={
    dealScaleColumns: [{
      key: 'id',
      title: 'id',
      dataIndex: 'id'
    },{
      key: 'tranType2',
      title: '交易类型2',
      dataIndex: 'tranType2',
      render: (tranType2, row) => {
        if(tranType2 == 10) {
          return "充值";
        } else if(tranType2 == 12) {
          return "奖励收益";
        } else if(tranType2 == 50) {
          return "推荐佣金";
        } else if(tranType2 == 13) {
          return "兑换金币";
        } else if(tranType2 == 14) {
          return "游戏中奖";
        } else if(tranType2 == 20) {
          return "打赏";
        } else if(tranType2 == 24) {
          return "守护";
        } else if(tranType2 == 25) {
          return "粉丝团";
        } else if(tranType2 == 26) {
          return "发送弹幕";
        } else if(tranType2 == 27) {
          return "购买贵族";
        } else if(tranType2 == 28) {
          return "付费通话";
        } else if(tranType2 == 29) {
          return "购买道具";
        } else if(tranType2 == 200) {
          return "游戏消费";
        } else if(tranType2 == 201) {
          return "红包";
        } else if(tranType2 == 202) {
          return "查看联系方式";
        } else if(tranType2 == 204) {
          return "购买SVIP";
        } else if(tranType2 == 205) {
          return "房间费用";
        } else if(tranType2 == 206) {
          return "付费私信";
        } else if(tranType2 == 208) {
          return "购买贵宾席";
        } else if(tranType2 == 209) {
          return "游戏抽奖";
        } else if(tranType2 == 60) {
          return "提现";
        } else {
          return "其他";
        }  
      }
    },{
      key: 'tranType3',
      title: '交易类型3',
      dataIndex: 'tranType3',
      render: (tranType3, row) => {
        if(tranType3 == 1001) {
          return "线上充值";
        } else if(tranType3 == 1101) {
          return "人工充值";
        } else if(tranType3 == 1108) {
          return "后台充值";
        } else if(tranType3 == 1102) {
          return "扣除金币";
        } else if(tranType3 == 1103) {
          return "游戏奖励";
        } else if(tranType3 == 1201) {
          return "首充奖励";
        } else if(tranType3 == 1202) {
          return "新手大礼包";
        } else if(tranType3 == 1203) {
          return "购买贵族赠送金币收益";
        } else if(tranType3 == 1204) {
          return "等级礼包";
        } else if(tranType3 == 1205) {
          return "连续登录奖励";
        } else if(tranType3 == 1206) {
          return "签到奖励";
        } else if(tranType3 == 1207) {
          return "充值奖励";
        } else if(tranType3 == 1301) {
          return "佣金兑换金币";
        } else if(tranType3 == 1302) {
          return "映票兑换金币";
        } else if(tranType3 == 2003) {
          return "聊天打赏";
        } else if(tranType3 == 2004) {
          return "通话打赏";
        } else if(tranType3 == 2005) {
          return "个人主页打赏";
        } else if(tranType3 == 2401) {
          return "守护";
        } else if(tranType3 == 2501) {
          return "加入粉丝团";
        } else if(tranType3 == 2701) {
          return "购买贵族";
        } else if(tranType3 == 2801) {
          return "视频通话";
        } else if(tranType3 == 2802) {
          return "语音通话";
        }  else if(tranType3 == 2803) {
          return "用户间通话";
        }  else if(tranType3 == 20202) {
          return "查看微信号";
        }  else if(tranType3 == 20203) {
          return "付费私信";
        }  else if(tranType3 == 5001) {
          return "注册佣金";
        }  else if(tranType3 == 5002) {
          return "主播认证佣金";
        }  else if(tranType3 == 1406) {
          return "签到佣金";
        }  else if(tranType3 == 5003) {
          return "通话佣金";
        }  else if(tranType3 == 5004) {
          return "打赏佣金";
        } else if(tranType3 == 5005) {
          return "补发佣金";
        } else if(tranType3 == 5006) {
          return "用户充值";
        } else if(tranType3 == 5007) {
          return "购买贵族佣金";
        } else if(tranType3 == 5008) {
          return "购买SVIP佣金";
        } else if(tranType3 == 6001) {
          return "用户佣金提现";
        } else if(tranType3 == 6002) {
          return "代理佣金提现";
        } else if(tranType3 == 6003) {
          return "主播收益提现";
        } else if(tranType3 == 6004) {
          return "公会佣金提现";
        } else if(tranType3 == 20401) {
          return "购买SVIP";
        } else {
          return "其他";
        }  
      }
    },{
      key: 'dealScale',
      title: '比例（范围在0~1之间）',
      dataIndex: 'dealScale',
      render: (dealScale, row) => {
        return (
          <Form.Item name={"dealScale_"+row.id} initialValue={dealScale}>
                <Input placeholder="比例"/>
          </Form.Item>
        )        
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.handleSubmit(row)}
                style={{marginRight:'5px'}} 
                type="primary">保存
              </Button>
            </div>
          )        
      }
    }],
    dealScaleTotal: 0,
    dealScaleList: null,
    pageSize: 10,
    pageNum:1,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =(row) => {
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      // console.log("value="+JSON.stringify(values));
      // console.log("row="+JSON.stringify(row));
      var dealScaleName = "dealScale_"+ row.id;
      var dealScaleValue = values[dealScaleName];
      var appDealScale = {
        id: row.id,
        dealScale: dealScaleValue,
      }

      dealScaleService.insertOrUpdateDealScale(appDealScale)
      .then(res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            console.log("修改失败")
            message.info("修改失败,"+res.message);  
            // 重置关闭对话框
            this.hanldeCloseModal();
          } else {
            message.info('修改成功！');
            // 重置关闭对话框
            //this.hanldeCloseModal();
            // 表单赋值数据
            this.formRef.setFieldsValue({dealScaleName: dealScaleValue});
            // // 刷新数据
            // var pageParam = {
            //   pageNum: this.state.pageNum,
            //   pageSize:  this.state.pageSize,
            // }
            //  var dealScaleParam = {
            //   planId : this.props.rowData != null ? this.props.rowData.planId : 0,
            //   pageParam: pageParam,
            //  }
            // this.props.refreshData(dealScaleParam);
          }
      })
    })
  }

  // 分成分页
  dealScaleChangePage=(page, pageSize) => {
    //console.log("dealScaleChangePage 进入 pageSize="+pageSize);

    this.state.pageNum = page;
    this.state.pageSize = pageSize;

    var pageParam = {
      pageNum: page,
      pageSize: pageSize,
    }
     var dealScaleParam = {
      planId : this.props.rowData != null ? this.props.rowData.planId : 0,
      pageParam: pageParam,
     }

     // 刷新数据
     this.props.refreshData(dealScaleParam);
  }


  render () {
    return (
      <Modal
        title="分成比例"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        width={1000}
        footer={[
          null, null,
        ]}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE'}}
                dataSource={this.props.rowData != null ? this.props.rowData.list : [] }
                columns={this.state.dealScaleColumns}
                rowKey="userid"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.props.rowData != null ? this.props.rowData.total : 0, defaultCurrent: 1, onChange: this.dealScaleChangePage}}
              >
              </Table>

        </Form>
      </Modal>
    )
  }
}

export default UpdateDealScalePlan