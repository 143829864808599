import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, Statistic} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import financialStatisticsService from '../../../Service/FinanceMgr/financialStatisticsService';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class FinancialStatistics extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  state={
    // ———————————————————— 今日 ————————————————————————————
    todayData: null,

    // ———————————————————— 昨日 ————————————————————————————
    yesterdayData: null,


    // ———————————————————— 本周 ————————————————————————————
    weekData: null,


    // ———————————————————— 上周 ————————————————————————————
    lastweekData: null,


    // ———————————————————— 本月 ————————————————————————————
    monthData: null,


    // ———————————————————— 上月 ————————————————————————————
    lastmonthData: null,
  }


  // 第一次渲染后调用
  componentDidMount() {
      let { history } = this.props;

    // ———————————————————— 今日 ————————————————————————————
    this.getTodayData();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 免费通话时长设置 ————————————————————————————

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "today") {
      this.getTodayData();
    } else if(key == "yesterday") {
      this.getYesterdayData();
    } else if(key == "week") {
      this.getWeekData();
    } else if(key == "lastweek") {
      this.getLastweekData();
    } else if(key == "month") {
      this.getMonthData();
    } else if(key == "lastmonth") {
      this.getLastmonthData();
    }
  }

  /**
   * 获取今天数据
   */
  getTodayData=()=> {
    let { history } = this.props;
    var todayParam = {
      type: "today"
    }

    financialStatisticsService.getRechargeStatistics(todayParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef.current.setFieldsValue({
            registeredNumber_1: res.retValue.registeredNumber,
            realRegisteredNumber_1: res.retValue.realRegisteredNumber,
            agentRegisteredNumber_1: res.retValue.agentRegisteredNumber,
            pidRegisteredNumber_1: res.retValue.pidRegisteredNumber,
            xiaomiRegisteredNumber_1: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_1: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_1: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_1: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_1: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_1: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_1: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_1: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_1: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_1: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_1: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_1: res.retValue.rongyaoRegisteredNumber,
            adUserNumber_1: res.retValue.adUserNumber,
            playNumber_1: res.retValue.playNumber,
            clickNumber_1: res.retValue.clickNumber,
            adCoinNumber_1: res.retValue.adCoinNumber,
            dau_1: res.retValue.dau,
            chatUserNumber_1: res.retValue.chatUserNumber,
            videoUserNumber_1: res.retValue.videoUserNumber,
            totalRechargeAmount_1: res.retValue.totalRechargeAmount,
            totalRechargeNumber_1: res.retValue.totalRechargeNumber,
            totalNobleAmount_1: res.retValue.totalNobleAmount,
            totalNobleNumber_1: res.retValue.totalNobleNumber,
            alipayRechargeAmount_1: res.retValue.alipayRechargeAmount,
            alipayRechargeNumber_1: res.retValue.alipayRechargeNumber,
            wechatRechargeAmount_1: res.retValue.wechatRechargeAmount,
            wechatRechargeNumber_1: res.retValue.wechatRechargeNumber,
            manualRechargeAmount_1: res.retValue.manualRechargeAmount,
            manualRechargeNumber_1: res.retValue.manualRechargeNumber,
            wechatNobleAmount_1: res.retValue.wechatNobleAmount,
            wechatNobleNumber_1: res.retValue.wechatNobleNumber,
            alipayNobleAmount_1: res.retValue.alipayNobleAmount,
            alipayNobleNumber_1: res.retValue.alipayNobleNumber,
            goldNobleAmount_1: res.retValue.goldNobleAmount,
            goldNobleNumber_1: res.retValue.goldNobleNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }

  /**
   * 获取昨天数据
   */
  getYesterdayData=()=> {
    let { history } = this.props;
    var yesterdayParam = {
      type: "yesterday"
    }

    financialStatisticsService.getRechargeStatistics(yesterdayParam)
    .then(
      res => {
        //console.log("yesterday retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            registeredNumber_2: res.retValue.registeredNumber,
            realRegisteredNumber_2: res.retValue.realRegisteredNumber,
            agentRegisteredNumber_2: res.retValue.agentRegisteredNumber,
            pidRegisteredNumber_2: res.retValue.pidRegisteredNumber,
            xiaomiRegisteredNumber_2: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_2: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_2: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_2: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_2: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_2: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_2: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_2: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_2: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_2: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_2: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_2: res.retValue.rongyaoRegisteredNumber,
            adUserNumber_2: res.retValue.adUserNumber,
            playNumber_2: res.retValue.playNumber,
            clickNumber_2: res.retValue.clickNumber,
            adCoinNumber_2: res.retValue.adCoinNumber,
            dau_2: res.retValue.dau,
            chatUserNumber_2: res.retValue.chatUserNumber,
            videoUserNumber_2: res.retValue.videoUserNumber,
            totalRechargeAmount_2: res.retValue.totalRechargeAmount,
            totalRechargeNumber_2: res.retValue.totalRechargeNumber,
            totalNobleAmount_2: res.retValue.totalNobleAmount,
            totalNobleNumber_2: res.retValue.totalNobleNumber,
            alipayRechargeAmount_2: res.retValue.alipayRechargeAmount,
            alipayRechargeNumber_2: res.retValue.alipayRechargeNumber,
            wechatRechargeAmount_2: res.retValue.wechatRechargeAmount,
            wechatRechargeNumber_2: res.retValue.wechatRechargeNumber,
            manualRechargeAmount_2: res.retValue.manualRechargeAmount,
            manualRechargeNumber_2: res.retValue.manualRechargeNumber,
            wechatNobleAmount_2: res.retValue.wechatNobleAmount,
            wechatNobleNumber_2: res.retValue.wechatNobleNumber,
            alipayNobleAmount_2: res.retValue.alipayNobleAmount,
            alipayNobleNumber_2: res.retValue.alipayNobleNumber,
            goldNobleAmount_2: res.retValue.goldNobleAmount,
            goldNobleNumber_2: res.retValue.goldNobleNumber,
          });
          // this.formRef.current.setFieldsValue(res.retValue);
        }
      
      }
    )   
  }

  /**
   * 获取本周数据
   */
  getWeekData=()=> {
    let { history } = this.props;
    var weekParam = {
      type: "week"
    }

    financialStatisticsService.getRechargeStatistics(weekParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));

        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef3.current.setFieldsValue({
            registeredNumber_3: res.retValue.registeredNumber,
            realRegisteredNumber_3: res.retValue.realRegisteredNumber,
            agentRegisteredNumber_3: res.retValue.agentRegisteredNumber,
            pidRegisteredNumber_3: res.retValue.pidRegisteredNumber,
            xiaomiRegisteredNumber_3: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_3: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_3: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_3: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_3: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_3: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_3: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_3: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_3: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_3: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_3: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_3: res.retValue.rongyaoRegisteredNumber,
            adUserNumber_3: res.retValue.adUserNumber,
            playNumber_3: res.retValue.playNumber,
            clickNumber_3: res.retValue.clickNumber,
            adCoinNumber_3: res.retValue.adCoinNumber,
            dau_3: res.retValue.dau,
            chatUserNumber_3: res.retValue.chatUserNumber,
            videoUserNumber_3: res.retValue.videoUserNumber,
            totalRechargeAmount_3: res.retValue.totalRechargeAmount,
            totalRechargeNumber_3: res.retValue.totalRechargeNumber,
            totalNobleAmount_3: res.retValue.totalNobleAmount,
            totalNobleNumber_3: res.retValue.totalNobleNumber,
            alipayRechargeAmount_3: res.retValue.alipayRechargeAmount,
            alipayRechargeNumber_3: res.retValue.alipayRechargeNumber,
            wechatRechargeAmount_3: res.retValue.wechatRechargeAmount,
            wechatRechargeNumber_3: res.retValue.wechatRechargeNumber,
            manualRechargeAmount_3: res.retValue.manualRechargeAmount,
            manualRechargeNumber_3: res.retValue.manualRechargeNumber,
            wechatNobleAmount_3: res.retValue.wechatNobleAmount,
            wechatNobleNumber_3: res.retValue.wechatNobleNumber,
            alipayNobleAmount_3: res.retValue.alipayNobleAmount,
            alipayNobleNumber_3: res.retValue.alipayNobleNumber,
            goldNobleAmount_3: res.retValue.goldNobleAmount,
            goldNobleNumber_3: res.retValue.goldNobleNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    ) 
  }

  /**
   * 获取上周数据
   */
  getLastweekData=()=> {
    let { history } = this.props;
    var lastweekParam = {
      type: "lastweek"
    }

    financialStatisticsService.getRechargeStatistics(lastweekParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef4.current.setFieldsValue({
            registeredNumber_4: res.retValue.registeredNumber,
            realRegisteredNumber_4: res.retValue.realRegisteredNumber,
            agentRegisteredNumber_4: res.retValue.agentRegisteredNumber,
            pidRegisteredNumber_4: res.retValue.pidRegisteredNumber,
            xiaomiRegisteredNumber_4: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_4: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_4: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_4: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_4: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_4: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_4: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_4: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_4: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_4: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_4: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_4: res.retValue.rongyaoRegisteredNumber,
            adUserNumber_4: res.retValue.adUserNumber,
            playNumber_4: res.retValue.playNumber,
            clickNumber_4: res.retValue.clickNumber,
            adCoinNumber_4: res.retValue.adCoinNumber,
            dau_4: res.retValue.dau,
            chatUserNumber_4: res.retValue.chatUserNumber,
            videoUserNumber_4: res.retValue.videoUserNumber,
            totalRechargeAmount_4: res.retValue.totalRechargeAmount,
            totalRechargeNumber_4: res.retValue.totalRechargeNumber,
            totalNobleAmount_4: res.retValue.totalNobleAmount,
            totalNobleNumber_4: res.retValue.totalNobleNumber,
            alipayRechargeAmount_4: res.retValue.alipayRechargeAmount,
            alipayRechargeNumber_4: res.retValue.alipayRechargeNumber,
            wechatRechargeAmount_4: res.retValue.wechatRechargeAmount,
            wechatRechargeNumber_4: res.retValue.wechatRechargeNumber,
            manualRechargeAmount_4: res.retValue.manualRechargeAmount,
            manualRechargeNumber_4: res.retValue.manualRechargeNumber,
            wechatNobleAmount_4: res.retValue.wechatNobleAmount,
            wechatNobleNumber_4: res.retValue.wechatNobleNumber,
            alipayNobleAmount_4: res.retValue.alipayNobleAmount,
            alipayNobleNumber_4: res.retValue.alipayNobleNumber,
            goldNobleAmount_4: res.retValue.goldNobleAmount,
            goldNobleNumber_4: res.retValue.goldNobleNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }        
      }
    )   
  }

  /**
   * 获取本月数据
   */
  getMonthData=()=> {
    let { history } = this.props;
    var monthParam = {
      type: "month"
    }

    financialStatisticsService.getRechargeStatistics(monthParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef5.current.setFieldsValue({
            registeredNumber_5: res.retValue.registeredNumber,
            realRegisteredNumber_5: res.retValue.realRegisteredNumber,
            agentRegisteredNumber_5: res.retValue.agentRegisteredNumber,
            pidRegisteredNumber_5: res.retValue.pidRegisteredNumber,
            xiaomiRegisteredNumber_5: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_5: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_5: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_5: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_5: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_5: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_5: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_5: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_5: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_5: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_5: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_5: res.retValue.rongyaoRegisteredNumber,
            adUserNumber_5: res.retValue.adUserNumber,
            playNumber_5: res.retValue.playNumber,
            clickNumber_5: res.retValue.clickNumber,
            adCoinNumber_5: res.retValue.adCoinNumber,
            dau_5: res.retValue.dau,
            chatUserNumber_5: res.retValue.chatUserNumber,
            videoUserNumber_5: res.retValue.videoUserNumber,
            totalRechargeAmount_5: res.retValue.totalRechargeAmount,
            totalRechargeNumber_5: res.retValue.totalRechargeNumber,
            totalNobleAmount_5: res.retValue.totalNobleAmount,
            totalNobleNumber_5: res.retValue.totalNobleNumber,
            alipayRechargeAmount_5: res.retValue.alipayRechargeAmount,
            alipayRechargeNumber_5: res.retValue.alipayRechargeNumber,
            wechatRechargeAmount_5: res.retValue.wechatRechargeAmount,
            wechatRechargeNumber_5: res.retValue.wechatRechargeNumber,
            manualRechargeAmount_5: res.retValue.manualRechargeAmount,
            manualRechargeNumber_5: res.retValue.manualRechargeNumber,
            wechatNobleAmount_5: res.retValue.wechatNobleAmount,
            wechatNobleNumber_5: res.retValue.wechatNobleNumber,
            alipayNobleAmount_5: res.retValue.alipayNobleAmount,
            alipayNobleNumber_5: res.retValue.alipayNobleNumber,
            goldNobleAmount_5: res.retValue.goldNobleAmount,
            goldNobleNumber_5: res.retValue.goldNobleNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }

  /**
   * 获取上月数据
   */
  getLastmonthData=()=> {
    let { history } = this.props;
    var lastmonthParam = {
      type: "lastmonth"
    }

    financialStatisticsService.getRechargeStatistics(lastmonthParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef6.current.setFieldsValue({
            registeredNumber_6: res.retValue.registeredNumber,
            realRegisteredNumber_6: res.retValue.realRegisteredNumber,
            agentRegisteredNumber_6: res.retValue.agentRegisteredNumber,
            pidRegisteredNumber_6: res.retValue.pidRegisteredNumber,
            xiaomiRegisteredNumber_6: res.retValue.xiaomiRegisteredNumber,
            vivoRegisteredNumber_6: res.retValue.vivoRegisteredNumber,
            huaweiRegisteredNumber_6: res.retValue.huaweiRegisteredNumber,
            oppoRegisteredNumber_6: res.retValue.oppoRegisteredNumber,
            meizuRegisteredNumber_6: res.retValue.meizuRegisteredNumber,
            threeSixRegisteredNumber_6: res.retValue.threeSixRegisteredNumber,
            aliRegisteredNumber_6: res.retValue.aliRegisteredNumber,
            txRegisteredNumber_6: res.retValue.txRegisteredNumber,
            baiduRegisteredNumber_6: res.retValue.baiduRegisteredNumber,
            lianxiangRegisteredNumber_6: res.retValue.lianxiangRegisteredNumber,
            sanxinRegisteredNumber_6: res.retValue.sanxinRegisteredNumber,
            rongyaoRegisteredNumber_6: res.retValue.rongyaoRegisteredNumber,
            adUserNumber_6: res.retValue.adUserNumber,
            playNumber_6: res.retValue.playNumber,
            clickNumber_6: res.retValue.clickNumber,
            adCoinNumber_6: res.retValue.adCoinNumber,
            dau_6: res.retValue.dau,
            chatUserNumber_6: res.retValue.chatUserNumber,
            videoUserNumber_6: res.retValue.videoUserNumber,
            totalRechargeAmount_6: res.retValue.totalRechargeAmount,
            totalRechargeNumber_6: res.retValue.totalRechargeNumber,
            totalNobleAmount_6: res.retValue.totalNobleAmount,
            totalNobleNumber_6: res.retValue.totalNobleNumber,
            alipayRechargeAmount_6: res.retValue.alipayRechargeAmount,
            alipayRechargeNumber_6: res.retValue.alipayRechargeNumber,
            wechatRechargeAmount_6: res.retValue.wechatRechargeAmount,
            wechatRechargeNumber_6: res.retValue.wechatRechargeNumber,
            manualRechargeAmount_6: res.retValue.manualRechargeAmount,
            manualRechargeNumber_6: res.retValue.manualRechargeNumber,
            wechatNobleAmount_6: res.retValue.wechatNobleAmount,
            wechatNobleNumber_6: res.retValue.wechatNobleNumber,
            alipayNobleAmount_6: res.retValue.alipayNobleAmount,
            alipayNobleNumber_6: res.retValue.alipayNobleNumber,
            goldNobleAmount_6: res.retValue.goldNobleAmount,
            goldNobleNumber_6: res.retValue.goldNobleNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/financial_statistics">财务统计</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="today" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 今日统计 ————————————————————————————
            <TabPane tab="今日统计" key="today">
              <Form
                name="TodayStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_1" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="realRegisteredNumber_1">
                      <Statistic title="自然注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="agentRegisteredNumber_1">
                      <Statistic title="代理注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pidRegisteredNumber_1">
                      <Statistic title="上级注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_1">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_1">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_1">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_1">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_1">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_1">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_1">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_1">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_1">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_1">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_1">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_1">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                    <Form.Item name="adUserNumber_1">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_1">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_1">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_1">
                      <Statistic title="广告发放金币数" />
                    </Form.Item> 
                  </Col> */}
                  <Col span={8}>
                    <Form.Item name="dau_1">
                      <Statistic title="日活" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="chatUserNumber_1">
                      <Statistic title="聊天人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="videoUserNumber_1">
                      <Statistic title="视频人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeAmount_1">
                      <Statistic title="充值总金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeNumber_1">
                      <Statistic title="充值笔数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleAmount_1">
                      <Statistic title="开通贵族总金额" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleNumber_1">
                      <Statistic title="开通贵族总笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayRechargeAmount_1">
                      <Statistic title="App支付宝充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayRechargeNumber_1">
                      <Statistic title="App支付宝充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeAmount_1">
                      <Statistic title="App微信充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeNumber_1">
                      <Statistic title="App微信充值笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="manualRechargeAmount_1">
                      <Statistic title="人工充值金币" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="manualRechargeNumber_1">
                      <Statistic title="人工充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleAmount_1">
                      <Statistic title="开通贵族微信支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleNumber_1">
                      <Statistic title="开通贵族微信笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayNobleAmount_1">
                      <Statistic title="开通贵族支付宝支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayNobleNumber_1">
                      <Statistic title="开通贵族支付宝笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleAmount_1">
                      <Statistic title="开通贵族金币支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleNumber_1">
                      <Statistic title="开通贵族金币笔数" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

            </TabPane>
            
            // ———————————————————— 昨日统计 ——————————————————————
            <TabPane tab="昨日统计" key="yesterday">
            <Form
                name="YesterdayStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_2" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="realRegisteredNumber_2">
                      <Statistic title="自然注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="agentRegisteredNumber_2">
                      <Statistic title="代理注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pidRegisteredNumber_2">
                      <Statistic title="上级注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_2">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_2">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_2">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_2">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_2">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_2">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_2">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_2">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_2">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_2">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_2">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_2">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                    <Form.Item name="adUserNumber_2">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_2">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_2">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_2">
                      <Statistic title="广告发放金币数" />
                    </Form.Item> 
                  </Col> */}
                  <Col span={8}>
                    <Form.Item name="dau_2">
                      <Statistic title="日活" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="chatUserNumber_2">
                      <Statistic title="聊天人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="videoUserNumber_2">
                      <Statistic title="视频人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeAmount_2">
                      <Statistic title="充值总金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeNumber_2">
                      <Statistic title="充值笔数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleAmount_2">
                      <Statistic title="开通贵族总金额" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleNumber_2">
                      <Statistic title="开通贵族总笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayRechargeAmount_2">
                      <Statistic title="App支付宝充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayRechargeNumber_2">
                      <Statistic title="App支付宝充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeAmount_2">
                      <Statistic title="App微信充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeNumber_2">
                      <Statistic title="App微信充值笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="manualRechargeAmount_2">
                      <Statistic title="人工充值金币" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="manualRechargeNumber_2">
                      <Statistic title="人工充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleAmount_2">
                      <Statistic title="开通贵族微信支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleNumber_2">
                      <Statistic title="开通贵族微信笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayNobleAmount_2">
                      <Statistic title="开通贵族支付宝支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayNobleNumber_2">
                      <Statistic title="开通贵族支付宝笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleAmount_2">
                      <Statistic title="开通贵族金币支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleNumber_2">
                      <Statistic title="开通贵族金币笔数" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            
            </TabPane>

            // —————————————————————— 本周统计 ————————————————————————
            <TabPane tab="本周统计" key="week">
              <Form
                name="weekStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_3" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="realRegisteredNumber_3">
                      <Statistic title="自然注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="agentRegisteredNumber_3">
                      <Statistic title="代理注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pidRegisteredNumber_3">
                      <Statistic title="上级注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_3">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_3">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_3">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_3">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_3">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_3">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_3">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_3">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_3">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_3">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_3">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_3">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                    <Form.Item name="adUserNumber_3">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_3">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_3">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_3">
                      <Statistic title="广告发放金币数" />
                    </Form.Item> 
                  </Col> */}
                  <Col span={8}>
                    <Form.Item name="dau_3">
                      <Statistic title="日活" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="chatUserNumber_3">
                      <Statistic title="聊天人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="videoUserNumber_3">
                      <Statistic title="视频人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeAmount_3">
                      <Statistic title="充值总金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeNumber_3">
                      <Statistic title="充值笔数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleAmount_3">
                      <Statistic title="开通贵族总金额" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleNumber_3">
                      <Statistic title="开通贵族总笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayRechargeAmount_3">
                      <Statistic title="App支付宝充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayRechargeNumber_3">
                      <Statistic title="App支付宝充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeAmount_3">
                      <Statistic title="App微信充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeNumber_3">
                      <Statistic title="App微信充值笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="manualRechargeAmount_3">
                      <Statistic title="人工充值金币" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="manualRechargeNumber_3">
                      <Statistic title="人工充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleAmount_3">
                      <Statistic title="开通贵族微信支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleNumber_3">
                      <Statistic title="开通贵族微信笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayNobleAmount_3">
                      <Statistic title="开通贵族支付宝支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayNobleNumber_3">
                      <Statistic title="开通贵族支付宝笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleAmount_3">
                      <Statistic title="开通贵族金币支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleNumber_3">
                      <Statistic title="开通贵族金币笔数" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
               
            </TabPane>

            // —————————————————————— 上周统计 ————————————————————————
            <TabPane tab="上周统计" key="lastweek">
              <Form
                name="lastweekStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef4}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_4" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="realRegisteredNumber_4">
                      <Statistic title="自然注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="agentRegisteredNumber_4">
                      <Statistic title="代理注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pidRegisteredNumber_4">
                      <Statistic title="上级注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_4">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_4">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_4">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_4">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_4">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_4">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_4">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_4">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_4">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_4">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_4">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_4">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                    <Form.Item name="adUserNumber_4">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_4">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_4">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_4">
                      <Statistic title="广告发放金币数" />
                    </Form.Item> 
                  </Col> */}
                  <Col span={8}>
                    <Form.Item name="dau_4">
                      <Statistic title="日活" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="chatUserNumber_4">
                      <Statistic title="聊天人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="videoUserNumber_4">
                      <Statistic title="视频人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeAmount_4">
                      <Statistic title="充值总金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeNumber_4">
                      <Statistic title="充值笔数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleAmount_4">
                      <Statistic title="开通贵族总金额" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleNumber_4">
                      <Statistic title="开通贵族总笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayRechargeAmount_4">
                      <Statistic title="App支付宝充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayRechargeNumber_4">
                      <Statistic title="App支付宝充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeAmount_4">
                      <Statistic title="App微信充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeNumber_4">
                      <Statistic title="App微信充值笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="manualRechargeAmount_4">
                      <Statistic title="人工充值金币" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="manualRechargeNumber_4">
                      <Statistic title="人工充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleAmount_4">
                      <Statistic title="开通贵族微信支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleNumber_4">
                      <Statistic title="开通贵族微信笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayNobleAmount_4">
                      <Statistic title="开通贵族支付宝支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayNobleNumber_4">
                      <Statistic title="开通贵族支付宝笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleAmount_4">
                      <Statistic title="开通贵族金币支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleNumber_4">
                      <Statistic title="开通贵族金币笔数" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>

            // —————————————————————— 本月统计 ————————————————————————
            <TabPane tab="本月统计" key="month">
              <Form
                name="monthStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef5}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_5" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="realRegisteredNumber_5">
                      <Statistic title="自然注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="agentRegisteredNumber_5">
                      <Statistic title="代理注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pidRegisteredNumber_5">
                      <Statistic title="上级注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_5">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_5">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_5">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_5">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_5">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_5">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_5">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_5">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_5">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_5">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_5">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_5">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                    <Form.Item name="adUserNumber_5">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_5">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_5">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_5">
                      <Statistic title="广告发放金币数" />
                    </Form.Item> 
                  </Col> */}
                  <Col span={8}>
                    <Form.Item name="dau_5">
                      <Statistic title="日活" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="chatUserNumber_5">
                      <Statistic title="聊天人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="videoUserNumber_5">
                      <Statistic title="视频人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeAmount_5">
                      <Statistic title="充值总金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeNumber_5">
                      <Statistic title="充值笔数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleAmount_5">
                      <Statistic title="开通贵族总金额" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleNumber_5">
                      <Statistic title="开通贵族总笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayRechargeAmount_5">
                      <Statistic title="App支付宝充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayRechargeNumber_5">
                      <Statistic title="App支付宝充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeAmount_5">
                      <Statistic title="App微信充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeNumber_5">
                      <Statistic title="App微信充值笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="manualRechargeAmount_5">
                      <Statistic title="人工充值金币" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="manualRechargeNumber_5">
                      <Statistic title="人工充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleAmount_5">
                      <Statistic title="开通贵族微信支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleNumber_5">
                      <Statistic title="开通贵族微信笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayNobleAmount_5">
                      <Statistic title="开通贵族支付宝支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayNobleNumber_5">
                      <Statistic title="开通贵族支付宝笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleAmount_5">
                      <Statistic title="开通贵族金币支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleNumber_5">
                      <Statistic title="开通贵族金币笔数" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>

            // —————————————————————— 上月统计 ————————————————————————
            <TabPane tab="上月统计" key="lastmonth">
              <Form
                name="lastmonthStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef6}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="registeredNumber_6" >
                      <Statistic title="总注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="realRegisteredNumber_6">
                      <Statistic title="自然注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="agentRegisteredNumber_6">
                      <Statistic title="代理注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="pidRegisteredNumber_6">
                      <Statistic title="上级注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="xiaomiRegisteredNumber_6">
                      <Statistic title="小米注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="vivoRegisteredNumber_6">
                      <Statistic title="vivo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="huaweiRegisteredNumber_6">
                      <Statistic title="华为注册数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="oppoRegisteredNumber_6">
                      <Statistic title="oppo注册数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="meizuRegisteredNumber_6">
                      <Statistic title="魅族注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="threeSixRegisteredNumber_6">
                      <Statistic title="360注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="aliRegisteredNumber_6">
                      <Statistic title="阿里注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="txRegisteredNumber_6">
                      <Statistic title="应用宝注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="baiduRegisteredNumber_6">
                      <Statistic title="百度注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="lianxiangRegisteredNumber_6">
                      <Statistic title="联想注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="sanxinRegisteredNumber_6">
                      <Statistic title="三星注册人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="rongyaoRegisteredNumber_6">
                      <Statistic title="荣耀注册人数" />
                    </Form.Item>
                  </Col>
                  {/* <Col span={8}>
                    <Form.Item name="adUserNumber_6">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_6">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_6">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_6">
                      <Statistic title="广告发放金币数" />
                    </Form.Item> 
                  </Col> */}
                  <Col span={8}>
                    <Form.Item name="dau_6">
                      <Statistic title="日活" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="chatUserNumber_6">
                      <Statistic title="聊天人数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="videoUserNumber_6">
                      <Statistic title="视频人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeAmount_6">
                      <Statistic title="充值总金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalRechargeNumber_6">
                      <Statistic title="充值笔数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleAmount_6">
                      <Statistic title="开通贵族总金额" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="totalNobleNumber_6">
                      <Statistic title="开通贵族总笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayRechargeAmount_6">
                      <Statistic title="App支付宝充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayRechargeNumber_6">
                      <Statistic title="App支付宝充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeAmount_6">
                      <Statistic title="App微信充值金额" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatRechargeNumber_6">
                      <Statistic title="App微信充值笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="manualRechargeAmount_6">
                      <Statistic title="人工充值金币" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="manualRechargeNumber_6">
                      <Statistic title="人工充值笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleAmount_6">
                      <Statistic title="开通贵族微信支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="wechatNobleNumber_6">
                      <Statistic title="开通贵族微信笔数" />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item name="alipayNobleAmount_6">
                      <Statistic title="开通贵族支付宝支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="alipayNobleNumber_6">
                      <Statistic title="开通贵族支付宝笔数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleAmount_6">
                      <Statistic title="开通贵族金币支付" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="goldNobleNumber_6">
                      <Statistic title="开通贵族金币笔数" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default FinancialStatistics;