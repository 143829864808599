import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 获取动态评论列表
   * @param {*} 
   * @returns
   */
   getCommentPage(usersVideoCommentsParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/usersVideoComments/getCommentPage",
      data: usersVideoCommentsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除动态评论
   * @param {*} 
   * @returns
   */
   deleteComment(usersVideoCommentsParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/usersVideoComments/deleteComment",
      data: usersVideoCommentsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}