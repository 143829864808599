import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import usersCashrecordService from "../../../Service/FinanceMgr/usersCashrecordService";


const { Option } = Select;
const { TextArea } = Input;

class UpdateWithdrawalRecord extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          let userData = GetLoginUserInfo();

          var appUsersCashrecord = {
            id: values.id,
            orderno: values.orderno,
            uid: values.uid,
            votes: values.votes,
            money: values.money,
            service: values.service,
            actualMoney: values.actualMoney,
            type: values.type,
            accountBank: values.accountBank,
            name: values.name,
            account: values.account,
            status: values.status,
            reason: values.reason,
            auditby: userData.userName,
            auditId: userData.userId,
          };
          usersCashrecordService.updateUsersCashrecord(appUsersCashrecord)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
 
  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
    

  };

  render () {
    return (
      <Modal
        title="修改"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="提现单号" labelCol={{span:10}} name="orderno" style={{ width: 420 }} >
                <Input placeholder="提现单号" disabled/>
            </Form.Item>

            <Form.Item label="UID" labelCol={{span:10}} name="uid" style={{ width: 420 }} >
                <Input placeholder="UID" disabled/>
            </Form.Item>

            <Form.Item label="交易映票" labelCol={{span:10}} name="votes" style={{ width: 420 }} >
                <Input placeholder="交易映票" disabled/>
            </Form.Item>

            <Form.Item label="交易金额" labelCol={{span:10}} name="money" style={{ width: 420 }} >
                <Input placeholder="交易金额" disabled/>
            </Form.Item>

            <Form.Item label="手续费" labelCol={{span:10}} name="service" style={{ width: 420 }} >
                <Input placeholder="手续费" disabled/>
            </Form.Item>

            <Form.Item label="到账金额" labelCol={{span:10}} name="actualMoney" style={{ width: 420 }} >
                <Input placeholder="到账金额" disabled/>
            </Form.Item>

            <Form.Item label="账户类型" labelCol={{span:10}} name="type" style={{ width: 420 }} initialValue={0}>
                <Select style={{ width: 220 }} defaultValue={0} disabled>
                    <Option value={1}>支付宝</Option>
                    <Option value={2}>微信</Option>
                    <Option value={3}>银行卡</Option>
                </Select>
            </Form.Item>

            <Form.Item label="开户行" labelCol={{span:10}} name="accountBank" style={{ width: 420 }} >
                <Input placeholder="开户行" disabled/>
            </Form.Item>

            <Form.Item label="真实姓名" labelCol={{span:10}} name="name" style={{ width: 420 }} >
                <Input placeholder="真实姓名" disabled/>
            </Form.Item>

            <Form.Item label="提现账号" labelCol={{span:10}} name="account" style={{ width: 420 }} >
                <Input placeholder="提现账号" disabled/>
            </Form.Item>

            <Form.Item label="审核结果" name="status" labelCol={{span:10}} style={{ width: 420 }}>
                <Radio.Group name="status"  >
                    <Radio value={1}>审核通过</Radio>
                    <Radio value={2}>审核拒绝</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="审核原因" labelCol={{span:10}} name="reason" style={{ width: 420 }} >
                <TextArea placeholder="审核原因" />
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default UpdateWithdrawalRecord