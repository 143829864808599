import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { GetLoginUserId, Logout } from '../../../Common/Auth';
import adminUserCashrecordService from '../../../Service/Admin/adminUserCashrecordService';


const { Option } = Select;
const { TextArea } = Input;

class AddMyWallet extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));

          var userId = GetLoginUserId();
          var adminUserCashrecord = {
            accountId : values.accountId,
            money: values.money,
            uid: userId
          };
          adminUserCashrecordService.insertAdminUserCashrecord(adminUserCashrecord)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("提现失败")
                message.info("提现失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('提现成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }





  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
        >

            <Form.Item label="提现金额" labelCol={{span:10}} name="money" style={{ width: 420 }}>
                <InputNumber  />
            </Form.Item>


            <Form.Item label="提现账号" name="accountId" labelCol={{span:10}} style={{ width: 420 }}>
                <Select style={{ width: 220 }} >
                    { this.props.rowData != null ? this.props.rowData.cashAccountList.map((item,index)=>
                        <Option value={item.id}>{item.account}</Option>
                    ) : null}
                </Select>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddMyWallet