import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import moment from 'moment'
import currencySettingService from '../../../Service/SystemSetting/currencySettingService';
import Service from '../../../Service/index';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class CoinSettings extends Component {
  formRef = React.createRef();
  state={

    // ———————————————————— 通话设置 ————————————————————————————
    coinSettings: null,
  

  }


  // 第一次渲染后调用
  componentDidMount() {

    // ———————————————————— 通话设置 ————————————————————————————

    currencySettingService.getConfig()
      .then(
        res => {
          //console.log("retValue=" + JSON.stringify(res.retValue));
          this.state.coinSettings = res.retValue;

          this.formRef.current.setFieldsValue({
            coinName: res.retValue.coinName,
            coinIcon: res.retValue.coinIcon,
            ticketName: res.retValue.ticketName,
            ticketIcon: res.retValue.ticketIcon,
          })
        }
      )

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 通话设置 ————————————————————————————



  /**
   * 一对一
   */
   handleCoinSettingsSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {

        var cfgCurrencySetting = values;
        currencySettingService.updateConfig(cfgCurrencySetting)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })

  }


  // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // coinIcon修改
  handleChangeFileCoinIcon=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("handleChangeFileApk="+e.file.response);
          this.state.loading=false;

          this.formRef.current.setFieldsValue({
            coinIcon: e.file.response,
          });
          return e.file.response;
        }
  }

  // ticketIcon
  handleChangeFileTicketIcon=(e) => {
    if (e.file.status === 'uploading') {
        this.state.loading=true;
        return;
    }
    if (e.file.status === 'done') {
        //console.log("handleChangeFileApk="+e.file.response);
        this.state.loading=false;

        this.formRef.current.setFieldsValue({
          ticketIcon: e.file.response,
        });
        return e.file.response;
      }
  }


  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })
  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
    

  };


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/coin_settings">代币设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>            
            <Form
              name="CoinSettingsForm"
              ref={this.formRef}
              style={{ margin: '20px 0px 0px 100px'}}
              initialValues={this.state.coinSettings}
            >
                  <Form.Item label="id" name="id" initialValue={1} hidden>
                      <Input placeholder="id" />
                  </Form.Item>

                  <Form.Item label="充值货币名" name="coinName" labelCol={{span:10}}  style={{ width: 420 }}>
                      <Input placeholder="充值货币名" />
                  </Form.Item>

                  <Row gutter={20}>
                    <Col span={7} key={1}>
                      <Form.Item dependencies={['coinIcon']} noStyle>
                          {({ getFieldValue }) => {
                              const coinIcon = getFieldValue('coinIcon')
                              return (
                                  <>
                                  <Form.Item label="充值货币图标" name="coinIconImage" labelCol={{span:10}} style={{ width: 420 }}>
                                    <Image width={55} height={55} src={coinIcon} />
                                  </Form.Item>
                                  </>
                              )
                              return null
                          }}
                      </Form.Item>
                    </Col>
                    <Col span={13} key={2}>
                      <Row>
                        <Form.Item  name="coinIconUpload" 
                            valuePropName="file"
                            style={{ width: 200 }}
                            getValueFromEvent={this.handleChangeFileCoinIcon}
                            >
                            <Upload
                                accept="apk/*"
                                name="fiavatarle"
                                onChange={this.handleChange}
                                customRequest={this.doImgUpload}
                                beforeUpload={this.beforeUpload}
                                showUploadList={false}
                            >
                                <Button icon={<UploadOutlined />}>选择文件</Button>
                            </Upload>
                        </Form.Item>
                        建议尺寸：130px * 130px
                      </Row>
                      <Row>
                        <Form.Item name="coinIcon" labelCol={{span:10}} style={{ width: 840 }} >
                            <Input />
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row>

                  <Form.Item label="充值货币名" name="ticketName" labelCol={{span:10}}  style={{ width: 420 }}>
                      <Input placeholder="充值货币名" />
                  </Form.Item>

                  <Row gutter={20}>
                    <Col span={7} key={1}>
                      <Form.Item dependencies={['ticketIcon']} noStyle>
                          {({ getFieldValue }) => {
                              const ticketIcon = getFieldValue('ticketIcon')
                              console.log("ticketIcon="+ticketIcon);
                              return (
                                  <>
                                  <Form.Item label="充值货币图标" name="ticketIconImage" labelCol={{span:10}} style={{ width: 420 }}>
                                    <Image width={55} height={55} src={ticketIcon} />
                                  </Form.Item>
                                  </>
                              )
                              return null
                          }}
                      </Form.Item>
                    </Col>
                    <Col span={13} key={2}>
                      <Row>
                        <Form.Item  name="ticketIconUpload" 
                            valuePropName="file"
                            style={{ width: 200 }}
                            getValueFromEvent={this.handleChangeFileTicketIcon}
                            >
                            <Upload
                                accept="apk/*"
                                name="fiavatarle"
                                onChange={this.handleChange}
                                customRequest={this.doImgUpload}
                                beforeUpload={this.beforeUpload}
                                showUploadList={false}
                            >
                                <Button icon={<UploadOutlined />}>选择文件</Button>
                            </Upload>
                        </Form.Item>
                        建议尺寸：26px * 26px
                      </Row>
                      <Row>
                        <Form.Item name="ticketIcon" labelCol={{span:10}} style={{ width: 840 }} >
                            <Input />
                        </Form.Item>
                      </Row>
                    </Col>
                  </Row>

            </Form>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.handleCoinSettingsSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
              <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
            </div>

            
        </StickyContainer>
        
      </div>
    );
  }
}
export default CoinSettings;