import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取映票转金币列表
   * @param {*} 
   * @returns
   */
   getTicketExchangeRulePage(ticketExchangeRuleParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/ticketExchangeRule/getTicketExchangeRulePage",
      data: ticketExchangeRuleParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入或更新映票转换金币
   * @param {*} 
   * @returns
   */
   insertOrUpdateTicketExchangeRule(ticketExchangeRule) {
      //console.log("commonWordsParam=" + JSON.stringify());
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/ticketExchangeRule/insertOrUpdateTicketExchangeRule",
        data: ticketExchangeRule,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
  },

  /**
   * 更新提现审核
   * @param {*} 
   * @returns
   */
   deleteTicketExchangeRule(ticketExchangeRuleParam) {
        //console.log("commonWordsParam=" + JSON.stringify());
        return axios({
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
          },
          method: "post",
          url: "/admin/ticketExchangeRule/deleteTicketExchangeRule",
          data: ticketExchangeRuleParam,
        }).then((res) => {
          //console.log("retValue=" + JSON.stringify(res));
          return res.data;
        });
    },
}