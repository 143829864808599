import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 更新协议
   * @param {*} appPost
   * @returns
   */
   updateConfig(appPost) {
      //console.log("appPost=" + JSON.stringify(appPost));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/postHmy/updateConfig",
        data: appPost,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取协议
   * @param {*} 
   * @returns
   */
   getConfig(postParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/postHmy/getConfig",
      data: postParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

    /**
   * 分页获取协议
   * @param {*} 
   * @returns
    */
    getPostPage(postParam) {
        //console.log("postParam=" + JSON.stringify(postParam));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/postHmy/getPostPage",
            data: postParam,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

}