import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,Popconfirm ,Table} from 'antd';
import gradeRewarReService from '../../../Service/GradeMgr/gradeRewarReService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class ShowGradeGiftRe extends Component {
  formRef = React.createRef();

  state={
    // ———————————————————— 财富等级 ————————————————————————————
    gradeGiftReColumns: [{
      key: 'id',
      title: '关联id',
      dataIndex: 'id'
    },{
      key: 'rewarType',
      title: '类别',
      dataIndex: 'rewarType',
      render: (rewarType, row) => {
        if(rewarType === 1) {
          return "金币"
        } else if(rewarType === 3) {
          return "礼物";
        } 
      }
    },{
      key: 'rewarId',
      title: '礼物id',
      dataIndex: 'rewarId'
    },{
      key: 'rewarName',
      title: '礼物名称',
      dataIndex: 'rewarName'
    },{
      key: 'rewarSum',
      title: '数量',
      dataIndex: 'rewarSum'
    },{
      key: 'createTime',
      title: '创建时间',
      dataIndex: 'createTime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteRe(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    gradeGiftReTotal: 0,
    gradeGiftReList: null,
  }

  // 根据Id删除礼物关联
  deleteRe=(id) => {
      let { history } = this.props;
      let gradeRewarReParam = {
        id : id
      };
      gradeRewarReService.deleteGradeRewarRe(gradeRewarReParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          console.log("添加失败")
          message.info("添加失败,"+res.message);  
        } else {
          message.success('删除成功！');
          // 重置关闭对话框
          this.hanldeCloseModal();
        }
      });
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
  }

  // 礼物关联分页
  gradeGiftReChangePage=(page, pageSize) => {
      this.refreshGradeGiftReData(page, pageSize, this.props.rowData.gradeId);
  }



  render () {
    return (
      <Modal
        title="查看礼包内容"
        destroyOnClose
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        width={1000}
        footer={[
          null, null,
        ]}
      >
        <Table 
          bordered
          style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
          dataSource={this.props.rowData != null ? this.props.rowData.gradeGiftReList : null}
          columns={this.state.gradeGiftReColumns}
          rowKey="userid"
          scroll={{ x: 'max-content' }}
          pagination = {{ total: this.props.rowData != null ? this.props.rowData.gradeGiftReTotal : null, defaultCurrent: 1, onChange: this.gradeGiftReChangePage}}
        >
        </Table>
      </Modal>
    )
  }
}

export default ShowGradeGiftRe