import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 获取常用语列表
   * @param {*} 
   * @returns
   */
  getRechargeStatistics(financialStatisticsParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/financialStatisticsHmy/getRechargeStatistics",
      data: financialStatisticsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


}