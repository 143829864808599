import React, { Component } from 'react'
import { Form, Input, Upload, Button, message } from 'antd';
import { UserOutlined,MailOutlined,SafetyOutlined,PhoneOutlined } from '@ant-design/icons';
import { GetLoginToken } from '../../../Common/Auth';
import Service from '../../../Service/index';

class EditUserFrm extends Component {
    formRef = React.createRef();

    // 页面第一次渲染时执行
    componentDidMount=() => {
        console.log('data=' +  JSON.stringify(this.props.rowData));
        this.formRef.current.setFieldsValue(this.props.rowData);
    }

    // 头像图片修改
    handleChangeFileAvatar=(e) => {
        if(e.file.response) {
            //console.log(e.file.response);
            return e.file.response;
        }
        return null;
    }

    // 上传前置校验
    beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    // 上传图片
    doImgUpload = (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        
        Service.uploadQiniu(file)
        .then((observable) => {
        observable.subscribe({
            error: () => {
                console.log('上传失败');
            },
            complete: (res) => {
            //console.log('上传完成，res='+JSON.stringify(res));
            var url = Service.getDoamin() + res.key;
            //console.log('上传成功：' + url);
            onProgress({percent: 100});
            onSuccess(url, file); 
            },
        });
        })
  
    };

    render () {
        return (
            <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="用户名" name="name" rules={ [
                    {
                        pattern: /\w{5,20}/gi,
                        message: '请输入5-20个字符!',
                    },
                    {
                        required: true,
                        message: '请输入用户名!',
                    }
                    ]
                }>
                <Input prefix={<UserOutlined />} 
                    placeholder="用户名"
                />
            </Form.Item>

            <Form.Item label="用户头像" name="avatar" 
                getValueFromEvent={this.handleChangeFileAvatar}
                >
                <Upload
                    accept="image/*"
                    action='/applicationApi/apiFunctionHandler'
                    headers={{Authorization: GetLoginToken()}}
                    name="file"
                    listType="picture" 
                    onChange={this.handleChangeFileAvatar}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                >
                    <Button>上传头像</Button>
                </Upload>
            </Form.Item>

            <Form.Item label="邮箱" name="email" rules={ [
                    {
                        type: 'email',
                        message: '请输入正确的邮箱格式!',
                    },
                    {
                        required: true,
                        message: '请输入邮箱!',
                    }
                    ]
                }>
                <Input prefix={<MailOutlined />} 
                    placeholder="邮箱"
                />
            </Form.Item>

            <Form.Item label="昵称" name="anonymous" rules={ [
                    {
                        min: 2,
                        message: '请输入大于2个字符!',
                    },
                    {
                        required: true,
                        message: '请输入昵称!',
                    }
                    ]
                }>
                <Input prefix={<UserOutlined />} 
                    placeholder="昵称"
                />
            </Form.Item>

            <Form.Item label="电话" name="phone" rules={ [
                    {
                        pattern: /\d{11}/gi,
                        message: '请输入11个字符!',
                    }
                    ]
                }>
                <Input prefix={<PhoneOutlined />} 
                    placeholder="电话"
                />
            </Form.Item>

        </Form>
        )
    }
}

export default EditUserFrm