import axios from "axios";
import Qs from "qs";
import { GetLoginToken } from "../../Common/Auth.js";

export default {
  /**
   * 清除所有缓存数据
   * @param {*}
   * @returns
   */
  cleanAllData() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/cleanAllData",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 获取缓存数据
   * @param {*}
   * @returns
   */
  getCacheData(systemInitParam) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/getCacheData",
      data: systemInitParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 上传图片
	 * @param isDemo
	 * @return
	 */
  uploadImg(isDemo) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      isDemo : isDemo
    }
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/uploadImg",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 检查并上传图片
	 * @param isDemo
	 * @return
	 */
  checkUploadImg(isDemo) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      isDemo : isDemo
    }
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/checkUploadImg",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 更新图片
	 * @param isDemo
	 * @return
	 */
  updateImgUrl(isDemo) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      isDemo : isDemo
    }
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/updateImgUrl",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 删除基础数据
	 * @param isDemo
	 * @return
	 */
  delBasData(isDemo) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      isDemo : isDemo
    }

    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delBasData",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 删除非基础数据
	 * @param isDemo
	 * @return
	 */
  delDataNotBas(isDemo) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      isDemo : isDemo
    }

    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delDataNotBas",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 创建数据库json
	 * @param isDemo
	 * @return
	 */
  createDBJson(isDemo) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      isDemo : isDemo
    }
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/createDBJson",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * JSON数据上传到数据库
	 * @param isDemo
	 * @return
	 */
  jsonImportDb(isDemo) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      isDemo : isDemo
    }
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/jsonImportDb",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 下载图片
	 * @param isDemo
	 * @return
	 */
  downloadImg(isDemo) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      isDemo : isDemo
    }
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/downloadImg",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 删除所有缓存数据
	 * @return
	 */
  delRedisData() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delRedisData",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 重置配置缓存
	 * @return
	 */
  resetCfgRedis() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/resetCfgRedis",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 删除qq缓存数据
	 * @return
	 */
  delRedisQQData() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delRedisQQData",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 删除微信缓存数据
	 * @return
	 */
  delRedisWXData() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delRedisWXData",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 清空粉丝群
	 * @return
	 */
  fansTeamUpgrade() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/fansTeamUpgrade",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 同步禁用缓存
	 * @return
	 */
  synDisableCache() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/synDisableCache",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 删除用户缓存
	 * @return
	 */
  delUserInfo() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delUserInfo",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 删除短信缓存
	 * @return
	 */
  delSmsCache() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delSmsCache",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 查询用户缓存
	 * @param userId
	 * @return
	 */
  getApiUserInfo(userId) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      userId : userId
    }
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/getApiUserInfo",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 删除用户token缓存
	 * @param userIdStr
	 * @return
	 */
  clearUserToken(userIdStr) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      userIdStr : userIdStr
    }
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/clearUserToken",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 消息测试
	 * @param userId
	 * @return
	 */
  msgTestUser(userId) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    let data = {
      userId : userId
    }
    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/msgTestUser",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 查询任务信息
	 * @return
	 */
  getTaskInfo() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/getTaskInfo",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
	 * 多用户上传IM
	 * @return
	 */
  onMultiAccountImport() {
      //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/systemAdmin/onMultiAccountImport",
        data: null,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  
  /**
   * 清除所有用户信息
   * @param {*}
   * @returns
   */
  cleanUserInfo() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/cleanUserInfo",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 清除某个用户信息
   * @param {*}
   * @returns
   */
  delUserInfo(systemInitParam) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delUserInfo",
      data: systemInitParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 清除某个用户token
   * @param {*}
   * @returns
   */
  delUserToken(systemInitParam) {
    console.log("systemInitParam=" + JSON.stringify(systemInitParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/delUserToken",
      data: systemInitParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 清除所有用户模拟消息
   * @param {*}
   * @returns
   */
  cleanFictitiousInfo() {
      //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/systemAdmin/cleanFictitiousInfo",
        data: null,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 更新未成年信息
   * @param {*}
   * @returns
   */
  updateMinority() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/updateMinority",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 清除用户所有会话
   * @param {*}
   * @returns
   */
  deleteContact(systemInitParam) {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/deleteContact",
      data: systemInitParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 撤回用户所有消息
   * @param {*}
   * @returns
   */
  msgWithdraw(systemInitParam) {
      //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/systemAdmin/msgWithdraw",
        data: systemInitParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
  

  /**
   * 清除推送匹配信息
   * @param {*}
   * @returns
   */
  cleanPushMateInfo() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/cleanPushMateInfo",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 重载黑名单IP缓存
   * @param {*}
   * @returns
   */
  reloadDomainBlackList() {
    //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/systemAdmin/reloadDomainBlackList",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 清除指定缓存
   * @param {*}
   * @returns
   */
  delSecify(systemInitParam) {
      //console.log("adminLogParam=" + JSON.stringify(adminLogParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/systemAdmin/delSecify",
        data: systemInitParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
};
