import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import chargeRulesService from '../../../Service/FinanceMgr/chargeRulesService';


const { Option } = Select;
const { TextArea } = Input;

/**
 * 添加用户任务
 */
class AddIosRecharge extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appChargeRules = values;
          chargeRulesService.insertOrUpdateChargeRules(appChargeRules)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  
  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
  };




  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="排序" name="orderno" labelCol={{span:10}} style={{ width: 420 }}>
                <InputNumber />
            </Form.Item>

            <Form.Item label="名称" name="name" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="名称" />
            </Form.Item>

            <Form.Item label="充值类型" labelCol={{span:10}} name="appType" style={{ width: 420 }} initialValue={2} >
                <Select style={{ width: 220 }} defaultValue={2} >
                    <Option value={2}>苹果</Option>
                </Select>
            </Form.Item>

            <Form.Item label="金币数量" name="coin" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="金币数量" />
            </Form.Item>
  
            <Form.Item label="苹果价格" name="moneyIos" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="安卓价格" />
            </Form.Item>

            <Form.Item label="苹果折扣价格" name="discountMoneyIos" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="安卓折扣价格" />
            </Form.Item>

            <Form.Item label="苹果折扣描述" name="iosDicountDesr" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="安卓折扣描述" />
            </Form.Item>

            <Form.Item label="苹果项目ID" name="productId" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="苹果项目ID" />
            </Form.Item>

            <Form.Item label="赠送金币" name="give" labelCol={{span:10}} style={{ width: 420 }}>
                <Input placeholder="赠送金币" />
            </Form.Item>

            <Form.Item label="是否启用" name="isTip" labelCol={{span:10}} style={{ width: 420 }}>
                <Radio.Group name="isTip"  >
                    <Radio value={0}>不启用</Radio>
                    <Radio value={1}>启用</Radio>
                </Radio.Group>
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddIosRecharge