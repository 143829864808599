import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

    /**
     * 获取等级列表
     * @returns
     */
     getGiftList() {
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/nobLiveGiftAdmin/getGiftList",
        data: null,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


    /**
     * 分页获取礼物
     * @param {*} giftParam
     * @returns
     */
     getGiftPage(giftParam) {
      //console.log("giftParam=" + JSON.stringify(giftParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/nobLiveGiftAdmin/getGiftPage",
        data: giftParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 更新礼物
   * @param {*} userAppeal
   * @returns
   */
   insertOrUpdateGift(nobLiveGift) {
        //console.log("nobLiveGift=" + JSON.stringify(nobLiveGift));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/nobLiveGiftAdmin/insertOrUpdateGift",
        data: nobLiveGift,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


    /**
     * 删除礼物
     * @param {*} giftParam
     * @returns
     */
    deleteGift(giftParam) {
      //console.log("giftParam=" + JSON.stringify(giftParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/nobLiveGiftAdmin/deleteGift",
        data: giftParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
}