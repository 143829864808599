import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import systemAdminService from '../../../Service/SystemInit/systemAdminService';
import ShowCacheData from './ShowCacheData';
import InputNumber from 'rc-input-number';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class CacheMgr extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state={
    showCacheRow: null,
    showCacheDialog: false,

  }


  // 第一次渲染后调用
  componentDidMount() {

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  /**
   * 一对一
   */
   handleSystemAdminSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    Modal.confirm({
      title: '您确认要清除吗？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.cleanAllData()
          .then(
            res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                //this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                //this.hanldeCloseModal();
              }
      
            }
          )
      }
    })
  };


  // 获取列表
  handleGetCacheData=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));

      var systemInitParam = {
        key: values.key,
      };
      systemAdminService.getCacheData(systemInitParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          var data = {
            content : res.retValue
          }
          this.setState({showCacheDialog:true, showCacheRow: data })
        }                  
        
      });
      
    })
  }

  // 隐藏
  hideCacheDialog=() => {
    this.setState({showCacheDialog: false});
  }


  /**
   * 清除所有用户信息
   */
  cleanUserInfo=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要清除吗？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.cleanUserInfo()
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("创建失败,"+res.message);  
          } else {
            message.success('创建成功！');
          }
          
        })
      }
    });
  }

  /**
   * 清除所有用户信息
   */
  delUserInfo=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要清除吗？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        this.formRef2.current.validateFields()
        .then(values => {
          console.log(JSON.stringify(values));
    
          var systemInitParam = {
            userId: values.userId,
          };
          systemAdminService.delUserInfo(systemInitParam)
          .then(res => {
            console.log("res=" + JSON.stringify(res));

            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("创建失败,"+res.message);  
            } else {
              message.success('创建成功！');
            }
            
          })
        })
      }
    });
  }


  /**
   * 清除所有用户信息
   */
  delUserToken=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要清除吗？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        this.formRef3.current.validateFields()
        .then(values => {
          console.log(JSON.stringify(values));
    
          var systemInitParam = {
            userId: values.userId,
          };
          systemAdminService.delUserToken(systemInitParam)
          .then(res => {
            console.log("res=" + JSON.stringify(res));

            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("创建失败,"+res.message);  
            } else {
              message.success('创建成功！');
            }
            
          })
        })
      }
    });
  }


  /**
   * 清除所有用户模拟消息
   */
  cleanFictitiousInfo=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要清除吗？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.cleanFictitiousInfo()
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("创建失败,"+res.message);  
          } else {
            message.success('创建成功！');
          }
          
        })
      }
    });
  }

  /**
   * 更新未成年年龄
   */
  updateMinority=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要更新吗？',
      okText: '更新',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.updateMinority()
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("更新失败,"+res.message);  
          } else {
            message.success('更新成功！');
          }
          
        })
      }
    });
  }


  /**
   * 清除推送匹配消息
   */
  cleanPushMateInfo=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要清除吗？',
      okText: '清除',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.cleanPushMateInfo()
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("创建失败,"+res.message);  
          } else {
            message.success('创建成功！');
          }
          
        })
      }
    });
  }

  /**
   * 重载黑名单IP缓存
   */
  reloadDomainBlackList=()=> {
    let { history } = this.props;
    // 确认是否需要重载
    Modal.confirm({
      title: '您确认要重载吗？',
      okText: '重载',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.reloadDomainBlackList()
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("创建失败,"+res.message);  
          } else {
            message.success('创建成功！');
          }
          
        })
      }
    });
  }


    /**
   * 清除指定缓存
   */
    delSecify=()=> {
      let { history } = this.props;
      // 确认是否需要删除
      Modal.confirm({
        title: '您确认要清除吗？',
        okText: '清除',
        cancelText: '取消',
        onOk: () => {
          this.formRef4.current.validateFields()
          .then(values => {
            console.log(JSON.stringify(values));
      
            var systemInitParam = {
              key: values.key,
            };
            systemAdminService.delSecify(systemInitParam)
            .then(res => {
              console.log("res=" + JSON.stringify(res));
  
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                message.info("删除失败,"+res.message);  
              } else {
                message.success('删除成功！');
              }
              
            })
          })
        }
      });
    }

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/cache_mgr">缓存管理</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>   
          <Form
              name="CloudStorageListForm"
              className="ant-advanced-search-form"
              ref={this.formRef}
              style={{ margin: '20px 0px 20px 200px'}}
            >
              <Row gutter={24}>
                  <Col span={7} key={1}>
                    <Form.Item label="缓存key:" name="key">
                        <Input placeholder="缓存key"/>
                    </Form.Item>
                  </Col>
                  <Col span={3} key={2} style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={this.handleGetCacheData} style={{ marginRight: '15px' }}>
                      查询
                    </Button>
                  </Col>
              </Row>
            </Form>         
            <ShowCacheData rowData={this.state.showCacheRow} close={this.hideCacheDialog} visible={this.state.showCacheDialog} ></ShowCacheData>
            
            <Form
              name="CloudStorageListForm"
              className="ant-advanced-search-form"
              ref={this.formRef4}
              style={{ margin: '20px 0px 20px 200px'}}
            >
              <Row gutter={24}>
                  <Col span={7} key={1}>
                    <Form.Item label="缓存key:" name="key">
                        <Input placeholder="缓存key"/>
                    </Form.Item>
                  </Col>
                  <Col span={3} key={2} style={{ textAlign: 'right' }}>
                    <Button type="danger" onClick={this.delSecify} style={{ marginRight: '15px' }}>
                      清除指定缓存
                    </Button>
                  </Col>
              </Row>
            </Form>  

            <Form
              name="CloudStorageListForm"
              className="ant-advanced-search-form"
              ref={this.formRef2}
              style={{ margin: '20px 0px 20px 200px'}}
            >
              <Row gutter={24}>
                  <Col span={7} key={1}>
                    <Form.Item label="用户Id:" name="userId">
                        <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col span={3} key={2} style={{ textAlign: 'right' }}>
                    <Button type="danger" onClick={this.delUserInfo} style={{ marginRight: '15px' }}>
                      清除用户信息
                    </Button>
                  </Col>
              </Row>
            </Form>  

            <Form
              name="CloudStorageListForm"
              className="ant-advanced-search-form"
              ref={this.formRef3}
              style={{ margin: '20px 0px 20px 200px'}}
            >
              <Row gutter={24}>
                  <Col span={7} key={1}>
                    <Form.Item label="用户Id:" name="userId">
                        <InputNumber />
                    </Form.Item>
                  </Col>
                  <Col span={3} key={2} style={{ textAlign: 'right' }}>
                    <Button type="danger" onClick={this.delUserToken} style={{ marginRight: '15px' }}>
                      清除用户TOKEN
                    </Button>
                  </Col>
              </Row>
            </Form> 

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.reloadDomainBlackList } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">重载黑名单IP缓存</Button></span>
            </div>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.cleanPushMateInfo } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">清空推送匹配缓存</Button></span>
            </div>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.updateMinority } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">更新未成年年龄</Button></span>
            </div>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.cleanFictitiousInfo } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">清空所有用户模拟消息</Button></span>
            </div>


            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.cleanUserInfo } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">清空所有用户信息缓存</Button></span>
            </div>



            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.handleSystemAdminSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">清空所有缓存</Button></span>
            </div>


        </StickyContainer>
        
      </div>
    );
  }
}
export default CacheMgr;