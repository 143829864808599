import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取等级权限
   * @param {*} gradePrivilegeParam
   * @returns
   */
  getGradePrivilegeFrontPage(gradePrivilegeParam) {
      //console.log("gradePrivilegeParam=" + JSON.stringify(gradePrivilegeParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradePrivilegeFront/getGradePrivilegeFrontPage",
        data: gradePrivilegeParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 更新等级权限
   * @param {*} appGradePrivilege
   * @returns
   */
  insertOrUpdateGradePrivilegeFront(appGradePrivilege) {
        //console.log("appGradePrivilege=" + JSON.stringify(appGradePrivilege));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradePrivilegeFront/insertOrUpdateGradePrivilegeFront",
        data: appGradePrivilege,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除等级权限
     * @param {*} appGradePrivilege
     * @returns
     */
    deleteGradePrivilegeFront(appGradePrivilege) {
      //console.log("appGradePrivilege=" + JSON.stringify(appGradePrivilege));
      return axios({
      headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/gradePrivilegeFront/deleteGradePrivilegeFront",
      data: appGradePrivilege,
      }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
      });
  },
}