import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取短信发送记录
   * @param {*} 
   * @returns
   */
  getSysLogPage(sysLogParam) {
    //console.log("sysLogParam=" + JSON.stringify(sysLogParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/sysLog/getSysLogPage",
      data: sysLogParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

    /**
   * 分页获取短信发送记录
   * @param {*} 
   * @returns
   */
  deleteByIdList(idList) {
      //console.log("idList=" + JSON.stringify(idList));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/sysLog/deleteByIdList",
        data: idList,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
  },
}