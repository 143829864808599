import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 获取列表
   * @param {*} 
   * @returns
   */
   getAdmodStatistics(admodStatisticsParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/admodStatistics/getAdmodStatistics",
      data: admodStatisticsParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


}