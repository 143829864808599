import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取用户任务
   * @param {*} userTaskParam
   * @returns
   */
   getUserTaskPage(userTaskParam) {
      //console.log("gradePrivilegeParam=" + JSON.stringify(userTaskParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/userTask/getUserTaskPage",
        data: userTaskParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新用户任务
   * @param {*} appUserTask
   * @returns
   */
   insertOrUpdateUserTask(appUserTask) {
        //console.log("appUserTask=" + JSON.stringify(appUserTask));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/userTask/insertOrUpdateUserTask",
        data: appUserTask,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除用户任务
     * @param {*} userTaskParam 
     * @returns 
     */
    deleteUserTask(userTaskParam) {
        //console.log("appUserTask=" + JSON.stringify(userTaskParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/userTask/deleteUserTask",
        data: userTaskParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}