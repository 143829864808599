import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio 
  , Row, Col, Avatar, Tabs, Image} from 'antd';
import appUserService from '../../../Service/UserMgr/appUserService';
import { StickyContainer, Sticky } from 'react-sticky';
import { LoadingOutlined,PlusOutlined,UploadOutlined } from '@ant-design/icons';
import {
  Player,
  ControlBar,
  PlayToggle, // PlayToggle 播放/暂停按钮 若需禁止加 disabled
  ReplayControl, // 后退按钮
  ForwardControl,  // 前进按钮
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,  // 倍速播放选项
  VolumeMenuButton
} from 'video-react';
import "video-react/dist/video-react.css"; // import css

const { Option } = Select;
const { TabPane } = Tabs;


class VideoPlay extends Component {
  formRef = React.createRef();

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
  }

  render () {
    return (
      <Modal
        destroyOnClose
        visible={this.props.visible}
        width={350}
        onCancel={this.hanldeCloseModal}
        footer={[
          null, null,
        ]}
      >
        <Player
            ref={c => {
              this.player = c;
            }}
            autoPlay={true}
            playsInline ={true}
            src={this.props.rowData ? this.props.rowData.videoUrl : null}
          >
            
            <ControlBar autoHide={false} disableDefaultControls={true}>
              <ReplayControl seconds={10} order={1.1} />
              <PlayToggle />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton rates={[5, 2, 1.5, 1, 0.5]} order={7.1} />
              <VolumeMenuButton />
            </ControlBar>
        </Player>
     
      </Modal>
    )
  }
}

export default VideoPlay