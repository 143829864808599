import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, Statistic} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import admodStatisticsService from '../../../Service/FinanceMgr/admodStatisticsService';



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class FinancialStatistics extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  state={
    // ———————————————————— 今日 ————————————————————————————
    todayData: null,

    // ———————————————————— 昨日 ————————————————————————————
    yesterdayData: null,


    // ———————————————————— 本周 ————————————————————————————
    weekData: null,


    // ———————————————————— 上周 ————————————————————————————
    lastweekData: null,


    // ———————————————————— 本月 ————————————————————————————
    monthData: null,


    // ———————————————————— 上月 ————————————————————————————
    lastmonthData: null,
  }


  // 第一次渲染后调用
  componentDidMount() {
      let { history } = this.props;

    // ———————————————————— 今日 ————————————————————————————
    this.getTodayData();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 免费通话时长设置 ————————————————————————————

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "today") {
      this.getTodayData();
    } else if(key == "yesterday") {
      this.getYesterdayData();
    } else if(key == "week") {
      this.getWeekData();
    } else if(key == "lastweek") {
      this.getLastweekData();
    } else if(key == "month") {
      this.getMonthData();
    } else if(key == "lastmonth") {
      this.getLastmonthData();
    }
  }

  /**
   * 获取今天数据
   */
  getTodayData=()=> {
    let { history } = this.props;
    var todayParam = {
      type: "today"
    }

    admodStatisticsService.getAdmodStatistics(todayParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef.current.setFieldsValue({
            playNumber_1: res.retValue.playNumber,
            clickNumber_1: res.retValue.clickNumber,
            adCoinNumber_1: res.retValue.adCoinNumber,
            adUserNumber_1: res.retValue.adUserNumber,
            adQueryCoinNumber_1: res.retValue.adQueryCoinNumber,
            adClickCoinNumber_1: res.retValue.adClickCoinNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }

  /**
   * 获取昨天数据
   */
  getYesterdayData=()=> {
    let { history } = this.props;
    var yesterdayParam = {
      type: "yesterday"
    }

    admodStatisticsService.getAdmodStatistics(yesterdayParam)
    .then(
      res => {
        //console.log("yesterday retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            playNumber_2: res.retValue.playNumber,
            clickNumber_2: res.retValue.clickNumber,
            adCoinNumber_2: res.retValue.adCoinNumber,
            adUserNumber_2: res.retValue.adUserNumber,
            adQueryCoinNumber_2: res.retValue.adQueryCoinNumber,
            adClickCoinNumber_2: res.retValue.adClickCoinNumber,
          });
          // this.formRef.current.setFieldsValue(res.retValue);
        }
      
      }
    )   
  }

  /**
   * 获取本周数据
   */
  getWeekData=()=> {
    let { history } = this.props;
    var weekParam = {
      type: "week"
    }

    admodStatisticsService.getAdmodStatistics(weekParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));

        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef3.current.setFieldsValue({
            playNumber_3: res.retValue.playNumber,
            clickNumber_3: res.retValue.clickNumber,
            adCoinNumber_3: res.retValue.adCoinNumber,
            adUserNumber_3: res.retValue.adUserNumber,
            adQueryCoinNumber_3: res.retValue.adQueryCoinNumber,
            adClickCoinNumber_3: res.retValue.adClickCoinNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    ) 
  }

  /**
   * 获取上周数据
   */
  getLastweekData=()=> {
    let { history } = this.props;
    var lastweekParam = {
      type: "lastweek"
    }

    admodStatisticsService.getAdmodStatistics(lastweekParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef4.current.setFieldsValue({
            playNumber_4: res.retValue.playNumber,
            clickNumber_4: res.retValue.clickNumber,
            adCoinNumber_4: res.retValue.adCoinNumber,
            adUserNumber_4: res.retValue.adUserNumber,
            adQueryCoinNumber_4: res.retValue.adQueryCoinNumber,
            adClickCoinNumber_4: res.retValue.adClickCoinNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }        
      }
    )   
  }

  /**
   * 获取本月数据
   */
  getMonthData=()=> {
    let { history } = this.props;
    var monthParam = {
      type: "month"
    }

    admodStatisticsService.getAdmodStatistics(monthParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef5.current.setFieldsValue({
            playNumber_5: res.retValue.playNumber,
            clickNumber_5: res.retValue.clickNumber,
            adCoinNumber_5: res.retValue.adCoinNumber,
            adUserNumber_5: res.retValue.adUserNumber,
            adQueryCoinNumber_5: res.retValue.adQueryCoinNumber,
            adClickCoinNumber_5: res.retValue.adClickCoinNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }

  /**
   * 获取上月数据
   */
  getLastmonthData=()=> {
    let { history } = this.props;
    var lastmonthParam = {
      type: "lastmonth"
    }

    admodStatisticsService.getAdmodStatistics(lastmonthParam)
    .then(
      res => {
        //console.log("retValue=" + JSON.stringify(res.retValue));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef6.current.setFieldsValue({
            playNumber_6: res.retValue.playNumber,
            clickNumber_6: res.retValue.clickNumber,
            adCoinNumber_6: res.retValue.adCoinNumber,
            adUserNumber_6: res.retValue.adUserNumber,
            adQueryCoinNumber_6: res.retValue.adQueryCoinNumber,
            adClickCoinNumber_6: res.retValue.adClickCoinNumber,
          });
          //this.formRef.current.setFieldsValue(res.retValue);
        }
        
      }
    )   
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/admod_statistics">财务统计</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="today" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 今日统计 ————————————————————————————
            <TabPane tab="今日统计" key="today">
              <Form
                name="TodayStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="adUserNumber_1">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_1">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_1">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_1">
                      <Statistic title="发放银币总数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adQueryCoinNumber_1">
                      <Statistic title="查询发放银币数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adClickCoinNumber_1">
                      <Statistic title="点击发放银币数" />
                    </Form.Item> 
                  </Col>
                </Row>
              </Form>

            </TabPane>
            
            // ———————————————————— 昨日统计 ——————————————————————
            <TabPane tab="昨日统计" key="yesterday">
            <Form
                name="YesterdayStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="adUserNumber_2">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_2">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_2">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_2">
                      <Statistic title="发放银币总数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adQueryCoinNumber_2">
                      <Statistic title="查询发放银币数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adClickCoinNumber_2">
                      <Statistic title="点击发放银币数" />
                    </Form.Item> 
                  </Col>
                </Row>
              </Form>
            
            </TabPane>

            // —————————————————————— 本周统计 ————————————————————————
            <TabPane tab="本周统计" key="week">
              <Form
                name="weekStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef3}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="adUserNumber_3">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_3">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_3">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_3">
                      <Statistic title="发放银币总数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adQueryCoinNumber_3">
                      <Statistic title="查询发放银币数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adClickCoinNumber_3">
                      <Statistic title="点击发放银币数" />
                    </Form.Item> 
                  </Col>
                </Row>
              </Form>
               
            </TabPane>

            // —————————————————————— 上周统计 ————————————————————————
            <TabPane tab="上周统计" key="lastweek">
              <Form
                name="lastweekStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef4}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="adUserNumber_4">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_4">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_4">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_4">
                      <Statistic title="发放银币总数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adQueryCoinNumber_4">
                      <Statistic title="查询发放银币数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adClickCoinNumber_4">
                      <Statistic title="点击发放银币数" />
                    </Form.Item> 
                  </Col>
                </Row>
              </Form>
            </TabPane>

            // —————————————————————— 本月统计 ————————————————————————
            <TabPane tab="本月统计" key="month">
              <Form
                name="monthStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef5}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="adUserNumber_5">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_5">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_5">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_5">
                      <Statistic title="发放银币总数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adQueryCoinNumber_5">
                      <Statistic title="查询发放银币数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adClickCoinNumber_5">
                      <Statistic title="点击发放银币数" />
                    </Form.Item> 
                  </Col>
                </Row>
              </Form>
            </TabPane>

            // —————————————————————— 上月统计 ————————————————————————
            <TabPane tab="上月统计" key="lastmonth">
              <Form
                name="lastmonthStatisticsForm"
                className="ant-advanced-search-form"
                ref={this.formRef6}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24} style={{ margin: '20px 0px 0px 0px'}}>
                  <Col span={8}>
                    <Form.Item name="adUserNumber_6">
                      <Statistic title="广告播放人数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="playNumber_6">
                      <Statistic title="广告播放数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="clickNumber_6">
                      <Statistic title="广告点击数" />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adCoinNumber_6">
                      <Statistic title="发放银币总数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adQueryCoinNumber_6">
                      <Statistic title="查询发放银币数" />
                    </Form.Item> 
                  </Col>
                  <Col span={8}>
                    <Form.Item name="adClickCoinNumber_6">
                      <Statistic title="点击发放银币数" />
                    </Form.Item> 
                  </Col>
                </Row>
              </Form>
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default FinancialStatistics;