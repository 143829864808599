import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import hotSortService from '../../../Service/ShortVideo/hotSortService';
import adminLiveConfigService from '../../../Service/UserMgr/adminLiveConfigService';
import shortVideoConfigService from '../../../Service/ShortVideo/shortVideoConfigService';

import AddHotSort from './AddHotSort';
import UpdateHotSort from './UpdateHotSort';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class VideoSetting extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  state={
    // ———————————————————— 短视频分类 ————————————————————————————
    hotSortColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'name',
      title: '分类名称',
      dataIndex: 'name'
    },{
      key: "image",
      title: "分类图片",
      dataIndex: "image",
      render: (image, row) => {
        if (image != null && image != "") {
          return <Image width={55} height={55} src={image} />;
        }
      },
    },
    {
      key: 'type',
      title: '类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "直播"
        } else if(type === 2) {
          return "语音";
        } else if(type === 3) {
          return "1v1";
        } else if(type === 4) {
          return "电台";
        } else if(type === 5) {
          return "派对";
        } else if(type === 6) {
          return "短视频";
        } else if(type === 7) {
          return "动态";
        } 
      }
    },{
      key: 'sort',
      title: '排序',
      dataIndex: 'sort'
    },{
      key: 'isTip',
      title: '启用状态',
      dataIndex: 'isTip',
      render: (isTip, row) => {
        if(isTip === 0) {
          return "不启用"
        } else if(isTip === 1) {
          return "启用";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateHotSortDialog:true, updateHotSortRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteHotSort(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    hotSortTotal: 0,
    hotSortList: [],
    updateHotSortRow: null,
    showUpdateHotSortDialog: false,
    addHotSortRow: null,
    showAddHotSortDialog: false,
    hotSortPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 短视频配置 ————————————————————————————
    videoSetting: null,
  

    // ———————————————————— 广告短视频设置 ————————————————————————————
    adsVideoSetting: null,


  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 短视频分类 ————————————————————————————
    this.handleGetHotSortPagePage();

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 短视频分类 ————————————————————————————

  // 关闭添加用户弹框
  hideAddHotSortDialog=() => {
    this.setState({showAddHotSortDialog: false});
  }

  // 关闭编辑用户弹框
  hideUpdateHotSortDialog=() => {
    this.setState({showUpdateHotSortDialog: false});
  }

  // 根据Id删除
  deleteHotSort=(id) => {
      let { history } = this.props;
      let appHotSort = {
        id : id
      };
      hotSortService.deleteHotSort(appHotSort)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshHotSortData();
          message.success('删除成功！');
        }                  
        
      })
  }

  // 分页获取
  handleGetHotSortPagePage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var hotSortParam={
        type: 6,
        pageParam: pageParam
      }
  
      hotSortService.getHotSortPage(hotSortParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({hotSortList: res.retValue.list, hotSortTotal: res.retValue.total});
        }                  
        
      });
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  hotSortChangePage(page, pageSize) {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      hotSortPageParam: pageParam,
    });

    var hotSortParam={
      type: 6,
      pageParam: pageParam
    }

    hotSortService.getHotSortPage(hotSortParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({hotSortList: res.retValue.list, hotSortTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新导航数据
   */
  refreshHotSortData=() => {
      let { history } = this.props;

      var hotSortParam={
        type: 6,
        pageParam: this.state.hotSortPageParam
      }

      hotSortService.getHotSortPage(hotSortParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({hotSortList: res.retValue.list, hotSortTotal: res.retValue.total});
          }
        }
      )
  }

// ———————————————————— 短视频设置 ————————————————————————————


handleVideoSettingSubmit=() => {
 //console.log("changePage 进入");
 let { history } = this.props;

 this.formRef2.current.validateFields()
 .then(values => {

     var videoSettingParam = values;

     adminLiveConfigService.updateVideoSetting(videoSettingParam)
     .then(
       res => {
         if(res == null || res.retCode != 200) {
           console.log("修改失败")
           message.info("修改失败,"+res.message);  
           // 重置关闭对话框
           //this.hanldeCloseModal();
           if(res.retCode == 10001) {
             // 清除登录信息
             Logout();
             // 跳转到登录页面
             history.push('/login');
             return;
           }
         } else {
           message.info('修改成功！');
           // 重置关闭对话框
           //this.hanldeCloseModal();
         }
 
       }
     )
 })
}

  /**
   * 获取短视频配置
   */
  getVideoSetting =() =>{
    let { history } = this.props;
    adminLiveConfigService.getVideoSetting()
    .then(
      res => {
        //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef2.current.setFieldsValue({
            id: res.retValue.id,
            isShortVideoNobleFree: res.retValue.isShortVideoNobleFree,
            authShortVideo: res.retValue.authShortVideo,
            isAuthShortVideo: res.retValue.isAuthShortVideo,
            videoCommentSwitch: res.retValue.videoCommentSwitch,
            isShortVideoFee: res.retValue.isShortVideoFee,
            shortVideoTrial: res.retValue.shortVideoTrial,
            shortVideoTrialTime: res.retValue.shortVideoTrialTime,
          });

        }
      }
    )  
  }

  // —————————————————————— 广告短视频设置 ————————————————————————

  handleAdsVideoSettingSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
   
    this.formRef3.current.validateFields()
    .then(values => {
   
        var adminShortVideoConfig = values;
   
        shortVideoConfigService.updateShortVideoConfig(adminShortVideoConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              console.log("修改失败")
              message.info("修改失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
            } else {
              message.info('修改成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
   }
   
     /**
      * 获取短视频配置
      */
     getAdsVideoSetting =() =>{
       let { history } = this.props;
       shortVideoConfigService.getShortVideoConfig()
       .then(
         res => {
           //console.log("apkUrl=" + JSON.stringify(this.state.apkUrl));
           if(res == null || res.retCode != 200) {
             if(res.retCode == 10001) {
               // 清除登录信息
               Logout();
               // 跳转到登录页面
               history.push('/login');
               return;
             }
             message.info("查询失败,"+res.message);  
           } else {
             this.formRef3.current.setFieldsValue({
               id: res.retValue.id,
               isStartAds: res.retValue.isStartAds,
               shortVideoId: res.retValue.shortVideoId,
               isRoll: res.retValue.isRoll,
               fristAdsPosition: res.retValue.fristAdsPosition,
               minNumber: res.retValue.minNumber,
               maxNumber: res.retValue.maxNumber,
             });
   
           }
         }
       )  
     }


 
  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.handleGetHotSortPagePage();
    } else if(key == "2") {
      this.getAdsVideoSetting();
    } else if(key == "3") {
      this.getAdsVideoSetting();
    } 
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/video_setting">短视频设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 短视频分类 ————————————————————————————
            <TabPane tab="短视频分类" key="1">
              <Form
                name="hotSortForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '10px 0px 0px 100px'}}
              >
                <Row gutter={24}>

                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddHotSortDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.hotSortList}
                columns={this.state.hotSortColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.hotSortTotal, defaultCurrent: 1, onChange: this.hotSortChangePage}}
              >
              </Table>
              <AddHotSort close={this.hideAddHotSortDialog} visible={this.state.showAddHotSortDialog} refreshData={this.refreshHotSortData}></AddHotSort>
              <UpdateHotSort rowData={this.state.updateHotSortRow} close={this.hideUpdateHotSortDialog} visible={this.state.showUpdateHotSortDialog} refreshData={this.refreshHotSortData}></UpdateHotSort>
              
            </TabPane>
            
            // ———————————————————— 短视频配置 ——————————————————————
            <TabPane tab="短视频配置" key="2">
              <Form
                  name="videoSettingForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef2}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.videoSetting}
                >

                      <Form.Item label="id" name="id" hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="短视频贵族免费" name="isShortVideoNobleFree" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <Radio.Group name="isShortVideoNobleFree" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="短视频认证限制" name="authShortVideo" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <Radio.Group name="authShortVideo" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="短视频是否需要审核" name="isAuthShortVideo" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <Radio.Group name="isAuthShortVideo" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="短视频评论" name="videoCommentSwitch" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <Radio.Group name="videoCommentSwitch" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="短视频收费" name="isShortVideoFee" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <Radio.Group name="isShortVideoFee" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="短视频试看" name="shortVideoTrial" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <Radio.Group name="shortVideoTrial" defaultValue={0}>
                          <Radio value={1}>开启</Radio>
                          <Radio value={0}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="短视频试看时间(秒)" labelCol={{span:10}} name="shortVideoTrialTime" style={{ width: 450 }} >
                          <InputNumber />
                      </Form.Item>
  
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleVideoSettingSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            
            </TabPane>

            // —————————————————————— 广告短视频设置 ————————————————————————
            <TabPane tab="广告短视频设置" key="3">
                <Form
                  name="adsVideoSettingForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.adsVideoSetting}
                >

                      <Form.Item label="id" name="id" hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="开屏广告开关" name="isStartAds" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <Radio.Group name="isStartAds" defaultValue={0}>
                          <Radio value={0}>开启</Radio>
                          <Radio value={1}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="广告ID" name="shortVideoId" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <InputNumber />
                      </Form.Item>

                      <Form.Item label="轮播广告开关" name="isRoll" labelCol={{span:10}}  style={{ width: 450 }} initialValue={1}>
                        <Radio.Group name="isRoll" defaultValue={1}>
                          <Radio value={1}>开启</Radio>
                          <Radio value={2}>关闭</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="第一条广告位置" name="fristAdsPosition" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <InputNumber />
                      </Form.Item>

                      <Form.Item label="广告随机最小值" name="minNumber" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                       <InputNumber />
                      </Form.Item>

                      <Form.Item label="广告随机最大值" name="maxNumber" labelCol={{span:10}}  style={{ width: 450 }} initialValue={0}>
                        <InputNumber />
                      </Form.Item>
  
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleAdsVideoSettingSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
                
              
            </TabPane>

           



          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default VideoSetting;