import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,Popconfirm ,Table,Avatar} from 'antd';
import gradeRewarReService from '../../../Service/GradeMgr/gradeRewarReService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';

const { Option } = Select;
const { TextArea } = Input;

class ShowAnswerRecord extends Component {
  formRef = React.createRef();

  state={
    // ———————————————————— 接听记录 ————————————————————————————
    answerRecordColumns: [{
        key: 'id',
        title: 'id',
        dataIndex: 'id'
      },{
        key: 'roomId',
        title: '房间号',
        dataIndex: 'roomId'
      },{
        key: 'showid',
        title: '通话标识',
        dataIndex: 'showid'
      },{
        key: 'uid',
        title: '拨打用户id',
        dataIndex: 'uid'
      },{
        key: 'dialUsername',
        title: '拨打用户名',
        dataIndex: 'dialUsername'
      },{
        key: 'dialAvatar',
        title: '拨打用户头像',
        dataIndex: 'dialAvatar',
        render: (dialAvatar, row) => {
          if(dialAvatar != null && dialAvatar != "") {
            return  <Avatar shape="square" size={55} src={dialAvatar}></Avatar>
          } 
        }
      },{
        key: 'dialMobile',
        title: '拨打用户手机号',
        dataIndex: 'dialMobile'
      },{
        key: 'isVideo',
        title: '通话方式',
        dataIndex: 'isVideo',
        render: (isVideo, row) => {
          if(isVideo === 0) {
            return "私聊"
          } else if(isVideo === 1) {
            return "群聊";
          } 
        }
      },{
        key: 'type',
        title: '通话类型',
        dataIndex: 'type',
        render: (type, row) => {
          if(type === 0) {
            return "语音"
          } else if(type === 1) {
            return "视频";
          } 
        }
      },{
        key: 'audienceId',
        title: '接听用户id',
        dataIndex: 'audienceId'
      },{
        key: 'answerUsername',
        title: '接听用户名',
        dataIndex: 'answerUsername'
      },{
        key: 'answerAvatar',
        title: '接听用户头像',
        dataIndex: 'answerAvatar',
        render: (answerAvatar, row) => {
          if(answerAvatar != null && answerAvatar != "") {
            return  <Avatar shape="square" size={55} src={answerAvatar}></Avatar>
          } 
        }
      },{
        key: 'answerMobile',
        title: '接听用户手机号',
        dataIndex: 'answerMobile'
      },{
        key: 'time',
        title: '通话时长',
        dataIndex: 'time',
        render: (time, row) => {
          return time + "秒";
        }
      },{
        key: 'oooFee',
        title: '通话单价',
        dataIndex: 'oooFee',
      },{
        key: 'totalCoin',
        title: '通话收益',
        dataIndex: 'totalCoin'
      },{
        key: 'giftCoin',
        title: '打赏映票',
        dataIndex: 'giftCoin'
      },{
        key: 'total',
        title: '本次通话收益',
        dataIndex: 'total'
      },{
        key: 'startTime',
        title: '开始时间',
        dataIndex: 'startTime'
      },{
        key: 'endTime',
        title: '结束时间',
        dataIndex: 'endTime'
      }],
    answerRecordTotal: 0,
    answerRecordList: null,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
  }

  // 礼物关联分页
  answerRecordChangePage=(page, pageSize) => {
      this.props.refreshAnswerRecordData(page, pageSize, this.props.rowData.userId);
  }



  render () {
    return (
      <Modal
        title="查看礼包内容"
        destroyOnClose
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        width={1000}
        footer={[
          null, null,
        ]}
      >
        <Table 
          bordered
          style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
          dataSource={this.props.rowData != null ? this.props.rowData.answerRecordList : null}
          columns={this.state.answerRecordColumns}
          rowKey="userid"
          scroll={{ x: 'max-content' }}
          pagination = {{ total: this.props.rowData != null ? this.props.rowData.answerRecordTotal : null, defaultCurrent: 1, onChange: this.answerRecordChangePage}}
        >
        </Table>
      </Modal>
    )
  }
}

export default ShowAnswerRecord