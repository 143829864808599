import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取系统通知
   * @param {*} systemNoticeParam
   * @returns
   */
   getSystemNoticePage(systemNoticeParam) {
      //console.log("systemNoticeParam=" + JSON.stringify(systemNoticeParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/systemNotice/getSystemNoticePage",
        data: systemNoticeParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新系统通知
   * @param {*} appSystemNotice
   * @returns
   */
   insertOrUpdateSystemNotice(appSystemNotice) {
        //console.log("appSystemNotice=" + JSON.stringify(appSystemNotice));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/systemNotice/insertOrUpdateSystemNotice",
        data: appSystemNotice,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


}