import React, { Component, PropTypes } from "react";
import {
  Breadcrumb,
  Table,
  Button,
  message,
  Modal,
  Avatar,
  Popconfirm,
  Input,
  Row,
  Col,
  DatePicker,
  Tabs,
  Form,
  Upload,
  Select,
  Checkbox,
  Radio,
  Image,
  InputNumber,
} from "antd";
import { Link } from "react-router-dom";
import { StickyContainer, Sticky } from "react-sticky";
import { GetLoginUserInfo, Logout } from "../../../Common/Auth";
import moment from "moment";
import adminLoginConfigService from "../../../Service/SystemSetting/adminLoginConfigService";
import adminPushConfigService from "../../../Service/SystemSetting/adminPushConfigService";
import liveKeyService from "../../../Service/SystemSetting/liveKeyService";
import smsSendRecordService from "../../../Service/SystemSetting/smsSendRecordService";
import videoConfigService from "../../../Service/SystemSetting/videoConfigService";
import domainBlackListService from "../../../Service/SystemSetting/domainBlackListService";
import adminImConfigService from "../../../Service/SystemSetting/adminImConfigService";



import AddDomainBlackList from "./AddDomainBlackList";
import UpdateDomainBlackList from "./UpdateDomainBlackList";

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar
        {...props}
        className="site-custom-tab-bar"
        style={{ ...style }}
      />
    )}
  </Sticky>
);

class ThreePartySetting extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  formRef4 = React.createRef();
  formRef5 = React.createRef();
  formRef6 = React.createRef();
  formRef7 = React.createRef();
  state = {
    // ———————————————————— 短信发送记录 ————————————————————————————

    smsSendRecordColumns: [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "tel",
        title: "手机号",
        dataIndex: "tel",
      },
      {
        key: "code",
        title: "验证码",
        dataIndex: "code",
      },
      {
        key: "ip",
        title: "ip",
        dataIndex: "ip",
      },
      {
        key: "createTime",
        title: "创建时间",
        dataIndex: "createTime",
      },
      {
        key: "result",
        title: "发送结果",
        dataIndex: "result",
      }
    ],
    smsSendRecordTotal: 0,
    smsSendRecordList: null,

    // ———————————————————— 域名黑名单 ————————————————————————————

    domainBlackListColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'ip',
      title: '域名',
      dataIndex: 'ip',
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Popconfirm
                onConfirm={() => {
                  this.deleteDomainBlackList(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    domainBlackListTotal: 0,
    domainBlackList: [],
    updateDomainBlackListRow: null,
    showUpdateDomainBlackListDialog: false,
    showAddDomainBlackListDialog:false,
    domainBlackListPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 直播Key配置 ————————————————————————————
    liveKey: null,
    
    // ———————————————————— 短信配置 ————————————————————————————
    adminLoginConfig: null,

    // ———————————————————— 视频配置 ————————————————————————————
    videoConfig: null,

    // ———————————————————— 推送配置 ————————————————————————————
    pushConfig: null,

    // ———————————————————— IM配置 ————————————————————————————
    adminImConfig: null,

  };

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 短信发送记录 ————————————————————————————
    this.getSmsSendRecord();
  }

  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && this.state.unsubscribe();
  }

  // ———————————————————— 短信发送记录 ————————————————————————————
  // 获取分页数据
  handleGetSmsSendRecordPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var smsSendRecordParam = {
        tel: values.tel,
        ip: values.ip,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam: pageParam,
      };

      smsSendRecordService.getSmsSendRecordPage(smsSendRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            smsSendRecordList: res.retValue.list,
            smsSendRecordTotal: res.retValue.total,
          });
        }                  

      });
    })
  }



  // 分页
  smsSendRecordChangePage = (page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then((values) => {
      var pageParam = {
        pageNum: page,
        pageSize: pageSize,
      };

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var smsSendRecordParam = {
        tel: values.tel,
        ip: values.ip,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam: pageParam,
      };

      smsSendRecordService.getSmsSendRecordPage(smsSendRecordParam)
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({
            smsSendRecordList: res.retValue.list,
            smsSendRecordTotal: res.retValue.total,
          });
        }                  
        
      });
    });
  };

  // 刷新数据
  refreshSmsSendRecordData = () => {
    //console.log("refreshSmsSendRecordData");
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };

    var smsSendRecordParam = {
      pageParam: pageParam,
    };
    smsSendRecordService.getSmsSendRecordPage(smsSendRecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          smsSendRecordList: res.retValue.list,
          smsSendRecordTotal: res.retValue.total,
        });
      }                  
      
    });
  };

  // ———————————————————— 域名黑名单 ————————————————————————————
  // 关闭更新
  hideUpdateDomainBlackListDialog=() => {
    this.setState({showUpdateDomainBlackListDialog: false});
  }

  // 关闭添加
  hideAddDomainBlackListDialog=() => {
    this.setState({showAddDomainBlackListDialog: false});
  }


  // 获取列表
  handleGetDomainBlackListPage=() =>{
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var domainBlackList={
        pageParam: pageParam
      }

      domainBlackListService.getDomainPage(domainBlackList)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({domainBlackList: res.retValue.list, domainBlackListTotal: res.retValue.total});
          }
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  domainBlackListChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      domainBlackListPageParam: pageParam,
    });

    var domainBlackList={
      pageParam: pageParam
    }

    domainBlackListService.getDomainPage(domainBlackList)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({domainBlackList: res.retValue.list, domainBlackListTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshDomainBlackListData=() => {
      let { history } = this.props;

      var domainBlackList={
        pageParam: this.state.domainBlackListPageParam
      }

      domainBlackListService.getDomainPage(domainBlackList)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({domainBlackList: res.retValue.list, domainBlackListTotal: res.retValue.total});
          }
        }
      )
  }

  // 根据Id删除礼物
  deleteDomainBlackList=(id) => {
    let { history } = this.props;
    let domainBlackList = {
      id : id
    };
    domainBlackListService.deleteDomain(domainBlackList)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshDomainBlackListData();
        message.success('删除成功！');
      }
      
    })

  }

  // ———————————————————— 直播Key配置 ————————————————————————————


  handleLiveKeySubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef7.current.validateFields()
    .then(values => {

        var cfgLiveKey = values;

        liveKeyService.updateConfig(cfgLiveKey)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("提交失败")
              message.info("提交失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('提交成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }

  
  // ———————————————————— 短信配置 ————————————————————————————
  handleAdminLoginConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {

        var adminLoginConfig = values;

        adminLoginConfigService.updateConfig(adminLoginConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("提交失败")
              message.info("提交失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('提交成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }

  // ———————————————————— 视频配置 ————————————————————————————
  handleVideoConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef4.current.validateFields()
    .then(values => {

        var videoConfig = values;

        videoConfigService.updateConfig(videoConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("提交失败")
              message.info("提交失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('提交成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }

  // ———————————————————— 推送配置 ————————————————————————————
  handlePushConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef5.current.validateFields()
    .then(values => {

        var pushConfig = values;

        adminPushConfigService.updateConfig(pushConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("提交失败")
              message.info("提交失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('提交成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }

  // ———————————————————— IM配置 ————————————————————————————
  handleAdminImConfigSubmit=() => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef6.current.validateFields()
    .then(values => {

        var adminImConfig = values;

        adminImConfigService.updateConfig(adminImConfig)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              console.log("提交失败")
              message.info("提交失败,"+res.message);  
              // 重置关闭对话框
              //this.hanldeCloseModal();
            } else {
              message.info('提交成功！');
              // 重置关闭对话框
              //this.hanldeCloseModal();
            }
    
          }
        )
    })
  }


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getSmsSendRecord();
    } else if(key == "2") {
      this.getDomainPage();
    } else if(key == "3") {
      this.getAdminLogin();
    } else if(key == "4") {
      this.getVideoConfig();
    } else if(key == "5") {
      this.getAdminPushConfig();
    } else if(key == "6") {
      this.getAdminPushConfig();
    } else if(key == "7") {
      this.getLiveKey();
    }
  }

  /**
   * 邀请码背景图设置
   */
  getDomainPage =() => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var domainBlackList={
      pageParam: pageParam
    }

    domainBlackListService.getDomainPage(domainBlackList)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({domainBlackList: res.retValue.list, domainBlackListTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 短信发送记录
   */
  getSmsSendRecord= () => {
    let { history } = this.props;
    var pageParam = {
      pageNum: 1,
      pageSize: 10,
    };
    var smsSendRecordParam = {
      pageParam: pageParam,
    };
    smsSendRecordService.getSmsSendRecordPage(smsSendRecordParam)
    .then((res) => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({
          smsSendRecordList: res.retValue.list,
          smsSendRecordTotal: res.retValue.total,
        });
      }                  
      
    });
  }

  /**
   * 直播Key配置
   */
  getLiveKey= () => {
    let { history } = this.props;
    liveKeyService.getConfig()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef7.current.setFieldsValue({
            id: res.retValue.id,
            //imIp: res.retValue.imIp,
            beautySwitch: res.retValue.beautySwitch,
            // beautyAppid: res.retValue.beautyAppid,
            // beautySecretKey: res.retValue.beautySecretKey,
            // beautyKeyInfo: res.retValue.beautyKeyInfo,
            // liveAppid: res.retValue.liveAppid,
            // liveSecretKey: res.retValue.liveSecretKey,
            // liveKeyInfo: res.retValue.liveKeyInfo,
            // imAppid: res.retValue.imAppid,
            // imSecretKey: res.retValue.imSecretKey,
            // imInfo: res.retValue.imInfo,
            // videoClipsAppId: res.retValue.videoClipsAppId,
            // videoClipsSecretKey: res.retValue.videoClipsSecretKey,
            // videoClipsInfo: res.retValue.videoClipsInfo,
            // cdnAppId: res.retValue.cdnAppId,
            // cdnSecretKey: res.retValue.cdnSecretKey,
            // cdnInfo: res.retValue.cdnInfo,
          });
          this.setState({liveKey: res.retValue})
        }                  
      }
    )  
  }

  /**
   * 短信配置
   */
  getAdminLogin= () => {
    let { history } = this.props;
    adminLoginConfigService.getConfig()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef3.current.setFieldsValue({
            id: res.retValue.id,
            smsSwitch: res.retValue.smsSwitch,
            smsbaoAccount: res.retValue.smsbaoAccount,
            smsbaoPs: res.retValue.smsbaoPs,
            smsSignDomestic: res.retValue.smsSignDomestic,
            systemTemplateId: res.retValue.systemTemplateId,
            smsSignForeign: res.retValue.smsSignForeign,
            systemTemplateId1: res.retValue.systemTemplateId1,
            sendcodeSwitch: res.retValue.sendcodeSwitch,
            disableOldApi: res.retValue.disableOldApi,
            ipLimitDayCount: res.retValue.ipLimitDayCount,
            mobileLimitDayCount: res.retValue.mobileLimitDayCount,
            smsSignDomesticHmy: res.retValue.smsSignDomesticHmy,
            systemTemplateIdHmy: res.retValue.systemTemplateIdHmy,
          });
          this.setState({adminLoginConfig: res.retValue})
        }                  
      }
    )  
  }

  /**
   * 视频配置
   */
  getVideoConfig= () => {
    let { history } = this.props;
    videoConfigService.getConfig()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef4.current.setFieldsValue({
            id: res.retValue.id,
            cloudtype: res.retValue.cloudtype,
            babaAppid: res.retValue.babaAppid,
            babaSecretKey: res.retValue.babaSecretKey,
            babaKeyInfo: res.retValue.babaKeyInfo,
          });
          this.setState({videoConfig: res.retValue})
        }                  
      }
    )  
  }

  /**
   * 推送配置
   */
  getAdminPushConfig =() => {
    let { history } = this.props;
    adminPushConfigService.getConfig()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef5.current.setFieldsValue({
            id: res.retValue.id,
            androidPackageNames: res.retValue.androidPackageNames,
            iosPackageNames: res.retValue.iosPackageNames,
            apnsTeamId: res.retValue.apnsTeamId,
            apnsKeyId: res.retValue.apnsKeyId,
            apnsAuthKeyPath: res.retValue.apnsAuthKeyPath,
            txAppId: res.retValue.txAppId,
            txSecretKey: res.retValue.txSecretKey,
            hwAppId: res.retValue.hwAppId,
            hwAppSecret: res.retValue.hwAppSecret,
            vivoAppId: res.retValue.vivoAppId,
            vivoAppKey: res.retValue.vivoAppKey,
            vivoSecretKey: res.retValue.vivoSecretKey,
            oppoAppKey: res.retValue.oppoAppKey,
            oppoSecretKey: res.retValue.oppoSecretKey,
            oppoPassageId: res.retValue.oppoPassageId,
            miSecretKey: res.retValue.miSecretKey,
            miPassageId: res.retValue.miPassageId,
          });
          this.setState({pushConfig: res.retValue})
        }                  
      }
    ) 
  }

  /**
   * IM配置
   */
  getAdminPushConfig =() => {
    let { history } = this.props;
    adminImConfigService.getConfig()
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.formRef6.current.setFieldsValue({
            id: res.retValue.id,
            imType: res.retValue.imType,
          });
          this.setState({adminImConfig: res.retValue})
        }                  
      }
    ) 
  }


  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/three_party_setting">三方设置</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr />

        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>

            // ———————————————————— 短信发送记录 ————————————————————————————
            <TabPane tab="短信发送记录" key="1">
              <Form
                name="SmsSendRecordListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="手机号" name="tel">
                          <Input placeholder="手机号"/>
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="ip地址" name="ip">
                          <Input placeholder="ip地址"/>
                      </Form.Item>
                    </Col>
                    <Col span={6} key={5}>
                        <Form.Item label="创建时间" name="sendTime">
                            <RangePicker />
                        </Form.Item>
                    </Col>
                    <Col span={4} key={7} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetSmsSendRecordPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table
                bordered
                style={{ backgroundColor: "#FEFEFE", marginTop: "10px" }}
                dataSource={this.state.smsSendRecordList}
                columns={this.state.smsSendRecordColumns}
                rowKey="id"
                scroll={{ x: "max-content" }}
                pagination={{
                  total: this.state.smsSendRecordTotal,
                  defaultCurrent: 1,
                  onChange: this.smsSendRecordChangePage,
                }}
              ></Table>
            </TabPane>

            // ———————————————————— 域名黑名单设置 ————————————————————————————
            <TabPane tab="域名黑名单设置" key="2">
              <Form
                name="domainBlackListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddDomainBlackListDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.domainBlackList}
                columns={this.state.domainBlackListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.domainBlackListTotal, defaultCurrent: 1, onChange: this.domainBlackListChangePage}}
              >
              </Table>
              <AddDomainBlackList close={this.hideAddDomainBlackListDialog} visible={this.state.showAddDomainBlackListDialog} refreshData={this.refreshDomainBlackListData}></AddDomainBlackList>
              <UpdateDomainBlackList rowData={this.state.updateDomainBlackListRow} close={this.hideUpdateDomainBlackListDialog} visible={this.state.showUpdateDomainBlackListDialog} refreshData={this.refreshDomainBlackListData}></UpdateDomainBlackList>
              
            </TabPane>

            // —————————————————————— 短信配置 ————————————————————————
            <TabPane tab="短信配置" key="3">
                <Form
                  name="AdminLoginConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef3}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.adminLoginConfig}
                >
                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="短信平台" name="smsSwitch" labelCol={{span:7}}  style={{ width: 600 }} initialValue={2}>
                        <Radio.Group name="smsSwitch" defaultValue={2}>
                          <Radio value={2}>阿里短信</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="AppID" name="smsbaoAccount" labelCol={{span:7}}  style={{ width: 600 }}>
                          <Input placeholder="AppID" />
                      </Form.Item>

                      <Form.Item label="SecretKey" name="smsbaoPs" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="SecretKey" />
                      </Form.Item>
  
                      <Form.Item label="短信签名（国内）" name="smsSignDomestic" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="短信签名（国内）" />
                      </Form.Item>

                      <Form.Item label="模板ID" name="systemTemplateId" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="模板ID" />
                      </Form.Item>

                      <Form.Item label="短信签名-陌盼（国内）" name="smsSignDomesticHmy" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="短信签名（国内）" />
                      </Form.Item>

                      <Form.Item label="模板ID-陌盼" name="systemTemplateIdHmy" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="模板ID" />
                      </Form.Item>

                      <Form.Item label="短信签名（国外）" name="smsSignForeign" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="短信签名（国外）"/>
                      </Form.Item>

                      <Form.Item label="模板ID(国外)" name="systemTemplateId1" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="模板ID(国外)" />
                      </Form.Item>

                      <Form.Item label="短信验证码开关" name="sendcodeSwitch" labelCol={{span:7}}  style={{ width: 600 }} initialValue={0}>
                        <Radio.Group name="sendcodeSwitch" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="禁用老接口" name="disableOldApi" labelCol={{span:7}}  style={{ width: 600 }} initialValue={0}>
                        <Radio.Group name="disableOldApi" defaultValue={0}>
                          <Radio value={0}>关闭</Radio>
                          <Radio value={1}>开启</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="一个IP24小时内短信发送次数" name="ipLimitDayCount" labelCol={{span:9}}  style={{ width: 600 }} >
                          <InputNumber />
                      </Form.Item>

                      <Form.Item label="一个手机号24小时内短信发送次数" name="mobileLimitDayCount" labelCol={{span:9}}  style={{ width: 600 }} >
                          <InputNumber />
                      </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleAdminLoginConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            {/* // —————————————————————— 视频配置 ————————————————————————
            <TabPane tab="视频配置" key="4">
              <Form
                  name="VideoConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef4}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.videoConfig}
                >
                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="选择存储方式" name="cloudtype" labelCol={{span:7}}  style={{ width: 600 }} initialValue={2}>
                        <Radio.Group name="cloudtype" defaultValue={2}>
                          <Radio value={0}>轩嗵云存储</Radio>
                          <Radio value={1}>七牛云存储</Radio>
                        </Radio.Group>
                      </Form.Item>

                      <Form.Item label="云存储 AppId" name="babaAppid" labelCol={{span:7}}  style={{ width: 600 }}>
                          <Input placeholder="云存储 AppId" />
                      </Form.Item>

                      <Form.Item label="云存储 SecretKey" name="babaSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="云存储 SecretKey" />
                      </Form.Item>
  
                      <Form.Item label="信息" name="babaKeyInfo" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="信息" disabled />
                      </Form.Item>


                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleVideoConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane> */}

            // —————————————————————— 推送配置 ————————————————————————
            <TabPane tab="推送配置" key="5">
                <Form
                  name="PushConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef5}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.pushConfig}
                >
                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      <Form.Item label="安卓包名" name="androidPackageNames" labelCol={{span:7}}  style={{ width: 600 }}>
                          <Input placeholder="安卓包名" />
                      </Form.Item>

                      <Form.Item label="ios包名" name="iosPackageNames" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="ios包名" />
                      </Form.Item>
  
                      <Form.Item label="APNS推送teamId" name="apnsTeamId" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="APNS推送teamId" />
                      </Form.Item>

                      <Form.Item label="APNS推送keyId/p12Pwd" name="apnsKeyId" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="APNS推送keyId/p12Pwd" />
                      </Form.Item>

                      <Form.Item label="APNS推送authKeyPath" name="apnsAuthKeyPath" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="APNS推送authKeyPath"/>
                      </Form.Item>

                      <Form.Item label="腾讯TNPS_AppId" name="txAppId" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="腾讯AppId" />
                      </Form.Item>

                      <Form.Item label="腾讯TNPS_SecretKey" name="txSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                        <Input placeholder="腾讯SecretKey" />
                      </Form.Item>

                      <Form.Item label="华为通道AppId" name="hwAppId" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="华为AppId" />
                      </Form.Item>

                      <Form.Item label="华为通道SecretKey" name="hwAppSecret" labelCol={{span:7}}  style={{ width: 600 }} >
                        <Input placeholder="华为SecretKey" />
                      </Form.Item>

                      <Form.Item label="VIVO通道AppId" name="vivoAppId" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="VIVO通道AppId" />
                      </Form.Item>

                      <Form.Item label="VIVO推送AppKey" name="vivoAppKey" labelCol={{span:7}}  style={{ width: 600 }} >
                        <Input placeholder="VIVO推送AppKey" />
                      </Form.Item>

                      <Form.Item label="VIVO通道SecretKey" name="vivoSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                        <Input placeholder="VIVO通道SecretKey" />
                      </Form.Item>

                      <Form.Item label="OPPO通道AppKey" name="oppoAppKey" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="OPPO通道AppKey" />
                      </Form.Item>

                      <Form.Item label="OPPO通道SecretKey" name="oppoSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                        <Input placeholder="OPPO通道SecretKey" />
                      </Form.Item>

                      <Form.Item label="OPPO通道ID" name="oppoPassageId" labelCol={{span:7}}  style={{ width: 600 }} >
                        <Input placeholder="OPPO通道ID" />
                      </Form.Item>

                      <Form.Item label="小米通道SecretKey" name="miSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                        <Input placeholder="小米通道SecretKey" />
                      </Form.Item>

                      <Form.Item label="小米通道ID" name="miPassageId" labelCol={{span:7}}  style={{ width: 600 }} >
                        <Input placeholder="小米通道ID" />
                      </Form.Item>


                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handlePushConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— IM配置 ————————————————————————
            <TabPane tab="IM配置" key="6">
                <Form
                  name="AdminImConfigForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef6}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.adminImConfig}
                >
                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>
                     
                      <Form.Item label="IM类型" name="imType" labelCol={{span:7}}  style={{ width: 600 }} initialValue={0}>
                        <Radio.Group name="imType" defaultValue={0}>
                          <Radio value={0}>腾讯IM</Radio>
                          <Radio value={1}>自研IM</Radio>
                          <Radio value={2}>腾讯IM+自研IM</Radio>
                        </Radio.Group>
                      </Form.Item>

                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleAdminImConfigSubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>
            </TabPane>

            // —————————————————————— 直播Key配置 ————————————————————————
            <TabPane tab="直播Key配置" key="7">
              <Form
                  name="LiveKeyForm"
                  layout="horizontal"
                  labelCol={{span:4}}
                  wrapperCol={{span: 20}}
                  ref={this.formRef7}
                  style={{ margin: '20px 0px 0px 100px'}}
                  initialValues={this.state.liveKey}
                >
                      <Form.Item label="id" name="id" initialValue={1} hidden>
                          <Input placeholder="id" />
                      </Form.Item>

                      {/* <Form.Item label="本机外网IP" name="imIp" labelCol={{span:7}}  style={{ width: 600 }}>
                          <Input placeholder="本机外网IP" />
                      </Form.Item> */}

                      <Form.Item label="美颜" name="beautySwitch" labelCol={{span:7}}  style={{ width: 600 }} initialValue={0}>
                        <Radio.Group name="beautySwitch" defaultValue={0}>
                          <Radio value={0}>基础美颜</Radio>
                          <Radio value={1}>自研美颜</Radio>
                        </Radio.Group>
                      </Form.Item>

                      {/* <Form.Item label="美颜SDK AppId" name="beautyAppid" labelCol={{span:7}}  style={{ width: 600 }}>
                          <Input placeholder="美颜SDK AppId" />
                      </Form.Item>

                      <Form.Item label="美颜SDK SecretKey" name="beautySecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="美颜SDK SecretKey" />
                      </Form.Item>
  
                      <Form.Item label="美颜信息" name="beautyKeyInfo" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="美颜信息" disabled/>
                      </Form.Item>

                      <Form.Item label="互动直播 AppId" name="liveAppid" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="互动直播 AppId" />
                      </Form.Item>

                      <Form.Item label="互动直播 SecretKey" name="liveSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="互动直播 SecretKey" />
                      </Form.Item>

                      <Form.Item label="互动直播信息" name="liveKeyInfo" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="互动直播信息" disabled/>
                      </Form.Item>

                      <Form.Item label="IM AppId" name="imAppid" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="IM AppId" />
                      </Form.Item>

                      <Form.Item label="IM SecretKey" name="imSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="IM SecretKey" />
                      </Form.Item>

                      <Form.Item label="IM 信息" name="imInfo" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="IM 信息" disabled/>
                      </Form.Item>

                      <Form.Item label="短视频SDK AppId" name="videoClipsAppId" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="短视频SDK AppId" />
                      </Form.Item>

                      <Form.Item label="短视频SDK SecretKey" name="videoClipsSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="短视频SDK SecretKey" />
                      </Form.Item>

                      <Form.Item label="短视频SDK 信息" name="videoClipsInfo" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="短视频SDK 信息" disabled/>
                      </Form.Item>
                      
                      <Form.Item label="CDN AppId" name="cdnAppId" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="CDN AppId" />
                      </Form.Item>

                      <Form.Item label="CDN SecretKey" name="cdnSecretKey" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="CDN SecretKey" />
                      </Form.Item>

                      <Form.Item label="CDN信息" name="cdnInfo" labelCol={{span:7}}  style={{ width: 600 }} >
                          <Input placeholder="CDN信息" disabled/>
                      </Form.Item> */}
                </Form>

                <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
                  <span onClick={ this.handleLiveKeySubmit } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">保存</Button></span>
                  <span style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} >取消</Button></span>
                </div>

            </TabPane>

          </Tabs>
        </StickyContainer>
      </div>
    );
  }
}
export default ThreePartySetting;
