import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Row, Col, Checkbox} from 'antd';
import appUserService from '../../../Service/UserMgr/appUserService';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';

class EnableUser extends Component {
  formRef = React.createRef();

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var user=GetLoginUserInfo();
          let enableUserParam = {
            userId : values.userId,
            optId : user.userId,
          };

          appUserService.enableUser(enableUserParam)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
  }


  render () {
    return (
      <Modal
        title="解禁用户"
        destroyOnClose
        okText="确认解禁"
        cancelText="取消解禁"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="用户ID" name="userId">
                <Input placeholder="用户ID" disabled />
            </Form.Item>

            <Form.Item label="禁用类型" name="disableType" >
              <Input placeholder="禁用类型" disabled />
            </Form.Item>

            <Form.Item label="创建时间" name="createTime" >
              <Input placeholder="创建时间" disabled/>
            </Form.Item>

            <Form.Item label="禁用天数" name="numStr" >
              <Input placeholder="禁用天数" disabled/>
            </Form.Item>

            <Form.Item label="剩余时间" name="timeLeft" >
              <Input placeholder="剩余时间" disabled/>
            </Form.Item>

            <Form.Item label="禁用原因" name="reason" >
              <Input.TextArea placeholder="禁用原因" disabled/>
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default EnableUser