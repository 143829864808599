import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页用户举报信息
   * @param {*} pageParam
   * @returns
   */
   getUserAppealTypePage(pageParam) {
      //console.log("userAppealParam=" + JSON.stringify(userAppealParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/userAppealType/getUserAppealTypePage",
        data: pageParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 更新举报类型
   * @param {*} userAppealType
   * @returns
   */
   insertOrUpdateAppealType(userAppealType) {
        //console.log("userAppealType=" + JSON.stringify(userAppealType));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/userAppealType/insertOrUpdateAppealType",
        data: userAppealType,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}