import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  getRelListByRoleId(roleId) {
    let data = {
      roleId: roleId,
    };

    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/rolePrivilege/getRelListByRoleId",
      data: Qs.stringify(data),
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  updateRelByRoleId(rolePrivilegeParamList) {
    console.log("rolePrivilegeParamList=" + JSON.stringify(rolePrivilegeParamList));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/rolePrivilege/updateRelByRoleId",
      data: rolePrivilegeParamList,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
};
