import axios from "axios";
import Qs from "qs";
import { Buffer } from 'antd';
import * as qiniu from 'qiniu-js';
import moment from 'moment'
import {GetLoginToken} from '../Common/Auth.js';
import {GetDomain} from '../Common/Domain.js';


const qiniuFile = (file, sceneType) => {
  var curr_uid = 10000
  var datePath = moment().format('/yyyy/MM');
  var strddhhmmssSSS = moment().format('ddhhmmssSSS');
  var hhmmssSSS = moment().format('hhmmssSSS');

  var fileName1 = getScene(sceneType) + datePath + "/" + curr_uid + "_" + hhmmssSSS;
  var fileName = fileName1 + file.name;
  return fileName;
}

const getScene = (type) => {
  var scene = "other";
  switch (type) {
      case 1:
          scene = "userimg";
          break;
      case 2:
          scene = "dynamicimg";
          break;
      case 3:
          scene = "dynamicvideo";
          break;
      case 4:
          scene = "uservideo";
          break;
      case 5:
          scene = "uservoice";
          break;
      case 6:
          scene = "other";
          break;
  }
  return scene;
}


export default {
    
  /**
   * 上传七牛
   * @param file 上传文件
   * @param key 目标文件名
   * @param token 上传凭证
   * @param putExtra 上传文件的相关资源信息配置
   * @param config 上传任务的配置
   * @returns 返回用于上传任务的可观察对象
   */
   uploadQiniu(file) {
    var putExtra = {
      fname: '',
      params: {},
      // mimeType: ['image/png', 'image/jpeg', 'image/gif'],
    };
    var config = {
        useCdnDomain: true, //使用cdn加速
    };
    var fileName = qiniuFile(file, 1);
    
    return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/common/getQiniuToken",
        data: null,
      }).then((res) => {
        //console.log("uploadQiniu fileName="+fileName);
  
        var observable = qiniu.upload(file, fileName, res.data.retValue, putExtra, config);
        // observable.subscribe({
        //     error: () => {
        //         console.log('上传图片失败');
        //     },
        //     complete: (res) => {
        //       console.log('上传图片完成，res='+JSON.stringify(res));
        //     },
        // });
        return observable;
      });
      
  },


   /**
   * 上传七牛
   * @param file 上传文件
   * @param key 目标文件名
   * @param token 上传凭证
   * @param putExtra 上传文件的相关资源信息配置
   * @param config 上传任务的配置
   * @returns 返回用于上传任务的可观察对象
   */
   uploadQiniu(file, type) {
    var putExtra = {
      fname: '',
      params: {},
      // mimeType: ['image/png', 'image/jpeg', 'image/gif'],
    };
    var config = {
        useCdnDomain: true, //使用cdn加速
    };
    var fileName = qiniuFile(file, type);
    
    return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/common/getQiniuToken",
        data: null,
      }).then((res) => {
        //console.log("uploadQiniu fileName="+fileName);
  
        var observable = qiniu.upload(file, fileName, res.data.retValue, putExtra, config);
        // observable.subscribe({
        //     error: () => {
        //         console.log('上传图片失败');
        //     },
        //     complete: (res) => {
        //       console.log('上传图片完成，res='+JSON.stringify(res));
        //     },
        // });
        return observable;
      });
      
  },


  uploadApp(file) {
    var putExtra = {
      fname: '',
      params: {},
    };
    var config = {
        useCdnDomain: true, //使用cdn加速
    };
    var fileName = qiniuFile(file, 6);

    return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/common/getQiniuToken",
        data: null,
      }).then((res) => {
        console.log("uploadQiniu fileName="+fileName);

        var observable = qiniu.upload(file, fileName, res.data.retValue, putExtra, config);
        return observable;
      });
      
  },

    /**
     * 获取域名
     * @returns 
     */
    getDoamin() {
      return GetDomain();
    }
};
