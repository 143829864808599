import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import dynamicAppealService from '../../../Service/Dynamic/dynamicAppealService';
import dynamicAppealTypeService from '../../../Service/Dynamic/dynamicAppealTypeService';
import UpdateDynamicReporting from './UpdateDynamicReporting';
import AddDynamicAppealType from './AddDynamicAppealType';
import UpdateDynamicAppealType from './UpdateDynamicAppealType';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class DynamicReporting extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 举报列表 ————————————————————————————
    dynamicReportingColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.userId}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'dynamicAppealTypeName',
      title: '举报类型名称',
      dataIndex: 'dynamicAppealTypeName'
    },{
      key: 'dynamicAppealImages',
      title: '图片',
      dataIndex: 'dynamicAppealImages',
      render: (dynamicAppealImages, row) => {
        if(dynamicAppealImages != null && dynamicAppealImages != "") {
          return (
            <div style={{ display: "flex" }}>
              {dynamicAppealImages.split(",").map((items, index) => {
                return (
                  <div
                  key={index}
                  className="common-img-list"
                  style={{
                    width: "80px",
                    height: "80px",
                    marginLeft: "4px",
                    overflow: "hidden"
                  }}
                >
                  <Image
                    width={80}
                    height={80}
                    src={items}
                  />
                </div>
                );
              })}
            </div>
          );
            
        }
      }
    },{
      key: 'dynamicAppealDescription',
      title: '举报描述',
      dataIndex: 'dynamicAppealDescription'
    },{
      key: 'dynamicId',
      title: '动态ID',
      dataIndex: 'dynamicId'
    },{
      key: 'addTime',
      title: '创建时间',
      dataIndex: 'addTime'
    },{
      key: 'isProcess',
      title: '是否处理',
      dataIndex: 'isProcess',
      render: (isProcess, row) => {
        if(isProcess === 0) {
          return "未处理"
        } else if(isProcess === 1) {
          return "处理完成";
        } 
      }
    },{
      key: 'processTime',
      title: '处理时间',
      dataIndex: 'processTime'
    },{
      key: 'remark',
      title: '备注',
      dataIndex: 'remark'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateDynamicReportingDialog:true, updateDynamicReportingRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
            </div>
          )
      }
    }],
    dynamicReportingTotal: 0,
    dynamicReportingList: [],
    updateDynamicReportingRow: null,
    showUpdateDynamicReportingDialog: false,
    dynamicReportingPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 举报类型配置 ————————————————————————————
  
    dynamicAppealTypeColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'appealTypeName',
      title: '举报类型名称',
      dataIndex: 'appealTypeName'
    },{
      key: 'isEnable',
      title: '是否启用',
      dataIndex: 'isEnable',
      render: (isEnable, row) => {
        if(isEnable === 0) {
          return "不启用"
        } else if(isEnable === 1) {
          return "启用";
        } 
      }
    },{
      key: 'addTime',
      title: '创建时间',
      dataIndex: 'addTime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showUpdateDynamicAppealTypeDialog:true, updateDynamicAppealTypeRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
            </div>
          )        
      }
    }],
    dynamicAppealTypeTotal: 0,
    dynamicAppealTypeList: null,
    updateDynamicAppealTypeRow: null,
    showUpdateDynamicAppealTypeDialog: false,
    addDynamicAppealTypeRow: null,
    showAddDynamicAppealTypeDialog: false,
  }


  // 第一次渲染后调用
  componentDidMount() {
      let { history } = this.props;

    // ———————————————————— 举报列表 ————————————————————————————
    this.getDynamicAppeal();

   
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 举报列表 ————————————————————————————

  // 关闭更新举报列表
  hideUpdateDynamicReportingDialog=() => {
    this.setState({showUpdateDynamicReportingDialog: false});
  }

  // 获取动态列表
  handleGetDynamicReportingPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var dynamicAppealParam={
        userId: values.userId,
        dynamicId: values.dynamicId,
        isProcess: values.isProcess,
        pageParam: pageParam
      }

      dynamicAppealService.getDynamicAppealPage(dynamicAppealParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({dynamicReportingList: res.retValue.list, dynamicReportingTotal: res.retValue.total});
          }
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  dynamicReportingChangePage(page, pageSize) {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      dynamicReportingPageParam: pageParam,
    });

    var dynamicAppealParam={
      pageParam: pageParam
    }

    dynamicAppealService.getDynamicAppealPage(dynamicAppealParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({dynamicReportingList: res.retValue.list, dynamicReportingTotal: res.retValue.total});
        }
      }
    )
  }

  /**
   * 刷新举报列表
   */
  refreshDynamicReportingData=() => {
      let { history } = this.props;

      var dynamicAppealParam={
        pageParam: this.state.dynamicReportingPageParam
      }
  
      dynamicAppealService.getDynamicAppealPage(dynamicAppealParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({dynamicReportingList: res.retValue.list, dynamicReportingTotal: res.retValue.total});
          }
        }
      )
  }

// ———————————————————— 举报类型配置 ————————————————————————————

// 关闭更新举报列表
hideUpdateDynamicAppealTypeDialog=() => {
  this.setState({showUpdateDynamicAppealTypeDialog: false});
}

// 关闭添加举报列表
hideAddDynamicAppealTypeDialog=() => {
  this.setState({showAddDynamicAppealTypeDialog: false});
}


/**
 * 分页查询
 * @param {*} page 
 * @param {*} pageSize 
 */
dynamicDynamicAppealTypePage(page, pageSize) {
  let { history } = this.props;
  var pageParam = { 
    pageNum: page,
    pageSize: pageSize
  }

  var dynamicAppealTypeParam={
    pageParam: pageParam
  }

  dynamicAppealTypeService.getDynamicAppealTypePage(dynamicAppealTypeParam)
  .then(
    res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({dynamicAppealTypeList: res.retValue.list, dynamicAppealTypeTotal: res.retValue.total});
      }
    }
  )
}

/**
 * 刷新举报列表
 */
refreshDynamicAppealTypeData=() => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var dynamicAppealTypeParam={
      pageParam: pageParam
    }

    dynamicAppealTypeService.getDynamicAppealTypePage(dynamicAppealTypeParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({dynamicAppealTypeList: res.retValue.list, dynamicAppealTypeTotal: res.retValue.total});
        }
      }
    )
}

onTabChanged = (key) => {
  //console.log("onTabChanged key="+key);
  if(key == "1") {
    this.getDynamicAppeal();
  } else if(key == "2") {
    this.getDynamicAppealType();
  }
}

/**
 * 举报列表
 */
getDynamicAppeal= () => {
  let { history } = this.props;
  var pageParam = { 
    pageNum: 1,
    pageSize: 10
  }

  var dynamicAppealParam={
    pageParam: pageParam
  }

  dynamicAppealService.getDynamicAppealPage(dynamicAppealParam)
  .then(
    res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({dynamicReportingList: res.retValue.list, dynamicReportingTotal: res.retValue.total});
      }
    }
  )
}

/**
 * 举报类型配置
 */
getDynamicAppealType= () => {
  let { history } = this.props;
  var pageParam = { 
    pageNum: 1,
    pageSize: 10
  }

  var dynamicAppealTypeParam={
    pageParam: pageParam
  }

  dynamicAppealTypeService.getDynamicAppealTypePage(dynamicAppealTypeParam)
  .then(
    res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        this.setState({dynamicAppealTypeList: res.retValue.list, dynamicAppealTypeTotal: res.retValue.total});
      }
    }
  )
}


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/dynamic_reporting">举报列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 举报列表 ————————————————————————————
            <TabPane tab="举报列表" key="1">
              <Form
                name="DynamicReportingListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户UID" name="userId">
                          <Input placeholder="用户UID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="动态ID" name="dynamicId">
                          <Input placeholder="动态ID" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="是否处理" name="isProcess">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={0}>未处理</Option>
                              <Option value={1}>处理完成</Option>
                          </Select>
                      </Form.Item>
                    </Col>

                    <Col span={5} key={4} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetDynamicReportingPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.dynamicReportingList}
                columns={this.state.dynamicReportingColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.dynamicReportingTotal, defaultCurrent: 1, onChange: this.dynamicReportingChangePage}}
              >
              </Table>
              <UpdateDynamicReporting rowData={this.state.updateDynamicReportingRow} close={this.hideUpdateDynamicReportingDialog} visible={this.state.showUpdateDynamicReportingDialog} refreshData={this.refreshDynamicReportingData}></UpdateDynamicReporting>
              
            </TabPane>
            
            // ———————————————————— 举报类型配置 ——————————————————————
            <TabPane tab="举报类型配置" key="2">
            <Form
                name="DynamicAppealTypeListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
   
                    <Col span={14} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddDynamicAppealTypeDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>

                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.dynamicAppealTypeList}
                columns={this.state.dynamicAppealTypeColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.dynamicAppealTypeTotal, defaultCurrent: 1, onChange: this.dynamicAppealTypeChangePage}}
              >
              </Table>
              <AddDynamicAppealType close={this.hideAddDynamicAppealTypeDialog} visible={this.state.showAddDynamicAppealTypeDialog} refreshData={this.refreshDynamicAppealTypeData}></AddDynamicAppealType>
              <UpdateDynamicAppealType rowData={this.state.updateDynamicAppealTypeRow} close={this.hideUpdateDynamicAppealTypeDialog} visible={this.state.showUpdateDynamicAppealTypeDialog} refreshData={this.refreshDynamicAppealTypeData}></UpdateDynamicAppealType>
              
            
            </TabPane>


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default DynamicReporting;