import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取系统通知类型
   * @param {*} systemNoticeTypeParam
   * @returns
   */
   getSystemNoticeTypePage(systemNoticeTypeParam) {
      //console.log("systemNoticeTypeParam=" + JSON.stringify(systemNoticeTypeParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/systemNoticeType/getSystemNoticeTypePage",
        data: systemNoticeTypeParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新系统通知类型
   * @param {*} adsParam
   * @returns
   */
   insertOrUpdateSystemNoticeType(appSystemNoticeType) {
        //console.log("appSystemNoticeType=" + JSON.stringify(appSystemNoticeType));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/systemNoticeType/insertOrUpdateSystemNoticeType",
        data: appSystemNoticeType,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


}