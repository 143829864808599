import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

    /**
     * 获取引流信息
     * @param {*}  
     * @returns 
     */
    getAppFlowPage(appFlow) {
        //console.log("appFlow=" + JSON.stringify(appFlow));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/appFlow/getAppFlowPage",
            data: appFlow,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
   * 插入更新引流信息
   * @param {*} appFlow
   * @returns
   */
    insertOrUpdateAppFlow(appFlow) {
        //console.log("insertOrUpdateTab appFlow=" + JSON.stringify(appFlow));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/appFlow/insertOrUpdateAppFlow",
            data: appFlow,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


  /**
   * 删除引流信息
   * @param {*} appFlow
   * @returns
   */
    deleteAppFlow(appFlow) {
        //console.log("deleteAppFlow appFlow=" + JSON.stringify(appFlow));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/appFlow/deleteAppFlow",
            data: appFlow,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },
}