import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取运营支出
   * @param {*} operatingExpensesParam
   * @returns
   */
   getOperatingExpensesPage(operatingExpensesParam) {
      //console.log("operatingExpensesParam=" + JSON.stringify(operatingExpensesParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/finSysTranRecord/getOperatingExpensesPage",
        data: operatingExpensesParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

}