import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
  getPrivilegeListByUserId(userId) {

    let data = {
      userId : userId
    }

    return axios({
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/userPrivilege/getPrivilegeListByUserId",
      data: Qs.stringify(data),
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
};
