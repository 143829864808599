import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import signInService from '../../../Service/Operation/signInService';
import nobLiveGiftService from '../../../Service/GradeMgr/nobLiveGiftService';
import giftPackService from '../../../Service/Operation/giftPackService';


import AddSignIn from './AddSignIn';
import UpdateSignIn from './UpdateSignIn';
import AddGiftPack from './AddGiftPack';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class BasicActivities extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
   
    // ———————————————————— 签到奖励 ————————————————————————————
    
    signInColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'dayNumber',
      title: '签到天数',
      dataIndex: 'dayNumber'
    },{
      key: 'type',
      title: '类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "金币"
        } else if(type === 2) {
          return "礼物";
        } 
      }
    },{
      key: 'giftId',
      title: '礼物id',
      dataIndex: 'giftId'
    },{
      key: 'image',
      title: '图片',
      dataIndex: 'image',
      render: (image, row) => {
        if(image != null && image != "") {
          return <Image
          width={55}
          height={55}
          src={image}
        />
        }
      }
    },{
      key: 'name',
      title: '礼物名称',
      dataIndex: 'name'
    },{
      key: 'typeVal',
      title: '金币/礼物个数',
      dataIndex: 'typeVal'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.getUpdateSignInRowData(row)}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteSignIn(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    signInTotal: 0,
    signInList: [],
    addSignInRow: null,
    updateSignInRow: null,
    showUpdateSignInDialog: false,
    showAddSignInDialog:false,
    signInPageParam:{
      pageNum: 1,
      pageSize: 10,
    },


    // ———————————————————— 新手大礼包 ————————————————————————————
    giftPackColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'action',
      title: '奖励项目',
      dataIndex: 'action',
      render: (action, row) => {
        if(action === 'coin') {
          return "金币"
        } else if(action === 'gift') {
          return "礼物";
        } 
      }
    },{
      key: 'name',
      title: '奖励内容',
      dataIndex: 'name'
    },{
      key: 'gifticon',
      title: '图片',
      dataIndex: 'gifticon',
      render: (gifticon, row) => {
        if(gifticon != null && gifticon != "") {
          return <Image
          width={55}
          height={55}
          src={gifticon}
        />
        }
      }
    },{
      key: 'typeVal',
      title: '类型值(个/天数)',
      dataIndex: 'typeVal'
    },{
      key: 'packType',
      title: '礼包类型',
      dataIndex: 'packType',
      render: (packType, row) => {
        if(packType === 1) {
          return "新手大礼包"
        } else if(packType === 2) {
          return "首冲奖励";
        } 
      }
    },{
      key: 'addtime',
      title: '发布时间',
      dataIndex: 'addtime'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteGiftPack(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    giftPackTotal: 0,
    giftPackList: [],
    addGiftPackRow: null,
    showAddGiftPackDialog:false,
    giftPackPageParam:{
      pageNum: 1,
      pageSize: 10,
    },

    // ———————————————————— 登录奖励 ————————————————————————————

    

  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 签到奖励 ————————————————————————————
    
    this.getSignIn();
  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }


  
  // ———————————————————— 签到奖励 ————————————————————————————
    
     // 关闭更新
  hideUpdateSignInDialog=() => {
    this.setState({showUpdateSignInDialog: false});
  }

  // 关闭添加
  hideAddSignInDialog=() => {
    this.setState({showAddSignInDialog: false});
  }


  // 获取列表
  handleGetSignInPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var signInParam={
        pageParam: pageParam
      }
  
      signInService.getSignInPage(signInParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({signInList: res.retValue.list, signInTotal: res.retValue.total});
          }                  
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  signInChangePage=(page, pageSize) => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    this.setState({
      signInPageParam: pageParam,
    });

    var signInParam={
      pageParam: pageParam
    }

    signInService.getSignInPage(signInParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({signInList: res.retValue.list, signInTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 刷新
   */
  refreshSignInData=() => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var signInParam={
        pageParam: this.state.signInPageParam
      }
  
      signInService.getSignInPage(signInParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({signInList: res.retValue.list, signInTotal: res.retValue.total});
          }                  
        }
      )
  }

  // 根据Id删除
  deleteSignIn=(id) => {
    let { history } = this.props;
    let signInParam = {
      id : id
    };
    signInService.deleteSignIn(signInParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("查询失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshSignInData();
        message.success('删除成功！');
      }                  
      
    })

  }
    

   /**
   * 获取添加签到奖励
   * @param {*} options 
   */
  getAddSignInRowData = () => {
      let { history } = this.props;
      // 获取分配比例列表
      nobLiveGiftService.getGiftList()
      .then((res) => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            if(res.retValue == null) {
              return ;
            }
            
            this.state.addSignInRow = {
              giftList: res.retValue
            }

            this.setState({showAddSignInDialog:true })
          }                  
          
      })
     
  };

   /**
   * 获取更新签到奖励
   * @param {*} options 
   */
  getUpdateSignInRowData = (row) => {
      let { history } = this.props;
      // 获取分配比例列表
      nobLiveGiftService.getGiftList()
      .then((res) => {
        if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            if(res.retValue == null) {
              return ;
            }
            
            this.state.updateSignInRow = {
              id: row.id,
              dayNumber: row.dayNumber,
              type: row.type,
              giftId: row.giftId,
              name: row.name,
              image: row.image,
              typeVal: row.typeVal,
              giftList: res.retValue
            }

            //console.log("updateSignInRow=" + JSON.stringify(this.state.updateSignInRow))
            this.setState({showUpdateSignInDialog:true })
          }                  
          
      })
     
  };
      

  // ———————————————————— 新手大礼包 ————————————————————————————
 
    // 关闭更新
    hideUpdateGiftPackDialog=() => {
      this.setState({showUpdateGiftPackDialog: false});
    }
  
    // 关闭添加
    hideAddGiftPackDialog=() => {
      this.setState({showAddGiftPackDialog: false});
    }
  
  
    // 获取列表
    handleGetGiftPackPage=() =>{
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
        //console.log(JSON.stringify(values));
        var pageParam = { 
          pageNum: 1,
          pageSize: 10
        }
  
        var giftPackParam={
          packType: 1,
          pageParam: pageParam
        }
    
        giftPackService.getGiftPackPage(giftPackParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({giftPackList: res.retValue.list, giftPackTotal: res.retValue.total});
            }                  
          }
        )
        
      })

    }
  
  
    /**
     * 分页查询
     * @param {*} page 
     * @param {*} pageSize 
     */
    giftPackChangePage=(page, pageSize) => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      this.setState({
        giftPackPageParam: pageParam,
      });
  
      var giftPackParam={
        packType: 1,
        pageParam: pageParam
      }
  
      giftPackService.getGiftPackPage(giftPackParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({giftPackList: res.retValue.list, giftPackTotal: res.retValue.total});
          }                  
        }
      )
    }
  
    /**
     * 刷新
     */
    refreshGiftPackData=() => {
        let { history } = this.props;
  
        var giftPackParam={
          packType: 1,
          pageParam: this.state.giftPackPageParam
        }
    
        giftPackService.getGiftPackPage(giftPackParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({giftPackList: res.retValue.list, giftPackTotal: res.retValue.total});
            }                  
          }
        )
    }
  
    // 根据Id删除
    deleteGiftPack=(id) => {
      let { history } = this.props;
      let giftPackParam = {
        id : id
      };
      giftPackService.deleteGiftPack(giftPackParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshGiftPackData();
          message.success('删除成功！');
        }                  
        
      })

    }
      
  
     /**
     * 获取添加签到奖励
     * @param {*} options 
     */
    getAddGiftPackRowData = () => {
        let { history } = this.props;
        // 获取分配比例列表
        nobLiveGiftService.getGiftList()
        .then((res) => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            if(res.retValue == null) {
              return ;
            }
            
            this.state.addGiftPackRow = {
              giftList: res.retValue
            }

            //console.log("addGiftPackRow=" + JSON.stringify(this.state.addGiftPackRow))
            this.setState({showAddGiftPackDialog:true })
          }                  
            
        })
       
    };


    onTabChanged = (key) => {
      //console.log("onTabChanged key="+key);
      if(key == "1") {
        this.getSignIn();
      } else if(key == "2") {
        this.getGiftPack();
      }
    }

    /**
     * 签到奖励
     */
    getSignIn = () => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
  
      var signInParam={
        pageParam: pageParam
      }
  
      signInService.getSignInPage(signInParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({signInList: res.retValue.list, signInTotal: res.retValue.total});
          }                  
        }
      )
    }

    /**
     * 新手大礼包
     */
    getGiftPack = () => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
      var giftPackParam={
        packType: 1,
        pageParam: pageParam
      }
  
      giftPackService.getGiftPackPage(giftPackParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({giftPackList: res.retValue.list, giftPackTotal: res.retValue.total});
          }                  
        })
      
    }
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/basic_activities">基础活动</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 签到奖励 ————————————————————————————
            <TabPane tab="签到奖励" key="1">
              <Form
                name="SignInForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.getAddSignInRowData()}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.signInList}
                columns={this.state.signInColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.signInTotal, defaultCurrent: 1, onChange: this.signInChangePage}}
              >
              </Table>
              <AddSignIn rowData={this.state.addSignInRow} close={this.hideAddSignInDialog} visible={this.state.showAddSignInDialog} refreshData={this.refreshSignInData}></AddSignIn>
              <UpdateSignIn rowData={this.state.updateSignInRow} close={this.hideUpdateSignInDialog} visible={this.state.showUpdateSignInDialog} refreshData={this.refreshSignInData}></UpdateSignIn>
              
            
            </TabPane>
            

            // ———————————————————— 新手大礼包 ————————————————————————————
            <TabPane tab="新手大礼包" key="2">
              <Form
                name="GiftPackForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.getAddGiftPackRowData()}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.giftPackList}
                columns={this.state.giftPackColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.giftPackTotal, defaultCurrent: 1, onChange: this.giftPackChangePage}}
              >
              </Table>
              <AddGiftPack rowData={this.state.addGiftPackRow} close={this.hideAddGiftPackDialog} visible={this.state.showAddGiftPackDialog} refreshData={this.refreshGiftPackData}></AddGiftPack>
              
            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default BasicActivities;