import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import liveChannelService from '../../../Service/1vs1Mgr/liveChannelService';
import { Logout } from '../../../Common/Auth';


const { Option } = Select;
const { TextArea } = Input;

class AddLiveChannel extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;

      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appLiveChannel = values;
          liveChannelService.insertOrUpdateLiveChannel(appLiveChannel)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 刷新数据
                this.props.refreshData();
                // 重置关闭对话框
                this.hanldeCloseModal();
              }
          })
      })
 
  }

  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="频道名称" labelCol={{span:10}} name="title" style={{ width: 420 }}>
                <Input placeholder="频道名称" />
            </Form.Item>

            <Form.Item label="排序" labelCol={{span:10}} name="sort" style={{ width: 420 }}>
                <Input placeholder="排序" />
            </Form.Item>

            <Form.Item label="启用状态" labelCol={{span:10}} name="isTip" style={{ width: 420 }} initialValue={1}>
                <Select style={{ width: 220 }} defaultValue={1}>
                    <Option value={0}>不启用</Option>
                    <Option value={1}>启用</Option>
                </Select>
            </Form.Item>

            <Form.Item label="类型" labelCol={{span:10}} name="type" style={{ width: 420 }} initialValue={3}>
                <Select style={{ width: 220 }} defaultValue={3}>
                    <Option value={3}>一对一</Option>
                </Select>
            </Form.Item>

            <Form.Item label="数量累计" labelCol={{span:10}} name="num" style={{ width: 420 }}>
                <Input placeholder="数量累计" />
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default AddLiveChannel