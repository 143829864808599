import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import nobLiveGiftService from '../../../Service/GradeMgr/nobLiveGiftService';


const { Option } = Select;
const { TextArea } = Input;

class UpdateGiftList extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var nobLiveGift = values;
          nobLiveGiftService.insertOrUpdateGift(nobLiveGift)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

   // 上传图片
   doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
    

  };

  render () {
    return (
      <Modal
        title="修改"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >
            <Form.Item label="id" name="id" hidden>
                <Input placeholder="id" />
            </Form.Item>

            <Form.Item label="礼物类型" labelCol={{span:10}} name="type" style={{ width: 420 }} initialValue={0}>
                <Select style={{ width: 220 }} defaultValue={0}>
                    <Option value={0}>普通礼物</Option>
                    <Option value={1}>粉丝团礼物</Option>
                    <Option value={2}>守护礼物</Option>
                    <Option value={3}>贵族礼物</Option>
                    <Option value={5}>幸运礼物</Option>
                </Select>
            </Form.Item>

            <Form.Item label="礼物标识" labelCol={{span:10}} name="mark" style={{ width: 420 }} initialValue={0}>
                <Select style={{ width: 220 }} defaultValue={0}>
                    <Option value={0}>普通</Option>
                    <Option value={1}>热门</Option>
                </Select>
            </Form.Item>

            <Form.Item label="礼物名称" labelCol={{span:10}} name="giftname" style={{ width: 420 }}>
                <Input placeholder="礼物名称" />
            </Form.Item>

            <Form.Item label="排序" labelCol={{span:10}} name="orderno" style={{ width: 420 }}>
                <Input placeholder="排序" />
            </Form.Item>

            <Form.Item label="价格（金币）" labelCol={{span:10}} name="needcoin" style={{ width: 420 }}>
                <Input placeholder="价格（金币）" />
            </Form.Item>

            <Form.Item label="图片(1:1)" name="gifticon" 
                valuePropName="file"
                labelCol={{span:10}} 
                style={{ width: 420 }}
                getValueFromEvent={this.handleChangeFileImage}

                >
                <Upload
                    accept="image/*"
                    name="fiavatarle"
                    listType="picture-card" 
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    beforeUpload={this.beforeUpload}
                    defaultFileList={[
                      {
                          url: this.props.rowData != null ? this.props.rowData.gifticon : null,
                          thumbUrl: this.props.rowData != null ? this.props.rowData.gifticon : null,
                      }
                    ]}
                >
                     {this.uploadButton}
                </Upload>
            </Form.Item>

            <Form.Item label="动图" name="swf" 
                valuePropName="file"
                labelCol={{span:10}} 
                style={{ width: 420 }}
                getValueFromEvent={this.handleChangeFileImage}
                >
                <Upload
                    accept="*/*"
                    name="fiavatarle"
                    onChange={this.handleChange}
                    customRequest={this.doImgUpload}
                    defaultFileList={[
                      {
                        url: this.props.rowData != null ? this.props.rowData.swf : null,
                        thumbUrl: this.props.rowData != null ? this.props.rowData.swf : null,
                        name: this.props.rowData != null ? this.props.rowData.swf : null,
                      }
                    ]}
                >
                     <Button icon={<UploadOutlined />}>Click to Upload</Button>
                </Upload>
            </Form.Item>

            <Form.Item label="动画时长（秒）" labelCol={{span:10}} name="swftime" style={{ width: 420 }}>
                <Input placeholder="动画时长（秒）" />
            </Form.Item>

            <Form.Item label="是否开启" labelCol={{span:10}} name="status" style={{ width: 420 }} initialValue={0}>
                <Select style={{ width: 220 }} defaultValue={0}>
                    <Option value={0}>显示</Option>
                    <Option value={1}>不显示</Option>
                </Select>
            </Form.Item>
 
            <Form.Item label="幸运礼物中奖概率" labelCol={{span:10}} name="luckyPerc" style={{ width: 420 }} initialValue={0}>
                <Input placeholder="幸运礼物中奖概率" />
            </Form.Item>

            <Form.Item label="幸运礼物中奖倍率" labelCol={{span:10}} name="luckOdds" style={{ width: 420 }} initialValue={0}>
                <Input placeholder="幸运礼物中奖倍率" />
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default UpdateGiftList