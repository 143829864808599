import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {
  /**
   * 接口处理
   * @param {*} data
   * @returns
   */
   getDealScalePlanList() {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/dealScalePlan/getDealScalePlanList",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


  /**
   * 分页查询主播分成方案
   * @param {*} dealScalePlanParam
   * @returns
   */
    getDealScalePlanPage(dealScalePlanParam) {
      //console.log("dealScalePlanParam=" + JSON.stringify(dealScalePlanParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/dealScalePlan/getDealScalePlanPage",
        data: dealScalePlanParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


    /**
     * 更新分成方案
     * @param {*} dealScalePlanParam
     * @returns
     */
    updateDealScalePlan(dealScalePlanParam) {
      //console.log("updateDealScalePlan dealScalePlanParam=" + JSON.stringify(dealScalePlanParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/dealScalePlan/updateDealScalePlan",
        data: dealScalePlanParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

    /**
     * 创建分成方案
     * @param {*} dealScalePlanParam 
     * @returns 
     */
    createDealScalePlan(dealScalePlanParam) {
      //console.log("updateDealScalePlan dealScalePlanParam=" + JSON.stringify(dealScalePlanParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/dealScalePlan/createDealScalePlan",
        data: dealScalePlanParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
}