import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 插入更新导航
   * @param {*} appLiveChannel
   * @returns
   */
   insertOrUpdateLiveChannel(appLiveChannel) {
      //console.log("appLiveChannel=" + JSON.stringify(appLiveChannel));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/liveChannel/insertOrUpdateLiveChannel",
        data: appLiveChannel,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 分页获取导航
   * @param {*} 
   * @returns
   */
   getLiveChannelPage(liveChannelParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/liveChannel/getLiveChannelPage",
      data: liveChannelParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


}