import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 插入或更新求聊话费设置
   * @param {*} adminRushToTalkConfig
   * @returns
   */
   insertOrUpdateConfig(adminRushToTalkConfig) {
      //console.log("adminRushToTalkConfig=" + JSON.stringify(adminRushToTalkConfig));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminRushToTalkConfig/insertOrUpdateConfig",
        data: adminRushToTalkConfig,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取求聊话费设置
   * @param {*} 
   * @returns
   */
   getConfigPage(adminRushToTalkConfigParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/adminRushToTalkConfig/getConfigPage",
      data: adminRushToTalkConfigParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除求聊话费设置
   * @param {*} 
   * @returns
   */
   deleteConfig(adminRushToTalkConfigParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/adminRushToTalkConfig/deleteConfig",
      data: adminRushToTalkConfigParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}