import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取用户反馈
   * @param {*} feedBackParam
   * @returns
   */
   getFeedBackPage(feedBackParam) {
      //console.log("feedBackParam=" + JSON.stringify(feedBackParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/feedBack/getFeedBackPage",
        data: feedBackParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新用户反馈
   * @param {*} adsParam
   * @returns
   */
   insertOrUpdateFeedBack(appFeedBack) {
        //console.log("appFeedBack=" + JSON.stringify(appFeedBack));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/feedBack/insertOrUpdateFeedBack",
        data: appFeedBack,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

    /**
     * 删除用户反馈
     * @param {*} feedBackParam 
     * @returns 
     */
    deleteFeedBack(feedBackParam) {
        //console.log("feedBackParam=" + JSON.stringify(feedBackParam));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/feedBack/deleteFeedBack",
        data: feedBackParam,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}