import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 更新全局弹窗设置
   * @param {*} appSysBoxConfig
   * @returns
   */
   updateSysBoxConfig(appSysBoxConfig) {
      //console.log("dynamicAppeal=" + JSON.stringify(appSysBoxConfig));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/sysBoxConfig/updateSysBoxConfig",
        data: appSysBoxConfig,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 分页获取全局弹窗设置
   * @param {*} 
   * @returns
   */
   getSysBoxConfigPage(sysBoxConfigParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/sysBoxConfig/getSysBoxConfigPage",
      data: sysBoxConfigParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}