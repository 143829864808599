import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取等级
   * @param {*} gradeParam
   * @returns
   */
   getGradePage(gradeParam) {
      //console.log("gradeParam=" + JSON.stringify(gradeParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradeAdmin/getGradePage",
        data: gradeParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 更新举报信息
   * @param {*} userAppeal
   * @returns
   */
   insertOrUpdateGrade(appGrade) {
        //console.log("appGrade=" + JSON.stringify(appGrade));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradeAdmin/insertOrUpdateGrade",
        data: appGrade,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


    /**
     * 获取等级列表
     * @param {*} gradeParam
     * @returns
     */
    getGradeList(gradeParam) {
      //console.log("gradeParam=" + JSON.stringify(gradeParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        method: "post",
        url: "/admin/gradeAdmin/getGradeList",
        data: gradeParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

}