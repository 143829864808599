import React from 'react';

// 获取七牛域名
const DOMAIN = process.env.REACT_APP_QINIU_PATH
// 获取后端域名
const ADMIN_PATH = process.env.REACT_APP_API_PATH

/**
 * 获取七牛域名
 */
export function GetDomain() {
    return DOMAIN;
}

/**
 * 获取后端域名
 */
export function GetAdminPath() {
    return ADMIN_PATH;
}