import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import adminLogService from '../../../Service/SystemSetting/adminLogService';
import sysLogService from '../../../Service/SystemSetting/sysLogService';


import ShowLogView from './ShowLogView'



const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class RevenueExpenseDetails extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
    // ———————————————————— 管理员日志 ————————————————————————————
    adminLogColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'adminid',
      title: '管理员ID',
      dataIndex: 'adminid'
    },{
      key: 'adminName',
      title: '管理员',
      dataIndex: 'adminName'
    },{
      key: 'action',
      title: '操作',
      dataIndex: 'action'
    },{
      key: 'ip',
      title: 'IP',
      dataIndex: 'ip'
    },{
      key: 'addtime',
      title: '时间',
      dataIndex: 'addtime'
    }],
    adminLogTotal: 0,
    adminLogList: [],


    // ———————————————————— 系统日志 ————————————————————————————
    
    sysLogColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'createTime',
      title: '时间',
      dataIndex: 'createTime'
    },{
      key: 'module',
      title: '模块名称',
      dataIndex: 'module'
    },{
      key: 'method',
      title: '执行方法',
      dataIndex: 'method'
    },{
      key: 'level',
      title: '错误等级',
      dataIndex: 'level'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
               <Button 
                onClick={() => this.setState({showShowLogDialog:true, showLogRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">查看
              </Button>
            </div>
          )        
      }
    }],
    sysLogTotal: 0,
    sysLogList: [],
    showLogRow: null,
    showShowLogDialog: false,
    selectRowKeys:[],
    adminLogPageParam:{
      pageNum: 1,
      pageSize: 10,
    },
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 管理员日志 ————————————————————————————
    this.getAdminLog();

  

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 管理员日志 ————————————————————————————

  // 关闭更新
  hideUpdateAdminLogDialog=() => {
    this.setState({showUpdateAdminLogDialog: false});
  }

  // 关闭添加
  hideAddAdminLogDialog=() => {
    this.setState({showAddAdminLogDialog: false});
  }


  // 获取列表
  handleGetAdminLogPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var adminLogParam={
        action: values.action,
        pageParam: pageParam
      }

      adminLogService.getAdminLogPage(adminLogParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({adminLogList: res.retValue.list, adminLogTotal: res.retValue.total});
          }                  
        }
      )
      
    })
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  adminLogChangePage=(page, pageSize)=>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      this.setState({
        adminLogPageParam: pageParam,
      });

      var adminLogParam={
        action: values.action,
        pageParam: pageParam
      }

      adminLogService.getAdminLogPage(adminLogParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({adminLogList: res.retValue.list, adminLogTotal: res.retValue.total});
          }                  
        }
      )

    })
    
  }

  /**
   * 刷新
   */
  refreshAdminLogData=() => {
      let { history } = this.props;

      var adminLogParam={
        pageParam: this.state.adminLogPageParam
      }

      adminLogService.getAdminLogPage(adminLogParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({adminLogList: res.retValue.list, adminLogTotal: res.retValue.total});
          }                  
        }
      )
  }


  // ———————————————————— 系统日志 ————————————————————————————
  // 关闭更新
  hideUpdateSysLogDialog=() => {
    this.setState({showUpdateSysLogDialog: false});
  }

  // 关闭添加
  hideAddSysLogDialog=() => {
    this.setState({showAddSysLogDialog: false});
  }

  hideShowLogDialog=() => {
    this.setState({showShowLogDialog: false});
  }


  // 获取列表
  handleGetSysLogPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var sysLogParam={
        module: values.module,
        method: values.method,
        level: values.level,
        pageParam: pageParam
      }
  
      sysLogService.getSysLogPage(sysLogParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({sysLogList: res.retValue.list, sysLogTotal: res.retValue.total});
          }                  
        }
      )
      
    })
  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  sysLogChangePage=(page, pageSize)=>{
    let { history } = this.props;
    this.formRef2.current.validateFields()
    .then(values => {
      var pageParam = { 
        pageNum: page,
        pageSize: pageSize
      }

      var sysLogParam={
        module: values.module,
        method: values.method,
        level: values.level,
        pageParam: pageParam
      }

      sysLogService.getSysLogPage(sysLogParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({sysLogList: res.retValue.list, sysLogTotal: res.retValue.total});
          }                  
        }
      )
    })
  }

  /**
   * 刷新
   */
  refreshSysLogData=() => {
      let { history } = this.props;
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var sysLogParam={
        pageParam: pageParam
      }

      sysLogService.getSysLogPage(sysLogParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({sysLogList: res.retValue.list, sysLogTotal: res.retValue.total});
          }                  
        }
      )
  }

  /**
   * 行选择操作
   */
  sysLogRowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //console.log(selectedRowKeys, selectedRows);
      this.setState({selectRowKeys: selectedRowKeys});
    }
  }

  // 删除数据
  handleSysLogDelete=()=> {
      // 拿到所有要删除的数据
      //console.log(JSON.stringify(this.state.selectRowKeys));
      // 确认是否需要删除
      let { history } = this.props;
      if(this.state.selectRowKeys.length <= 0 ) {
        message.warn('请选择要删除的数据！');
        return ;
      }
      Modal.confirm({
        title: '您确认要删除吗？',
        okText: '删除',
        cancelText: '取消',
        onOk: () => {
          // 调用后端删除
          let idList = this.state.selectRowKeys;

          sysLogService.deleteByIdList(idList)
          .then(res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("删除失败,"+res.message);  
            } else {
              this.state.selectRowKeys =[]; 
              // 刷新数据
              this.refreshSysLogData();
              message.success('删除成功！');
            }                  
            
          })
        }
      });

  }

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getAdminLog();
    } else if(key == "2") {
      this.getSysLog();
    }
  }

  /**
   * 管理员日志
   */
  getAdminLog = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var adminLogParam={
      pageParam: pageParam
    }

    adminLogService.getAdminLogPage(adminLogParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({adminLogList: res.retValue.list, adminLogTotal: res.retValue.total});
        }                  
      }
    )
  }

  /**
   * 系统日志 
   */
  getSysLog = () => {
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var sysLogParam={
      pageParam: pageParam
    }

    sysLogService.getSysLogPage(sysLogParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({sysLogList: res.retValue.list, sysLogTotal: res.retValue.total});
        }                  
      }
    )
  }

  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/log_view">日志查看</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 管理员日志 ————————————————————————————
            <TabPane tab="管理员日志" key="1">
              <Form
                name="AdminLogForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="操作" name="action">
                          <Input placeholder="操作" />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={5} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetAdminLogPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.adminLogList}
                columns={this.state.adminLogColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.adminLogTotal, defaultCurrent: 1, onChange: this.adminLogChangePage}}
              >
              </Table>
              
            </TabPane>
            
            // ———————————————————— 系统日志 ——————————————————————
            <TabPane tab="系统日志" key="2">
              <Form
                name="SysLogForm"
                className="ant-advanced-search-form"
                ref={this.formRef2}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="模块名称" name="module">
                          <Input placeholder="模块名称" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="执行方法" name="method">
                          <Input placeholder="执行方法" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={3}>
                      <Form.Item label="错误等级" name="level">
                          <Input placeholder="错误等级" />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={5} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetSysLogPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button onClick={ this.handleSysLogDelete } style={this.buttonStyle} type="danger">删除</Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.sysLogList}
                columns={this.state.sysLogColumns}
                rowSelection={this.sysLogRowSelection}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.sysLogTotal, defaultCurrent: 1, onChange: this.sysLogChangePage}}
              >
              </Table>
              <ShowLogView rowData={this.state.showLogRow} close={this.hideShowLogDialog} visible={this.state.showShowLogDialog} ></ShowLogView>

            </TabPane>

          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default RevenueExpenseDetails;