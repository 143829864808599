import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 插入更新分类
   * @param {*} appHotSort
   * @returns
   */
  insertOrUpdateHotSort(appHotSort) {
      //console.log("appShortVideo=" + JSON.stringify(appShortVideo));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/hotSortAdmin/insertOrUpdateHotSort",
        data: appHotSort,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取分类分页
   * @param {*} 
   * @returns
   */
  getHotSortPage(appHotSort) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/hotSortAdmin/getHotSortPage",
      data: appHotSort,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除分类
   * @param {*} 
   * @returns
   */
  deleteHotSort(appHotSort) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/hotSortAdmin/deleteHotSort",
      data: appHotSort,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 获取分类列表
   * @param {*} 
   * @returns
   */
  getHotSortList(appHotSort) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/hotSortAdmin/getHotSortList",
      data: appHotSort,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}