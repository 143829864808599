import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 更新短信配置
   * @param {*} adminLoginConfig
   * @returns
   */
   updateConfig(adminLoginConfig) {
      //console.log("adminLoginConfig=" + JSON.stringify(adminLoginConfig));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/adminLoginConfig/updateConfig",
        data: adminLoginConfig,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取短信配置
   * @param {*} 
   * @returns
   */
   getConfig() {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/adminLoginConfig/getConfig",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

}