import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取消息记录
   * @param {*} 
   * @returns
   */
   getUserChatRecordPage(userChatRecordParam) {
    //console.log("userLastChatParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/userChatRecord/getUserChatRecordPage",
      data: userChatRecordParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除消息记录
   * @param {*} 
   * @returns
   */
   deleteUserChatRecord(userChatRecordParam) {
    //console.log("userLastChatParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/userChatRecord/deleteUserChatRecord",
      data: userChatRecordParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}