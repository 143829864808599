import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,InputNumber } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import vipService from "../../../Service/NobleMgr/vipService";
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class AddNoblePrice extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appVip = {
            orderno: values.orderno,
            name: values.name,
            coin: values.coin,
            androidCoin: values.androidCoin,
            iosCoin: values.iosCoin,
            length: values.length,
            dayLength: values.length * 30,
            sendCoin: values.sendCoin,
            payType: values.payType,
            grade: values.grade,
            isTip: values.isTip,
            iosId: values.iosId,
            svipPackageId: values.svipPackageId,
          }
          vipService.insertOrUpdateVip(appVip)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("修改失败")
                message.info("修改失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('修改成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })
  
  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
    

  };


  render () {
    return (
      <Modal
        title="修改"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="排序" labelCol={{span:10}} name="orderno" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写排序!',
                }
                ]
              }>
                <Input placeholder="排序" />
            </Form.Item>

            <Form.Item label="名称" labelCol={{span:10}} name="name" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写名称!',
                }
                ]
              }>
                <Input placeholder="特权名称" />
            </Form.Item>

            <Form.Item label="金币价格" labelCol={{span:10}} name="coin" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写金币价格!',
                }
                ]
              }>
                <InputNumber placeholder="金币价格" />
            </Form.Item>

            <Form.Item label="安卓价格（￥）" labelCol={{span:10}} name="androidCoin" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写安卓价格!',
                }
                ]
              }>
                <InputNumber placeholder="安卓价格" />
            </Form.Item>

            <Form.Item label="ios价格（￥）" labelCol={{span:10}} name="iosCoin" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写ios价格!',
                }
                ]
              }>
                <InputNumber placeholder="ios价格" />
            </Form.Item>

            <Form.Item label="时长（月）" labelCol={{span:10}} name="length" style={{ width: 420 }} rules={ [
                {
                  required: true,
                  message: '请填写时长（月）!',
                }
                ]
              }>
                <InputNumber placeholder="时长（月）" />
            </Form.Item>

            <Form.Item label="赠送金币" labelCol={{span:10}} name="sendCoin" style={{ width: 420 }} >
                <Input placeholder="赠送金币" />
            </Form.Item>

            <Form.Item label="支付方式" labelCol={{span:10}} name="payType" style={{ width: 420 }} initialValue={3}>
                <Select style={{ width: 220 }} defaultValue={3}>
                    <Option value={1}>金币</Option>
                    <Option value={2}>RMB</Option>
                    <Option value={3}>人民币/金币</Option>
                </Select>
            </Form.Item>

            <Form.Item label="贵族等级" name="grade" labelCol={{span:10}} style={{ width: 420 }}
              initialValue={this.props.rowData != null ? this.props.rowData.nobleGradeList[0].grade : null} rules={ [
                {
                  required: true,
                  message: '请选择贵族等级!',
                }
                ]
              }>
              <Select style={{ width: 220 }} defaultValue={this.props.rowData != null ? this.props.rowData.nobleGradeList[0].grade : null}>
                {this.props.rowData == null ? null : this.props.rowData.nobleGradeList.map((item)=>
                  <Option value={item.grade}>{item.name}</Option>
                )}
              </Select>
            </Form.Item>
          
            <Form.Item label="是否启用" labelCol={{span:10}} name="isTip" style={{ width: 420 }} initialValue={0}>
                <Select style={{ width: 220 }} defaultValue={0}>
                    <Option value={0}>未启用</Option>
                    <Option value={1}>启用</Option>
                </Select>
            </Form.Item>

            <Form.Item label="苹果项目ID" labelCol={{span:10}} name="iosId" style={{ width: 420 }} >
                <Input placeholder="苹果项目ID" />
            </Form.Item>

            <Form.Item label="SVIP包ID" labelCol={{span:10}} name="svipPackageId" style={{ width: 420 }} >
                <Input placeholder="SVIP包ID" />
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddNoblePrice