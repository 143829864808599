import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 获取统计数据
   * @param {*} finAdminTranRecord
   * @returns
   */
  getListByAgent(finAdminTranRecord) {
      //console.log("agentDataStatisticsParam=" + JSON.stringify(agentDataStatisticsParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/finAdminTranRecord/getListByAgent",
        data: finAdminTranRecord,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


}