import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import gradeService from '../../../Service/GradeMgr/gradeService';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class ShowData extends Component {
  formRef = React.createRef();

  state={
    loading: false,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  
  render () {
    return (
      <Modal
        title="信息"
        destroyOnClose
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        width={1000}
        footer={[
          null, null,
        ]}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="处理内容" labelCol={{span:5}} name="content" style={{ width: 840 }}>
                <TextArea bordered={false} style={{ height: 300 }} readOnly/>
            </Form.Item>

           
        </Form>
      </Modal>
    )
  }
}

export default ShowData