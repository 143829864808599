import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 更新动态
   * @param {*} appUserVideo
   * @returns
   */
   updateUserVideo(appUserVideo) {
      console.log("appUserVideo=" + JSON.stringify(appUserVideo));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/usersVideo/updateUserVideo",
        data: appUserVideo,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },

  /**
   * 获取动态列表
   * @param {*} 
   * @returns
   */
   getUserVideoPage(usersVideoParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/usersVideo/getUserVideoPage",
      data: usersVideoParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 删除动态
   * @param {*} 
   * @returns
   */
   deleteUserVideo(usersVideoParam) {
    //console.log("liveRoomRecordParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/usersVideo/deleteUserVideo",
      data: usersVideoParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
}