import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取等级礼包
   * @param {*} gradeRewarParam
   * @returns
   */
   getGradeRewarPage(gradeRewarParam) {
      //console.log("gradeRewarParam=" + JSON.stringify(gradeRewarParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradeRewar/getGradeRewarPage",
        data: gradeRewarParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入或更新等级礼包
   * @param {*} appGradeReWar
   * @returns
   */
   insertOrUpdateGradeRewar(appGradeReWar) {
        //console.log("appMedal=" + JSON.stringify(appGradeReWar));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/gradeRewar/insertOrUpdateGradeRewar",
        data: appGradeReWar,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}