import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import appFlowService from '../../../Service/FlowMgr/appFlowService';
import { Logout } from '../../../Common/Auth';

const { Option } = Select;
const { TextArea } = Input;

class AddFlow extends Component {
  formRef = React.createRef();


  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var appFlow = values;
          appFlowService.insertOrUpdateAppFlow(appFlow)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })
 
  }


  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
        >

            <Form.Item label="用户Id" name="userId" >
                <Input placeholder="用户Id" />
            </Form.Item>

            <Form.Item label="引流话术" name="script" >
                <TextArea style={{ height: 50 }} />
            </Form.Item>
            
            <Form.Item label="消息类型" labelCol={{span:4}} name="type" initialValue={0} >
                <Radio.Group name="type" defaultValue={0}>
                  <Radio value={1}>图片消息</Radio>
                  <Radio value={0}>文本消息</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
      </Modal>
    )
  }
}

export default AddFlow