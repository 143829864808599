import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import appFlowService from '../../../Service/FlowMgr/appFlowService';

import UpdateFlow from './UpdateFlow';
import AddFlow from './AddFlow';

const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class FlowList extends Component {
  formRef = React.createRef();
  state={
    // ———————————————————— 动态列表 ————————————————————————————
    flowListColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'userId',
      title: '用户Id',
      dataIndex: 'userId'
    },{
      key: 'script',
      title: '引流话术',
      dataIndex: 'script'
    },{
      key: 'type',
      title: '消息类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "图片消息"
        } else if(type === 0) {
          return "文本消息";
        } 
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateFlowListDialog:true, updateFlowListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">编辑 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteFlow(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    flowListTotal: 0,
    flowList: null,
    updateFlowListRow: null,
    showUpdateFlowListDialog: false,
    showAddFlowListDialog: false,
    flowListPageParam:{
      userId : null,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 获取等级礼物 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var flowListPageParam = {
      userId : null,
      pageParam : pageParam,
    }

    this.setState({
      flowListPageParam: flowListPageParam,
    });

    appFlowService.getAppFlowPage(flowListPageParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({flowList: res.retValue.list, flowListTotal: res.retValue.total});
          }
        }
      )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 动态列表 ————————————————————————————

  

  // 获取动态列表
  handleGetFlowListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
     // console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var flowListPageParam = {
        userId : values.userId,
        pageParam : pageParam,
      }

      this.setState({
        flowListPageParam: flowListPageParam,
      });

      appFlowService.getAppFlowPage(flowListPageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({flowList: res.retValue.list, flowListTotal: res.retValue.total});
            }
          }
        )
      
    })

  }

  // 分页
  flowListChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var flowListPageParam = {
          userId : values.userId,
          pageParam : pageParam,
        }

        this.setState({
          flowListPageParam: flowListPageParam,
        });

        appFlowService.getAppFlowPage(flowListPageParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({flowList: res.retValue.list, flowListTotal: res.retValue.total});
            }
          }
        )
    })
  }

  // 刷新数据
  refreshFlowListData=() => {
    let { history } = this.props;
    //console.log("refreshFlowListData");

    var appFlow = this.state.flowListPageParam;

    appFlowService.getAppFlowPage(appFlow)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({flowList: res.retValue.list, flowListTotal: res.retValue.total});
        }
      }
    )
  }
 


  
  // 根据Id删除
  deleteFlow=(id) => {
      let { history } = this.props;
      let appFlow = {
        id : id
      };
      appFlowService.deleteAppFlow(appFlow)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("删除失败,"+res.message);  
        } else {
          // 刷新数据
          this.refreshFlowListData();
          message.success('删除成功！');
        }
       
      })

  }

  // 隐藏
  hideUpdateFlowListDialog=() => {
    this.setState({showUpdateFlowListDialog: false});
  }

  // 隐藏
  hideAddFlowListDialog=() => {
    this.setState({showAddFlowListDialog: false});
  }
   
 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/flow_list">引流列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>

              <Form
                name="FlowListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 0px'}}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="用户ID" name="userId">
                          <Input placeholder="用户id" />
                      </Form.Item>
                    </Col>
                    <Col span={3} key={2} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetFlowListPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                    </Col>
                    <Col span={3} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => this.setState({showAddFlowListDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.flowList}
                columns={this.state.flowListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.flowListTotal, defaultCurrent: 1, onChange: this.flowListChangePage}}
              >
              </Table>
              <AddFlow close={this.hideAddFlowListDialog} visible={this.state.showAddFlowListDialog} refreshData={this.refreshFlowListData}></AddFlow>
              <UpdateFlow rowData={this.state.updateFlowListRow} close={this.hideUpdateFlowListDialog} visible={this.state.showUpdateFlowListDialog} refreshData={this.refreshFlowListData}></UpdateFlow>

        </StickyContainer>
        
      </div>
    );
  }
}
export default FlowList;