import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio, InputNumber,Image } from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import giftPackService from '../../../Service/Operation/giftPackService';


const { Option } = Select;
const { TextArea } = Input;

/**
 * 添加签到奖励
 */
class AddGiftPack extends Component {
  formRef = React.createRef();

  state={
    loading: false,
    imageUrl : null,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
    // 重置表单
    this.formRef.current.resetFields();
  }
  
  // 提交操作
  handleSubmit =() => {
      let { history } = this.props;
      this.formRef.current.validateFields()
      .then(values => {
          //console.log(JSON.stringify(values));
          var adminGiftPack = values;
          giftPackService.insertOrUpdateGiftPack(adminGiftPack)
          .then(res => {
              if(res == null || res.retCode != 200) {
                if(res.retCode == 10001) {
                  // 清除登录信息
                  Logout();
                  // 跳转到登录页面
                  history.push('/login');
                  return;
                }
                console.log("添加失败")
                message.info("添加失败,"+res.message);  
                // 重置关闭对话框
                this.hanldeCloseModal();
              } else {
                message.info('添加成功！');
                // 重置关闭对话框
                this.hanldeCloseModal();
                // 刷新数据
                this.props.refreshData();
              }
          })
      })

  }


    // 图片修改
  handleChangeFileImage=(e) => {
      if (e.file.status === 'uploading') {
          this.state.loading=true;
          return;
      }
      if (e.file.status === 'done') {
          //console.log("test="+e.file.response);
          this.state.loading=false;
          return e.file.response;
        }
  }

  // 上传前置校验
  beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
          message.error('Image must smaller than 2MB!');
      }
      return isJpgOrPng && isLt2M;
  }

  // 上传图片
  doImgUpload = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    
    Service.uploadQiniu(file)
    .then((observable) => {
      observable.subscribe({
        error: () => {
            console.log('上传失败');
        },
        complete: (res) => {
          //console.log('上传完成，res='+JSON.stringify(res));
          var url = Service.getDoamin() + res.key;
          //console.log('上传成功：' + url);
          onProgress({percent: 100});
          onSuccess(url, file); 
        },
      });
    })

  };

  /**
   * 上传按钮
   */
  uploadButton = (
    <div>
      {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  handleChange = ({ file, fileList }) => {
    //console.log("file=" + JSON.stringify(file)); // file 是当前正在上传的 单个 img
    //console.log("fileList=" + fileList.length); // fileList 是已上传的全部 img 列表
    

  };

  resetForm = (value) => {
    console.log("value="+JSON.stringify(value));

    this.formRef.current.setFieldsValue({
        type: this.props.rowData != null ? this.props.rowData.giftList[value].id : null,
        gifticon: this.props.rowData != null ? this.props.rowData.giftList[value].gifticon : null,
        name: this.props.rowData != null ? this.props.rowData.giftList[value].giftname : null
    });
    this.state.imageUrl = this.props.rowData != null ? this.props.rowData.giftList[value].gifticon : null;
  }


  render () {
    return (
      <Modal
        title="添加"
        destroyOnClose
        okText="保存"
        cancelText="取消"
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        onOk={this.handleSubmit}
        width={600}
      >
         <Form 
            layout="horizontal"
            labelCol={{span:4}}
            wrapperCol={{span: 20}}
            ref={this.formRef}
            initialValues={this.props.rowData}
            
        >

            <Form.Item label="packType" name="packType" initialValue={1} hidden>
                <Input placeholder="packType" />
            </Form.Item>

            <Form.Item label="类型" labelCol={{span:10}} name="action" style={{ width: 420 }} initialValue="coin">
                <Radio.Group name="action" defaultValue="coin">
                    <Radio value="coin">金币</Radio>
                    <Radio value="gift">礼物</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item dependencies={['action']} noStyle>
                {({ getFieldValue }) => {
                    const action = getFieldValue('action')

                    if (action === 'coin') {
                    return (
                        <>
                        <Form.Item label="金币数量" name="typeVal" labelCol={{span:10}} style={{ width: 420 }}>
                            <InputNumber />
                        </Form.Item>
                        <Form.Item label="礼物名称" name="name" labelCol={{span:10}}  style={{ width: 420 }} initialValue="金币">
                            <Input placeholder="礼物名称" disabled/>
                        </Form.Item>
                        </>
                    )
                    }

                    if (action === 'gift') {
                    return (
                        <>
                        <Form.Item label="礼物" name="giftIndex" labelCol={{span:10}} style={{ width: 420 }}>
                            <Select style={{ width: 220 }} onChange={this.resetForm} >
                                { this.props.rowData != null ? this.props.rowData.giftList.map((item,index)=>
                                    <Option value={index}>{item.giftname}</Option>
                                ) : null}
                            </Select>
                        </Form.Item>
                        <Form.Item label="type" name="type" labelCol={{span:10}} style={{ width: 420 }} hidden>
                            <Input placeholder="type" />
                        </Form.Item>
                        <Form.Item label="图片" name="gifticon" 
                            labelCol={{span:10}} 
                            style={{ width: 420 }}
                            >
                            <Image width={55} height={55} src={this.state.imageUrl}/>
                        </Form.Item>
                        <Form.Item label="礼物名称" name="name" labelCol={{span:10}}  style={{ width: 420 }} >
                            <Input placeholder="礼物名称" disabled/>
                        </Form.Item>

                        <Form.Item label="礼物数量" name="typeVal" labelCol={{span:10}} style={{ width: 420 }} >
                            <InputNumber />
                        </Form.Item>
                
                        </>
                    )
                    }

                    return null
                }}
            </Form.Item>

        </Form>
      </Modal>
    )
  }
}

export default AddGiftPack