import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {
    /**
     * 获取主播星级列表
     * @param {*}  
     * @returns 
     */
     getAnchorStarList() {
        //console.log("tabInfoParam=" + JSON.stringify(tabInfoParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/anchorStar/getAnchorStarList",
            data: null,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
     * 获取主播星级
     * @param {*} tabInfoParam 
     * @returns 
     */
    getAnchorStarPage(pageParam) {
        //console.log("tabInfoParam=" + JSON.stringify(tabInfoParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/anchorStar/getAnchorStarPage",
            data: pageParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
   * 插入更新主播星级
   * @param {*} appAnchorStar
   * @returns
   */
    insertOrUpdateAnchorStar(appAnchorStar) {
        //console.log("insertOrUpdateTab appUser=" + JSON.stringify(appUser));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/anchorStar/insertOrUpdateAnchorStar",
            data: appAnchorStar,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },
}