import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image, InputNumber} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import commonShortcutService from '../../../Service/PrivateLetter/commonShortcutService';
import userShortcutService from '../../../Service/PrivateLetter/userShortcutService';


import AddUserShortcutList from './AddUserShortcutList';
import UpdateUserShortcutList from './UpdateUserShortcutList';
import AddCommonShortcutList from './AddCommonShortcutList';
import UpdateCommonShortcutList from './UpdateCommonShortcutList';




const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class Shortcut extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  state={
    // ———————————————————— 通用快捷语列表 ————————————————————————————
    commonShortcutListColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'content',
      title: '快捷语',
      dataIndex: 'content'
    },{
      key: 'type',
      title: '类型',
      dataIndex: 'type',
      render: (type, row) => {
        if(type === 1) {
          return "男"
        } else if(type === 2) {
          return "女"
        }
      }
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateCommonShortcutListDialog:true, updateCommonShortcutListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteCommonShortcutList(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    commonShortcutListTotal: 0,
    commonShortcutListList: [],
    updateCommonShortcutListRow: null,
    showUpdateCommonShortcutListDialog: false,
    showAddCommonShortcutListDialog:false,
    commonShortcutListPageParam: {
      content: null,
      type : null,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    },
    // ———————————————————— 用户快捷语 ————————————————————————————
    
    userShortcutListColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'userid',
      title: '用户id',
      dataIndex: 'userid'
    },{
      key: 'content',
      title: '快捷语',
      dataIndex: 'content'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Button 
                onClick={() => this.setState({showUpdateUserShortcutListDialog:true, updateUserShortcutListRow: row })}
                style={{marginRight:'5px'}} 
                type="primary">修改 
              </Button>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteUserShortcutList(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )
      }
    }],
    userShortcutListTotal: 0,
    userShortcutListList: [],
    updateuserShortcutListRow: null,
    showUpdateuserShortcutListDialog: false,
    showAdduserShortcutListDialog:false,
    userShortcutListPageParam: {
      content: null,
      type : null,
      pageParam : {
        pageNum: 1,
        pageSize: 10,
      }
    }
  
    
  }


  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;

    // ———————————————————— 通用快捷语列表 ————————————————————————————
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var commonShortcut={
        pageParam: pageParam
      }

      commonShortcutService.getCommonShortcutPage(commonShortcut)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({commonShortcutListList: res.retValue.list, commonShortcutListTotal: res.retValue.total});
          }    
        }
      )


    // ———————————————————— 用户快捷语 ————————————————————————————

    var userShortcut={
      pageParam: pageParam
    }

    userShortcutService.getUserShortcutPage(userShortcut)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userShortcutListList: res.retValue.list, userShortcutListTotal: res.retValue.total});
        }    
      }
    )

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

// ———————————————————— 通用快捷语 ————————————————————————————

  // 关闭更新
  hideUpdateCommonShortcutListDialog=() => {
    this.setState({showUpdateCommonShortcutListDialog: false});
  }

  // 关闭添加
  hideAddCommonShortcutListDialog=() => {
    this.setState({showAddCommonShortcutListDialog: false});
  }


  // 获取列表
  handleGetCommonShortcutListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var commonShortcut={
        content: values.content,
        type: values.type,
        pageParam: pageParam
      }

      this.setState({
        commonShortcutListPageParam: commonShortcut,
      });

      commonShortcutService.getCommonShortcutPage(commonShortcut)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({commonShortcutListList: res.retValue.list, commonShortcutListTotal: res.retValue.total});
          }    
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  commonShortcutListChangePage=(page, pageSize)=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    var commonShortcut={
      content: this.commonShortcutListPageParam.content,
      type: this.commonShortcutListPageParam.type,
      pageParam: pageParam,
    }
    
    this.setState({
      commonShortcutListPageParam: commonShortcut,
    });

    commonShortcutService.getCommonShortcutPage(commonShortcut)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({commonShortcutListList: res.retValue.list, commonShortcutListTotal: res.retValue.total});
        }    
      }
    )
  }

  /**
   * 刷新
   */
  refreshCommonShortcutListData=() => {
      let { history } = this.props;

      var commonShortcutParam={
        pageParam: this.state.commonShortcutListPageParam
      }
  
      commonShortcutService.getCommonShortcutPage(commonShortcutParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({commonShortcutListList: res.retValue.list, commonShortcutListTotal: res.retValue.total});
          }    
        }
      )
  }

  // 根据Id删除礼物
  deleteCommonShortcutList=(id) => {
    let { history } = this.props;
    let commonShortcutParam = {
      id : id
    };
    commonShortcutService.deleteCommonShortcut(commonShortcutParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("删除失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshCommonShortcutListData();
        message.success('删除成功！');
      }    
      
    })

  }


  // ———————————————————— 用户快捷语 ————————————————————————————

  // 关闭更新
  hideUpdateUserShortcutListDialog=() => {
    this.setState({showUpdateUserShortcutListDialog: false});
  }

  // 关闭添加
  hideAddUserShortcutListDialog=() => {
    this.setState({showAddUserShortcutListDialog: false});
  }


  // 获取列表
  handleGetUserShortcutListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var userShortcut={
        content: values.content,
        userid: values.userid,
        pageParam: pageParam
      }

      this.setState({
        userShortcutListPageParam: userShortcut,
      });

      userShortcutService.getUserShortcutPage(userShortcut)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userShortcutListList: res.retValue.list, userShortcutListTotal: res.retValue.total});
          }    
        }
      )
      
    })

  }


  /**
   * 分页查询
   * @param {*} page 
   * @param {*} pageSize 
   */
  userShortcutListChangePage=(page, pageSize)=> {
    let { history } = this.props;
    var pageParam = { 
      pageNum: page,
      pageSize: pageSize
    }

    var userShortcut={
      content: this.userShortcutListPageParam.content,
      type: this.userShortcutListPageParam.type,
      pageParam: pageParam,
    }
    
    this.setState({
      userShortcutListPageParam: userShortcut,
    });

    userShortcutService.getUserShortcutPage(userShortcut)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({userShortcutListList: res.retValue.list, userShortcutListTotal: res.retValue.total});
        }    
      }
    )
  }

  /**
   * 刷新
   */
  refreshUserShortcutListData=() => {
      let { history } = this.props;

      var userShortcutParam={
        pageParam: this.state.userShortcutListPageParam
      }
  
      userShortcutService.getUserShortcutPage(userShortcutParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userShortcutListList: res.retValue.list, userShortcutListTotal: res.retValue.total});
          }    
        }
      )
  }

  // 根据Id删除礼物
  deleteUserShortcutList=(id) => {
    let { history } = this.props;
    let userShortcutParam = {
      id : id
    };
    userShortcutService.deleteUserShortcut(userShortcutParam)
    .then(res => {
      if(res == null || res.retCode != 200) {
        if(res.retCode == 10001) {
          // 清除登录信息
          Logout();
          // 跳转到登录页面
          history.push('/login');
          return;
        }
        message.info("删除失败,"+res.message);  
      } else {
        // 刷新数据
        this.refreshUserShortcutListData();
        message.success('删除成功！');
      }    
      
    })

  }


  // ———————————————————— tab分页 ————————————————————————————

  

  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "1") {
      this.getCommonShortcut();
    } else if(key == "2") {
      this.getUserShortcut();
    } else if(key == "3") {

    }
  }

  /**
   * 获取列表
   */
  getCommonShortcut = () => {
    let { history } = this.props;

    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var commonShortcut={
      pageParam: pageParam
    }

    commonShortcutService.getCommonShortcutPage(commonShortcut)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({commonShortcutListList: res.retValue.list, commonShortcutListTotal: res.retValue.total});
        }    
      }
    )
  }


  /**
   * 获取列表
   */
    getUserShortcut = () => {
      let { history } = this.props;
  
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }
  
      var userShortcut={
        pageParam: pageParam
      }
  
      userShortcutService.getUserShortcutPage(userShortcut)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({userShortcutListList: res.retValue.list, userShortcutListTotal: res.retValue.total});
          }    
        }
      )
  
    }

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/shortcut">快捷语</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
          <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} onChange={this.onTabChanged}>


            // ———————————————————— 通用快捷语 ————————————————————————————
            <TabPane tab="通用快捷语" key="1">
              <Form
                name="CommonShortcutListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="内容" name="content">
                          <Input placeholder="内容" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="类型" name="type">
                          <Select style={{ width: 220 }} defaultValue="全部">
                              <Option >全部</Option>
                              <Option value={1}>男</Option>
                              <Option value={2}>女</Option>
                          </Select>
                      </Form.Item>
                    </Col>

                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetCommonShortcutListPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddCommonShortcutListDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.commonShortcutListList}
                columns={this.state.commonShortcutListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.commonShortcutListTotal, defaultCurrent: 1, onChange: this.commonShortcutListChangePage}}
              >
              </Table>
              <AddCommonShortcutList close={this.hideAddCommonShortcutListDialog} visible={this.state.showAddCommonShortcutListDialog} refreshData={this.refreshCommonShortcutListData}></AddCommonShortcutList>
              <UpdateCommonShortcutList rowData={this.state.updateCommonShortcutListRow} close={this.hideUpdateCommonShortcutListDialog} visible={this.state.showUpdateCommonShortcutListDialog} refreshData={this.refreshCommonShortcutListData}></UpdateCommonShortcutList>
              
            </TabPane>
            
            // ———————————————————— 用户快捷语 ——————————————————————
            <TabPane tab="用户快捷语" key="2">
    
              <Form
                name="UserShortcutListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
              >
                <Row gutter={24}>
                    <Col span={6} key={1}>
                      <Form.Item label="内容" name="content">
                          <Input placeholder="内容" />
                      </Form.Item>
                    </Col>
                    <Col span={6} key={2}>
                      <Form.Item label="用户id" name="userid">
                          <Input placeholder="用户id" />
                      </Form.Item>
                    </Col>

                    <Col span={5} key={3} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetUserShortcutListPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                      <Button type="primary" onClick={() => this.setState({showAddUserShortcutListDialog:true})}  >
                        + 新增
                      </Button>
                    </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.userShortcutListList}
                columns={this.state.userShortcutListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.userShortcutListTotal, defaultCurrent: 1, onChange: this.userShortcutListChangePage}}
              >
              </Table>
              <AddUserShortcutList close={this.hideAddUserShortcutListDialog} visible={this.state.showAddUserShortcutListDialog} refreshData={this.refreshUserShortcutListData}></AddUserShortcutList>
              <UpdateUserShortcutList rowData={this.state.updateUserShortcutListRow} close={this.hideUpdateUserShortcutListDialog} visible={this.state.showUpdateUserShortcutListDialog} refreshData={this.refreshUserShortcutListData}></UpdateUserShortcutList>

            </TabPane>

          


          </Tabs>
        </StickyContainer>
        
      </div>
    );
  }
}
export default Shortcut;