import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

    /**
   * 更新引流设置
   * @param {*} appFlowSettings
   * @returns
   */
    updateAppFlowSettings(appFlowSettings) {
        //console.log("updateAppFlowSettings appFlow=" + JSON.stringify(appFlow));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/appFlowSettings/updateAppFlowSettings",
            data: appFlowSettings,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },


    /**
   * 获取引流设置
   * @param {*} appFlowSettings
   * @returns
   */
    getAppFlowSettings(appFlowSettings) {
        //console.log("updateAppFlowSettings appFlow=" + JSON.stringify(appFlow));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/appFlowSettings/getAppFlowSettings",
            data: appFlowSettings,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

}