import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import { CSVDownload, CSVLink } from 'react-csv';
import moment from 'moment'
import finStatementAgentService from '../../../Service/FinanceMgr/finStatementAgentService';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class ShowFinancialStatement extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  formRef3 = React.createRef();
  state={
    // ———————————————————— 总报表 ————————————————————————————
    summaryStatementsColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'agentId',
      title: '代理ID',
      dataIndex: 'agentId'
    },{
      key: 'dayOfStatement',
      title: '统计日期',
      dataIndex: 'dayOfStatement',
      render: (dayOfStatement, row) => {
        if(dayOfStatement != null && dayOfStatement != "") {
          return moment(dayOfStatement).format('YYYY-MM-DD');
        }
      }
    },{
      key: 'popularizeUserNum',
      title: '推广用户数量',
      dataIndex: 'popularizeUserNum'
    },{
      key: 'popularizeAnchorNum',
      title: '推广主播数量',
      dataIndex: 'popularizeAnchorNum'
    },{
      key: 'totalAmount',
      title: '总收益佣金（元）',
      dataIndex: 'totalAmount'
    },{
      key: 'rechargeTotalMoney',
      title: '充值金额（元）',
      dataIndex: 'rechargeTotalMoney'
    },{
      key: 'rechargeCommission',
      title: '充值佣金（元）',
      dataIndex: 'rechargeCommission'
    },{
      key: 'nobleTotalMoney',
      title: '购买贵族金额（元）',
      dataIndex: 'nobleTotalMoney'
    },{
      key: 'nobleCommission',
      title: '购买贵族佣金（元）',
      dataIndex: 'nobleCommission'
    },{
      key: 'anchorIncomeCoin',
      title: '主播收益金币',
      dataIndex: 'anchorIncomeCoin'
    },{
      key: 'incomeCommission',
      title: '主播收益分佣（元）',
      dataIndex: 'incomeCommission'
    }],
    summaryStatementsTotal: 0,
    summaryStatementsList: null,
    summarySelectedRows:[],

    // ———————————————————— 下级报表 ————————————————————————————
    lowerLevelStatementsColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'agentId',
      title: '下级代理ID',
      dataIndex: 'agentId'
    },{
      key: 'dayOfStatement',
      title: '统计日期',
      dataIndex: 'dayOfStatement',
      render: (dayOfStatement, row) => {
        if(dayOfStatement != null && dayOfStatement != "") {
          return moment(dayOfStatement).format('YYYY-MM-DD');
        }
      }
    },{
      key: 'popularizeUserNum',
      title: '推广用户数量',
      dataIndex: 'popularizeUserNum'
    },{
      key: 'popularizeAnchorNum',
      title: '推广主播数量',
      dataIndex: 'popularizeAnchorNum'
    },{
      key: 'totalAmount',
      title: '总收益佣金（元）',
      dataIndex: 'totalAmount'
    },{
      key: 'rechargeTotalMoney',
      title: '充值金额（元）',
      dataIndex: 'rechargeTotalMoney'
    },{
      key: 'rechargeCommission',
      title: '充值佣金（元）',
      dataIndex: 'rechargeCommission'
    },{
      key: 'nobleTotalMoney',
      title: '购买贵族金额（元）',
      dataIndex: 'nobleTotalMoney'
    },{
      key: 'nobleCommission',
      title: '购买贵族佣金（元）',
      dataIndex: 'nobleCommission'
    },{
      key: 'anchorIncomeCoin',
      title: '主播收益金币',
      dataIndex: 'anchorIncomeCoin'
    },{
      key: 'incomeCommission',
      title: '主播收益分佣（元）',
      dataIndex: 'incomeCommission'
    }],
    lowerLevelStatementsTotal: 0,
    lowerLevelStatementsList: null,
    lowerLevelSelectedRows:[],
  }


  // ———————————————————— 公共方法 ————————————————————————————
    // 取消操作
    hanldeCloseModal = () => {
      this.setState({ summaryStatementsTotal: 0 });
      this.setState({ summaryStatementsList: null });
      this.setState({ lowerLevelStatementsTotal: 0 });
      this.setState({ lowerLevelStatementsList: null });
      this.props.close();
      // 重置表单
      this.formRef.current.resetFields();
    }

    /**
   * 本月第一天
   * @returns 
   */
    getMonthStartDay = () => {
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = '01'
      m = m < 10 ? '0' + m : m; //月份补 0
      
      return [y,m,d].join("-") + " 00:00:00";
    };
  
    /**
     * 获取本月最后一天
     * @returns 
     */
    getMonthLastDay = () => {
      var y = new Date().getFullYear(); //获取年份
      var m = new Date().getMonth() + 1; //获取月份
      var d = new Date(y, m, 0).getDate(); //获取当月最后一日
      m = m < 10 ? '0' + m : m; //月份补 0
      d = d < 10 ? '0' + d : d; //日数补 0
   
      return [y,m,d].join("-")+ " 23:59:59";
    };

  // ———————————————————— 总报表 ————————————————————————————

  // 获取动态列表
  handleGetSummaryStatementsPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finStatementParam = {
        agentId: this.props.rowData != null ? this.props.rowData.id : null,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam : pageParam,
        type : 1,
      }
      finStatementAgentService.getFinStatementAgentPageByAgent(finStatementParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({summaryStatementsList: res.retValue.list, summaryStatementsTotal: res.retValue.total});
          }
        }
      )

      // 导出数据准备
      if(timeStart == null || timeEnd == null) {
        var timeStart = this.getMonthStartDay();
        var timeEnd = this.getMonthLastDay();
      }
      var finStatementParam2 = {
        agentId: this.props.rowData != null ? this.props.rowData.id : null,
        startTime: timeStart ,
        endTime: timeEnd,
        type : 1,
      }
      finStatementAgentService.getFinStatementAgentListByAgent(finStatementParam2)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            // 导出数据处理
            this.summaryDataRowHandle(res.retValue);
          }
        }
      )
      
    })
  
  }

  // 分页
  summaryStatementsChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var timeStart = null;
        var timeEnd = null;
        if(values.sendTime != null) {
          timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
          timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
        }

        var finStatementParam = {
          agentId: this.props.rowData != null ? this.props.rowData.id : null,
          startTime: timeStart,
          endTime: timeEnd,
          pageParam : pageParam,
          type : 1,
        }
        finStatementAgentService.getFinStatementAgentPageByAgent(finStatementParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {

              this.setState({summaryStatementsList: res.retValue.list, summaryStatementsTotal: res.retValue.total});

            }
          }
        )
    })
  }

  
  /**
   * 导出数据处理
   * @param {*} contents 
   */
  summaryDataRowHandle = (contents) => {
      var rows = [];
      contents.forEach((item)=>{
        var data = {
          "统计日期": item.dayOfStatement,
          "代理ID": item.agentId,
          "推广用户数量": item.popularizeUserNum,
          "推广主播数量": item.popularizeAnchorNum,
          "总收益佣金（元）": item.totalAmount,
          "充值金额（元）": item.rechargeTotalMoney,
          "充值佣金（元）": item.rechargeCommission,
          "购买贵族金额（元）": item.nobleTotalMoney,
          "购买贵族佣金（元）": item.nobleCommission,
          "主播收益金币": item.anchorIncomeCoin,
          "主播收益分佣（元）": item.incomeCommission,
        }
        rows.push(data);
      });

      this.setState({summarySelectedRows: rows});
  }

  // ———————————————————— 下级报表 ————————————————————————————

  // 获取动态列表
  handleGetLowerLevelStatementsPage=() =>{
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var timeStart = null;
      var timeEnd = null;
      if(values.sendTime != null) {
        timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
        timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
      }

      var finStatementParam = {
        agentId: this.props.rowData != null ? this.props.rowData.id : null,
        startTime: timeStart,
        endTime: timeEnd,
        pageParam : pageParam,
        type : 2,
      }
      finStatementAgentService.getFinStatementAgentPageByAgent(finStatementParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({lowerLevelStatementsList: res.retValue.list, lowerLevelStatementsTotal: res.retValue.total});
          }
        }
      )

      // 导出数据准备
      if(timeStart == null || timeEnd == null) {
        var timeStart = this.getMonthStartDay();
        var timeEnd = this.getMonthLastDay();
      }
      var finStatementParam2 = {
        agentId: this.props.rowData != null ? this.props.rowData.id : null,
        startTime: timeStart ,
        endTime: timeEnd,
        type : 2,
      }
      finStatementAgentService.getFinStatementAgentListByAgent(finStatementParam2)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            // 导出数据处理
            this.lowerLevelDataRowHandle(res.retValue);
          }
        }
      )
      
    })
  
  }

  // 用户分页
  lowerLevelStatementsChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef3.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var timeStart = null;
        var timeEnd = null;
        if(values.sendTime != null) {
          timeStart = values.sendTime[0].format('YYYY-MM-DD') + " 00:00:00";
          timeEnd = values.sendTime[1].format('YYYY-MM-DD' + " 23:59:59");
        }

        var finStatementParam = {
          agentId: this.props.rowData != null ? this.props.rowData.id : null,
          startTime: timeStart,
          endTime: timeEnd,
          pageParam : pageParam,
          type : 2,
        }
        finStatementAgentService.getFinStatementAgentPageByAgent(finStatementParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({lowerLevelStatementsList: res.retValue.list, lowerLevelStatementsTotal: res.retValue.total});
            }
          }
        )
    })
  }

  /**
   * 导出数据处理
   * @param {*} contents 
   */
  lowerLevelDataRowHandle = (contents) => {
      var rows = [];
      contents.forEach((item)=>{
        var data = {
          "统计日期": item.dayOfStatement,
          "下级代理ID": item.agentId,
          "推广用户数量": item.popularizeUserNum,
          "推广主播数量": item.popularizeAnchorNum,
          "总收益佣金（元）": item.totalAmount,
          "充值金额（元）": item.rechargeTotalMoney,
          "充值佣金（元）": item.rechargeCommission,
          "购买贵族金额（元）": item.nobleTotalMoney,
          "购买贵族佣金（元）": item.nobleCommission,
          "主播收益金币": item.anchorIncomeCoin,
          "主播收益分佣（元）": item.incomeCommission,
        }
        rows.push(data);
      });

      this.setState({lowerLevelSelectedRows: rows});
  }


  // ———————————————————— TAB切换 ————————————————————————————


  onTabChanged = (key) => {
    //console.log("onTabChanged key="+key);
    if(key == "summary") {
      this.getSummaryStatementsPage();
    } else if(key == "lowerLevel") {
      this.getLowerLevelStatementsPage();
    } 
  }


  // 总报表
  getSummaryStatementsPage=() =>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var finStatementParam = {
      agentId: this.props.rowData != null ? this.props.rowData.id : null,
      type : 1,
      pageParam : pageParam,
    }
    finStatementAgentService.getFinStatementAgentPageByAgent(finStatementParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({summaryStatementsList: res.retValue.list, summaryStatementsTotal: res.retValue.total});
        }
      }
    )

    // 导出数据准备
    var timeStart = this.getMonthStartDay();
    var timeEnd = this.getMonthLastDay();

    var finStatementParam2 = {
      agentId: this.props.rowData != null ? this.props.rowData.id : null,
      startTime: timeStart,
      endTime: timeEnd,
      type : 1,
    }
    finStatementAgentService.getFinStatementAgentListByAgent(finStatementParam2)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 导出数据处理
          this.summaryDataRowHandle(res.retValue);
        }
      }
    )
  }


  // 下级报表
  getLowerLevelStatementsPage=() =>{
    let { history } = this.props;
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }
    var finStatementParam = {
      agentId: this.props.rowData != null ? this.props.rowData.id : null,
      type : 2,
      pageParam : pageParam,
    }
    finStatementAgentService.getFinStatementAgentPageByAgent(finStatementParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({lowerLevelStatementsList: res.retValue.list, lowerLevelStatementsTotal: res.retValue.total});
        }
      }
    )

    // 导出数据准备
    var timeStart = this.getMonthStartDay();
    var timeEnd = this.getMonthLastDay();

    var finStatementParam2 = {
      agentId: this.props.rowData != null ? this.props.rowData.id : null,
      startTime: timeStart,
      endTime: timeEnd,
      type : 2,
    }
    finStatementAgentService.getFinStatementAgentListByAgent(finStatementParam2)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          // 导出数据处理
          this.summaryDataRowHandle(res.retValue);
        }
      }
    )
  }

 
  render() {
    return (
      <Modal
        destroyOnClose
        visible={this.props.visible}
        width={1000}
        onCancel={this.hanldeCloseModal}
        footer={[
          null, null,
        ]}
      >
          <Tabs defaultActiveKey="today" renderTabBar={renderTabBar} onChange={this.onTabChanged}>
            
            // ———————————————————— 总报表 ————————————————————————————
            <TabPane tab="总报表" key="summary">
                <Form
                  name="summaryForm"
                  className="ant-advanced-search-form"
                  ref={this.formRef}
                  style={{ margin: '20px 0px 0px 0px'}}
                >
                  <Row gutter={24}>
                      <Col span={8} key={3}>
                        <Form.Item label="统计时间" name="sendTime">
                            <RangePicker />
                        </Form.Item>
                      </Col>
                      <Col span={6} key={4} style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={this.handleGetSummaryStatementsPage} style={{ marginRight: '15px' }}>
                          查询
                        </Button>
                        <CSVLink data={this.state.summarySelectedRows} >
                          导出报表
                        </CSVLink>
                        
                        
                      </Col>
                  </Row>
                </Form>

                <Table 
                  bordered
                  style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                  dataSource={this.state.summaryStatementsList == null && this.props.rowData != null ? this.props.rowData.summaryStatementsList : this.state.summaryStatementsList}
                  columns={this.state.summaryStatementsColumns}
                  rowKey="id"
                  scroll={{ x: 'max-content' }}
                  pagination = {{ total: this.state.summaryStatementsTotal == 0 && this.props.rowData != null ? this.props.rowData.summaryStatementsTotal : this.state.summaryStatementsTotal,
                    defaultCurrent: 1, onChange: this.summaryStatementsChangePage}}
                >
                </Table>
              </TabPane>

              // ———————————————————— 下级报表 ————————————————————————————
              <TabPane tab="下级报表" key="lowerLevel">
                  <Form
                    name="lowerLevelForm"
                    className="ant-advanced-search-form"
                    ref={this.formRef3}
                    style={{ margin: '20px 0px 0px 0px'}}
                  >
                    <Row gutter={24}>
                        <Col span={8} key={3}>
                          <Form.Item label="统计时间" name="sendTime">
                              <RangePicker />
                          </Form.Item>
                        </Col>
                        <Col span={6} key={4} style={{ textAlign: 'right' }}>
                          <Button type="primary" onClick={this.handleGetLowerLevelStatementsPage} style={{ marginRight: '15px' }}>
                            查询
                          </Button>
                          <CSVLink data={this.state.lowerLevelSelectedRows} >
                            导出报表
                          </CSVLink>
                          
                          
                        </Col>
                    </Row>
                  </Form>

                  <Table 
                    bordered
                    style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                    dataSource={this.state.lowerLevelStatementsList}
                    columns={this.state.lowerLevelStatementsColumns}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    pagination = {{ total: this.state.lowerLevelStatementsTotal, defaultCurrent: 1, onChange: this.lowerLevelStatementsChangePage}}
                  >
                  </Table>
                </TabPane>

          </Tabs>
        
      </Modal>
    );
  }
}
export default ShowFinancialStatement;