import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import appGuardUsersService from '../../../Service/UserMgr/appGuardUsersService';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class GuardList extends Component {
  formRef = React.createRef();
  formRef2 = React.createRef();
  state={
    // ———————————————————— 守护列表 ————————————————————————————
    guardListColumns: [{
      key: 'id',
      title: 'ID',
      dataIndex: 'id'
    },{
      key: 'avatar',
      title: '用户信息',
      dataIndex: 'avatar',
      align: 'center',
      render: (avatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.uid}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={avatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.username}</div>
            <div style={{ marginTop: '3px'}}>{row.mobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'lastPayTime',
      title: '最后续费时间',
      dataIndex: 'lastPayTime'
    },{
      key: 'endtime',
      title: '守护结束时间',
      dataIndex: 'endtime',
    },{
      key: 'toAvatar',
      title: '被守护用户信息',
      dataIndex: 'toAvatar',
      align: 'center',
      render: (toAvatar, row) => {
        var info = (
          <>
            <div style={{ float: 'left', marginTop: '18px', marginRight: '5px'}}>
            <div>{row.anchorId}</div>
            </div>
            <div style={{ float: 'left', marginRight: '5px'}}>
            <Image width={55} height={55} src={toAvatar} />
            </div>
            <div style={{ float: 'left' }}>
            <div style={{ marginTop: '3px'}}>{row.toUsername}</div>
            <div style={{ marginTop: '3px'}}>{row.toMobile}</div>
            </div>
          </>
        );
        return info;
      }
    },{
      key: 'addtime',
      title: '创建时间',
      dataIndex: 'addtime',
    }],
    guardListTotal: 0,
    guardListList: null,
    guardListPageParam: {
      pageParam : {
        pageNum: 1,
        pageSize: 10
      }
    }
    
  }

  // 第一次渲染后调用
  componentDidMount() {
    let { history } = this.props;
    // ———————————————————— 守护列表 ————————————————————————————
    var pageParam = { 
      pageNum: 1,
      pageSize: 10
    }

    var guardListParam = {
      pageParam : pageParam
    }

    this.setState({
      guardListPageParam: guardListParam,
    });

    appGuardUsersService.getGuardUsersPage(guardListParam)
    .then(
      res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          this.setState({guardListList: res.retValue.list, guardListTotal: res.retValue.total});
        }                            
      }
    )

  }
  
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // ———————————————————— 守护列表 ————————————————————————————


  handleGetGuardListPage=() =>{
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
      //console.log(JSON.stringify(values));
      var pageParam = { 
        pageNum: 1,
        pageSize: 10
      }

      var guardListParam = {
        userId : values.userId,
        anchorId : values.anchorId,
        pageParam : pageParam,
      }

      this.setState({
        guardListPageParam: guardListParam,
      });
      
      appGuardUsersService.getGuardUsersPage(guardListParam)
      .then(
        res => {
          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("查询失败,"+res.message);  
          } else {
            this.setState({guardListList: res.retValue.list, guardListTotal: res.retValue.total});
          }                            
        }
      )
      
    })
  }



  guardListChangePage=(page, pageSize) => {
    //console.log("changePage 进入");
    let { history } = this.props;
    this.formRef.current.validateFields()
    .then(values => {
        var pageParam = {
          pageNum: page,
          pageSize: pageSize,
        }

        var guardListParam = {
          userId : values.userId,
          anchorId : values.anchorId,
          pageParam : pageParam,
        }

        this.setState({
          guardListPageParam: guardListParam,
        });

        appGuardUsersService.getGuardUsersPage(guardListParam)
        .then(
          res => {
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("查询失败,"+res.message);  
            } else {
              this.setState({guardListList: res.retValue.list, guardListTotal: res.retValue.total});
            }                            
          }
        )
    })
  }


 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/user_reports">守护列表</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>
            
              <Form
                name="guardListForm"
                className="ant-advanced-search-form"
                ref={this.formRef}
                style={{ margin: '20px 0px 0px 100px'}}
              >
                <Row gutter={24}>
                    <Col span={5} key={1}>
                      <Form.Item label="用户UID" name="userId">
                          <Input placeholder="UID" />
                      </Form.Item>
                    </Col>
                    <Col span={5} key={2}>
                      <Form.Item label="主播UID" name="anchorId">
                          <Input placeholder="主播UID" />
                      </Form.Item>
                    </Col>
                    <Col span={5} key={4} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={this.handleGetGuardListPage} style={{ marginRight: '15px' }}>
                        查询
                      </Button>
                  </Col>
                </Row>
              </Form>

              <Table 
                bordered
                style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
                dataSource={this.state.guardListList}
                columns={this.state.guardListColumns}
                rowKey="id"
                scroll={{ x: 'max-content' }}
                pagination = {{ total: this.state.guardListTotal, defaultCurrent: 1, onChange: this.guardListChangePage}}
              >
              </Table>

           
        </StickyContainer>
        
      </div>
    );
  }
}
export default GuardList;