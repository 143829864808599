import Password from "antd/lib/input/Password";
import axios from "axios";
import Qs from "qs";
import MD5 from "crypto-js/md5";
import { GetLoginToken } from "../../Common/Auth.js";

export default {
  /**
   * 分页查询用户
   * @param {*} key
   * @param {*} pageParam
   * @returns
   */
  getUserPage(key, pageParam) {
    let data = {
      key: key,
      pageParam: pageParam,
    };
    //console.log("getUserPage=" + JSON.stringify(data));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/getUserPage",
      data: data,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入更新用户
   * @param {*} userParam
   * @returns
   */
  insertOrUpdateUser(userParam) {
    console.log("insertOrUpdateUser userParam=" + JSON.stringify(userParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/insertOrUpdateUser",
      data: userParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 根据id删除用户
   * @param {*} idList
   * @returns
   */
  deleteUserByIdList(idList) {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/deleteUserByIdList",
      data: idList,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 更新用户密码
   * @param {*} userParam
   * @returns
   */
  updatePasswordById(userParam) {
    console.log("userParam=" + JSON.stringify(userParam));
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/updatePasswordById",
      data: userParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 分页查询代理用户
   * @param {*} key
   * @param {*} pageParam
   * @returns
   */
  getAgentPage(searchParam) {
    //console.log("getUserPage=" + JSON.stringify(data));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/getAgentPage",
      data: searchParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 查询代理信息
   * @param {*} key
   * @param {*} pageParam
   * @returns
   */
  getAgentInfo(searchParam) {
    //console.log("getUserPage=" + JSON.stringify(data));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/getAgentInfo",
      data: searchParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 查询代理佣金余额
   * @returns
   */
  getAgentMoney() {
    //console.log("getUserPage=" + JSON.stringify(data));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/getAgentMoney",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 分页获取二级代理列表
   * @param {*} key
   * @param {*} pageParam
   * @returns
   */
  getSecondAgentPage(searchParam) {
    //console.log("getUserPage=" + JSON.stringify(data));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/getSecondAgentPage",
      data: searchParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入更新二级代理
   * @param {*} userParam
   * @returns
   */
  insertOrUpdateSecondAgent(userParam) {
    //console.log("getUserPage=" + JSON.stringify(data));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/insertOrUpdateSecondAgent",
      data: userParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入更新二级代理
   * @param {*} userParam
   * @returns
   */
  deleteSecondAgentById(userParam) {
    //console.log("getUserPage=" + JSON.stringify(data));

    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/user/deleteSecondAgentById",
      data: userParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },


    /**
   * 分页查询二级代理用户
   * @param {*} searchParam
   * @returns
   */
    getSecondAgentPageByAdmin(searchParam) {
      //console.log("getUserPage=" + JSON.stringify(data));
  
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/user/getSecondAgentPageByAdmin",
        data: searchParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },
};
