import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 分页获取勋章
   * @param {*} medalParam
   * @returns
   */
   getMedalPage(medalParam) {
      //console.log("medalParam=" + JSON.stringify(medalParam));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/medalAdmin/getMedalPage",
        data: medalParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入或更新勋章
   * @param {*} appMedal
   * @returns
   */
   insertOrUpdateMedal(appMedal) {
        //console.log("appMedal=" + JSON.stringify(appMedal));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
        method: "post",
        url: "/admin/medalAdmin/insertOrUpdateMedal",
        data: appMedal,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },

}