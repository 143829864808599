import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {
    /**
     * 获取用户审核分页
     * @param {*} userAuthParam 
     * @returns 
     */
     getUserAuthPage(userAuthParam) {
        //console.log("userAuthParam=" + JSON.stringify(userAuthParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/userAuth/getUserAuthPage",
            data: userAuthParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
     * 获取用户审核详情
     * @param {*} userAuthParam 
     * @returns 
     */
    getUserAuthDetail(userAuthParam) {
        //console.log("userAuthParam=" + JSON.stringify(userAuthParam));
        return axios({
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/userAuth/getUserAuthDetail",
            data: userAuthParam,
            }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
    },

    /**
     * 接口处理
     * @param {*} data
     * @returns
     */
     updateUserAuth(userAuthParam) {
        //console.log("updateUserAuth userAuthParam=" + JSON.stringify(userAuthParam));
        return axios({
            headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
            },
            method: "post",
            url: "/admin/userAuth/updateUserAuth",
            data: userAuthParam,
        }).then((res) => {
            //console.log("retValue=" + JSON.stringify(res));
            return res.data;
        });
        },
}