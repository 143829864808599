import React, { Component } from 'react'
import { Modal, Form, Input, Upload, Button, message, Select,Radio,Popconfirm ,Table,Avatar, Image} from 'antd';
import { LoadingOutlined,PlusOutlined, UploadOutlined } from '@ant-design/icons';
import Service from '../../../Service/index';
import giftPackService from "../../../Service/Operation/giftPackService";
import { Logout } from '../../../Common/Auth';


const { Option } = Select;
const { TextArea } = Input;

class ShowGiftPack extends Component {
  formRef = React.createRef();

  state={
    // ———————————————————— 礼包内容 ————————————————————————————
    giftPackColumns: [{
        key: 'id',
        title: 'id',
        dataIndex: 'id'
    },{
      key: 'action',
      title: '奖励类别',
      dataIndex: 'action',
      render: (action, row) => {
        if(action === 'coin') {
          return "金币"
        } else if(action === 'gift' ) {
          return "礼物";
        } else if(action === 'car') {
          return "坐骑";
        } else if(action === 'video') {
          return "短视频";
        } else if(action === 'noble') {
          return "贵族";
        }
      }
    },{
      key: 'name',
      title: '奖励项目',
      dataIndex: 'name',
    },{
      key: 'type',
      title: '礼物id',
      dataIndex: 'type'
    },{
      key: 'typeVal',
      title: '奖励数量',
      dataIndex: 'typeVal'
    },{
      key: 'del',
      title: '编辑',
      dataIndex: 'del',
      render: (del, row) => {
          return (
            <div>
              <Popconfirm
                onConfirm={() => {
                  //message.info(row.id);
                  this.deleteGiftPack(row.id);
                }}
                onText="确认"
                cancelText="取消"
                title="您确认要删除吗？"
              >
                  <Button type="danger" style={{marginRight:'5px'}} >删除</Button>
              </Popconfirm>
            </div>
          )        
      }
    }],
    giftPackTotal: 0,
    giftPackList: null,
  }

  // 取消操作
  hanldeCloseModal = () => {
    this.props.close();
  }

  // 记录分页
  giftPackChangePage=(page, pageSize) => {
      this.props.refreshGiftPackData(page, pageSize, this.props.rowData.rechargeId);
  }

  // 根据Id删除评论
  deleteGiftPack=(id) => {
      let { history } = this.props;
      let adminGiftPackParam = {
        id : id
      };
      giftPackService.deleteGiftPack(adminGiftPackParam)
      .then(res => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          console.log("删除失败")
          message.info("删除失败,"+res.message);  
          // 重置关闭对话框
          this.hanldeCloseModal();
        } else {
          // 刷新数据
          this.props.refreshGiftPackData(1, 10, this.props.rowData.rechargeId);
          message.success('删除成功！');
        }
        
        
      })

  }


  render () {
    return (
      <Modal
        title="消息记录"
        destroyOnClose
        onCancel={this.hanldeCloseModal}
        visible={this.props.visible}
        width={1000}
        footer={[
          null, null,
        ]}
      >
        <Table 
          bordered
          style={{backgroundColor: '#FEFEFE', marginTop: '10px'}}
          dataSource={this.props.rowData != null ? this.props.rowData.giftPackList : null}
          columns={this.state.giftPackColumns}
          rowKey="id"
          scroll={{ x: 'max-content' }}
          pagination = {{ total: this.props.rowData != null ? this.props.rowData.giftPackTotal : null, defaultCurrent: 1, onChange: this.giftPackChangePage}}
        >
        </Table>

      </Modal>
    )
  }
}

export default ShowGiftPack