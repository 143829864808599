import React, {Component, PropTypes} from 'react';
import { Breadcrumb, Table, Button, message, Modal,
   Avatar, Popconfirm, Input, Row, Col, DatePicker,
  Tabs, Form, Upload, Select, Checkbox, Radio, Image} from 'antd';
import { Link } from 'react-router-dom';
import { StickyContainer, Sticky } from 'react-sticky';
import { GetLoginUserInfo, Logout } from '../../../Common/Auth';
import moment from 'moment'
import systemAdminService from '../../../Service/SystemInit/systemAdminService';
import ShowData from './ShowData';


const { TextArea } = Input;
const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

const renderTabBar = (props, DefaultTabBar) => (
  <Sticky bottomOffset={80}>
    {({ style }) => (
      <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style }} />
    )}
  </Sticky>
);


class DBMgr extends Component {
  formRef = React.createRef();
  state={
    showRow: null,
    showDialog: false,

  }


  // 第一次渲染后调用
  componentDidMount() {

  }
  
  // 组件销毁，用完后执行
  componentWillUnmount() {
    // 释放数据订阅
    this.state.unsubscribe && (this.state.unsubscribe());
  }

  // 删除基础数据库
  delBasData=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要删除吗？',
      okText: '删除',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.delBasData(0)
        .then(res => {
          //console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("删除失败,"+res.message);  
          } else {
            message.success('删除成功！');
          }
          
        })
      }
    });
  }

  /**
   * 创建数据库json
   */
  createDBJson=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要创建吗？',
      okText: '创建',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.createDBJson(0)
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("创建失败,"+res.message);  
          } else {
            message.success('创建成功！');
          }
          
        })
      }
    });
  }

  // 隐藏
  hideShowDialog=() => {
    this.setState({showDialog: false});
  }

  // 获取列表
  getTaskInfo=() =>{
      let { history } = this.props;

      systemAdminService.getTaskInfo()
      .then((res) => {
        if(res == null || res.retCode != 200) {
          if(res.retCode == 10001) {
            // 清除登录信息
            Logout();
            // 跳转到登录页面
            history.push('/login');
            return;
          }
          message.info("查询失败,"+res.message);  
        } else {
          var data = {
            content : res.retValue
          }
          this.setState({showDialog:true, showRow: data })
        }                  
        
      });
      
  }

  /**
   * JSON数据上传到数据库
   */
  jsonImportDb=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要创建吗？',
      okText: '上传',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.jsonImportDb(0)
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("上传失败,"+res.message);  
          } else {
            message.success('上传成功！');
          }
          
        })
      }
    });
  }



  /**
   * 下载图片
   */
  downloadPicture=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要下载吗？',
      okText: '下载',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.downloadImg(0)
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("创建失败,"+res.message);  
          } else {
            message.success('创建成功！');
          }
          
        })
      }
    });
  }


  /**
   * 上传图片
   */
  uploadPicture=()=> {
    let { history } = this.props;
    // 确认是否需要删除
    Modal.confirm({
      title: '您确认要上传吗？',
      okText: '上传',
      cancelText: '取消',
      onOk: () => {
        systemAdminService.uploadImg(0)
        .then(res => {
          console.log("res=" + JSON.stringify(res));

          if(res == null || res.retCode != 200) {
            if(res.retCode == 10001) {
              // 清除登录信息
              Logout();
              // 跳转到登录页面
              history.push('/login');
              return;
            }
            message.info("创建失败,"+res.message);  
          } else {
            message.success('创建成功！');
          }
          
        })
      }
    });
  }



  /**
   * 上传IM用户
   */
  onMultiAccountImport=()=> {
      let { history } = this.props;
      // 确认是否需要删除
      Modal.confirm({
        title: '您确认要创建吗？',
        okText: '上传',
        cancelText: '取消',
        onOk: () => {
          systemAdminService.onMultiAccountImport()
          .then(res => {
            //console.log("res=" + JSON.stringify(res));
  
            if(res == null || res.retCode != 200) {
              if(res.retCode == 10001) {
                // 清除登录信息
                Logout();
                // 跳转到登录页面
                history.push('/login');
                return;
              }
              message.info("上传失败,"+res.message);  
            } else {
              message.success('上传成功！');
            }
            
          })
        }
      });
    }

 
  render() {
    return (
      <div className="admin-sitemgr" style={{
        height: document.body.clientHeight > 800 ? document.body.clientHeight : 800 ,
        width: document.body.clientWidth > 800 ? document.body.clientWidth : 800,
        overflow: 'auto' }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/home">首页</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/db_mgr">数据库管理</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <hr/>
        
       
        <StickyContainer>   
            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.getTaskInfo } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">查询任务进度</Button></span>
              {/* <span onClick={ this.onMultiAccountImport } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">上传IM用户</Button></span> */}

            </div>

            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.createDBJson } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="primary">创建数据库json</Button></span>
              <span onClick={ this.jsonImportDb } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">JSON数据上传到数据库</Button></span>
            </div>
                
            <div style={{ margin: '0px 0px 20px 200px', padding: '20px'}}>
              <span onClick={ this.delBasData } style={{ margin: '10px', padding: '10px'}}><Button style={this.buttonStyle} type="danger">删除数据库数据</Button></span>
            </div>

            <ShowData rowData={this.state.showRow} close={this.hideShowDialog} visible={this.state.showDialog} ></ShowData>

        </StickyContainer>
        
      </div>
    );
  }
}
export default DBMgr;