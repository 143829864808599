import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';


export default {

  /**
   * 分页获取充值规则
   * @param {*} 
   * @returns
   */
   getChargeRulesPage(chargeRulesParam) {
    //console.log("commonWordsParam=" + JSON.stringify());
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        authToken: GetLoginToken(),
      },
      method: "post",
      url: "/admin/chargeRules/getChargeRulesPage",
      data: chargeRulesParam,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },

  /**
   * 插入更新充值规则
   * @param {*} 
   * @returns
   */
   insertOrUpdateChargeRules(appChargeRules) {
      //console.log("commonWordsParam=" + JSON.stringify());
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/chargeRules/insertOrUpdateChargeRules",
        data: appChargeRules,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
  },

  /**
   * 删除充值规则
   * @param {*} 
   * @returns
   */
  deleteChargeRules(chargeRulesParam) {
      //console.log("commonWordsParam=" + JSON.stringify());
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/chargeRules/deleteChargeRules",
        data: chargeRulesParam,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
  },
}