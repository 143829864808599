import axios from "axios";
import Qs from "qs";
import {GetLoginToken} from '../../Common/Auth.js';

export default {

  /**
   * 获取文本描述
   * @param {*} textDescription
   * @returns
   */
   getText(textDescription) {
      //console.log("textDescription=" + JSON.stringify(textDescription));
      return axios({
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/textDescription/getText",
        data: textDescription,
      }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
      });
    },


  /**
   * 插入更新文本描述
   * @param {*} textDescription
   * @returns
   */
   insertOrUpdateText(textDescription) {
        //console.log("textDescription=" + JSON.stringify(textDescription));
        return axios({
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            authToken: GetLoginToken(),
        },
        method: "post",
        url: "/admin/textDescription/insertOrUpdateText",
        data: textDescription,
        }).then((res) => {
        //console.log("retValue=" + JSON.stringify(res));
        return res.data;
        });
    },


}